import pureAxios from 'axios';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { isArray, isEmpty, isNull, isUndefined, omit } from 'lodash';
import moment from 'moment';
import type { Device, DeviceOverviewProps } from 'src/types/device';
import axios from 'src/utils/axios';
import getExcludedErrors from 'src/utils/getExcludedErrors';
import getId from 'src/utils/getId';
import getMbusRegisterUnit from 'src/utils/getMbusRegisterUnit';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

const partnerGlobalConfigLocal: any = JSON.parse(window.localStorage.getItem('partner_settings'));
let shouldDisplayDistributorArticleAndHiu = false;
if (!isNull(partnerGlobalConfigLocal) && !isUndefined(partnerGlobalConfigLocal)) {
  shouldDisplayDistributorArticleAndHiu = partnerGlobalConfigLocal?.should_display_distributor_article_and_hiu;
}

class DeviceApi {
  async create(device: Device): Promise<Device> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete device[prop];
      // Create Device
      axios.post(`${process.env.REACT_APP_DEVICES_URL}`, device).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async edit(device: Device): Promise<Device> {
    return new Promise((resolve, reject) => {
      const deviceId = device?.device_id;
      const props = ['submit', 'client', 'preset', 'project', 'device_id'];
      omit(device, props);

      // Update Device
      axios.put(`${process.env.REACT_APP_DEVICES_URL}/${deviceId}`, device).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async editSuperAdmin(device: Device): Promise<Device> {
    return new Promise((resolve, reject) => {
      const deviceId = device?.device_id;
      const props = ['submit', 'client', 'preset', 'project', 'device_id'];
      omit(device, props);

      // Update Device
      axios.put(`${process.env.REACT_APP_DEVICES_URL}/editDevice/${deviceId}`, device).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getAll(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean, advancedFilter: any, diagonsticFilter: any, isMbusConnected: any, connectedMbusMeters: any): Promise<DeviceOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All
      let sortList = {};
      if (sortColumn.device_name) {
        sortList = {
          'device.device_name': sortDirection ? 'ASC' : 'DESC',
          'device.serial_number': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'device.firmware': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          'device.street_number': sortDirection ? 'ASC' : 'DESC',
          'device.house_number': sortDirection ? 'ASC' : 'DESC',
          'device.neighbourhood': sortDirection ? 'ASC' : 'DESC',
          'device.city': sortDirection ? 'ASC' : 'DESC',
          'device.country': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.description) {
        sortList = {
          'device.description': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.project) {
        sortList = {
          'project.project_id': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.activated) {
        sortList = {
          'device.activated': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'device.firmware': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.device_type) {
        sortList = {
          'device.device_type': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.updated_at) {
        sortList = {
          'deviceRegister.updated_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.mbus_meter_count) {
        sortList = {
          mbus_meter_count: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      if (!isUndefined(isMbusConnected) && !isEmpty(isMbusConnected) && isMbusConnected !== '-1') {
        data.is_mbus_connected = isMbusConnected !== '0';
      }
      if (!isUndefined(connectedMbusMeters) && !isEmpty(connectedMbusMeters) && connectedMbusMeters !== '-1') {
        data.mbus_meter_count = parseInt(connectedMbusMeters, 10);
      }

      if (advancedFilter?.length > 0) {
        data.AdvanceFilter = {};
        const res = advancedFilter?.map((item) => {
          if (item?.value?.length > 0 || item?.value > 0) {
            if (item?.column === 'device.production_date') {
              data.AdvanceFilter[item?.column] = [moment(item?.value)?.format('YYYY-MM-DD')];
            } else {
              data.AdvanceFilter[item?.column] = [item?.value];
            }
          }
          return item;
        });
        logger(res);
      }
      data.diagnosticFilters = diagonsticFilter;
      if (!isEmpty(diagonsticFilter)) {
        if (diagonsticFilter?.length <= 1) {
          if (diagonsticFilter[0]?.reg_name?.length === 0 && diagonsticFilter[0]?.value?.length === 0) {
            const prop = 'diagnosticFilters';
            delete data[prop];
          }
        }
      }

      if (isEmpty(diagonsticFilter)) {
        const prop = 'diagnosticFilters';
        delete data[prop];
      }

      if (!isUndefined(currentTab)) {
        const tab = +currentTab;
        if (tab !== -1) {
          if (tab === 0) {
            data.is_device_online = false;
          }
          if (tab === 1) {
            // data.online = true;
            data.is_device_online = true;
          }
          if (tab === 2) {
            // filter.activated = tab;
            data.is_in_error_mode = true;
          }
          if (tab === 3) {
            // filter.unprovisioned = true;
            data.is_device_unprovisioned = true;
          }
        }
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDevices`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = !isUndefined(response?.data?.totalCount) ? response?.data : response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllDevicesFiltered(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean, advancedFilter: any, diagonsticFilter: any): Promise<DeviceOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All
      let sortList = {};
      if (sortColumn.device_name) {
        sortList = {
          'device.device_name': sortDirection ? 'ASC' : 'DESC',
          'device.serial_number': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'device.firmware': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          'device.street_number': sortDirection ? 'ASC' : 'DESC',
          'device.house_number': sortDirection ? 'ASC' : 'DESC',
          'device.neighbourhood': sortDirection ? 'ASC' : 'DESC',
          'device.city': sortDirection ? 'ASC' : 'DESC',
          'device.country': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.description) {
        sortList = {
          'device.description': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.project) {
        sortList = {
          'project.project_id': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.activated) {
        sortList = {
          'device.activated': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'device.firmware': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.device_type) {
        sortList = {
          'device.device_type': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.updated_at) {
        sortList = {
          'deviceRegister.updated_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      if (advancedFilter?.length > 0) {
        data.AdvanceFilter = {};
        const res = advancedFilter?.map((item) => {
          if (item?.value?.length > 0 || item?.value > 0) {
            if (item?.column === 'device.production_date') {
              data.AdvanceFilter[item?.column] = [moment(item?.value)?.format('YYYY-MM-DD')];
            } else {
              data.AdvanceFilter[item?.column] = [item?.value];
            }
          }
          return item;
        });
        logger(res);
      }

      data.diagnosticFilters = diagonsticFilter;
      if (!isEmpty(diagonsticFilter)) {
        if (diagonsticFilter?.length === 1) {
          if (diagonsticFilter[0]?.reg_name?.length === 0 && diagonsticFilter[0]?.value?.length === 0) {
            const prop = 'diagnosticFilters';
            delete data[prop];
          }
        }
      }

      if (!isUndefined(currentTab)) {
        const tab = +currentTab;
        if (tab !== -1) {
          if (tab === 0) {
            data.is_device_online = false;
          }
          if (tab === 1) {
            data.online = true;
          }
          if (tab === 2) {
            filter.activated = tab;
            data.is_device_online = true;
          }
          if (tab === 3) {
            filter.unprovisioned = true;
          }
        }
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDevicesFiltered`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = !isUndefined(response?.data?.totalCount) ? response?.data : response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllDevicesByProjectId(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean, advancedFilter: any, diagonsticFilter: any, isMbusConnected: any, connectedMbusMeters: any): Promise<DeviceOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All Devices By Project Id
      let sortList = {};
      if (sortColumn.device_name) {
        sortList = {
          'device.device_name': sortDirection ? 'ASC' : 'DESC',
          'device.serial_number': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'device.firmware': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          'device.street_number': sortDirection ? 'ASC' : 'DESC',
          'device.house_number': sortDirection ? 'ASC' : 'DESC',
          'device.neighbourhood': sortDirection ? 'ASC' : 'DESC',
          'device.city': sortDirection ? 'ASC' : 'DESC',
          'device.country': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.description) {
        sortList = {
          'device.description': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.project) {
        sortList = {
          'project.project_id': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.activated) {
        sortList = {
          'device.activated': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'device.firmware': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.device_type) {
        sortList = {
          'device.device_type': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.updated_at) {
        sortList = {
          'deviceRegister.updated_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.mbus_meter_count) {
        sortList = {
          mbus_meter_count: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }
      if (search?.length === 0) {
        const key = 'search';
        delete data[key];
      }

      if (!isUndefined(currentTab)) {
        const tab = +currentTab;
        if (tab !== -1) {
          if (tab === 0) {
            data.is_device_online = false;
          }
          if (tab === 1) {
            // data.online = true;
            data.is_device_online = true;
          }
          if (tab === 2) {
            // filter.activated = tab;
            data.is_in_error_mode = true;
          }
          if (tab === 3) {
            // filter.unprovisioned = true;
            data.is_device_unprovisioned = true;
          }
        }
      }

      if (!isUndefined(isMbusConnected) && !isEmpty(isMbusConnected) && isMbusConnected !== '-1') {
        data.is_mbus_connected = isMbusConnected !== '0';
      }
      if (!isUndefined(connectedMbusMeters) && !isEmpty(connectedMbusMeters) && connectedMbusMeters !== '-1') {
        data.mbus_meter_count = parseInt(connectedMbusMeters, 10);
      }

      if (advancedFilter?.length > 0) {
        data.AdvanceFilter = {};
        const res = advancedFilter?.map((item) => {
          if (item?.value?.length > 0 || item?.value > 0) {
            if (item?.column === 'device.production_date') {
              data.AdvanceFilter[item?.column] = [moment(item?.value)?.format('YYYY-MM-DD')];
            } else {
              data.AdvanceFilter[item?.column] = [item?.value];
            }
          }
          return item;
        });
        logger(res);
      }

      data.diagnosticFilters = diagonsticFilter;
      if (!isEmpty(diagonsticFilter)) {
        if (diagonsticFilter?.length === 1) {
          if (diagonsticFilter[0]?.reg_name?.length === 0 && diagonsticFilter[0]?.value?.length === 0) {
            const prop = 'diagnosticFilters';
            delete data[prop];
          }
        }
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDevicesByProjectId/${getId()}`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = !isUndefined(response?.data?.totalCount) ? response?.data : response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async downloadDeviceTableByProjectId(t: any, search: string = '', currentTab: any, selectedDevices: any): Promise<DeviceOverviewProps> {
    // Download Project Devices Table
    const tabs: any = {};
    let filters: any = {
      Filter: {
      },
    };

    if (search?.length > 0) {
      filters = {
        Filter: {},
        search
      };
    } else {
      filters = {
        Filter: {},
      };
    }

    if (!isUndefined(currentTab)) {
      const tab = +currentTab;
      if (tab !== -1) {
        if (tab === 0) {
          tabs.is_device_online = false;
        }
        if (tab === 1) {
          // tabs.online = true;
          tabs.is_device_online = true;
        }
        if (tab === 2) {
          // filter.activated = tab;
          tabs.is_in_error_mode = true;
        }
        if (tab === 3) {
          // filter.unprovisioned = true;
          tabs.is_device_unprovisioned = true;
        }
      }
    }

    if (selectedDevices?.length > 0) {
      filters.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      // Download Device Table
      let fieldList: any = {
        device_id: t('devices_device_id'),
        devices_active: t('devices_active'),
        devices_inactive: t('devices_inactive'),
        devices_unprovisioned: t('devices_unprovisioned'),
        devices_error: t('devices_error'),
        device_status: t('devices_status'),
        device_name: t('devices_form_name_field'),
        device_type: t('devices_form_type_field'),
        ip_address: t('devices_form_ip_address_field'),
        firmware: t('devices_form_firmware_field'),
        preset_id: t('devices_form_preset_field'),
        project_id: t('devices_form_project_field'),
        client_id: t('devices_form_client_field'),
        description: t('devices_form_description_field'),
        batch_number: t('devices_form_batch_number_field'),
        serial_number_mercurius: t('devices_form_serial_number_mercurius_field'),
        box_serial_number: t('devices_form_box_serial_number_field'),
        street_number: t('devices_form_street_number_field'),
        house_number: t('devices_form_house_number_field'),
        postal_code: t('devices_form_postal_code_field'),
        neighbourhood: t('devices_form_neighbourhood_field'),
        floor: t('devices_form_floor_number_field'),
        construction_number: t('devices_form_building_number_field'),
        city: t('devices_form_city_field'),
        country: t('devices_form_country_field'),
        // sn_heat_unit: t('devices_form_sn_heat_unit_field'),
        article_number: t('devices_form_article_number_field'),
        prod_order_nr_week: t('devices_form_prod_order_nr_week_field'),
        sim_card_number: t('devices_form_sim_card_number_field'),
        customer_ref: t('devices_form_customer_ref_field'),
        production_date: t('devices_form_production_date_field'),
        order_number: t('devices_form_order_number_field'),
        product_number: t('devices_form_product_number_field'),
        model_number: t('devices_form_model_number_field'),
        note: t('devices_form_note_field'),
        order_type: t('devices_form_order_type_field'),
        rssi: `${t('device_single_rss_signal_strength')} (%)`,
        mbus_meter_count: `${t('devices_mbus_meter_count')}`,
        updated_at: t('devices_last_viewed'),
      };

      if (shouldDisplayDistributorArticleAndHiu) {
        fieldList = {
          device_id: t('devices_device_id'),
          devices_active: t('devices_active'),
          devices_inactive: t('devices_inactive'),
          devices_unprovisioned: t('devices_unprovisioned'),
          devices_error: t('devices_error'),
          device_status: t('devices_status'),
          device_name: t('devices_form_name_field'),
          device_type: t('devices_form_type_field'),
          ip_address: t('devices_form_ip_address_field'),
          firmware: t('devices_form_firmware_field'),
          preset_id: t('devices_form_preset_field'),
          project_id: t('devices_form_project_field'),
          client_id: t('devices_form_client_field'),
          description: t('devices_form_description_field'),
          distributor_serial_number: t('devices_partner_serial_number'),
          batch_number: t('devices_form_batch_number_field'),
          serial_number_mercurius: t('devices_form_serial_number_mercurius_field'),
          box_serial_number: t('devices_form_box_serial_number_field'),
          street_number: t('devices_form_street_number_field'),
          house_number: t('devices_form_house_number_field'),
          postal_code: t('devices_form_postal_code_field'),
          neighbourhood: t('devices_form_neighbourhood_field'),
          floor: t('devices_form_floor_number_field'),
          construction_number: t('devices_form_building_number_field'),
          city: t('devices_form_city_field'),
          country: t('devices_form_country_field'),
          // sn_heat_unit: t('devices_form_sn_heat_unit_field'),
          article_number: t('devices_form_article_number_field'),
          distributor_article_number: t('devices_partner_article_number_field'),
          prod_order_nr_week: t('devices_form_prod_order_nr_week_field'),
          sim_card_number: t('devices_form_sim_card_number_field'),
          customer_ref: t('devices_form_customer_ref_field'),
          production_date: t('devices_form_production_date_field'),
          order_number: t('devices_form_order_number_field'),
          product_number: t('devices_form_product_number_field'),
          model_number: t('devices_form_model_number_field'),
          note: t('devices_form_note_field'),
          order_type: t('devices_form_order_type_field'),
          rssi: `${t('device_single_rss_signal_strength')} (%)`,
          mbus_meter_count: `${t('devices_mbus_meter_count')}`,
          updated_at: t('devices_last_viewed'),
        };
      }

      const data = {
        fieldList,
        ...filters,
        search,
        ...tabs,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/downloadDevicesByProjectId/${getId()}`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async exportMbusLogsByDeviceId(deviceId: number, t: any, meterDetails: string, date: any): Promise<any> {
    const mbusMeterDetails: any = meterDetails?.split('-');
    const filters: any = {
      device_id: deviceId,
      start_date: date?.startDate,
      end_date: date?.endDate,
      mbus_serial_number: mbusMeterDetails[1],
      more_options: {
        date_format: 'date_time',
        meter_type_friendly: t(mbusMeterDetails[0])
      }
    };

    return new Promise((resolve, reject) => {
      // Export Mbus Logs By DeviceId Table
      const data = {
        fieldList: {
          MBUS_METER_HEATING: t('MBUS_METER_HEATING'),
          MBUS_METER_COOLING: t('MBUS_METER_COOLING'),
          MBUS_METER_ELECTRICITY: t('MBUS_METER_ELECTRICITY'),
          MBUS_METER_WATER: t('MBUS_METER_WATER'),
          MBUS_METER_BI_DIRECTIONAL: t('MBUS_METER_BI_DIRECTIONAL'),
          MBUS_METER_MANUFACTURER: t('MBUS_METER_MANUFACTURER'),
          MBUS_METER_SERIAL_NUMBER: t('EXPORT_MBUS_METER_SERIAL_NUMBER'),
          MBUS_METER_METER_TYPE: t('MBUS_METER_METER_TYPE'),
          MBUS_METER_STATUS: t('MBUS_METER_STATUS'),
          MBUS_METER_FLOW: `${t('MBUS_METER_FLOW')} (${getMbusRegisterUnit('MBUS_METER_FLOW').trim()})`,
          MBUS_METER_POWER: `${t('MBUS_METER_POWER')} (${getMbusRegisterUnit('MBUS_METER_POWER').trim()})`,
          MBUS_METER_SUPPLY_TEMPERATURE: `${t('MBUS_METER_SUPPLY_TEMPERATURE')} (${getMbusRegisterUnit('MBUS_METER_SUPPLY_TEMPERATURE').trim()})`,
          MBUS_METER_RETURN_TEMPERATURE: `${t('MBUS_METER_RETURN_TEMPERATURE')} (${getMbusRegisterUnit('MBUS_METER_RETURN_TEMPERATURE').trim()})`,
          MBUS_METER_HEATING_ENERGY: `${t('MBUS_METER_HEATING_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_HEATING_ENERGY').trim()})`,
          MBUS_METER_ELECTRICITY_ENERGY: `${t('MBUS_METER_ELECTRICITY_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_ELECTRICITY_ENERGY').trim()})`,
          MBUS_METER_VOLUME: `${t('MBUS_METER_VOLUME')} (m3)`,
          MBUS_METER_COOLING_ENERGY: `${t('MBUS_METER_COOLING_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_COOLING_ENERGY').trim()})`,
          MBUS_METER_CURRENT: `${t('MBUS_METER_CURRENT')} (${getMbusRegisterUnit('MBUS_METER_CURRENT').trim()})`,
          device_name: t('devices_form_name_field'),
          serial_number_mercurius: t('devices_form_serial_number_mercurius_field'),
          date: t('device_single_status_mbus_date_export_label'),
          time: t('device_single_status_mbus_time_export_label'),
          // isEnabled: t('device_single_status_mbus_meter_status_export_label'),
          street_number: t('devices_form_street_number_field'),
          house_number: t('devices_form_house_number_field'),
          postal_code: t('devices_form_postal_code_field'),
          city: t('devices_form_city_field'),
          country: t('devices_form_country_field'),
          meter_type: t('MBUS_METER_METER_TYPE'),
          isValidated: t('mbus_export_is_validated_field')
        },
        ...filters,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/exportMbusLogsByDeviceId`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getDeviceStatusCounter(deviceId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getDeviceStatusCounter`, {
        device_id: deviceId
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllRegister1(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllRegister1`, {}).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllRegister2(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllRegister2`, {}).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllModbusRegisters(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getAllModbusRegisters`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDeviceRegister(deviceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getDeviceRegister/${deviceId}`, {}).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async deviceSettings(deviceSerial: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Get By Id Device
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/deviceSettings/${deviceSerial}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async requestSecondaryDeviceType(queryString: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // requestSecondaryDeviceType
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/requestSecondaryDeviceType${queryString}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async requestModbusErrorRegisterValue(queryString: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // requestModbusErrorRegisterValue
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/requestModbusErrorRegisterValue${queryString}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async requestModbusErrorRegisterAddress(queryString: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // requestModbusErrorRegisterAddress
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/requestModbusErrorRegisterAddress${queryString}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async requestDeviceBaudRate(queryString: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // requestDeviceBaudRate
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/requestDeviceBaudRate${queryString}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async requestDeviceBaseTopic(queryString: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // requestDeviceBaseTopic
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/requestDeviceBaseTopic${queryString}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getById(deviceId: number): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Get By Id Device
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getMbusHistoricDateRange(deviceId: number, meterType: string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Get By Id Device

      let meterTypeParts: any = meterType?.split('-');
      // eslint-disable-next-line prefer-destructuring
      meterTypeParts = meterTypeParts[0];
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getMbusHistoricDateRange?meter_type=${meterTypeParts}&device_id=${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getMbusRegistersById(deviceId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Mbus RegistersBy Id Device
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getMbusRegisters/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async deActivate(deviceId: string, isActive: number): Promise<Device> {
    return new Promise((resolve, reject) => {
      // De-active Device
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/delete/${deviceId}`, {
        activated: !isActive
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkViewAnomaly(mbusReportIds: any): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Bulk View Anomaly
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/anomaly/viewed`, {
        ids: mbusReportIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async exportMbusReporting(t: any, selectedDevices: any, sortColumn: any, sortDirection: boolean, currentTab: string, deviceId: number, projectId: number, firmwareValue: string, mbusSerialNumberValue: string, createdAt: any): Promise<any> {
    // Export Mbus Reporting
    let sortList = {};
    if (sortColumn.created_at) {
      sortList = {
        createdAt: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.device) {
      sortList = {
        device_name: sortDirection ? 1 : -1,
        device_serial: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.project) {
      sortList = {
        project_name: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.viewed_by) {
      sortList = {
        viewed_by: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.viewed_at) {
      sortList = {
        viewed_at: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.firmware) {
      sortList = {
        firmware: sortDirection ? 1 : -1,
      };
    }

    const filter: any = {
      sortList,
      date: createdAt,
    };

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sort';
      delete filter[prop];
    }

    const data: any = {
      Filter: filter,
      is_viewed: currentTab,
      device_id: deviceId,
      project_id: projectId,
      firmware: firmwareValue,
      mbus_serial: mbusSerialNumberValue,
      digest_id: '',
    };

    if (currentTab === 'all') {
      const prop = 'is_viewed';
      delete data[prop];
    } else if (data?.is_viewed === 'read') {
      data.is_viewed = true;
    } else {
      data.is_viewed = false;
    }

    if (window.location.href?.indexOf('mbus_reporting_digest') === -1) {
      const prop = 'digest_id';
      delete data[prop];
    } else {
      data.digest_id = window.location.pathname?.replace('/admin/mbus_reporting_digest/', '')?.replace('/', '');
    }

    if (!deviceId) {
      const prop = 'device_id';
      delete data[prop];
    }

    if (!projectId) {
      const prop = 'project_id';
      delete data[prop];
    }

    if (!firmwareValue) {
      const prop = 'firmware';
      delete data[prop];
    }

    if (!mbusSerialNumberValue) {
      const prop = 'mbus_serial';
      delete data[prop];
    }

    if (selectedDevices?.length > 0) {
      filter.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      // Export Mbus Reporting
      const fieldList = {
        fieldList: {
          createdAt: t('mbus_reporting_table_created_at'),
          device_name: t('devices_form_name_field'),
          device_serial: t('devices_form_serial_number_field'),
          project_name: t('mbus_reporting_table_project'),
          firmware: t('mbus_reporting_table_firmware'),
          register: t('mbus_reporting_anomaly_table_mbus_register'),
          mbus_type: t('mbus_reporting_anomaly_table_mbus_meter_type'),
          mbus_port: t('mbus_reporting_anomaly_table_mbus_port'),
          mbus_serial: t('mbus_reporting_anomaly_table_mbus_serial_number'),
          condition: t('mbus_reporting_anomaly_table_mbus_reason'),
          old_value: t('mbus_reporting_anomaly_table_mbus_old_value'),
          new_value: t('mbus_reporting_anomaly_table_mbus_new_value'),
          diff_value: t('mbus_reporting_anomaly_table_difference'),
          detection_type: t('mbus_reporting_anomaly_table_detection'),
        },
        ...filter,
        ...data
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/mbusReporting/anomalyLog`,
        responseType: 'blob',
        method: 'post',
        data: fieldList,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async exportMbusHourlyMissingDataReporting(t: any, selectedDevices: any, sortColumn: any, sortDirection: boolean, deviceId: number, projectId: number, firmwareValue: string, reportingUniqId: string = ''): Promise<any> {
    // Export Mbus Hourly Missing Data Reporting
    let sortList = {};

    if (sortColumn.device) {
      sortList = {
        device_name: sortDirection ? 1 : -1,
        device_serial: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.project) {
      sortList = {
        project_name: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.last_seen) {
      sortList = {
        last_seen: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.createdAt) {
      sortList = {
        createdAt: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.firmware) {
      sortList = {
        firmware: sortDirection ? 1 : -1,
      };
    }

    const filter: any = {
      sortList,
    };

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sort';
      delete filter[prop];
    }

    const data: any = {
      Filter: filter,
      device_id: deviceId,
      project_id: projectId,
      firmware: firmwareValue,
      unique_identifier: reportingUniqId,
      digest_id: '',
    };

    if (reportingUniqId?.length === 0) {
      const prop = 'unique_identifier';
      delete data[prop];
    }

    if (window.location.pathname?.indexOf('mbus_missing_hourly_details_digest_reporting') === -1) {
      const prop = 'digest_id';
      delete data[prop];
    } else {
      data.digest_id = window.location.pathname?.replace('/admin/mbus_missing_hourly_details_digest_reporting/', '')?.replace('/', '');
    }

    if (!deviceId) {
      const prop = 'device_id';
      delete data[prop];
    }

    if (!projectId) {
      const prop = 'project_id';
      delete data[prop];
    }

    if (!firmwareValue) {
      const prop = 'firmware';
      delete data[prop];
    }

    if (selectedDevices?.length > 0) {
      filter.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      // Export Mbus Reporting
      const fieldList = {
        fieldList: {
          createdAt: t('mbus_hourly_missing_reporting_table_created_at'),
          last_seen: t('mbus_hourly_missing_reporting_table_last_seen'),
          device_name: t('devices_form_name_field'),
          device_serial: t('devices_form_serial_number_field'),
          project_name: t('mbus_hourly_missing_reporting_table_project'),
          firmware: t('mbus_hourly_missing_reporting_table_firmware'),
        },
        ...filter,
        ...data
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/mbusReporting/hourlyMissingData`,
        responseType: 'blob',
        method: 'post',
        data: fieldList,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async bulkSnoozeDevices(payload: any): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Bulk Snooze Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/createDeviceSnoozed`, {
        payload
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateDevicesSnoozedStatus(ids: any): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Bulk UnSnooze Devices
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/updateDevicesSnoozedStatus`, {
        ids,
        status: 0
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDeActivate(deviceIds: string[]): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/bulk_deactive`, {
        list: deviceIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async fetchRegisters(serialNumber: string, registers: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/fetch-multiple-registers`, {
        serial_number: serialNumber,
        register: registers,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async fetchMbusRegistersByProjectId(projectId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // Fetch Mbus Registers By Project Id
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/search-mbus-registers-project/${projectId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDeviceCountAccordingToMbusTypesByProject(projectId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // getDeviceCountAccordingToMbusTypesByProject
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getDeviceCountAccordingToMbusTypesByProject/${projectId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async postSecondaryDeviceType(serialNumber: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // postSecondaryDeviceType
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-secondary-device-type`, {
        serial_number: serialNumber,
        type: value,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async postBaseTopic(serialNumber: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // postBaseTopic
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-base-topic`, {
        serial_number: serialNumber,
        topic: value?.toLowerCase(),
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async setFQDN(serialNumber: string, fqdn: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // postBaseTopic
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/setFqdn`, {
        serial_number: serialNumber,
        fqdn: fqdn?.toLowerCase(),
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async postModbusBaudRate(serialNumber: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // postModbusBaudRate
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-modbus-baud-rate`, {
        serial_number: serialNumber,
        baud_rate: value,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async postModbusErrorRegisterAddress(serialNumber: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // postModbusErrorRegisterAddress
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-modbus-error-register-address`, {
        serial_number: serialNumber,
        register: value,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async postModbusExpectedErrorRegisterValue(serialNumber: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // postModbusExpectedErrorRegisterValue
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-modbus-error-register-value`, {
        serial_number: serialNumber,
        value,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async deviceV2Reboot(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // deviceV2Reboot
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/device-reboot`, {
        serial_number: serialNumber,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async hiuResetV2Device(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // deviceV2Reboot
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/reboot-modbus-hiu-reset`, {
        serial_number: serialNumber,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getMbusIntervalV2Device(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // deviceV2Reboot
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/get-mbus-interval`, {
        serial_number: serialNumber
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async setMbusIntervalV2Device(serialNumber: string, intervalInMinutes: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // deviceV2Reboot
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-mbus-interval`, {
        serial_number: serialNumber,
        interval_in_minutes: intervalInMinutes
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async fetchMbusRegisters(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // fetchMbusRegisters
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/fetch-all-mbus-registers`, {
        serial_number: serialNumber
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async searchMbusRegisters(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/search-mbus-registers`, {
        serial_number: serialNumber
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDeviceDetailsByIdsList(list): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getDeviceIdsListTr`, {
        list
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async write(serialNumber: Device, register: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const key = Object.keys(register)[0];
      // Write Single Register
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/write-single-register`, {
        serial_number: serialNumber,
        register: key,
        // value: register[key]?.type === '°C' || ['512']?.includes(key) ? register[key]?.value * 10 : register[key]?.value,
        value: register[key]?.type === '°C' || ['512', '513']?.includes(key) ? register[key]?.value * 10 : register[key]?.value,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateMultipleRegisters(trSerialNumbers: any, trRegisters: any, trReportingMessage: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/update-multiple-registers-tr`, {
        serial_number_list: trSerialNumbers,
        registers: trRegisters,
        reporting_message: trReportingMessage
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateMultipleOTA(serialNumbers: any, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/write-update-ota`, {
        device_serial_numbers: serialNumbers,
        value
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDevicesCount(clientId: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Devices Count
      let filter: any = {};
      if (clientId) {
        filter = {
          client_id: parseInt(clientId, 10)
        };
      }
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getDevicesCounter`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data:any = response?.data?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDeviceErrors(page: number, limit: number, clientId: string = '', deviceId: string = '', sortColumn: any = {}, sortDirection: boolean = false, permissions: any = []): Promise<any> {
    console.log('permissionspermissions', permissions);

    return new Promise((resolve, reject) => {
      // Get By Id Client
      let sortList: string = '';
      if (sortColumn.device_name) {
        sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}`;
        // sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}, device.serial_number ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code_date) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code) {
        sortList = `error_code ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.address) {
        sortList = `device.street_number ${sortDirection ? 'ASC' : 'DESC'}`;
        // sortList = `device.street_number ${sortDirection ? 'ASC' : 'DESC'}, device.house_number ${sortDirection ? 'ASC' : 'DESC'}, device.neighbourhood ${sortDirection ? 'ASC' : 'DESC'}, device.city ${sortDirection ? 'ASC' : 'DESC'}, device.country ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortList?.length === 0) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      const filter: any = {
        page,
        limit,
        client_id: clientId,
        device_id: deviceId,
        sortList,
      };

      if (clientId?.length === 0) {
        const prop = 'client_id';
        delete filter[prop];
      }

      if (deviceId?.length === 0) {
        const prop = 'device_id';
        delete filter[prop];
      }

      const excludedErrors = getExcludedErrors();

      if (excludedErrors?.length > 0) {
        filter.excluded_errors = excludedErrors;
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getDevicesError`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          if (response?.status === 204) {
            reject(new Error(i18next.t('invalid_server_response')));
          }
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getActiveDeviceErrors(page: number, limit: number, projectIdList: any = [], deviceIdList: any = [], errorCodeList: any = [], sortColumn: any = {}, sortDirection: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Active Device Errors
      let sortList: string = '';
      if (sortColumn.device_name) {
        sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}`;
        // sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}, device.serial_number ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code_date) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code) {
        sortList = `error_code ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortList?.length === 0) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      const filter: any = {
        page,
        limit,
        project_id_list: projectIdList,
        device_id_list: deviceIdList,
        error_codes: errorCodeList,
        sortList
      };

      if (projectIdList?.length === 0) {
        const prop = 'project_id_list';
        delete filter[prop];
      }

      if (deviceIdList?.length === 0) {
        const prop = 'device_id_list';
        delete filter[prop];
      }

      if (errorCodeList?.length === 0) {
        const prop = 'error_codes';
        delete filter[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getProjectsActiveErrors`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          if (response?.status === 204) {
            reject(new Error(i18next.t('invalid_server_response')));
          }
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllActiveErrorsCounter(page: number, limit: number, projectIdList: any = [], deviceIdList: any = [], errorCodeList: any = [], sortColumn: any = {}, sortDirection: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Active Device Errors
      let sortList: string = '';
      if (sortColumn.device_name) {
        sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}`;
        // sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}, device.serial_number ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code_date) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code) {
        sortList = `error_code ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortList?.length === 0) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      const filter: any = {
        page,
        limit,
        project_id_list: projectIdList,
        device_id_list: deviceIdList,
        error_codes: errorCodeList,
        sortList
      };

      if (projectIdList?.length === 0) {
        const prop = 'project_id_list';
        delete filter[prop];
      }

      if (deviceIdList?.length === 0) {
        const prop = 'device_id_list';
        delete filter[prop];
      }

      if (errorCodeList?.length === 0) {
        const prop = 'error_codes';
        delete filter[prop];
      }

      const excludedErrors = getExcludedErrors();

      if (excludedErrors?.length > 0) {
        filter.excluded_errors = excludedErrors;
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllActiveErrorsCounter`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          if (response?.status === 204) {
            reject(new Error(i18next.t('invalid_server_response')));
          }
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getHistoricErrorDevicesCounter(filters: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let prop = '';
      if (isEmpty(filters?.client_list)) {
        prop = 'client_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.device_list)) {
        prop = 'device_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.project_list)) {
        prop = 'project_list';
        delete filters[prop];
      }

      const filter: any = {
        ...filters,
      };

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getHistoricErrorDevicesCounter`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getProjectsActiveErrors(page: number, limit: number, projectIdList: any = [], deviceIdList: any = [], errorCodeList: any = [], sortColumn: any = {}, sortDirection: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Active Device Errors
      let sortList: string = '';
      if (sortColumn.device_name) {
        sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}`;
        // sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}, device.serial_number ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code_date) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code) {
        sortList = `error_code ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortList?.length === 0) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      const filter: any = {
        page,
        limit,
        project_id_list: projectIdList,
        device_id_list: deviceIdList,
        error_codes: errorCodeList,
        sortList
      };

      if (projectIdList?.length === 0) {
        const prop = 'project_id_list';
        delete filter[prop];
      }

      if (deviceIdList?.length === 0) {
        const prop = 'device_id_list';
        delete filter[prop];
      }

      if (errorCodeList?.length === 0) {
        const prop = 'error_codes';
        delete filter[prop];
      }

      const excludedErrors = getExcludedErrors();

      if (excludedErrors?.length > 0) {
        filter.excluded_errors = excludedErrors;
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getProjectsActiveErrors`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          if (response?.status === 204) {
            reject(new Error(i18next.t('invalid_server_response')));
          }
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllDeviceErrorLogs(page: number, limit: number, sortColumn: any, sortDirection: boolean, filters: any, startDate: any, endDate: any, errorCodes: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let prop = '';
      if (isEmpty(filters?.client_list)) {
        prop = 'client_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.device_list)) {
        prop = 'device_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.project_list)) {
        prop = 'project_list';
        delete filters[prop];
      }
      let sortList = {};
      if (sortColumn.error_code) {
        sortList = {
          'device_error.error_code': sortDirection ? 'ASC' : 'DESC',
        };
      }
      if (sortColumn.started_at) {
        sortList = {
          'device_error.started_at': sortDirection ? 'ASC' : 'DESC',
        };
      }
      if (sortColumn.ended_at) {
        sortList = {
          'device_error.ended_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        Filter: {
          page,
          limit,
          sortList,
        },
        ...filters,
        date: {
          startDate: moment(startDate)?.format('YYYY-MM-DD'),
          endDate: moment(endDate)?.format('YYYY-MM-DD'),
        }
      };
      if (errorCodes?.length > 0) {
        filter.error_codes = errorCodes;
      }

      axios.post(`${process.env.REACT_APP_DEVICE_MONITORING_URL}/getAllDeviceErrorLogs`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getHistoricErrorDevicesCounters(filters: any, errorCodes: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let prop = '';
      if (isEmpty(filters?.client_id_list)) {
        prop = 'client_id_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.device_id_list)) {
        prop = 'device_id_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.project_id_list)) {
        prop = 'project_id_list';
        delete filters[prop];
      }
      const filter: any = {
        page: 0,
        limit: 10000,
        ...filters,
      };

      if (errorCodes?.length > 0) {
        filter.error_codes = errorCodes;
      }

      const excludedErrors = getExcludedErrors();

      if (!isEmpty(excludedErrors) && isArray(excludedErrors)) {
        filter.excluded_errors = excludedErrors;
      }

      console.log('excludedErrors', excludedErrors);
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getHistoricErrorDevices`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getHistoricErrorDevicesList(page: number, limit: number, sortColumn: any, sortDirection: boolean, filters: any, errorCodes: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let prop = '';
      if (isEmpty(filters?.client_id_list)) {
        prop = 'client_id_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.device_id_list)) {
        prop = 'device_id_list';
        delete filters[prop];
      }
      if (isEmpty(filters?.project_id_list)) {
        prop = 'project_id_list';
        delete filters[prop];
      }
      let sortList = {};
      if (sortColumn.device) {
        sortList = `device_name ${sortDirection ? 'ASC' : 'DESC'}`;
      }
      if (sortColumn.error_code) {
        sortList = `error_code ${sortDirection ? 'ASC' : 'DESC'}`;
      }
      if (sortColumn.started_at) {
        sortList = `started_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }
      if (sortColumn.ended_at) {
        sortList = `ended_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }
      const filter: any = {
        page,
        limit,
        sortList,
        ...filters,
      };
      if (errorCodes?.length > 0) {
        filter.error_codes = errorCodes;
      }

      const excludedErrors = getExcludedErrors();
      if (!isEmpty(excludedErrors) && isArray(excludedErrors)) {
        filter.excluded_errors = excludedErrors;
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getHistoricErrorDevices`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getHistoricErrorDevices(page: number, limit: number, projectIdList: any = [], deviceIdList: any = [], errorCodeList: any = [], sortColumn: any = {}, sortDirection: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Active Device Errors
      let sortList: string = '';
      if (sortColumn.device_name) {
        sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}`;
        // sortList = `device.device_name ${sortDirection ? 'ASC' : 'DESC'}, device.serial_number ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code_date) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortColumn.error_code) {
        sortList = `error_code ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      if (sortList?.length === 0) {
        sortList = `device.device_errored_at ${sortDirection ? 'ASC' : 'DESC'}`;
      }

      const filter: any = {
        page,
        limit,
        project_id_list: projectIdList,
        device_id_list: deviceIdList,
        error_codes: errorCodeList,
        sortList
      };

      if (projectIdList?.length === 0) {
        const prop = 'project_id_list';
        delete filter[prop];
      }

      if (deviceIdList?.length === 0) {
        const prop = 'device_id_list';
        delete filter[prop];
      }

      if (errorCodeList?.length === 0) {
        const prop = 'error_codes';
        delete filter[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getHistoricErrorDevices`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          if (response?.status === 204) {
            reject(new Error(i18next.t('invalid_server_response')));
          }
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getTempratureByDeviceId(deviceId: number, registers: any, startDate: string, endDate: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Temprature By Device Id
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/mongo/devicegraph/${deviceId}?${registers}&dt=${endDate}&df=${startDate}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getTempratureByClientId(clientId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Temprature By Device Id

      axios.get(`${process.env.REACT_APP_DEVICES_URL}/mongo/dashboardgraph/${clientId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getSimCardDataUsageBySubscriptionId(subscriptionId: string): Promise<any> {
    // Get Sim Card Data By Subscription Id
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('apikey', process.env.REACT_APP_SIM_CARD_API_KEY);
      formData.append('clientid', process.env.REACT_APP_SIM_CARD_CLIENT_ID);
      formData.append('subscription_id', subscriptionId);
      pureAxios.post(`${process.env.REACT_APP_SIM_CARD_API_URL}/GetStats`, formData).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getSimCardStatusBySubscriptionId(subscriptionId: string): Promise<any> {
    // Get Sim Card Data By Subscription Id
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('apikey', process.env.REACT_APP_SIM_CARD_API_KEY);
      formData.append('clientid', process.env.REACT_APP_SIM_CARD_CLIENT_ID);
      formData.append('subscription_id', subscriptionId);
      pureAxios.post(`${process.env.REACT_APP_SIM_CARD_API_URL}/GetMobileStatus`, formData).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async deleteSimCardSubscriptionBySubscriptionId(subscriptionId: string): Promise<any> {
    // Delete Sim Card Subscription By Subscription Id
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('apikey', process.env.REACT_APP_SIM_CARD_API_KEY);
      formData.append('clientid', process.env.REACT_APP_SIM_CARD_CLIENT_ID);
      formData.append('subscription_id', subscriptionId);
      pureAxios.post(`${process.env.REACT_APP_SIM_CARD_API_URL}/DeleteSubscription`, formData).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async toggleMbusMeter(serialNumber: string, port: number, isEnable): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-mbus-meter-enable`, {
        serial_number: serialNumber,
        instanceId: port,
        enable: !isEnable,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async saveSetDeviceRegisters(data): Promise<any> {
    return new Promise((resolve, reject) => {
      // saveSetDeviceRegisters
      const url = process.env.REACT_APP_DEVICES_URL?.replace('/v1/device', '/v1');

      axios.post(`${url}/set-registers`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateSetDeviceRegisters(data:any, setRegisterId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // updateSetDeviceRegisters
      const url = process.env.REACT_APP_DEVICES_URL?.replace('/v1/device', '/v1');
      axios.patch(`${url}/set-registers/${setRegisterId}`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getSetDeviceRegistersById(data): Promise<any> {
    return new Promise((resolve, reject) => {
      // getSetDeviceRegistersById
      const url = process.env.REACT_APP_DEVICES_URL?.replace('/v1/device', '/v1');
      axios.post(`${url}/set-registers/getByProjectDeviceId`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getRegisterConfigurationFromDevice(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // getRegisterConfigurationFromDevice
      const url = process.env.REACT_APP_DEVICES_URL?.replace('/v1/device', '/v1');
      axios.get(`${url}/set-registers/registerConfigurationFromDevice/${serialNumber}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async setDeviceRegisters(data): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-modbus-configuration`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async setDeviceRegistersByProject(data): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Devices
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mqtt_worker/set-modbus-configuration-by-project-tr`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllDeviceBySerialNumberAutoComplete(search: string): Promise<Device[]> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDeviceBySerialNumberAutoComplete`, {
        search
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDeviceCommissionDataById(deviceId: number): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Get Device Commission Data By Id
      // getDeviceCommissionData
      // axios.get(`${process.env.REACT_APP_DEVICES_URL}/getDeviceCommissionData/${deviceId}`).then((response) => {
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async updateDeviceCommissionData(device: any, deviceId: number): Promise<Device> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete device[prop];
      // Update Device Commission Data
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/updateDeviceCommissionDataNew/${deviceId}`, device).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async swapDevices(oldDeviceId: number, newSerialNumber: string, newDeviceName:string): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Update Device Commission Data
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/swappingDevice/${oldDeviceId}`, {
        serial_number_mercurius: newSerialNumber,
        device_name: newDeviceName,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getDeviceBySerialNumber(deviceId: number): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Get Device Commission Data By Id
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getDeviceBySerialNumber/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async clearDevice(deviceId: number): Promise<Device> {
    return new Promise((resolve, reject) => {
      // Get Device Commission Data By Id
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/clearDevice/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async downloadDeviceTable(t: any, search: string = '', currentTab: any, advancedFilter: any, selectedDevices: any, diagonsticFilter: any): Promise<Device> {
    const tabs: any = {};
    const filters: any = {
      Filter: {
      },
    };
    if (!isUndefined(currentTab)) {
      const tab = +currentTab;
      if (tab !== -1) {
        if (tab === 0) {
          tabs.is_device_online = false;
        }
        if (tab === 1) {
          // tabs.online = true;
          tabs.is_device_online = true;
        }
        if (tab === 2) {
          // filter.activated = tab;
          tabs.is_in_error_mode = true;
        }
        if (tab === 3) {
          // filter.unprovisioned = true;
          tabs.is_device_unprovisioned = true;
        }
      }
    }

    if (selectedDevices?.length > 0) {
      filters.idList = selectedDevices;
    }

    if (window.location.href?.indexOf('project') !== -1) {
      filters.project_id = parseInt(getId(), 10);
    }

    if (advancedFilter?.length > 0) {
      filters.AdvanceFilter = {};
      const res = advancedFilter?.map((item) => {
        if (item?.value?.length > 0 || item?.value > 0) {
          if (item?.column === 'device.production_date') {
            filters.AdvanceFilter[item?.column] = [moment(item?.value)?.format('YYYY-MM-DD')];
          } else {
            filters.AdvanceFilter[item?.column] = [item?.value];
          }
        }
        return item;
      });
      logger(res);
    }

    filters.diagnosticFilters = diagonsticFilter;
    if (!isEmpty(diagonsticFilter)) {
      if (diagonsticFilter?.length === 1) {
        if (diagonsticFilter[0]?.reg_name?.length === 0 && diagonsticFilter[0]?.value?.length === 0) {
          const prop = 'diagnosticFilters';
          delete filters[prop];
        }
      }
    }

    return new Promise((resolve, reject) => {
      // Download Device Table

      let fieldList: any = {
        device_id: t('devices_device_id'),
        devices_active: t('devices_active'),
        devices_inactive: t('devices_inactive'),
        devices_unprovisioned: t('devices_unprovisioned'),
        devices_error: t('devices_error'),
        device_status: t('devices_status'),
        device_name: t('devices_form_name_field'),
        device_type: t('devices_form_type_field'),
        ip_address: t('devices_form_ip_address_field'),
        firmware: t('devices_form_firmware_field'),
        preset_id: t('devices_form_preset_field'),
        project_id: t('devices_form_project_field'),
        client_id: t('devices_form_client_field'),
        description: t('devices_form_description_field'),
        batch_number: t('devices_form_batch_number_field'),
        serial_number_mercurius: t('devices_form_serial_number_mercurius_field'),
        box_serial_number: t('devices_form_box_serial_number_field'),
        street_number: t('devices_form_street_number_field'),
        house_number: t('devices_form_house_number_field'),
        postal_code: t('devices_form_postal_code_field'),
        neighbourhood: t('devices_form_neighbourhood_field'),
        floor: t('devices_form_floor_number_field'),
        construction_number: t('devices_form_building_number_field'),
        city: t('devices_form_city_field'),
        country: t('devices_form_country_field'),
        // sn_heat_unit: t('devices_form_sn_heat_unit_field'),
        article_number: t('devices_form_article_number_field'),
        prod_order_nr_week: t('devices_form_prod_order_nr_week_field'),
        sim_card_number: t('devices_form_sim_card_number_field'),
        customer_ref: t('devices_form_customer_ref_field'),
        production_date: t('devices_form_production_date_field'),
        order_number: t('devices_form_order_number_field'),
        product_number: t('devices_form_product_number_field'),
        model_number: t('devices_form_model_number_field'),
        note: t('devices_form_note_field'),
        order_type: t('devices_form_order_type_field'),
        rssi: `${t('device_single_rss_signal_strength')} (%)`,
        mbus_meter_count: `${t('devices_mbus_meter_count')}`,
        updated_at: t('devices_last_viewed'),
      };

      if (shouldDisplayDistributorArticleAndHiu) {
        fieldList = {
          device_id: t('devices_device_id'),
          devices_active: t('devices_active'),
          devices_inactive: t('devices_inactive'),
          devices_unprovisioned: t('devices_unprovisioned'),
          devices_error: t('devices_error'),
          device_status: t('devices_status'),
          device_name: t('devices_form_name_field'),
          device_type: t('devices_form_type_field'),
          ip_address: t('devices_form_ip_address_field'),
          firmware: t('devices_form_firmware_field'),
          preset_id: t('devices_form_preset_field'),
          project_id: t('devices_form_project_field'),
          client_id: t('devices_form_client_field'),
          description: t('devices_form_description_field'),
          distributor_serial_number: t('devices_partner_serial_number'),
          batch_number: t('devices_form_batch_number_field'),
          serial_number_mercurius: t('devices_form_serial_number_mercurius_field'),
          box_serial_number: t('devices_form_box_serial_number_field'),
          street_number: t('devices_form_street_number_field'),
          house_number: t('devices_form_house_number_field'),
          postal_code: t('devices_form_postal_code_field'),
          neighbourhood: t('devices_form_neighbourhood_field'),
          floor: t('devices_form_floor_number_field'),
          construction_number: t('devices_form_building_number_field'),
          city: t('devices_form_city_field'),
          country: t('devices_form_country_field'),
          // sn_heat_unit: t('devices_form_sn_heat_unit_field'),
          article_number: t('devices_form_article_number_field'),
          distributor_article_number: t('devices_partner_article_number_field'),
          prod_order_nr_week: t('devices_form_prod_order_nr_week_field'),
          sim_card_number: t('devices_form_sim_card_number_field'),
          customer_ref: t('devices_form_customer_ref_field'),
          production_date: t('devices_form_production_date_field'),
          order_number: t('devices_form_order_number_field'),
          product_number: t('devices_form_product_number_field'),
          model_number: t('devices_form_model_number_field'),
          note: t('devices_form_note_field'),
          order_type: t('devices_form_order_type_field'),
          rssi: `${t('device_single_rss_signal_strength')} (%)`,
          mbus_meter_count: `${t('devices_mbus_meter_count')}`,
          updated_at: t('devices_last_viewed'),
        };
      }

      const decoded: any = jwtDecode(localStorage.getItem('accessToken'));
      const roles = decoded?.realm_access?.roles;
      const isFortesUserRegex = new RegExp('^fortes_');
      const isFortesUser = roles?.find((role) => (
        isFortesUserRegex?.test(role)
      ));

      if (isFortesUser) {
        fieldList.default_controller_type = t('devices_form_default_controller_type_field');
        fieldList.default_soft_version = t('devices_form_default_soft_version_field');
        fieldList.default_appliance_type = t('devices_form_default_appliance_type_field');
        fieldList.default_client_name = t('devices_form_default_client_name_field');
      }

      const data = {
        fieldList,
        ...filters,
        search,
        ...tabs,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/download`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  // Device List - Download All Devices Mbus Data
  async downloadDeviceMbusHistoricalData(t: any, selectedDevices: any, dateRange: any, dataType: string, exportType: string): Promise<any> {
    const filters: any = {
    };

    if (selectedDevices?.length > 0) {
      filters.idList = selectedDevices;
    }
    filters.date = dateRange;
    filters.data_type = dataType;
    filters.export_type = exportType;

    return new Promise((resolve, reject) => {
      // Download Device Table
      const data = {
        fieldList: {
          MBUS_METER_HEATING: t('MBUS_METER_HEATING'),
          MBUS_METER_COOLING: t('MBUS_METER_COOLING'),
          MBUS_METER_ELECTRICITY: t('MBUS_METER_ELECTRICITY'),
          MBUS_METER_WATER: t('MBUS_METER_WATER'),
          MBUS_METER_BI_DIRECTIONAL: t('MBUS_METER_BI_DIRECTIONAL'),
          MBUS_METER_MANUFACTURER: t('MBUS_METER_MANUFACTURER'),
          MBUS_METER_SERIAL_NUMBER: t('EXPORT_MBUS_METER_SERIAL_NUMBER'),
          MBUS_METER_METER_TYPE: t('MBUS_METER_METER_TYPE'),
          MBUS_METER_STATUS: t('MBUS_METER_STATUS'),
          MBUS_METER_FLOW: `${t('MBUS_METER_FLOW')} (${getMbusRegisterUnit('MBUS_METER_FLOW').trim()})`,
          MBUS_METER_POWER: `${t('MBUS_METER_POWER')} (${getMbusRegisterUnit('MBUS_METER_POWER').trim()})`,
          MBUS_METER_SUPPLY_TEMPERATURE: `${t('MBUS_METER_SUPPLY_TEMPERATURE')} (${getMbusRegisterUnit('MBUS_METER_SUPPLY_TEMPERATURE').trim()})`,
          MBUS_METER_RETURN_TEMPERATURE: `${t('MBUS_METER_RETURN_TEMPERATURE')} (${getMbusRegisterUnit('MBUS_METER_RETURN_TEMPERATURE').trim()})`,
          MBUS_METER_HEATING_ENERGY: `${t('MBUS_METER_HEATING_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_HEATING_ENERGY').trim()})`,
          MBUS_METER_ELECTRICITY_ENERGY: `${t('MBUS_METER_ELECTRICITY_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_ELECTRICITY_ENERGY').trim()})`,
          MBUS_METER_VOLUME: `${t('MBUS_METER_VOLUME')} (m3)`,
          MBUS_METER_COOLING_ENERGY: `${t('MBUS_METER_COOLING_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_COOLING_ENERGY').trim()})`,
          MBUS_METER_CURRENT: `${t('MBUS_METER_CURRENT')} (${getMbusRegisterUnit('MBUS_METER_CURRENT').trim()})`,
          city: t('devices_form_city_field'),
          country: t('devices_form_country_field'),
          date: t('device_single_status_mbus_date_export_label'),
          device_name: t('devices_form_name_field'),
          house_number: t('devices_form_house_number_field'),
          // isEnabled: t('meter_enabled'),
          postal_code: t('devices_form_postal_code_field'),
          serial_number_mercurius: t('devices_form_serial_number_field'),
          street_number: t('devices_form_street_number_field'),
          time: t('device_single_status_mbus_time_export_label'),
          isValidated: t('mbus_export_is_validated_field')
        },
        ...filters,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/exportAllDevicesMbusLogs`,
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  // Device List - Download All Devices Modbus Data
  async exportModbusLogsByDeviceId(idList: any, t: any, dateRange: any, dataType: string, exportType: string, fieldList: any): Promise<any> {
    const filters: any = {
    };

    const deviceType = Object.keys(fieldList)[0];
    console.log('fieldList[deviceType]?.registers', fieldList[deviceType]?.registers);
    filters.idList = idList;
    filters.date = dateRange;
    filters.data_type = dataType;
    filters.export_type = exportType;
    filters.registers = fieldList[deviceType]?.registers;

    // const prop = 'registers';
    // delete fieldList[deviceType][prop];
    return new Promise((resolve, reject) => {
      const data = {
        fieldList,
        ...filters,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/exportModbusTrLogsByDeviceId`,
        method: 'post',
        data,
        responseType: 'arraybuffer'
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  // Device List - Download All Devices Modbus Data
  async downloadDeviceModbusHistoricalData(t: any, selectedDevices: any, dateRange: any, dataType: string, exportType: string, fieldList: any): Promise<any> {
    const filters: any = {
    };

    if (selectedDevices?.TR03?.length > 0 || selectedDevices?.TR04?.length > 0) {
      filters.idList = selectedDevices;
    }
    filters.date = dateRange;
    filters.data_type = dataType;
    filters.export_type = exportType;
    filters.registers = {
      TR03: fieldList?.TR03.registers,
      TR04: fieldList?.TR04.registers,
    };

    // const prop = 'registers';
    // delete fieldList.TR03[prop];
    // delete fieldList.TR04[prop];
    return new Promise((resolve, reject) => {
      const data = {
        fieldList,
        ...filters,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/exportAllDevicesModbusLogsTR`,
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  // Project List - Download All Projects Modbus Data
  async exportAllProjectDevicesModbusLogsTr(t: any, selectedProjects: any, dateRange: any, dataType: string, exportType: string, fieldList: any): Promise<any> {
    const filters: any = {
    };

    if (selectedProjects?.length > 0) {
      filters.idList = selectedProjects;
    }
    filters.date = dateRange;
    filters.data_type = dataType;
    filters.export_type = exportType;
    filters.registers = {
      TR03: fieldList?.TR03.registers,
      TR04: fieldList?.TR04.registers,
    };

    // const prop = 'registers';
    // delete fieldList.TR03[prop];
    // delete fieldList.TR04[prop];
    return new Promise((resolve, reject) => {
      const data = {
        fieldList,
        ...filters,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/exportAllProjectDevicesModbusLogsTr`,
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  // dateRange:DateRange
  async getAllOTA(page: number, limit: number, search: string, sortColumn: any, sortDirection: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Device

      let sortList = {};
      if (sortColumn.updatedAt) {
        sortList = {
          'ota_bulk_update_details.updated_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.totalDevices) {
        sortList = {
          'ota.total_devices': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.user) {
        sortList = {
          'user.first_name': sortDirection ? 'ASC' : 'DESC',
          'user.last_name': sortDirection ? 'ASC' : 'DESC'
        };
      }

      if (sortColumn.message) {
        sortList = {
          'ota.message': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllOTA`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllDevicesWithInconsistentMbus(page: number, limit: number, search: string, sortColumn: any, sortDirection: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // GetAllDevicesWithInconsistentMbus

      let sortList = {};
      if (sortColumn.createdAt) {
        sortList = {
          createdAt: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.totalDevices) {
        sortList = {
          total_devices: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.totalProjects) {
        sortList = {
          total_projects: sortDirection ? 1 : -1,
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDevicesWithInconsistentMbus`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDevicesWithInconsistentMbusById(page: number, limit: number, sortColumn: any, sortDirection: boolean, deviceId: any, projectId: any, firmwareValue: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // getAllDevicesWithInconsistentMbusById

      let sortList = {};
      if (sortColumn.created_at) {
        sortList = {
          'devices.created_at': sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.device) {
        sortList = {
          'devices.device_name': sortDirection ? 1 : -1,
          'devices.serial_number': sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.project) {
        sortList = {
          'devices.project_name': sortDirection ? 1 : -1,
          'devices.order_number': sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.client) {
        sortList = {
          'devices.client_name': sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          'devices.firmware': sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.actual_registers_count) {
        sortList = {
          'devices.actual_registers_count': sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.shown_registers_count) {
        sortList = {
          'devices.shown_registers_count': sortDirection ? 1 : -1,
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
      };

      const data: any = {
        device_id: deviceId,
        project_id: projectId,
        firmware: firmwareValue,
        Filter: filter,
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (!deviceId) {
        const prop = 'device_id';
        delete data[prop];
      }

      if (!projectId) {
        const prop = 'project_id';
        delete data[prop];
      }

      if (!firmwareValue) {
        const prop = 'firmware';
        delete data[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDevicesWithInconsistentMbusById/${window.location.pathname?.split('/')[3]}`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async exportDevicesWithInconsistentMbus(t: any, selectedDevices: any, search: any, sortColumn: any, sortDirection: boolean): Promise<any> {
    let sortList = {};
    if (sortColumn.createdAt) {
      sortList = {
        createdAt: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.totalDevices) {
      sortList = {
        total_devices: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.totalProjects) {
      sortList = {
        total_projects: sortDirection ? 1 : -1,
      };
    }

    const filter: any = {
      sortList
    };

    let data: any;

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sortList';
      delete filter[prop];
    }

    if (selectedDevices?.length > 0) {
      filter.idList = selectedDevices;
    }

    if (search?.length > 0) {
      data = {
        Filter: filter,
        search
      };
    } else {
      data = {
        Filter: filter,
      };
    }

    return new Promise((resolve, reject) => {
      // Export Inconsistent Mbus Devices
      const fieldList = {
        fieldList: {
          createdAt: t('inconsistent_mbus_devices_reporting_table_created_at'),
          total_devices: t('inconsistent_mbus_devices_reporting_table_total_devices'),
          total_projects: t('inconsistent_mbus_devices_reporting_table_total_projects'),
        },
        ...data
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/devicesWithInconsistentMbus`,
        responseType: 'blob',
        method: 'post',
        data: fieldList,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async exportDevicesWithInconsistentMbusById(t: any, sortColumn: any, sortDirection: boolean, deviceId: any, projectId: any, firmwareValue: any): Promise<any> {
    let sortList = {};
    if (sortColumn.created_at) {
      sortList = {
        'devices.created_at': sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.device) {
      sortList = {
        'devices.device_name': sortDirection ? 1 : -1,
        'devices.serial_number': sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.project) {
      sortList = {
        'devices.project_name': sortDirection ? 1 : -1,
        'devices.order_number': sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.client) {
      sortList = {
        'devices.client_name': sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.firmware) {
      sortList = {
        'devices.firmware': sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.actual_registers_count) {
      sortList = {
        'devices.actual_registers_count': sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.shown_registers_count) {
      sortList = {
        'devices.shown_registers_count': sortDirection ? 1 : -1,
      };
    }

    const filter: any = {
      sortList
    };

    const data: any = {
      device_id: deviceId,
      project_id: projectId,
      firmware: firmwareValue,
      Filter: filter,
    };

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sortList';
      delete filter[prop];
    }

    if (!deviceId) {
      const prop = 'device_id';
      delete data[prop];
    }

    if (!projectId) {
      const prop = 'project_id';
      delete data[prop];
    }

    if (!firmwareValue) {
      const prop = 'firmware';
      delete data[prop];
    }

    return new Promise((resolve, reject) => {
      // Export Inconsistent Mbus Devices
      const fieldList = {
        fieldList: {
          createdAt: t('inconsistent_mbus_devices_reporting_details_table_created_at'),
          'devices.device_name': t('devices_form_name_field'),
          'devices.serial_number': t('devices_form_serial_number_field'),
          'devices.project_name': t('inconsistent_mbus_devices_reporting_details_table_project'),
          'devices.client_name': t('inconsistent_mbus_devices_reporting_details_table_client'),
          'devices.firmware': t('inconsistent_mbus_devices_reporting_details_table_firmware'),
          'devices.actual_registers_count': t('inconsistent_mbus_devices_reporting_details_table_actual_rmeters_count'),
          'devices.shown_registers_count': t('inconsistent_mbus_devices_reporting_details_table_shown_meters_count'),
        },
        ...data
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/devicesWithInconsistentMbus/${window.location.pathname?.split('/')[3]}`,
        responseType: 'blob',
        method: 'post',
        data: fieldList,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllMbusReporting(page: number, limit: number, sortColumn: any, sortDirection: boolean, currentTab: string, deviceId: number, projectId: number, firmwareValue: string, mbusSerialNumberValue: string, createdAt: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get All Mbus Reporting

      let sortList = {};
      if (sortColumn.created_at) {
        sortList = {
          createdAt: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.device) {
        sortList = {
          device_name: sortDirection ? 1 : -1,
          device_serial: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.project) {
        sortList = {
          project_name: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.viewed_by) {
        sortList = {
          viewed_by: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.viewed_at) {
        sortList = {
          viewed_at: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          firmware: sortDirection ? 1 : -1,
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sort';
        delete filter[prop];
      }

      const data: any = {
        Filter: filter,
        is_viewed: currentTab,
        date: createdAt,
        device_id: deviceId,
        project_id: projectId,
        firmware: firmwareValue,
        mbus_serial: mbusSerialNumberValue,
        digest_id: '',
      };

      if (currentTab === 'all') {
        const prop = 'is_viewed';
        delete data[prop];
      } else if (data?.is_viewed === 'read') {
        data.is_viewed = true;
      } else {
        data.is_viewed = false;
      }

      if (window.location.href?.indexOf('mbus_reporting_digest') === -1) {
        const prop = 'digest_id';
        delete data[prop];
      } else {
        data.digest_id = window.location.pathname?.replace('/admin/mbus_reporting_digest/', '')?.replace('/', '');
      }

      if (!deviceId) {
        const prop = 'device_id';
        delete data[prop];
      }

      if (!projectId) {
        const prop = 'project_id';
        delete data[prop];
      }

      if (!firmwareValue) {
        const prop = 'firmware';
        delete data[prop];
      }

      if (!mbusSerialNumberValue) {
        const prop = 'mbus_serial';
        delete data[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/anomaly-log`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllDevicesSnoozed(page: number, limit: number, sortColumn: any, sortDirection: boolean, currentTab: string, deviceId: number, projectId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get All Devices Snoozed

      let sortList = {};
      if (sortColumn.updatedAt) {
        sortList = {
          updatedAt: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.device) {
        sortList = {
          device_name: sortDirection ? 1 : -1,
          device_serial: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.project) {
        sortList = {
          project_name: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.snooze_end_at) {
        sortList = {
          snooze_end_at: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.snooze_request_at) {
        sortList = {
          snooze_request_at: sortDirection ? 1 : -1,
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sort';
        delete filter[prop];
      }

      const data: any = {
        Filter: filter,
        is_snoozed: currentTab,
        device_list: [deviceId],
        project_list: [projectId],
      };

      if (currentTab === 'all') {
        const prop = 'is_snoozed';
        delete data[prop];
      } else if (data?.is_snoozed === '1') {
        data.is_snoozed = 1;
      } else {
        data.is_snoozed = 0;
      }

      if (!deviceId) {
        const prop = 'device_list';
        delete data[prop];
      }

      if (!projectId) {
        const prop = 'project_list';
        delete data[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDevicesSnoozed`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async exportSnoozedDevicesReporting(t: any, selectedDevices: any, sortColumn: any, sortDirection: boolean, currentTab: string, deviceId: number, projectId: number): Promise<any> {
    let sortList = {};
    if (sortColumn.updatedAt) {
      sortList = {
        updatedAt: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.device) {
      sortList = {
        device_name: sortDirection ? 1 : -1,
        device_serial: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.project) {
      sortList = {
        project_name: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.snooze_end_at) {
      sortList = {
        snooze_end_at: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.snooze_request_at) {
      sortList = {
        snooze_request_at: sortDirection ? 1 : -1,
      };
    }

    const filter: any = {
      sortList,
    };

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sort';
      delete filter[prop];
    }

    const data: any = {
      Filter: filter,
      is_snoozed: currentTab,
      device_list: [deviceId],
      project_list: [projectId],
    };

    if (currentTab === 'all') {
      const prop = 'is_snoozed';
      delete data[prop];
    } else if (data?.is_snoozed === '1') {
      data.is_snoozed = 1;
    } else {
      data.is_snoozed = 0;
    }

    if (!deviceId) {
      const prop = 'device_list';
      delete data[prop];
    }

    if (!projectId) {
      const prop = 'project_list';
      delete data[prop];
    }

    if (selectedDevices?.length > 0) {
      filter.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      // Export Mbus Reporting
      const fieldList = {
        fieldList: {
          updatedAt: t('mbus_reporting_table_created_at'),
          device_name: t('devices_form_name_field'),
          device_serial: t('devices_form_serial_number_field'),
          name: t('mbus_reporting_table_project'),
          snooze_request_at: t('mbus_reporting_table_snooze_request_at'),
          snooze_end_at: t('mbus_reporting_table_snooze_end_at'),
        },
        ...filter,
        ...data
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/mbusReporting/snoozeDevices`,
        responseType: 'blob',
        method: 'post',
        data: fieldList,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllMbusHourlyMissingDataReporting(page: number, limit: number, sortColumn: any, sortDirection: boolean, deviceId: number, projectId: number, firmwareValue: string, reportingUniqId: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get All Mbus Hourly Missing Data Reporting
      let sortList = {};

      if (sortColumn.device) {
        sortList = {
          device_name: sortDirection ? 1 : -1,
          device_serial: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.project) {
        sortList = {
          project_name: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.last_seen) {
        sortList = {
          last_seen: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.createdAt) {
        sortList = {
          createdAt: sortDirection ? 1 : -1,
        };
      }

      if (sortColumn.firmware) {
        sortList = {
          firmware: sortDirection ? 1 : -1,
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sort';
        delete filter[prop];
      }

      const data: any = {
        Filter: filter,
        device_id: [deviceId],
        project_id: [projectId],
        firmware: firmwareValue,
        unique_identifier: reportingUniqId,
        digest_id: '',
      };

      if (reportingUniqId?.length === 0) {
        const prop = 'unique_identifier';
        delete data[prop];
      }

      if (window.location.pathname?.indexOf('mbus_missing_hourly_details_digest_reporting') === -1) {
        const prop = 'digest_id';
        delete data[prop];
      } else {
        data.digest_id = window.location.pathname?.replace('/admin/mbus_missing_hourly_details_digest_reporting/', '')?.replace('/', '');
      }
      if (!deviceId) {
        const prop = 'device_id';
        delete data[prop];
      }

      if (!projectId) {
        const prop = 'project_id';
        delete data[prop];
      }

      if (!firmwareValue) {
        const prop = 'firmware';
        delete data[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/anomaly-missing-messages`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async mbusAnamolyAutocomplete(fieldName: string, fieldValue: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Mbus Anamoly Autocomplete
      const data: any = {
        fieldName,
        fieldValue
      };
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/mbus_reporting_detail/autocomplete`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getMbusReportingById(mbusReportingId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get By Id Device
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/mbus_reporting_detail/${mbusReportingId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getFirmwares(): Promise<any> {
    return new Promise((resolve, reject) => {
      // get Firmwares

      axios.post(`${process.env.REACT_APP_FIRMWARES_URL}`, {
        Filter: {
          page: 0,
          limit: 10000,
          is_activated: true
        }
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  // dateRange:DateRange
  async downloadOTAReportingTable(t: any, search: string, sortColumn: any, sortDirection: boolean, selectedDevices: any): Promise<any> {
    // Download OTA Reporting Table

    let sortList = {};
    if (sortColumn.updatedAt) {
      sortList = {
        'ota_bulk_update_details.updated_at': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.totalDevices) {
      sortList = {
        'ota.total_devices': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.user) {
      sortList = {
        'user.first_name': sortDirection ? 'ASC' : 'DESC',
        'user.last_name': sortDirection ? 'ASC' : 'DESC'
      };
    }

    if (sortColumn.message) {
      sortList = {
        'ota.message': sortDirection ? 'ASC' : 'DESC',
      };
    }

    const filter: any = {
      sortList
    };

    let payload: any;

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sortList';
      delete filter[prop];
    }

    if (search?.length > 0) {
      payload = {
        Filter: filter,
        search
      };
    } else {
      payload = {
        Filter: filter,
      };
    }

    if (selectedDevices?.length > 0) {
      payload.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      const data = {
        fieldList: {
          id: 'id',
          ota_bulk_update_details_updated_at: t('reporting_ota_table_updated_at'),
          total_devices: t('reporting_ota_table_total_devices'),
          user_id: t('reporting_ota_table_user'),
          message: t('reporting_ota_table_message'),
          reporting_ota_message: t('reporting_ota_message'),
          reporting_registers_details_table_pending_message: t('reporting_registers_details_table_pending_message'),
          reporting_registers_details_table_successful_message: t('reporting_registers_details_table_failed_message'),
          reporting_registers_details_table_failed_message: t('reporting_registers_details_table_successful_message'),
        },
        ...payload,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/ota/download`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getOtaBulkUpdateDetails(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Ota Bulk Update Details

      let sortList = {};
      if (sortColumn.updated_at) {
        sortList = {
          'ota.updated_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.device) {
        sortList = {
          'device.device_name': sortDirection ? 'ASC' : 'DESC',
          'device.serial_number': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.old_firmware_version) {
        sortList = {
          'ota.old_firmware_version': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.user) {
        sortList = {
          'user.first_name': sortDirection ? 'ASC' : 'DESC',
          'user.last_name': sortDirection ? 'ASC' : 'DESC'
        };
      }

      if (sortColumn.message) {
        sortList = {
          'ota_bulk_update.message': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.status) {
        sortList = {
          'ota.update_status': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      data.status = currentTab;

      if (currentTab === 'all') {
        const prop = 'status';
        delete data[prop];
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getOtaBulkUpdateDetails/${getId()}`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async downloadOTADetailsReportingTable(t: any, currentTab: string, search: string, sortColumn: any, sortDirection: boolean, selectedDevices: any): Promise<any> {
    // Download Ota Bulk Update Details

    let sortList = {};
    if (sortColumn.updated_at) {
      sortList = {
        'ota.updated_at': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.device) {
      sortList = {
        'device.device_name': sortDirection ? 'ASC' : 'DESC',
        'device.serial_number': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.old_firmware_version) {
      sortList = {
        'ota.old_firmware_version': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.user) {
      sortList = {
        'user.first_name': sortDirection ? 'ASC' : 'DESC',
        'user.last_name': sortDirection ? 'ASC' : 'DESC'
      };
    }

    if (sortColumn.message) {
      sortList = {
        'ota_bulk_update.message': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.status) {
      sortList = {
        'ota.update_status': sortDirection ? 'ASC' : 'DESC',
      };
    }
    const filter: any = {
      sortList
    };

    let payload: any;

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sortList';
      delete filter[prop];
    }

    if (search?.length > 0) {
      payload = {
        Filter: filter,
        search
      };
    } else {
      payload = {
        Filter: filter,
      };
    }

    payload.status = currentTab;

    if (selectedDevices?.length > 0) {
      payload.idList = selectedDevices;
    }

    if (currentTab === 'all') {
      const prop = 'status';
      delete payload[prop];
    }

    return new Promise((resolve, reject) => {
      const data = {
        fieldList: {
          updated_at: t('reporting_ota_details_table_created_at'),
          device_id: t('devices_form_name_field'),
          // serial_number: t('reporting_registers_details_table_device'),
          device_serial: t('reporting_ota_details_table_device'),
          user_id: t('reporting_ota_details_table_user'),
          old_firmware_version: t('reporting_ota_details_table_old_firmware_version'),
          current_firmware_version: t('reporting_ota_details_table_current_firmware_version'),
          message: t('reporting_ota_details_table_message'),
          ota_status: t('reporting_ota_details_table_current_status'),
          reporting_ota_message: t('reporting_ota_message'),
          ota_pending: t('ota_pending'),
          ota_success: t('ota_success'),
          ota_failed: t('ota_failed'),
          reporting_registers_details_table_pending_message: t('reporting_registers_details_table_pending_message'),
          reporting_registers_details_table_successful_message: t('reporting_registers_details_table_failed_message'),
          reporting_registers_details_table_failed_message: t('reporting_registers_details_table_successful_message'),
        },
        ...payload,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/ota/download/${getId()}`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllSwappedDevices(page: number, limit: number, search: string, deviceId: number, sortColumn: any, sortDirection: boolean): Promise<DeviceOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All Swapped Devices

      const filter: any = {
        page,
        limit,
        device_id: deviceId,
        orderBy: '',
      };

      if (sortColumn.old_device_name) {
        filter.orderBy = `swap_devices.device_name${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.device_name) {
        filter.orderBy = `device.device_name${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.old_firmware) {
        filter.orderBy = `swap_devices.firmware${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.new_firmware) {
        filter.orderBy = `device.firmware${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.old_sim_card_number) {
        filter.orderBy = `swap_devices.sim_card_number${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.new_sim_card_number) {
        filter.orderBy = `device.sim_card_number${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.old_box_serial_number) {
        filter.orderBy = `swap_devices.box_serial_number${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.new_box_serial_number) {
        filter.orderBy = `device.box_serial_number${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (sortColumn.created_at) {
        filter.orderBy = `swap_devices.created_at${sortDirection ? ' ASC' : ' DESC'}`;
      }

      if (filter?.orderBy?.length === 0) {
        const prop = 'orderBy';
        delete filter[prop];
      }

      if (Number.isNaN(deviceId)) {
        const prop = 'device_id';
        delete filter[prop];
      }

      let data: any;

      if (search?.length > 0) {
        data = {
          ...filter,
          search
        };
      } else {
        data = {
          ...filter,
        };
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllSwappedDevices`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async downloadAllSwappedDevices(t: any, search: string, sortColumn: any, sortDirection: boolean, selectedDevices: any): Promise<DeviceOverviewProps> {
    // Get All Swapped Devices

    const filter: any = {
      orderBy: '',
    };

    if (sortColumn.device_name) {
      filter.orderBy = `device.device_name${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.old_device_name) {
      filter.orderBy = `swap_devices.device_name${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.old_firmware) {
      filter.orderBy = `swap_devices.firmware${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.new_firmware) {
      filter.orderBy = `device.firmware${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.old_sim_card_number) {
      filter.orderBy = `swap_devices.sim_card_number${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.new_sim_card_number) {
      filter.orderBy = `device.sim_card_number${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.old_box_serial_number) {
      filter.orderBy = `swap_devices.box_serial_number${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.new_box_serial_number) {
      filter.orderBy = `device.box_serial_number${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (sortColumn.created_at) {
      filter.orderBy = `swap_devices.created_at${sortDirection ? ' ASC' : ' DESC'}`;
    }

    if (filter?.orderBy?.length === 0) {
      const prop = 'orderBy';
      delete filter[prop];
    }

    let payload: any;

    if (search?.length > 0) {
      payload = {
        ...filter,
        search
      };
    } else {
      payload = {
        ...filter,
      };
    }

    if (selectedDevices?.length > 0) {
      payload.idList = selectedDevices;
    }

    const data = {
      fieldList: {
        created_at: t('devices_single_swapped_device_created_at'),
        old_device_name: t('devices_single_swapped_old_device_name'),
        new_device_name: t('devices_single_swapped_new_device_name'),
        old_firmware: t('devices_single_swapped_device_old_firmware'),
        new_firmware: t('devices_single_swapped_device_new_firmware'),
        new_box_serial_number: t('devices_single_swapped_device_new_box_serial_number'),
        old_sim_card_number: t('devices_single_swapped_device_old_sim_card_number'),
        new_sim_card_number: t('devices_single_swapped_device_new_sim_card_number'),
      },
      ...payload,
    };

    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/downloadSwappedDevices`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllRegistersReporting(page: number, limit: number, search: string, sortColumn: any, sortDirection: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get All Registers Reporting

      let sortList = {};
      if (sortColumn.createdAt) {
        sortList = {
          'registers_bulk_update.created_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.totalRegisters) {
        sortList = {
          'registers_bulk_update.total_devices': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.user) {
        sortList = {
          'user.first_name': sortDirection ? 'ASC' : 'DESC',
          'user.last_name': sortDirection ? 'ASC' : 'DESC'
        };
      }

      if (sortColumn.message) {
        sortList = {
          'registers_bulk_update.reporting_message': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllRegistersBulkRegister`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async downloadRegistersReporting(t: any, search: string, sortColumn: any, sortDirection: boolean, selectedDevices: any): Promise<any> {
    // Download All Registers Reporting

    let sortList = {};
    if (sortColumn.createdAt) {
      sortList = {
        'registers_bulk_update.created_at': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.totalRegisters) {
      sortList = {
        'registers_bulk_update.total_devices': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.user) {
      sortList = {
        'user.first_name': sortDirection ? 'ASC' : 'DESC',
        'user.last_name': sortDirection ? 'ASC' : 'DESC'
      };
    }

    if (sortColumn.message) {
      sortList = {
        'registers_bulk_update.reporting_message': sortDirection ? 'ASC' : 'DESC',
      };
    }

    const filter: any = {
      sortList
    };

    let payload: any;

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sortList';
      delete filter[prop];
    }

    if (search?.length > 0) {
      payload = {
        Filter: filter,
        search
      };
    } else {
      payload = {
        Filter: filter,
      };
    }

    if (selectedDevices?.length > 0) {
      payload.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      const data = {
        fieldList: {
          id: 'id',
          created_at: t('reporting_registers_table_created_at'),
          total_devices: t('reporting_registers_table_total_devices'),
          user_id: t('reporting_registers_table_user'),
          reporting_message: t('reporting_registers_table_message')
        },
        ...payload,
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/downloadRegistersBulkUpdate`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllRegistersBulkUpdateDetails(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Ota Bulk Update Details

      let sortList = {};
      if (sortColumn.createdAt) {
        sortList = {
          'registers_bulk_update_details.created_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.registers_pending) {
        sortList = {
          'registers_bulk_update_details.registers_pending': sortDirection ? 'ASC' : 'DESC',
        };
      }
      if (sortColumn.registers_updated_fail) {
        sortList = {
          'registers_bulk_update_details.registers_updated_fail': sortDirection ? 'ASC' : 'DESC',
        };
      }
      if (sortColumn.registers_updated_successful) {
        sortList = {
          'registers_bulk_update_details.registers_updated_successful': sortDirection ? 'ASC' : 'DESC',
        };
      }
      if (sortColumn.status) {
        sortList = {
          'registers_bulk_update_details.status': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.device) {
        sortList = {
          'device.device_name': sortDirection ? 'ASC' : 'DESC',
          'device.serial_number': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.user) {
        sortList = {
          'user.first_name': sortDirection ? 'ASC' : 'DESC',
          'user.last_name': sortDirection ? 'ASC' : 'DESC'
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (currentTab !== 'all') {
        filter.status = currentTab;
      }

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllRegistersBulkUpdateDetails/${getId()}`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async downloadRegistersDetailsReportingTable(t: any, currentTab: string, search: string, sortColumn: any, sortDirection: boolean, selectedDevices: any): Promise<any> {
    // Download Ota Bulk Update Details

    let sortList = {};
    if (sortColumn.createdAt) {
      sortList = {
        'registers_bulk_update_details.created_at': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.registers_pending) {
      sortList = {
        'registers_bulk_update_details.registers_pending': sortDirection ? 'ASC' : 'DESC',
      };
    }
    if (sortColumn.registers_updated_fail) {
      sortList = {
        'registers_bulk_update_details.registers_updated_fail': sortDirection ? 'ASC' : 'DESC',
      };
    }
    if (sortColumn.registers_updated_successful) {
      sortList = {
        'registers_bulk_update_details.registers_updated_successful': sortDirection ? 'ASC' : 'DESC',
      };
    }
    if (sortColumn.status) {
      sortList = {
        'registers_bulk_update_details.status': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.device) {
      sortList = {
        'device.device_name': sortDirection ? 'ASC' : 'DESC',
        'device.serial_number': sortDirection ? 'ASC' : 'DESC',
      };
    }

    if (sortColumn.user) {
      sortList = {
        'user.first_name': sortDirection ? 'ASC' : 'DESC',
        'user.last_name': sortDirection ? 'ASC' : 'DESC'
      };
    }
    const filter: any = {
      sortList
    };

    let payload: any;

    if (currentTab !== 'all') {
      filter.status = currentTab;
    }

    if (Object.keys(sortList)?.length === 0) {
      const prop = 'sortList';
      delete filter[prop];
    }

    if (search?.length > 0) {
      payload = {
        Filter: filter,
        search
      };
    } else {
      payload = {
        Filter: filter,
      };
    }

    if (selectedDevices?.length > 0) {
      payload.idList = selectedDevices;
    }

    return new Promise((resolve, reject) => {
      const data = {
        fieldList: {
          created_at: t('reporting_registers_details_table_created_at'),
          device_name: t('devices_form_name_field'),
          device_id: t('reporting_ota_details_table_device'),
          serial_number: t('reporting_registers_details_table_device'),
          user_id: t('reporting_registers_details_table_user'),
          // reporting_message: t('reporting_registers_details_table_message'),
          registers_pending: t('reporting_registers_details_table_registers_pending'),
          registers_updated_fail: t('reporting_registers_details_table_registers_updated_fail'),
          registers_updated_successful: t('reporting_registers_details_table_registers_updated_successful'),
          status: t('reporting_registers_details_table_status'),
        },
        ...payload,
      };
      axios({
        url: `${process.env.REACT_APP_EXPORT_URL}/downloadRegistersBulkUpdateDetails/${getId()}`,
        responseType: 'blob',
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAdvancedAutoComplete(column: string, search: string): Promise<Device[]> {
    return new Promise((resolve, reject) => {
      const data = {
        project_id: parseInt(getId(), 10),
        AdvanceFilter: {
          [column]: search
        }
      };
      if (window.location.href?.indexOf('project') === -1) {
        const prop = 'project_id';
        delete data[prop];
      }
      if (window.location.href?.indexOf('_project_frequency') !== -1) {
        const prop = 'project_id';
        delete data[prop];
      }
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/deviceAdvanceAutoCompleted`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async deviceProductionDateAutoComplete(search: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/deviceProductionDateAutoComplete`, {
        production_date: search
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAdvanceAutoCompleteFilters(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/advanceAutoComplete`, {
        AdvanceFilter: {
          'client.name': '',
          'device.device_name': '',
          'device.street_number': '',
          'device.house_number': '',
          'device.postal_code': '',
          'device.city': '',
          'device.country': '',
          'device.floor': '',
          'device.construction_number': '',
          'device.firmware': '',
          'device.description': '',
          'device.box_serial_number': '',
          'device.sim_card_number': '',
          'device.order_number': '',
          'device.production_date': '',
          'device.customer_ref': '',
          'device.article_number': '',
          'device.prod_order_nr_week': '',
          'device.neighbourhood': '',
          'device.device_type': '',
          'device.note': ''
        },
        page: 0, // default 0
        limit: 10000 // default 25
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDocumentsByArticleNumber(articleNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DOCS_URL}/article/${articleNumber}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDocumentsDirectories(): Promise <any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DOCS_URL}/all`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async toggleServiceDevice(serialNumberMercurius: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let url: string;
      if (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
        url = process.env.REACT_APP_DEVICES_URL?.replace('cloud/device', 'cloud');
      } else {
        url = process.env.REACT_APP_DEVICES_URL?.replace('/v1/device', '/v1');
      }
      axios.post(`${url}/service-portal`, {
        serial_number: serialNumberMercurius
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async exportAllProjectDevicesMbusLogs(t: any, selectedProjects: any, dateRange: any, dataType: string, exportType: string): Promise<any> {
    const filters: any = {
    };

    if (selectedProjects?.length > 0) {
      filters.idList = selectedProjects;
    }
    filters.date = dateRange;
    filters.data_type = dataType;
    filters.export_type = exportType;

    return new Promise((resolve, reject) => {
      // Download Device Table
      const data = {
        fieldList: {
          MBUS_METER_HEATING: t('MBUS_METER_HEATING'),
          MBUS_METER_COOLING: t('MBUS_METER_COOLING'),
          MBUS_METER_ELECTRICITY: t('MBUS_METER_ELECTRICITY'),
          MBUS_METER_WATER: t('MBUS_METER_WATER'),
          MBUS_METER_BI_DIRECTIONAL: t('MBUS_METER_BI_DIRECTIONAL'),
          MBUS_METER_MANUFACTURER: t('MBUS_METER_MANUFACTURER'),
          MBUS_METER_SERIAL_NUMBER: t('EXPORT_MBUS_METER_SERIAL_NUMBER'),
          MBUS_METER_METER_TYPE: t('MBUS_METER_METER_TYPE'),
          MBUS_METER_STATUS: t('MBUS_METER_STATUS'),
          MBUS_METER_FLOW: `${t('MBUS_METER_FLOW')} (${getMbusRegisterUnit('MBUS_METER_FLOW').trim()})`,
          MBUS_METER_POWER: `${t('MBUS_METER_POWER')} (${getMbusRegisterUnit('MBUS_METER_POWER').trim()})`,
          MBUS_METER_SUPPLY_TEMPERATURE: `${t('MBUS_METER_SUPPLY_TEMPERATURE')} (${getMbusRegisterUnit('MBUS_METER_SUPPLY_TEMPERATURE').trim()})`,
          MBUS_METER_RETURN_TEMPERATURE: `${t('MBUS_METER_RETURN_TEMPERATURE')} (${getMbusRegisterUnit('MBUS_METER_RETURN_TEMPERATURE').trim()})`,
          MBUS_METER_HEATING_ENERGY: `${t('MBUS_METER_HEATING_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_HEATING_ENERGY').trim()})`,
          MBUS_METER_ELECTRICITY_ENERGY: `${t('MBUS_METER_ELECTRICITY_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_ELECTRICITY_ENERGY').trim()})`,
          MBUS_METER_VOLUME: `${t('MBUS_METER_VOLUME')} (m3)`,
          MBUS_METER_COOLING_ENERGY: `${t('MBUS_METER_COOLING_ENERGY')} (${getMbusRegisterUnit('MBUS_METER_COOLING_ENERGY').trim()})`,
          MBUS_METER_CURRENT: `${t('MBUS_METER_CURRENT')} (${getMbusRegisterUnit('MBUS_METER_CURRENT').trim()})`,
          city: t('devices_form_city_field'),
          project_name: t('devices_form_project_field'),
          country: t('devices_form_country_field'),
          date: t('device_single_status_mbus_date_export_label'),
          device_name: t('devices_form_name_field'),
          house_number: t('devices_form_house_number_field'),
          // isEnabled: t('meter_enabled'),
          postal_code: t('devices_form_postal_code_field'),
          serial_number_mercurius: t('devices_form_serial_number_field'),
          street_number: t('devices_form_street_number_field'),
          time: t('device_single_status_mbus_time_export_label'),
          isValidated: t('mbus_export_is_validated_field')
        },
        ...filters,
      };
      axios({
        url: `${process.env.REACT_APP_DEVICES_URL}/exportAllProjectDevicesMbusLogs`,
        method: 'post',
        data,
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllDeviceAutoComplete(search: string): Promise<Device[]> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllDeviceAutoComplete`, {
        search
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async sendMessageToFetchLatestDeviceRegisters(serialNumberList: any, registers: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendMessageToFetchLatestDeviceRegisters`, {
        serialNumberList,
        registers
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDeviceSensorStatus(deviceId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // getDeviceSensorStatus
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/${deviceId}/getDeviceSensorStatus`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async checkDeviceMessageStatusModBus(deviceId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // checkDeviceMessageStatusModBus
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/command_sync/checkDeviceMessageStatusModBusTesting/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async checkDeviceMessageStatusMBus(deviceId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // checkDeviceMessageStatusMBus
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/command_sync/checkDeviceMessageStatusMBus/${deviceId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async sendCommissioningModbusRequest(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // sendCommissioningModbusRequest
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendModbusRequest?device_serial=${serialNumber}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async sendCommissioningMbusRequest(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // sendCommissioningModbusRequest
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendMbusRequest?device_serial=${serialNumber}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async sendModbusMessageToDeviceV2(serialNumber, Modbus): Promise<Device> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendModbusMessageToDeviceV2`, {
        deviceSerial: serialNumber,
        modBusRegisters: Modbus
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async sendFetchSingleMbusMessageToDeviceV2(serialNumber, mbus): Promise<Device> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendFetchSingleMbusMessageToDeviceV2`, {
        deviceSerial: serialNumber,
        mbusRegister: mbus
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async sendFetchAllMbusMessageToDeviceV2(serialNumber): Promise<Device> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendFetchAllMbusMessageToDeviceV2`, {
        deviceSerial: serialNumber,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async sendSearchAllMbusMessageToDeviceV2(serialNumber): Promise<Device> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/sendSearchAllMbusMessageToDeviceV2`, {
        deviceSerial: serialNumber,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getMaxitelStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Maxitel Status

      axios.get(`${process.env.REACT_APP_DEVICE_MONITORING_URL}/maxitelStatus`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllProjectModbusSettings(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get All Unique Article Numbers

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllProjectModbusSettings`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getGlobalModbusSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Global Modbus Settings

      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getGlobalModbusSettings`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async saveGlobalModbusSettings(TR03: any, TR04: any, unGrouped: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Save Global Modbus Settings

      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/saveGlobalModbusSettings`, {
        data: {
          TR03,
          TR04,
          unGrouped,
        }
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async addNewArticleNumber(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Save Global Modbus Settings
      const prop = 'submit';
      delete data[prop];
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/deviceTrCategory`, data).then((response) => {
        // response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getSettingsByArticleNumber(articleNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Save Global Modbus Settings
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getSettingsByArticleNumber`, {
        article_number: articleNumber
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async changeBaudRate(baudRate: number, serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Save Global Modbus Settings
      // axios.post(`https://be-device-fortes-energy-demo.apps.alpha.it22.cloud/device/changeBaudRate/${serialNumber}`, {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/changeBaudRate/${serialNumber}`, {
        baud_rate: baudRate
      }).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async isBaudRateChanged(serialNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Save Global Modbus Settings
      // axios.get(`https://be-device-fortes-energy-demo.apps.alpha.it22.cloud/device/isBaudRateChanged/${serialNumber}`).then((response) => {
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/isBaudRateChanged/${serialNumber}`).then((response) => {
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkUpdateLegalDocumentReview(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk Update Legal Document Review
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/bulkUpdateLegalDocumentReview`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data:any = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateDocumentReviewStatus(userId: any, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Bulk Update Legal Document Review
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/updateDocumentReviewStatus/${userId}`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data:any = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateUserCookieConsent(userId, userCookieConsent): Promise<any> {
    // Update User Last Seen
    return new Promise((resolve, reject) => {
      let formattedCookieConsent = userCookieConsent === true ? 1 : userCookieConsent;
      formattedCookieConsent = userCookieConsent === false ? 0 : userCookieConsent;
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/updateUserCookieConsent/${userId}`, {
        user_cookie_consent: formattedCookieConsent
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async globalModbusSettingAutoComplete(value: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Global Modbus Setting AutoComplete
      const data: any = {
        search: value
      };
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/globalModbusSetting/autoComplete`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async insertCustomDeviceEvent(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/insertDeviceEvent`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }
}

export const deviceApi = new DeviceApi();
