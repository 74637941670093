/* eslint-disable import/extensions */
// import { isUndefined } from 'lodash';
import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DeviceTesting from './pages/device/DeviceTesting';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);
// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const NoContentFound = Loadable(lazy(() => import('./pages/NoContentFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const TwoFactorAuthentication = Loadable(lazy(() => import('./pages/authentication/TwoFactorAuthentication')));
const ForgotPassword = Loadable(lazy(() => import('./pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./pages/authentication/ResetPassword')));

// Dashboard pages
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));

// Device Pages
const DeviceDowloadBulkMbusDataView = Loadable(lazy(() => import('./pages/device/DeviceBulkMbusDownloadView')));
const DeviceDowloadBulkModbusDataView = Loadable(lazy(() => import('./pages/device/DeviceBulkModbusDownloadView')));
const DeviceSingleDeviceView = Loadable(lazy(() => import('./pages/device/DeviceSingleDeviceView')));
const DeviceMain = Loadable(lazy(() => import('./pages/device/DeviceOverview')));
const DeviceAddView = Loadable(lazy(() => import('./pages/device/DeviceAddView')));
const DeviceEditView = Loadable(lazy(() => import('./pages/device/DeviceEditView')));
const DeviceBulkEditRegisterView = Loadable(lazy(() => import('./pages/device/DeviceBulkEditRegisterView')));
const DeviceBulkEditOTAView = Loadable(lazy(() => import('./pages/device/DeviceBulkEditOTAView')));
const DeviceMonitoringView = Loadable(lazy(() => import('./pages/device/DeviceMonitoringView')));
const DeviceMonitoringErrorDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/DeviceMonitoringErrorDetailsView')));
const DeviceMonitoringSIMDataUsageDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/DeviceMonitoringSIMDataUsageDetailsView')));
const DeviceMonitoringOTADetailsView = Loadable(lazy(() => import('./pages/device_monitoring/DeviceMonitoringOTADetailsView')));
const MonitoringProjectDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringProjectDetailsView')));
const MonitoringFirmwareDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringFirmwareDetailsView')));
const MonitoringDeviceFrequencyDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringDeviceFrequencyDetailsView')));
const MonitoringProjectFrequencyDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringProjectFrequencyDetailsView')));
const MonitoringFirmwareFrequencyDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringFirmwareFrequencyDetailsView')));
const MonitoringUserDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringUserDetailsView')));
const MonitoringDeviceStatusDetailsView = Loadable(lazy(() => import('./pages/device_monitoring/MonitoringDeviceStatusDetailsView')));

// Commissioning Pages
// const CommissioningUpdateView = Loadable(lazy(() => import('./pages/commissioning/CommissioningUpdateView')));
const CommissioningWizardView = Loadable(lazy(() => import('./pages/commissioning/CommissioningWizardView')));

// Swapping Pages
const SwappingUpdateView = Loadable(lazy(() => import('./pages/swapping/SwappingUpdateView')));

// Swapped Devices Pages
const SwappedDevicesView = Loadable(lazy(() => import('./pages/swapped_device/SwappedDevicesView')));

// Device Clearing Pages
const DeviceClearingUpdateView = Loadable(lazy(() => import('./pages/clear_device/ClearDeviceUpdateView')));

// PartnerSetting Pages
const PartnerSettingEditView = Loadable(lazy(() => import('./pages/partner_setting/PartnerSettingEditView')));

// Client Pages
const ClientOverview = Loadable(lazy(() => import('./pages/client/ClientOverview')));
const ClientSingleView = Loadable(lazy(() => import('./pages/client/ClientSingleView')));
const ClientSingleErrorView = Loadable(lazy(() => import('./pages/client/ClientSingleErrorView')));
const ClientEditView = Loadable(lazy(() => import('./pages/client/ClientEditView')));
const ClientAddView = Loadable(lazy(() => import('./pages/client/ClientAddView')));

// Legal Document Pages
const LegalDocumentOverview = Loadable(lazy(() => import('./pages/legal_document/LegalDocumentOverview')));
const LegalDocumentEditView = Loadable(lazy(() => import('./pages/legal_document/LegalDocumentEditView')));
const LegalDocumentAddView = Loadable(lazy(() => import('./pages/legal_document/LegalDocumentAddView')));

// Project Pages
const ProjectOverview = Loadable(lazy(() => import('./pages/project/ProjectOverview')));
const ProjectSingleView = Loadable(lazy(() => import('./pages/project/ProjectSingleView')));
const ProjectAddView = Loadable(lazy(() => import('./pages/project/ProjectAddView')));
const ServiceClientSingleErrorView = Loadable(lazy(() => import('./pages/service_client/ServiceClientSingleErrorView')));
const ProjectBulkModbusDowloadView = Loadable(lazy(() => import('./pages/project/ProjectBulkModbusDownloadView')));
const ProjectBulkMbusDowloadView = Loadable(lazy(() => import('./pages/project/ProjectBulkMbusDownloadView')));
const ProjectEditView = Loadable(lazy(() => import('./pages/project/ProjectEditView')));

// Subscription Pages
const SubscriptionOverview = Loadable(lazy(() => import('./pages/subscription/SubscriptionOverview')));
const SubscriptionAnalyticsView = Loadable(lazy(() => import('./pages/subscription_analytics/SubscriptionAnalyticsView')));
const SubscriptionAddView = Loadable(lazy(() => import('./pages/subscription/SubscriptionAddView')));
const SubscriptionEditView = Loadable(lazy(() => import('./pages/subscription/SubscriptionEditView')));

// Service Client Pages
const ServiceClientOverview = Loadable(lazy(() => import('./pages/service_client/ServiceClientOverview')));
const ServiceClientSingleView = Loadable(lazy(() => import('./pages/service_client/ServiceClientSingleView')));
const ServiceClientEditView = Loadable(lazy(() => import('./pages/service_client/ServiceClientEditView')));
const ServiceClientAddView = Loadable(lazy(() => import('./pages/service_client/ServiceClientAddView')));

// User Pages
const UserEditView = Loadable(lazy(() => import('./pages/user/UserEditView')));
const UserAddView = Loadable(lazy(() => import('./pages/user/UserAddView')));

// Admin Overview Page
const AdminOverview = Loadable(lazy(() => import('./pages/admin/AdminOverview')));

// Appliance Reference Overview Page
const ApplianceReferenceOverview = Loadable(lazy(() => import('./pages/appliance_reference/ApplianceReferenceOverview')));
const ApplianceReferenceAddView = Loadable(lazy(() => import('./pages/appliance_reference/ApplianceReferenceAddView')));
const ApplianceReferenceEditView = Loadable(lazy(() => import('./pages/appliance_reference/ApplianceReferenceEditView')));

const SettingView = Loadable(lazy(() => import('./pages/setting/SettingView')));

// settings_menu
const SettingMenuView = Loadable(lazy(() => import('./pages/setting_menu/SettingMenuView')));
const SettingMenuDetailView = Loadable(lazy(() => import('./pages/setting_menu_detail/SettingMenuDetailView')));

// reporting_menu
const ReportingMenuView = Loadable(lazy(() => import('./pages/reporting_menu/ReportingMenuView')));

// Preset Pages
const PresetOverview = Loadable(lazy(() => import('./pages/preset/PresetOverview')));
const PresetAddView = Loadable(lazy(() => import('./pages/preset/PresetAddView')));
const PresetEditView = Loadable(lazy(() => import('./pages/preset/PresetEditView')));

// Audit
const AuditOverview = Loadable(lazy(() => import('./pages/audit/AuditOverview')));
const OTAAuditOverview = Loadable(lazy(() => import('./pages/audit/OTAAuditOverview')));
const OTADeviceDetailsOverview = Loadable(lazy(() => import('./pages/audit/OTADeviceDetailsOverview')));
const RegistersAuditOverview = Loadable(lazy(() => import('./pages/audit/RegistersAuditOverview')));
const RegistersDeviceDetailsOverview = Loadable(lazy(() => import('./pages/audit/RegistersDeviceDetailsOverview')));

// Error Check
const ErrorCheckOverview = Loadable(lazy(() => import('./pages/error_check/ErrorCheckOverview')));
const ErrorCheckDetailsOverview = Loadable(lazy(() => import('./pages/error_check/ErrorCheckDetailsOverview')));

// Change Logs
const ChangeLogs = Loadable(lazy(() => import('./pages/ChangeLogs')));

// Search
const SearchOverview = Loadable(lazy(() => import('./pages/search/SearchOverview')));

// Role Pages
const RolesOverview = Loadable(lazy(() => import('./pages/role/RolesOverview')));
const RoleList = Loadable(lazy(() => import('./pages/role/RoleOverview')));
const RoleAddView = Loadable(lazy(() => import('./pages/role/RoleAddView')));
const RoleEditView = Loadable(lazy(() => import('./pages/role/RoleEditView')));
const RoleDuplicateView = Loadable(lazy(() => import('./pages/role/RoleDuplicateView')));

// Kong Users Pages
const KongUsersOverview = Loadable(lazy(() => import('./pages/kong_client_user/KongUserOverview')));
const KongUsersAddView = Loadable(lazy(() => import('./pages/kong_client_user/KongUserAddView')));
const KongUsersEditView = Loadable(lazy(() => import('./pages/kong_client_user/KongUserEditView')));
const KongUserSingleView = Loadable(lazy(() => import('./pages/kong_client_user/KongUserSingleView')));

// Kong Admin Users Pages
const KongUsersAdminOverview = Loadable(lazy(() => import('./pages/kong_admin_user/KongUserOverview')));
const KongUsersAdminAddView = Loadable(lazy(() => import('./pages/kong_admin_user/KongUserAddView')));
const KongUsersAdminEditView = Loadable(lazy(() => import('./pages/kong_admin_user/KongUserEditView')));
const KongUserAdminSingleView = Loadable(lazy(() => import('./pages/kong_admin_user/KongUserSingleView')));

// Module Pages
const ModuleOverview = Loadable(lazy(() => import('./pages/module/ModuleOverview')));
const ModuleAddView = Loadable(lazy(() => import('./pages/module/ModuleAddView')));
const ModuleEditView = Loadable(lazy(() => import('./pages/module/ModuleEditView')));

// Firmware Pages
const FirmwareOverview = Loadable(lazy(() => import('./pages/firmware/FirmwareOverview')));
const FirmwareEditView = Loadable(lazy(() => import('./pages/firmware/FirmwareEditView')));

// Modbus Global Configuration Page
const ModbusGlobalConfigurationOverview = Loadable(lazy(() => import('./pages/modbus_global_configuration/ModbusGlobalConfigurationOverview')));
const ModbusGlobalConfigurationAddView = Loadable(lazy(() => import('./pages/modbus_global_configuration/ModbusGlobalConfigurationAddView')));

// Diagnostic Formulas Pages
const DiagnosticFormulasOverview = Loadable(lazy(() => import('./pages/diagnostic_formula/DiagnosticFormulasOverview')));
const DiagnosticFormulasAddView = Loadable(lazy(() => import('./pages/diagnostic_formula/DiagnosticFormulasAddView')));
const DiagnosticFormulasEditView = Loadable(lazy(() => import('./pages/diagnostic_formula/DiagnosticFormulasEditView')));

// Diagnostic Category Pages
const DiagnosticCategoriesOverview = Loadable(lazy(() => import('./pages/diagnostic_category/DiagnosticCategoriesOverview')));
const DiagnosticCategoriesAddView = Loadable(lazy(() => import('./pages/diagnostic_category/DiagnosticCategoriesAddView')));
const DiagnosticCategoriesEditView = Loadable(lazy(() => import('./pages/diagnostic_category/DiagnosticCategoriesEditView')));

// Diagnostic Flows Execution Pages
const DiagnosticExecutionCounterOverview = Loadable(lazy(() => import('./pages/audit/DiagnosticExecutionCounterOverview')));

// Diagnostic Flows Execution Pages
const DiagnosticExecutionOverview = Loadable(lazy(() => import('./pages/audit/DiagnosticExecutionOverview')));
const DiagnosticExecutionSingleView = Loadable(lazy(() => import('./pages/audit/DiagnosticExecutionSingleView')));

// Diagnostic Flows Pages
const DiagnosticFlowsOverview = Loadable(lazy(() => import('./pages/diagnostic_flow/DiagnosticFlowsOverview')));
const DiagnosticFlowsAddView = Loadable(lazy(() => import('./pages/diagnostic_flow/DiagnosticFlowsAddView')));
const DiagnosticFlowsEditView = Loadable(lazy(() => import('./pages/diagnostic_flow/DiagnosticFlowsEditView')));
const DiagnosticFlowsDuplicateView = Loadable(lazy(() => import('./pages/diagnostic_flow/DiagnosticFlowsDuplicateView')));

// Notifications Pages
const NotificationsOverview = Loadable(lazy(() => import('./pages/notification/NotificationOverview')));
const NotificationsAddView = Loadable(lazy(() => import('./pages/notification/NotificationAddView')));
const NotificationsEditView = Loadable(lazy(() => import('./pages/notification/NotificationEditView')));
const UserNotificationsOverview = Loadable(lazy(() => import('./pages/user_notification/NotificationOverview')));

// Permission Pages
const PermissionOverview = Loadable(lazy(() => import('./pages/permission/PermissionOverview')));
const PermissionAddView = Loadable(lazy(() => import('./pages/permission/PermissionAddView')));
const PermissionEditView = Loadable(lazy(() => import('./pages/permission/PermissionEditView')));

// Monitoring
const MonitoringOverview = Loadable(lazy(() => import('./pages/monitoring/MonitoringOverview')));

// react-flow
const Flow = Loadable(lazy(() => import('./pages/Flow')));

// Mbus Reporting Overview
const MbusReportingOverview = Loadable(lazy(() => import('./pages/audit/MbusReportingOverview')));
const MbusMissingHourlyReportingOverview = Loadable(lazy(() => import('./pages/audit/MbusMissingHourlyReportingOverview')));

// Mbus Reporting Snoozed Devices
const MbusReportingSnoozedDevicesOverview = Loadable(lazy(() => import('./pages/audit/MbusReportingSnoozedDevicesOverview')));

// Inconsistent Mbus Devices Reporting
const InconsistentMbusDevicesReportingOverview = Loadable(lazy(() => import('./pages/audit/InconsistentMbusDevicesReportingOverview')));
const InconsistentMbusDevicesReportingDetailsOverview = Loadable(lazy(() => import('./pages/audit/InconsistentMbusDevicesReportingDetailsOverview')));

// Email Reporting Pages
const EmailReportingOverview = Loadable(lazy(() => import('./pages/audit/EmailReportingOverviewView')));
const EmailReportingSingleView = Loadable(lazy(() => import('./pages/audit/EmailReportingSingleView')));

// eslint-disable-next-line import/no-mutable-exports
const routes: PartialRouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Analytics />
      },
    ]
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'client',
        children: [
          {
            path: '/',
            element: <ClientOverview />
          },
          {
            path: '/create',
            element: <ClientAddView />
          },
          {
            path: ':clientId',
            element: <ClientSingleView />
          },
          {
            path: 'edit/:clientId',
            element: <ClientEditView />
          },
          {
            path: 'error_device/:clientId',
            element: <ClientSingleErrorView />
          },
        ]
      },
      {
        path: 'legal_document',
        children: [
          {
            path: '/',
            element: <LegalDocumentOverview />
          },
          {
            path: '/create',
            element: <LegalDocumentAddView />
          },
          {
            path: 'edit/:legalDocumentId',
            element: <LegalDocumentEditView />
          },
        ]
      },
      {
        path: 'subscription',
        children: [
          {
            path: '/',
            element: <SubscriptionOverview />
          },
          {
            path: '/create',
            element: <SubscriptionAddView />
          },
        ]
      },
      {
        path: '/analytics_subscription',
        children: [
          {
            path: '/',
            element: <SubscriptionAnalyticsView />
          },
        ]
      },
      {
        path: 'project',
        children: [
          {
            path: '/',
            element: <ProjectOverview />
          },
          {
            path: '/create',
            element: <ProjectAddView />
          },
          {
            path: ':projectId',
            element: <ProjectSingleView />
          },
          {
            path: 'edit/:projectId',
            element: <ProjectEditView />
          },
          {
            path: 'bulk-dowload-mbus',
            element: <ProjectBulkMbusDowloadView />
          },
          {
            path: 'bulk-dowload-modbus',
            element: <ProjectBulkModbusDowloadView />
          },
          {
            path: 'subscription',
            children: [
              {
                path: ':projectId',
                element: <SubscriptionEditView />
              },
            ]
          },
        ]
      },
      {
        path: 'diagnostic_formula',
        children: [
          {
            path: '/',
            element: <DiagnosticFormulasOverview />
          },
          {
            path: '/create',
            element: <DiagnosticFormulasAddView />
          },
          {
            path: 'edit/:projectId',
            element: <DiagnosticFormulasEditView />
          },
        ]
      },
      {
        path: 'diagnostic_category',
        children: [
          {
            path: '/',
            element: <DiagnosticCategoriesOverview />
          },
          {
            path: '/create',
            element: <DiagnosticCategoriesAddView />
          },
          {
            path: 'edit/:projectId',
            element: <DiagnosticCategoriesEditView />
          },
        ]
      },
      {
        path: 'diagnostic_flow',
        children: [
          {
            path: '/',
            element: <DiagnosticFlowsOverview />
          },
          {
            path: '/create',
            element: <DiagnosticFlowsAddView />
          },
          {
            path: 'edit/:flowId',
            element: <DiagnosticFlowsEditView />
          },
          {
            path: 'duplicate/:flowId',
            element: <DiagnosticFlowsDuplicateView />
          },
        ]
      },
      {
        path: 'diagnostic_flow_execution',
        children: [
          {
            path: '/',
            element: <DiagnosticExecutionOverview />
          },
          {
            path: ':diagnosticExecutionId',
            element: <DiagnosticExecutionSingleView />
          },
        ]
      },
      {
        path: 'email_report',
        children: [
          {
            path: '/',
            element: <EmailReportingOverview />
          },
          {
            path: ':emailId',
            element: <EmailReportingSingleView />
          },
        ]
      },
      {
        path: 'diagnostic_counter',
        children: [
          {
            path: '/',
            element: <DiagnosticExecutionCounterOverview />
          },
        ]
      },
      {
        path: 'role',
        children: [
          {
            path: '/overview',
            element: <RolesOverview />
          },
          {
            path: '/',
            element: <RoleList />
          },
          {
            path: '/create',
            element: <RoleAddView />
          },
          {
            path: 'edit/:roleId',
            element: <RoleEditView />
          },
          {
            path: 'duplicate/:roleId',
            element: <RoleDuplicateView />
          },
        ]
      },
      {
        path: 'kong_client_user',
        children: [
          {
            path: '/',
            element: <KongUsersOverview />
          },
          {
            path: '/create',
            element: <KongUsersAddView />
          },
          {
            path: 'edit/:threeScaleUserId',
            element: <KongUsersEditView />
          },
          {
            path: ':threeScaleUserId',
            element: <KongUserSingleView />
          },
        ]
      },
      {
        path: 'kong_admin_user',
        children: [
          {
            path: '/',
            element: <KongUsersAdminOverview />
          },
          {
            path: '/create',
            element: <KongUsersAdminAddView />
          },
          {
            path: 'edit/:threeScaleUserId',
            element: <KongUsersAdminEditView />
          },
          {
            path: ':threeScaleUserId',
            element: <KongUserAdminSingleView />
          },
        ]
      },
      {
        path: 'module',
        children: [
          {
            path: '/',
            element: <ModuleOverview />
          },
          {
            path: '/create',
            element: <ModuleAddView />
          },
          {
            path: 'edit/:moduleId',
            element: <ModuleEditView />
          },
        ]
      },
      {
        path: 'firmware',
        children: [
          {
            path: '/',
            element: <FirmwareOverview />
          },
          {
            path: 'edit/:moduleId',
            element: <FirmwareEditView />
          },
        ]
      },
      {
        path: 'permission',
        children: [
          {
            path: '/',
            element: <PermissionOverview />
          },
          {
            path: '/create',
            element: <PermissionAddView />
          },
          {
            path: 'edit/:permissionId',
            element: <PermissionEditView />
          },
        ]
      },
      {
        path: 'presets',
        children: [
          {
            path: '/',
            element: <PresetOverview />
          },
          {
            path: '/create',
            element: <PresetAddView />
          },
          {
            path: 'edit/:presetId',
            element: <PresetEditView />
          },
        ]
      },
      {
        path: 'partner_setting',
        children: [
          {
            path: '/',
            element: <PartnerSettingEditView />
          },
        ]
      },
      {
        path: 'service_client',
        children: [
          {
            path: '/',
            element: <ServiceClientOverview />
          },
          {
            path: '/create',
            element: <ServiceClientAddView />
          },
          {
            path: ':serviceClientId',
            element: <ServiceClientSingleView />
          },
          {
            path: 'edit/:serviceClientId',
            element: <ServiceClientEditView />
          },
          {
            path: 'error_device/:serviceClientId',
            element: <ServiceClientSingleErrorView />
          },
        ]
      },
      {
        path: 'client_user',
        children: [
          {
            path: '/create/:clientId',
            element: <UserAddView />
          },
          {
            path: 'edit/:userId',
            element: <UserEditView />
          },
        ]
      },
      {
        path: 'fortes_user',
        children: [
          {
            path: '/',
            element: <AdminOverview />
          },
          {
            path: '/create',
            element: <UserAddView />
          },
          {
            path: 'edit/:userId',
            element: <UserEditView />
          },
        ]
      },
      {
        path: 'appliance_type',
        children: [
          {
            path: '/',
            element: <ApplianceReferenceOverview />
          },
          {
            path: '/create',
            element: <ApplianceReferenceAddView />
          },
          {
            path: 'edit/:deviceApplianceTypeId',
            element: <ApplianceReferenceEditView />
          },
        ]
      },
      {
        path: 'service_client_user',
        children: [
          {
            path: '/create/:clientId',
            element: <UserAddView />
          },
          {
            path: 'edit/:userId',
            element: <UserEditView />
          },
        ]
      },
      {
        path: 'monitoring',
        element: <DeviceMonitoringView />,
      },
      {
        path: 'monitoring_error_list',
        element: <DeviceMonitoringErrorDetailsView />,
      },
      {
        path: 'monitoring_sim_data_usage_list',
        element: <DeviceMonitoringSIMDataUsageDetailsView />,
      },
      {
        path: 'monitoring_ota_detail_list',
        element: <DeviceMonitoringOTADetailsView />,
      },
      {
        path: 'monitoring_project_detail_list',
        element: <MonitoringProjectDetailsView />,
      },
      {
        path: 'monitoring_firmware_detail_list',
        element: <MonitoringFirmwareDetailsView />,
      },
      {
        path: 'monitoring_device_frequency_detail_list',
        element: <MonitoringDeviceFrequencyDetailsView />,
      },
      {
        path: 'monitoring_project_frequency_detail_list',
        element: <MonitoringProjectFrequencyDetailsView />,
      },
      {
        path: 'monitoring_firmware_frequency_detail_list',
        element: <MonitoringFirmwareFrequencyDetailsView />,
      },
      {
        path: 'monitoring_user_detail_list',
        element: <MonitoringUserDetailsView />,
      },
      {
        path: 'monitoring_device_status_detail_list',
        element: <MonitoringDeviceStatusDetailsView />,
      },
      {
        path: 'change_logs',
        element: <ChangeLogs />
      },
      {
        path: 'flow',
        element: <Flow />
      },
      {
        path: 'search',
        element: <SearchOverview />
      },
      {
        path: 'monitoring',
        element: <MonitoringOverview />
      },
      {
        path: 'device',
        children: [
          {
            path: '/',
            element: <DeviceMain />,
          },
          {
            path: '/create',
            element: <DeviceAddView />
          },
          {
            path: '/test',
            element: <DeviceTesting />
          },
          {
            path: ':deviceId',
            element: <DeviceSingleDeviceView />
          },
          {
            path: 'edit/:deviceId',
            element: <DeviceEditView />
          },
          {
            path: 'bulk-edit-registers',
            element: <DeviceBulkEditRegisterView />
          },
          {
            path: 'bulk-edit-ota',
            element: <DeviceBulkEditOTAView />
          },
          {
            path: 'bulk-dowload-mbus',
            element: <DeviceDowloadBulkMbusDataView />
          },
          {
            path: 'bulk-dowload-modbus',
            element: <DeviceDowloadBulkModbusDataView />
          },
        ]
      },
      {
        path: 'commissioning',
        children: [
          {
            path: '/',
            element: <CommissioningWizardView />,
            // element: <CommissioningUpdateView />,
          }
        ]
      },
      {
        path: 'wizard_for_commissioning',
        children: [
          {
            path: '/',
            element: <CommissioningWizardView />,
          }
        ]
      },
      {
        path: 'notification',
        children: [
          {
            path: '/',
            element: <NotificationsOverview />,
          },
          {
            path: '/create',
            element: <NotificationsAddView />
          },
          {
            path: 'edit/:deviceId',
            element: <NotificationsEditView />
          },
        ]
      },
      {
        path: 'user_notification',
        children: [
          {
            path: '/',
            element: <UserNotificationsOverview />,
          },
        ]
      },
      {
        path: 'ota_reporting',
        children: [
          {
            path: '/',
            element: <OTAAuditOverview />,
          },
          {
            path: ':otaId',
            element: <OTADeviceDetailsOverview />,
          },
        ]
      },
      {
        path: 'inconsistent_mbus_devices_reporting',
        children: [
          {
            path: '/',
            element: <InconsistentMbusDevicesReportingOverview />,
          },
          {
            path: ':inconsistentMbusDevicesReportingId',
            element: <InconsistentMbusDevicesReportingDetailsOverview />,
          },
        ]
      },
      {
        path: 'mbus_reporting',
        children: [
          {
            path: '/',
            element: <MbusReportingOverview />,
          },
        ]
      },
      {
        path: 'mbus_reporting_snoozed_device',
        children: [
          {
            path: '/',
            element: <MbusReportingSnoozedDevicesOverview />,
          },
        ]
      },
      {
        path: 'mbus_missing_hourly_reporting',
        children: [
          {
            path: '/',
            element: <MbusMissingHourlyReportingOverview />,
          },
        ]
      },
      {
        path: 'mbus_missing_hourly_details_reporting',
        children: [
          {
            path: ':mbusReportingId',
            element: <MbusMissingHourlyReportingOverview />,
          },
        ]
      },
      {
        path: 'mbus_missing_hourly_details_digest_reporting',
        children: [
          {
            path: ':digestId',
            element: <MbusMissingHourlyReportingOverview />,
          },
        ]
      },
      {
        path: 'mbus_reporting_detail',
        children: [
          {
            path: ':uniqueIdentifier',
            element: <MbusReportingOverview />,
          },
        ]
      },
      {
        path: 'mbus_reporting_digest',
        children: [
          {
            path: ':digestId',
            element: <MbusReportingOverview />,
          },
        ]
      },
      {
        path: 'error_device',
        children: [
          {
            path: '/',
            element: <ErrorCheckOverview />,
          },
          {
            path: ':errorDeviceId',
            element: <ErrorCheckDetailsOverview />,
          },
        ]
      },
      {
        path: 'register_reporting',
        children: [
          {
            path: '/',
            element: <RegistersAuditOverview />,
          },
          {
            path: ':registerId',
            element: <RegistersDeviceDetailsOverview />,
          },
        ]
      },
      {
        path: 'swapping',
        children: [
          {
            path: '/',
            element: <SwappingUpdateView />,
          }
        ]
      },
      {
        path: 'swapped_device',
        children: [
          {
            path: '/',
            element: <SwappedDevicesView />,
          }
        ]
      },
      {
        path: 'clear_device',
        children: [
          {
            path: '/',
            element: <DeviceClearingUpdateView />,
          }
        ]
      },
      {
        path: 'setting',
        children: [
          {
            path: '/',
            element: <SettingView />,
          },
        ]
      },
      {
        path: 'setting_menu',
        children: [
          {
            path: '/',
            element: <SettingMenuView />,
          },
          {
            path: ':settingMenu',
            element: <SettingMenuDetailView />,
          },
        ]
      },
      {
        path: 'reporting_menu',
        children: [
          {
            path: '/',
            element: <ReportingMenuView />,
          },
        ]
      },
      {
        path: 'audit',
        children: [
          {
            path: '/',
            element: <AuditOverview />,
          },
        ]
      },
      {
        path: 'modbus_global_configuration',
        children: [
          {
            path: '/',
            element: <ModbusGlobalConfigurationOverview />,
          },
          {
            path: 'create',
            element: <ModbusGlobalConfigurationAddView />,
          },
        ]
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/two-factor-authentication',
        element: (
          <AuthGuard>
            <TwoFactorAuthentication />
          </AuthGuard>
        )
      },
      {
        path: '/forgot-password',
        element: (
          <GuestGuard>
            <ForgotPassword />
          </GuestGuard>
        )
      },
      {
        path: '/reset-password/:id',
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: 'not-found',
        element: <NoContentFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
