import { chain, isArray, isNull, isUndefined } from 'lodash';

const errorsList = [
  { 0: 'E2PROM_READ_ERROR' },
  { 4: 'BLOCKING_TOO_LONG' },
  { 8: 'RAM_ERROR' },
  { 9: 'WRONG_EEPROM_SIGNATURE' },
  { 10: 'E2PROM_ERROR' },
  { 11: 'STATE_ERROR' },
  { 12: 'ROM_ERROR' },
  { 15: 'MAX_TEMP_ERROR' },
  { 17: 'STACK_ERROR' },
  { 18: 'INSTRUCTION_ERROR' },
  { 23: 'CORRUPTED_ERROR_NR' },
  { 29: 'PSM_ERROR' },
  { 30: 'REGISTER_ERROR' },
  { 36: 'COOLING_TO_HIGH' },
  { 106: 'REFHI_TOO_HIGH' },
  { 107: 'REFHI_TOO_LOW' },
  { 108: 'REFLO_TOO_HIGH' },
  { 109: 'REFLO_TOO_LOW' },
  { 110: 'REFHI2_TOO_HIGH' },
  { 111: 'REFHI2_TOO_LOW' },
  { 112: 'REFLO2_TOO_HIGH' },
  { 113: 'REFLO2_TOO_LOW' },
  { 115: 'LOW_WATER_PRESSURE_ERROR' },
  { 116: 'LOW_WATER_PRESSURE_SENSOR' },
  { 119: 'RETURN_OPEN' },
  { 120: 'SUPPLY_OPEN' },
  { 121: 'SUPPLY2_OPEN' },
  { 122: 'DHW_OPEN' },
  { 126: 'RETURN_SHORTED' },
  { 127: 'SUPPLY_SHORTED' },
  { 128: 'SUPPLY2_SHORTED' },
  { 129: 'DHW_SHORTED' },
  { 134: 'RESET_BUTTON_ERROR' },
  { 167: 'RETURN2_SHORTED' },
  { 168: 'RETURN2_OPEN' },
  { 169: 'HEAT_COOL_ACTIVE' },
  { 170: 'HW_IDENTIFIER_ERROR' },
  { 203: 'HIGH_PRESSURE' },
  { 204: 'OUTDOOR_WRONG' },
  { 206: 'TE_BOOSTER_WARNING' },
  { 256: 'MODBUS_WARNING_MESSAGE' },
  { 255: 'No Error' }
];

const getExcludedErrors = (): any => {
  let permissions: any = sessionStorage.getItem('permissions');
  permissions = !isUndefined(permissions) && !isNull(permissions) ? JSON.parse(permissions)?.permissions : [];

  permissions = isArray(permissions) ? permissions : [];
  const processedPermissions = chain(permissions)
    .map((perm) => perm.replace('-read', ''))
    .map((perm) => perm.replace(/-/g, '_'))
    .filter((perm) => /^[A-Z]{1}/.test(perm))
    .value();

  const excludedErrors = errorsList
    .filter((obj) => {
      const errorCode = Object.keys(obj)[0];
      const errorValue = Object.values(obj)[0];
      return errorCode !== '255' && !processedPermissions.includes(errorValue);
    })
    .map((obj) => Object.keys(obj)[0]);
  return excludedErrors;
};

export default getExcludedErrors;
