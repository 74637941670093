import type { FC } from 'react';
import { Box } from '@material-ui/core';
import Logo from './Logo';
import PropTypes from 'prop-types';

interface SplashScreenProps {
  size?: any;
}

const SplashScreen: FC<SplashScreenProps> = ({ size }) => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    <Logo
      size={size}
    />
  </Box>
);

SplashScreen.propTypes = {
  size: PropTypes.string.isRequired,
};

export default SplashScreen;
