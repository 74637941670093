/* eslint-disable operator-linebreak */
import { isNull, isUndefined } from 'lodash';

const partnerGlobalConfigLocal = JSON.parse(
  window.localStorage.getItem('partner_settings')
);
let primaryColor =
  process.env.REACT_APP_PLATFORM_DEFAULT_PRIMARY_COLOR || '#006699';

if (
  !isNull(partnerGlobalConfigLocal) &&
  !isUndefined(partnerGlobalConfigLocal)
) {
  primaryColor = partnerGlobalConfigLocal?.color_schema?.primary;
}

/* eslint-disable @typescript-eslint/indent */
export const nl = {
         login_sign_in: 'Log in',
         login_email_address: 'Email adres',
         login_form_password_label: 'Wachtwoord',
         login_forget_password: 'Wachtwoord vergeten',
         login_credentials_error: 'Controleer uw email adres en wachtwoord',
         login_api_error: 'Interne login API Error',
         internal_server_error: 'Internal Server Error',
         /* Forget Password */
         forget_password_title: 'Wachtwoord vergeten',
         forget_password_email_address: 'Email adres',
         forget_password_button: 'Stuur een link om uw wachtwoord te resetten.',
         forget_password_login: 'Terug',
         forgot_password_form_success:
           'E-mail voor wachtwoordherstel wordt doorgestuurd. Controleer uw inbox. Het kan zijn dat de e-mail in uw spam terecht is gekomen.',
         forgot_password_subject: 'Fortes Data Beheer  - Wachtwoord Vergeten',
         temporary_password_subject:
           'Fortes Data Beheer - Nieuw Account Aangemaakt',
         forgot_password_required_form_error: 'Email adres is verplicht',
         forgot_password_valid_form_error: 'Geen geldig email adres',
         /* END Forget Password */
         /** Herstel Password */
         reset_password_title: 'Herstel wachtwoord',
         reset_password_new_password: 'Nieuw wachtwoord',
         reset_password_confirm_password: 'Bevestig wachtwoord',
         reset_password_new_password_required_form_error:
           'Nieuw wachtwoord is verplicht',
         reset_password_confirm_password_required_form_error:
           'Bevestiging van het wachtwoord is verplicht',
         reset_password_passwords_not_matching:
           'De wachtwoorden komen niet overeen',
         reset_password_new_password_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken.',
         reset_password_button: 'Herstel wachtwoord',
         reset_password_form_success: 'Het wachtwoord is succesvol gewijzigd.',
         /** Eind Herstel Password */
         /* DASHBOARD */
         dashboard_title: 'Dashboard',
         dashboard_amount_of_devices: 'AANTAL DEVICES',
         dashboard_amount_of_devices_week: 'AANTAL DEVICES VORIGE WEEK',
         dashboard_amount_of_devices_error: 'AANTAL DEVICES MET STORINGEN',
         dashboard_temperature: 'RETOURTEMPERATUUR IN CELCIUS',
         dashboard_device_status: 'Device overzicht',
         dashboard_routing_temperature: 'Temperaturen van alles devices',
         dashboard_routing_temperature_hr: 'Uur',
         dashboard_error_devices: 'Devices met Actieve storingen:',
         dashboard_error: 'STORING',
         dashboard_online: 'ONLINE',
         dashboard_offline: 'OFFLINE',
         'dashboard organic': 'Organic',
         'dashboard referal': 'Referral',
         dashboard_see_all: 'SEE ALL',
         /* END DASHBOARD */
         /* Sidebar */
         date_range: 'Datum',
         sidebar_dashboard: 'Dashboard',
         sidebar_clients_overview: 'Klantbeheer',
         sidebar_clients: 'Klanten',
         sidebar_service_clients: 'Service klanten',
         sidebar_devices_overview: 'Device beheer',
         sidebar_devices: 'Device overzicht',
         sidebar_projects: 'Project overzicht',
         sidebar_presets: 'Presets',
         sidebar_fortes_users: 'Administrators',
         sidebar_fortes_settings: 'Instellingen',
         sidebar_audit: 'Audit',
         sidebar_reports: 'Rapporten',
         sidebar_total_devices: 'Devices - Totaal',
         sidebar_online_devices: 'Devices - Online',
         sidebar_offline_devices: 'Devices - Offline',
         sidebar_error_devices: 'Devices - Storing',
         sidebar_change_logs: 'Change Logs',
         sidebar_version_title: 'Versie',
         image_upload_success: 'Afeelding is succesvol gewijzigd!',
         image_upload_file_type_error:
           'Alleen JPEG en PNG bestanden zijn toegestaan.',
         image_upload_file_size_error: 'Het bestand moet minder dan 5MB zijn.',
         image_uploading_text: 'Uploaden...',
         /* END Sidebar */
         /* Navbar */
         navbar_search: 'Zoeken',
         navbar_notification: 'Meldingen',
         navbar_no_notification: 'Er zijn geen meldingen',
         navbar_profile: 'Account',
         navbar_setting: 'Account instellingen',
         navbar_logout: 'Uitloggen',
         /* END Navbar */
         active_label: 'ACTIEF',
         deactive_label: 'INACTIEF',
         please_fix_form_errors: 'Corrigeer a.u.b. formulierfouten',
         /* Klanten */
         clients: 'Klanten',
         clients_all: 'ALLE',
         clients_active: 'ACTIEF',
         clients_inactive: 'INACTIEF',
         clients_add_new: 'Klant toevoegen',
         clients_filter_search: 'Zoek klant',
         clients_sort_by_filter_label: 'Sorteer op',
         clients_sort_by_value_1: 'Datum(nieuwste)',
         clients_sort_by_value_2: 'Datum(oudste)',
         clients_sort_by_value_3: 'Alfabetisch',
         clients_country_filter_label: 'Locatie',
         clients_filter_reset_button: 'Herstel',
         clients_filter_delete_button: 'Deactiveren',
         clients_table_name: 'Naam',
         clients_table_country: 'Locatie',
         clients_table_devices: 'Devices',
         clients_table_added: 'Toegevoegd op',
         clients_table_status: 'Status',
         clients_table_row_per_page: 'Resultaten per pagina',
         clients_bread_crumb_main_title: 'Klanten',
         add_clients_bread_crumb_first_link: 'Klant toevoegen',
         add_clients_form_header: 'Klant toevoegen',
         edit_clients_bread_crumb_first_link: 'Klant wijzigen',
         edit_clients_form_header: 'Klant wijzigen',
         clients_form_client_name_field: 'Naam',
         clients_form_email_field: 'Email adres',
         clients_form_user_field: 'Gebruiker',
         clients_form_city_field: 'Stad',
         clients_form_country_field: 'Land',
         clients_form_client_kong_role_field: 'Kong Rol',
         clients_form_client_kong_role_member_label: 'Gebruiker',
         clients_form_client_kong_role_admin_label: 'Admin',
         clients_form_allow_customization_field: 'White-labeling toestaan',
         clients_form_add_client_button: 'Klant toevoegen',
         clients_form_edit_client_button: 'Wijzigingen opslaan',
         clients_form_cancel_button: 'Annuleer',
         clients_form_submitting_client_button: 'Verwerken...',
         clients_name_required_form_error: 'Naam is verplicht',
         clients_email_required_form_error: 'Email adres is verplicht',
         clients_api_kong_role_required_form_error: 'Kong rol is verplicht',
         clients_email_valid_form_error: 'Ongeldig email adres',
         clients_city_required_form_error: 'Stad is verplicht',
         clients_country_required_form_error: 'Land is verplicht',
         clients_add_form_success: 'Klant is succesvol aangemaakt!',
         clients_edit_form_success: 'Klant is succesvol gewijzigd!',
         clients_form_api_error: 'Internal klant - API Server Error',
         clients_form_email_error: 'Het email adres bestaat al',
         clients_no_record_found: 'Geen klant gevonden',
         client_deactivated_message: 'Klant is succesvol gedeactiveerd',
         client_activated_message: 'Klant is succesvol geactiveerd',
         clients_bulk_no_user_is_selected_error_message:
           'Selecteer meerdere rijen om te deactiveren',
         clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Klant(en) zijn succesvol gedeactiveerd',
         client_single_stats_table_title: 'Status',
         /* END Klanten */
         client_single_first_bread_crumb: 'Klanten',
         client_single_second_bread_crumb: 'Toon klant',
         client_single_country_label: 'Land',
         client_single_devices_label: 'Devices',
         client_single_edit_button: 'Wijzigen',
         client_single_users_tab: 'Gebruikers',
         client_single_apis_tab: 'API Keys',
         client_single_customization_tab: 'White label',
         client_single_customization_tab_logo: 'Logo',
         client_single_customization_tab_primary_color: 'Primaire kleur',
         client_single_customization_tab_secondary_color: 'Secondaire kleur',
         client_single_customization_tab_submit_button: 'Wijzigen opslaan',
         client_single_customization_color_schema_success:
           'White labeling is succesvol.',
         client_single_filter_search: 'Zoeken',
         client_single_add_new_user_button: 'Gebruiker toevoegen',
         client_single_add_new_api_key_button: 'API key toevoegen',
         client_single_role_id_filter: 'Rol',
         client_single_users_search: 'Zoeken',
         client_single_filter_reset_button: 'Herstel',
         client_single_users_all: 'ALL',
         client_single_users_active: 'ACTIEF',
         client_single_users_inactive: 'INACTIEF',
         client_single_filter_delete_button: 'Deactiveren',
         client_single_users_table_name: 'Naam',
         client_single_users_table_address: 'Adres',
         client_single_users_table_role_id: 'Rol',
         client_single_users_table_status: 'Status',
         client_single_users_table_added: 'Toegevoegd',
         client_single_users_table_no_record_found: 'Geen gebruiker gevonden',
         client_single_table_row_per_page: 'Resultaten per pagina',
         client_single_stats_online_devices_label: 'Online devices',
         client_single_stats_error_devices_label: 'Devices in storing',
         client_single_no_user_selected_for_de_activation_message:
           'Selecteer een Device om te deactiveren',
         /* END Klanten Single */
         /* Service Klanten */
         service_clients: 'Service klant',
         service_clients_all: 'ALL',
         service_clients_active: 'ACTIEF',
         service_clients_inactive: 'INACTIEF',
         service_clients_add_new: 'Service klant toevoegen',
         service_clients_filter_search: 'Zoeken',
         service_clients_sort_by_filter_label: 'Sorteren op',
         service_clients_sort_by_value_1: 'Datum (nieuwste)',
         service_clients_sort_by_value_2: 'Datum (oudste)',
         service_clients_sort_by_value_3: 'Alfabetisch',
         service_clients_country_filter_label: 'Land',
         service_clients_filter_reset_button: 'Herstel',
         service_clients_filter_delete_button: 'Verwijderen',
         service_clients_table_name: 'Naam',
         service_clients_table_country: 'Land',
         service_clients_table_devices: 'Devices',
         service_clients_table_added: 'Toegevoegd',
         service_clients_table_status: 'Status',
         service_clients_table_row_per_page: 'Resultaten per pagina',
         service_clients_bread_crumb_main_title: 'Service klanten',
         add_service_clients_bread_crumb_first_link: 'Service klant toevoegen',
         add_service_clients_form_header: 'Service klant toevoegen',
         edit_service_clients_bread_crumb_first_link: 'Service klant wijzigen',
         edit_service_clients_form_header: 'Service klant wijzigen',
         service_clients_form_service_client_name_field: 'Naam',
         service_clients_form_email_field: 'Email adres',
         service_clients_form_client_field: 'Klant',
         service_clients_form_user_field: 'Gebruiker',
         service_clients_form_city_field: 'Stad',
         service_clients_form_country_field: 'Land',
         service_clients_form_add_service_client_button: 'Wijzigingen opslaan',
         service_clients_form_edit_service_client_button: 'Wijzigingen opslaan',
         service_clients_form_cancel_button: 'Annuleer',
         service_clients_form_submitting_service_client_button: 'Verwerken...',
         service_clients_name_required_form_error: 'Naam is verplicht',
         service_clients_email_required_form_error: 'Email is verplicht',
         service_clients_focal_user_id_required_form_error:
           'Hoofdgebruiker is verplicht',
         service_clients_email_valid_form_error: 'Email adres is ongeldig',
         service_clients_city_required_form_error: 'Stad is verplicht',
         service_clients_client_required_form_error: 'Klant is verplicht',
         service_clients_country_required_form_error: 'Land is verplicht',
         service_clients_add_form_success:
           'Service klant is succesvol aangemaakt!',
         service_clients_edit_form_success:
           'Service klant is succesvol gewijzigd!',
         service_clients_form_api_error:
           'Internal Service klant - API Server Error',
         service_clients_form_email_error: 'Email adres is reeds in gebruik',
         service_clients_no_record_found: 'Geen Service klant gevonden',
         service_client_deactivated_message:
           'Service klant is succesvol gedeactiveerd',
         service_client_activated_message:
           'Service klant is succesvol geactiveerd',
         service_clients_bulk_no_user_is_selected_error_message:
           'Selecteer een gebruiker om te deactiveren',
         service_clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Service klant(en) zijn succesvol gedeactiveerd',
         service_client_single_stats_table_title: 'Status',
         /* END Service Klanten */
         service_client_single_first_bread_crumb: 'Service klanten',
         service_client_single_second_bread_crumb: 'Service klant weergeven',
         service_client_single_country_label: 'Land',
         service_client_single_devices_label: 'Devices',
         service_client_single_edit_button: 'Wijzigen',
         service_client_single_users_tab: 'Gebruikers',
         service_client_single_apis_tab: 'API Keys',
         service_client_single_filter_search: 'Zoeken',
         service_client_single_add_new_user_button: 'Gebruiker toevoegen',
         service_client_single_add_new_api_key_button: 'API key toevoegen',
         service_client_single_role_id_filter: 'Rol',
         service_client_single_users_search: 'Zoeken',
         service_client_single_filter_reset_button: 'Herstel',
         service_client_single_users_all: 'ALL',
         service_client_single_users_active: 'ACTIEF',
         service_client_single_users_inactive: 'INACTIEF',
         service_client_single_filter_delete_button: 'Verwijderen',
         service_client_single_users_table_name: 'Naam',
         service_client_single_users_table_address: 'Adres',
         service_client_single_users_table_role_id: 'Rol',
         service_client_single_users_table_status: 'Status',
         service_client_single_users_table_added: 'Toegevoegd',
         service_client_single_users_table_no_record_found:
           'Geen gebruiker gevonden',
         service_client_single_table_row_per_page: 'Resultaten per pagina',
         service_client_single_stats_active_devices_label: 'Online devices',
         service_client_single_stats_error_devices_label: 'Devices in storing',
         service_client_single_no_user_selected_for_de_activation_message:
           'Please select records to deactivate',
         /* END Service Klanten Single */
         /* Gebruikers */
         users_bread_crumb_main_title: 'Klanten',
         add_users_bread_crumb_first_link: 'Gebruiker toevoegen',
         add_users_form_header: 'Gebruiker toevoegen',
         edit_users_bread_crumb_first_link: 'Gebruiker wijzigen',
         edit_users_form_header: 'Wijzigen gebruiker',
         users_form_first_name_field: 'Voornaam',
         users_form_last_name_field: 'Achternaam',
         users_form_email_field: 'Email adres',
         users_form_official_email_field: 'Email adres',
         users_form_phone_field: 'Telefoonnummer',
         users_form_official_phone_number_field: 'Telefoonnummer',
         users_form_password_field: 'Wachtwoord',
         users_form_role_id_field: 'Rol',
         users_form_select_role_id_field: 'Selecteer rol',
         users_form_street_number_field: 'Straat',
         users_form_house_number_field: 'Huisnummer',
         users_form_neighbourhood_field: 'Buurt',
         users_form_postal_code_field: 'Postcode',
         users_form_city_field: 'Stad',
         users_form_country_field: 'Land',
         users_form_email_verification_field: 'Email bevestiging',
         users_form_email_verification_sub_title_field:
           'Als u deze knop uitschakelt, wordt automatisch een bevestigingsmail naar de gebruiker verzonden',
         users_form_add_user_button: 'Gebruiker toevoegen',
         users_form_edit_user_button: 'Wijzigingen opslaan',
         users_form_cancel_button: 'Annuleer',
         users_form_submitting_user_button: 'Verwerken...',
         users_first_name_required_form_error: 'Voornaam is verplicht',
         users_last_name_required_form_error: 'Achternaam is verplicht',
         users_email_required_form_error: 'Email is verplicht',
         users_email_valid_form_error: 'Uw email adres is ongeldig',
         users_password_required_form_error: 'Wachtwoord is verplicht',
         users_country_required_form_error: 'Land is verplicht',
         users_role_id_required_form_error: 'Rol is verplicht',
         users_password_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken',
         users_add_form_success: 'Gebruiker is succesvol aangemaakt!',
         users_edit_form_success: 'Gebruiker is succesvol gewijzigd!',
         users_form_api_error: 'Internal Gebruikers - API Server Error',
         users_form_email_error: 'Email adres bestaat al',
         users_no_record_found: 'Geen gebruikers gevonden',
         user_deactivated_message: 'Gebruiker is succesvol gedeactiveerd',
         user_activated_message: 'Gebruiker is succesvol geactiveerd',
         user_email_verified_title: 'Email adres bevestigd',
         user_email_verified_sub_title:
           'Als u deze knop uitschakelt, wordt automatisch een bevestigingsmail naar de gebruiker verzonden',
         users_bulk_no_user_is_selected_error_message:
           'Selecteer gebruiker(s) om te deactiveren',
         users_bulk_deactivated_message:
           '{{ USERS_COUNT }} Gebruikers zijn succesvol gedeactiveerd',
         /** Projecten */
         projecten: 'Projecten',
         projects_all: 'ALLE',
         projects_active: 'ACTIEF',
         projects_inactive: 'INACTIEF',
         projects_unassigned: 'NIET TOEGEWEZEN',
         projects_add_new: 'Nieuw project',
         projects_filter_search: 'Project zoeken',
         projects_sort_by_filter_label: 'Sorteren op',
         projects_sort_by_value_1: 'Datum (nieuwste)',
         projects_sort_by_value_2: 'Datum (oudste)',
         projects_sort_by_value_3: 'Alfabetisch',
         projects_country_filter_label: 'Land',
         projects_filter_reset_button: 'Herstel',
         projects_filter_delete_button: 'Verwijderen',
         projects_table_name: 'Naam project',
         projects_table_batch_number: 'Batchnummer',
         projects_table_client: 'Toegewezen klant',
         projects_table_devices: 'Devices',
         projects_table_added: 'Toegevoegd op',
         projects_table_status: 'Status',
         projects_table_row_per_page: 'Resultaten per pagina',
         projects_bread_crumb_main_title: 'Projecten',
         add_projects_bread_crumb_first_link: 'Project toevoegen',
         add_projects_form_header: 'Project toevoegen',
         edit_projects_bread_crumb_first_link: 'Project wijzigen',
         edit_projects_form_header: 'Project wijzigen',
         projects_form_project_name_field: 'Projectnaam',
         projects_form_email_field: 'Email adres',
         projects_form_client_field: 'Klant',
         projects_form_service_client_field: 'Service klant',
         projects_form_user_field: 'Gebruiker',
         projects_form_description_field: 'Omschrijving (optioneel)',
         projects_form_add_project_button: 'Project opslaan',
         projects_form_edit_project_button: 'Wijzigingen opslaan',
         projects_form_cancel_button: 'Annuleer',
         projects_form_submitting_service_client_button: 'Verwerken...',
         projects_name_required_form_error: 'Naam is verplicht',
         projects_form_unqiue_project_order_number_error:
           'Project Order Number moet uniek zijn',
         projects_batch_number_required_form_error: 'Batch nummer is verplicht',
         projects_client_required_form_error: 'Klant is verplicht',
         projects_description_required_form_error: 'Omschrijving is verplicht',
         projects_add_form_success: 'Project is succesvol aangemaakt!',
         projects_edit_form_success: 'Project is succesvol gewijzigd!',
         projects_form_api_error: 'Internal projecten - API Server Error',
         projects_no_record_found: 'Geen projecten gevonden',
         projects_deactivated_message: 'Project is succesvol gedeactiveerd',
         projects_activated_message: 'Project is succesvol geactiveerd',
         projects_bulk_no_user_is_selected_error_message:
           'Selecteer project(en) om te deactiveren',
         projects_bulk_deactivated_message:
           '{{ USERS_COUNT }} Project(en) zijn succesvol gedeactiveerd',
         projects_single_stats_table_title: 'Status',
         /** Devices */
         devices_form_header: 'Device overzicht',
         devices_all: 'ALLE',
         devices_active: 'Online',
         devices_inactive: 'Offline',
         devices_error: 'ALARM',
         devices_register_enabled: 'Ingeschakeld',
         devices_register_disabled: 'Uitgeschakeld',
         devices_add_new: 'Nieuw Device',
         devices_name: 'Naam',
         devices_address: 'Installatie adres',
         devices_description: 'Type afleverset',
         devices_client: 'Klant',
         devices_project: 'Project',
         devices_status: 'Status',
         device_is_online: 'Device is "online"',
         device_is_offline: 'Device is "offline"',
         devices_last_viewed: 'Laatste bericht',
         devices_action: 'Actie',
         devices_search: 'Zoeken',
         devices_row_per_page: 'Resultaten per pagina',
         device_rssi_title: 'RSSI',
         device_status_reg_105_no_error_255: 'Geen storing',
         device_status_menu_keys_list: JSON.stringify([
           'operating_state',
           'settings_hot_water',
           'settings_heating_and_cooling',
           'manual_control',
           'firmware_registers',
           'lin_pump_delta_parameters',
           'm_bus_meters',
           'live_hot_water',
           'live_heating',
           'live_cooling',
         ]),
         devices_filter_search: 'Device zoeken',
         devices_sort_by_filter_label: 'Sorteren op',
         devices_sort_by_value_1: 'Datum (nieuwste)',
         devices_sort_by_value_2: 'Datum (oudste)',
         devices_sort_by_value_3: 'Alfabetisch',
         devices_country_filter_label: 'Land',
         devices_filter_reset_button: 'Herstel',
         devices_filter_delete_button: 'Verwijderen',
         devices_table_name: 'Naam',
         devices_table_firmware: 'Firmware',
         devices_table_device_type: 'Device type',
         devices_table_batch_number: 'Batch nummer',
         devices_table_client: 'Klanten',
         devices_table_devices: 'Devices',
         devices_table_added: 'Toegevoegd',
         devices_table_status: 'Status',
         devices_table_row_per_page: 'Resultaten per pagina',
         devices_bread_crumb_main_title: 'Devices',
         add_devices_bread_crumb_first_link: 'Device toevoegen',
         add_devices_form_header: 'Device toevoegen',
         edit_devices_bread_crumb_first_link: 'Device wijzigen',
         edit_devices_form_header: 'Device wijzigen',
         devices_form_device_information_tab: 'Device info',
         devices_form_name_field: 'Device naam',
         devices_form_type_field: 'Device type',
         devices_form_ip_address_field: 'IP Adres',
         devices_form_preset_field: 'Preset',
         devices_form_project_field: 'Project',
         devices_form_client_field: 'Klant',
         devices_form_batch_number_field: 'Batch nummer',
         devices_form_serial_number_field: 'Serienummer Mercurius',
         devices_form_box_serial_number_field: 'Serienummer afleverset',
         devices_form_firmware_field: 'Firmwareversie',
         devices_form_description_field: 'Type afleverset',
         devices_form_device_address_tab: 'Installatie adres',
         devices_form_street_number_field: 'Straat',
         devices_form_house_number_field: 'Huisnummer',
         devices_form_postal_code_field: 'Postcode',
         devices_form_neighbourhood_field: 'Extra informatie',
         devices_form_city_field: 'Stad',
         devices_form_country_field: 'Land',
         devices_form_floor_number_field: 'Verdieping',
         devices_form_building_number_field: 'Bouwnummer',
         devices_form_additional_information_tab: 'Extra informatie',
         devices_form_sn_heat_unit_field: 'Serienummer afleverset',
         devices_form_article_number_field: 'Artikelnummer afleverset',
         devices_form_prod_order_nr_week_field: 'Productie order nummer',
         devices_form_serial_number_mercurius_field: 'Serienummer Mercurius',
         devices_form_sim_card_number_field: 'Simkaart nummer',
         devices_form_customer_ref_field: 'Referentie klant',
         devices_form_production_date_field: 'Productiedatum',
         devices_form_order_number_field: 'Ordernummer',
         devices_form_product_number_field: 'Artikelnummer',
         devices_form_model_number_field: 'Model nummer',
         devices_form_note_field: 'Notitie (optioneel)',
         devices_form_settings_tab: 'Instellingen',
         devices_form_device_interval_field: 'Interval',
         devices_form_is_device_enabled_sensor_field: 'Inschakelen sensor data',
         devices_form_is_device_activated_field: 'Deactivate Device',
         devices_form_mbus_tab: 'M-Bus',
         devices_form_is_device_enabled_heat_meter_field:
           'Inschakelen warmtemeter',
         devices_form_is_device_enabled_cool_meter_field:
           'Inschakelen koudemeter',
         devices_form_is_device_enabled_electric_meter_field:
           'Inschakelen elektriciteitsmeter',
         devices_form_is_device_enabled_bi_directional_meter_field:
           'Inschakelen warmte/koudemeter',
         devices_form_is_device_enabled_water_meter_field:
           'Inschakelen watermeter',
         devices_form_is_device_enabled_mbus_search_meter_button:
           'Zoek Energiemeters',
         devices_form_add_device_button: 'Device toevoegen',
         devices_form_edit_device_button: 'Wijzigingen opslaan',
         devices_form_cancel_button: 'Annuleer',
         devices_form_submitting_service_client_button: 'Verwerken...',
         devices_name_required_form_error: 'Naam is verplicht',
         devices_batch_number_required_form_error: 'Batch number is verplicht',
         devices_serial_number_required_form_error: 'Serienummer is verplicht',
         devices_sn_heat_number_numeric_form_error:
           'Serienummer van de afleverset moet een nummer zijn',
         devices_article_number_form_error: 'Artikelnummer is verplicht',
         devices_prod_order_nr_week_form_error:
           'Productie order nummer is verplicht',
         devices_serial_number_mercurius_form_error:
           'Serienummer Mercurius is verplicht',
         devices_sim_card_number_form_error: 'Simkaart nummer is verplicht',
         devices_device_interval_numeric_form_error:
           'Interval moet een getal zijn',
         devices_client_required_form_error: 'Klant is verplicht',
         devices_project_required_form_error: 'Project is verplicht',
         devices_preset_required_form_error: 'Preset is verplicht',
         devices_description_required_form_error: 'Omschrijving is verplicht',
         devices_add_form_success: 'Device is succesvol aangemaakt!',
         devices_edit_form_success: 'Device is succesvol gewijzigd!',
         devices_form_unique_error:
           'Serienummer afleverset en serienummer Mercurius moeten uniek zijn',
         devices_form_api_error: 'Internal devices - API Server Error',
         devices_no_record_found: 'Geen devices gevonden',
         devices_deactivated_message: 'Device is succesvol gedeactiveerd',
         devices_activated_message: 'Device is succesvol geactiveerd',
         devices_search_mbus_registers_success:
           'Het bericht is succesvol naar het apparaat verzonden.',
         devices_search_mbus_registers_missing_serial_number_error:
           'Vul het serienummer in om verder te gaan.',
         devices_bulk_no_user_is_selected_error_message:
           'Selecteer gebruikers om te deactiveren',
         devices_bulk_deactivated_message:
           '{{ USERS_COUNT }} Devices zijn succesvol gedeactiveerd',
         // Devices Single
         device_single_page_title: 'Device details',
         devices_single_bread_crumb_main_title: 'Device overzicht',
         devices_single_bread_crumb_first_link: 'Devicepagina',
         devices_single_basic_details_client: 'KLANT',
         devices_single_basic_details_serial_number: 'SERIENUMMER MERCURIUS',
         devices_single_basic_details_firmware: 'FIRMWARE',
         devices_single_basic_details_type: 'Device TYPE',
         devices_single_edit_device_button: 'Device wijzigen',
         device_single_error_status: 'Error',
         device_single_online_status: 'Online',
         device_single_offline_status: 'Offline',
         device_single_rss_device_status: 'Device status',
         device_single_rss_signal_strength: 'Signaal sterkte',
         device_single_temperature_tab: 'Historie',
         device_single_temperature_title:
           'De historische data van de afleverset',
         device_single_temperature_hr: 'uur',
         device_single_registers_tab: 'Registers',
         device_single_event_tab: 'Events',
         device_single_device_details_tab: 'Info',
         device_single_device_api_timer_seconds_label: 'Seconden',
         device_single_device_api_timer_minute_label: 'Minuut',
         device_single_device_api_timer_minutes_label: 'Minuten',
         device_single_recall_device_status_api_timer_field: 'API Interval',
         devices_single_last_seen: 'Laatste bericht',
         devices_single_fetch_registers: 'VERNIEUW DATA',
         fetch_registers_success:
           'Het verzoek is succesvol verzonden naar het Device.',
         write_registers_success:
           'Schrijfbericht is succesvol verzonden naar Device.',
         write_registers_error: 'Wijzig de waarde om door te gaan.',
         fetch_registers_m_bus_meters_not_supported:
           'M-Bus Meters worden momenteel niet herkend. Controleer de aansluiting en/of de M-Bus module van de energiemeter.',
         // Operating Status
         business_situation_operating_status_TR03_title:
           'Bedrijfsstatus afleverset',
         device_status_nr: 'NR',
         device_status_name: 'Naam',
         device_status_value: 'Waarde',
         business_situation_operating_status_TR03_100: 'Verwarming status',
         business_situation_operating_status_TR03_100_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na circulatie',
             4: 'Uit',
             5: 'Voor circulatie',
           },
         }),
         business_situation_operating_status_TR03_101: 'Tapwater status',
         business_situation_operating_status_TR03_101_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek (tijdelijk onderbroken)',
             2: 'Vraag',
             3: 'Na circuleren',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR03_102: 'Warmhoud status',
         business_situation_operating_status_TR03_102_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Actief',
           },
         }),
         business_situation_operating_status_TR03_103: 'Anti Legionella status',
         business_situation_operating_status_TR03_103_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek (tijdelijk onderbroken)',
             2: 'Vraag',
             3: 'Na circuleren',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR03_104: 'Koelen status',
         business_situation_operating_status_TR03_104_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek (tijdelijk onderbroken)',
             2: 'Initialiseren',
             3: 'Vraag',
             4: 'Na circulatie',
             5: 'Voor circulatie',
           },
         }),
         business_situation_operating_status_TR03_1802: 'Elektrisch element',
         business_situation_operating_status_TR03_1802_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         business_situation_operating_status_TR03_105: 'Storingscode',
         business_situation_operating_status_TR03_106: 'Software versie',
         business_situation_operating_status_TR03_107:
           'Tapwater regelafsluiter (M1)',
         business_situation_operating_status_TR03_108:
           'Verwarmen/koelen regelafsluiter (M2)',
         business_situation_operating_status_TR03_109: 'Circulatie pomp',
         business_situation_operating_status_TR03_109_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Circuleren',
           },
         }),
         business_situation_operating_status_TR03_110: '6-weg kogelafsluiter',
         business_situation_operating_status_TR03_110_list: JSON.stringify({
           list: {
             0: 'Stand: Verwarmen',
             1: 'Stand: Koelen',
           },
         }),
         business_situation_operating_status_TR03_112_list: JSON.stringify({
           list: {
             0: 'Verwarmen of vraag',
             1: 'Koelen',
           },
         }),
         business_situation_operating_status_TR03_113: 'Ruimte thermostaat',
         business_situation_operating_status_TR03_113_list: JSON.stringify({
           list: {
             1: 'Geen warmte vraag',
             2: 'Warmte vraag',
           },
         }),
         business_situation_operating_status_TR03_114: 'OpenTherm Indicatie',
         business_situation_operating_status_TR03_114_list: JSON.stringify({
           list: {
             0: 'Inactieve verbinding',
             1: 'Actieve verbinding',
           },
         }),
         business_situation_operating_status_TR03_115: 'Toesteltype',
         // Business situation
         business_situation_measured_values_TR03_title: 'Meetwaarden',
         business_situation_measured_values_TR03_200: 'Volumestroom tapwater',
         business_situation_measured_values_TR03_201: 'T-Tapwater',
         business_situation_measured_values_TR03_202: 'T-Primaire aanvoer',
         business_situation_measured_values_TR03_203:
           'T-Primaire retour (T-Booster voor TE-Booster toestellen)',
         business_situation_measured_values_TR03_205: 'T-Secundaire aanvoer',
         business_situation_measured_values_TR03_206: 'T-Secundaire retour',
         business_situation_measured_values_TR03_204:
           'Berekende aanvoertemperatuur (setpoint)',
         business_situation_measured_values_TR03_207:
           'Ruimte temperatuur(OpenTherm)',
         business_situation_measured_values_TR03_208:
           'Ruimte setpoint (OpenTherm)',
         business_situation_measured_values_TR03_210:
           'Waterdruk in de binnenhuisinstallatie',
         // Operating time
         business_situation_operating_time_TR03_title: 'Bedrijfstijden',
         business_situation_operating_time_TR03_800:
           'Totale bedrijfstijd afleverset',
         business_situation_operating_time_TR03_801:
           'Totale bedrijfstijd warmte-vraag',
         business_situation_operating_time_TR03_802:
           'Totale bedrijfstijd koude-vraag',
         business_situation_operating_time_TR03_803:
           'Totale bedrijfstijd tapwater-vraag',
         business_situation_operating_time_TR03_804: 'Aantal tappingen',
         business_situation_operating_time_TR03_805:
           'Totale volume tapwater (let op! dit is een indicatie)',
         business_situation_operating_time_TR03_806: 'Stroomonderbrekingen',
         // Warmtapwater
         settings_dhw_warm_tap_water_TR03_title: 'Instellingen tapwater',
         settings_dhw_warm_tap_water_TR03_300: 'Tapwater setpoint',
         settings_dhw_warm_tap_water_TR03_301: 'Warmhoudmodus',
         settings_dhw_warm_tap_water_TR03_301_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Anti-vries programma',
             2: 'Eco programma',
             3: 'Comfort programma',
             4: 'Dynamisch (aanbevolen)',
           },
         }),
         settings_dhw_warm_tap_water_TR03_302:
           'Warmhoudsetpoint (comfort stand)',
         settings_dhw_warm_tap_water_TR03_303: 'Tapdrempel',
         settings_dhw_warm_tap_water_TR03_304: 'Tapwater inschakelen',
         settings_dhw_warm_tap_water_TR03_304_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         // Thermal disinfection
         settings_thermal_disinfection_TR03_title:
           'Instellingen anti-legionella',
         settings_thermal_disinfection_TR03_400:
           'Anti-Legionella ingeschakeling',
         settings_thermal_disinfection_TR03_400_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_thermal_disinfection_TR03_401: 'Anti-Legionella setpoint',
         settings_thermal_disinfection_TR03_402: 'Anti-Legionella brandtijd',
         settings_thermal_disinfection_TR03_403: 'Anti-Legionella wachttijd',
         // TE-Booster
         settings_dhw_te_booster_TR03_title: 'Instellingen TE-Booster',
         settings_dhw_te_booster_TR03_1800: 'TE-Booster',
         settings_dhw_te_booster_TR03_1800_list: JSON.stringify({
           list: {
             0: 'Uitgeschakeld',
             1: 'Ingeschakeld',
           },
         }),
         settings_dhw_te_booster_TR03_1804: 'TE-Booster setpoint',
         settings_dhw_te_booster_TR03_1805: 'TE-Booster setpoint hysterese',
         settings_dhw_te_booster_TR03_1812: 'TE-Booster setpoint timeout',
         settings_dhw_te_booster_TR03_1813: 'TE-Booster setpoint timer',
         settings_dhw_te_booster_TR03_1815: 'TE-Booster activatie drempel',
         settings_dhw_te_booster_TR03_1816: 'TE-Booster getapt volume',
         // Heating
         heating_and_cooling_settings_heating_TR03_title:
           'Instellingen verwarmen',
         heating_and_cooling_settings_heating_TR03_500:
           'Maximale setpoint verwarmen',
         heating_and_cooling_settings_heating_TR03_501:
           'Minimale setpoint verwarmen',
         heating_and_cooling_settings_heating_TR03_506:
           'Maximale retourtemperatuur verwarmen',
         heating_and_cooling_settings_heating_TR03_507:
           'Compensatie factor van de primaire retour',
         heating_and_cooling_settings_heating_TR03_508:
           'Delta primaire-secondary setpoint',
         heating_and_cooling_settings_heating_TR03_510:
           'Maximaalbeveiliging verwarmen',
         heating_and_cooling_settings_heating_TR03_509:
           'Minimum primaire-secundaire delta',
         heating_and_cooling_settings_heating_TR03_514: 'Verwarmen setpoint',
         heating_and_cooling_settings_heating_TR03_515: 'Verwarmen inschakelen',
         heating_and_cooling_settings_heating_TR03_515_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'aan',
           },
         }),
         // Cooling
         heating_and_cooling_settings_cooling_TR03_title: 'Instellingen koelen',
         heating_and_cooling_settings_cooling_TR03_600: 'Koelen setpoint',
         heating_and_cooling_settings_cooling_TR03_601:
           'Minimale primaire retour',
         heating_and_cooling_settings_cooling_TR03_602:
           'Compensatiefactor van de primaire retour',
         heating_and_cooling_settings_cooling_TR03_605:
           'Maximaalbeveiliging koelen',
         heating_and_cooling_settings_cooling_TR03_606:
           'Minimale setpoint koelen',
         heating_and_cooling_settings_cooling_TR03_607:
           'Maximale setpoint koelen',
         heating_and_cooling_settings_cooling_TR03_611: 'Koelen inschakelen',
         heating_and_cooling_settings_cooling_TR03_611_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         // Secundaire General
         heating_and_cooling_settings_secondary_general_TR03_title:
           'Algemene instellingen',
         heating_and_cooling_settings_secondary_general_TR03_512:
           'Minimale druk',
         heating_and_cooling_settings_secondary_general_TR03_513:
           'Maximale druk',
         heating_and_cooling_settings_secondary_general_TR03_603:
           'Wachttijd omschakelen verwarmen-koelen',
         heating_and_cooling_settings_secondary_general_TR03_604:
           '6-weg kogelkraan wachttijd',
         // Changeover WKW
         heating_and_cooling_settings_changeover_wkw_TR03_title:
           'Instellingen 3.5 pijps systeem',
         heating_and_cooling_settings_changeover_wkw_TR03_1850:
           '3.5 pijps configuratie',
         heating_and_cooling_settings_changeover_wkw_TR03_1850_list: JSON.stringify(
           {
             list: {
               0: 'Verwarmen',
               1: 'Koelen',
             },
           }
         ),
         heating_and_cooling_settings_changeover_wkw_TR03_1851:
           'Koeling detectie drempel',
         heating_and_cooling_settings_changeover_wkw_TR03_1852:
           'Verwarming detectie drempel',
         heating_and_cooling_settings_changeover_wkw_TR03_1853:
           'Verwarming verlaagd setpoint',
         heating_and_cooling_settings_changeover_wkw_TR03_1854:
           'Verlaagd setpoint gebruikt',
         heating_and_cooling_settings_changeover_wkw_TR03_1854_list: JSON.stringify(
           {
             list: {
               0: 'Uit',
               1: 'Aan',
             },
           }
         ),
         heating_and_cooling_settings_changeover_wkw_TR03_1855:
           'Predetectie M2 positie',
         heating_and_cooling_settings_changeover_wkw_TR03_1856:
           'Detectie interval',
         heating_and_cooling_settings_changeover_wkw_TR03_1857:
           'Detectie interval timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1858:
           'Predetectie timeout',
         heating_and_cooling_settings_changeover_wkw_TR03_1859:
           'Predetectie timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1860:
           'Configuratie switch timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1861:
           'Switch timeout',
         heating_and_cooling_settings_changeover_wkw_TR03_1862:
           'Configuratie switch drempel',
         heating_and_cooling_settings_changeover_wkw_TR03_1863:
           'Boost functie modus',
         heating_and_cooling_settings_changeover_wkw_TR03_1863_list: JSON.stringify(
           {
             list: {
               0: 'Uitgeschakeld',
               1: 'Ingeschakeld',
             },
           }
         ),
         heating_and_cooling_settings_changeover_wkw_TR03_1864:
           'Boost detectie timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1865:
           'Boost detectie timeout',
         heating_and_cooling_settings_changeover_wkw_TR03_1866:
           'Maximum boost positie',
         // Manual Operation
         settings_manual_operation_TR03_title: 'Handbediening',
         settings_manual_operation_TR03_700: 'Handbediening inschakelen',
         settings_manual_operation_TR03_700_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_manual_operation_TR03_701:
           'Tapwater regelafsluiter bedienen (M1)',
         settings_manual_operation_TR03_702:
           'Verwarmen/koelen regelafsluiter bedienen (M2)',
         settings_manual_operation_TR03_703: 'Pomp',
         settings_manual_operation_TR03_703_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_manual_operation_TR03_704: '6-weg kogelafsluiter',
         settings_manual_operation_TR03_704_list: JSON.stringify({
           list: {
             0: 'Stand: Verwarmen',
             1: 'Stand: Koelen',
           },
         }),
         settings_manual_operation_TR03_706: 'Verwarmingsvraag status output',
         settings_manual_operation_TR03_706_list: JSON.stringify({
           list: {
             0: 'Verwarming or vraag',
             1: 'Koeling',
           },
         }),
         settings_manual_operation_TR03_707:
           'Tapwater regelafsluiter bedienen (M1)[%]',
         settings_manual_operation_TR03_708:
           'Verwarmen/koelen regelafsluiter bedienen (M2)[%]',
         settings_manual_operation_TR03_1801: 'Elektisch element TE-Booster',
         settings_manual_operation_TR03_1801_list: JSON.stringify({
           list: {
             0: 'Uitgeschakeld',
             1: 'Ingeschakeld',
           },
         }),
         settings_manual_operation_TR03_709: 'Handbediening uitstellen',
         // Live Tap Water
         live_tap_water_title: 'Livelog tapwater',
         live_tap_water_101: 'Tapwater status',
         live_tap_water_107: 'Tapwater regelafsluiter (M1)',
         live_tap_water_200: 'Tapwater volumestroom',
         live_tap_water_201: 'T-Tapwater',
         live_tap_water_202: 'T-Primaire aanvoer',
         live_tap_water_203: 'T-Primaire retour',
         live_tap_water_1007: 'Primaire flow energiemeter',
         live_tap_water_1008: 'Actuele vermogen',
         // Live CV
         live_cv_title: 'Livelog verwarmen',
         live_cv_100: 'Verwarmen status',
         live_cv_108: 'Verwarmen/koelen regelafsluiter (M2)',
         live_cv_202: 'T-Primaire aanvoer',
         live_cv_203: 'T-Primaire retour',
         live_cv_204: 'Berekende aanvoertemperatuur (setpoint)',
         live_cv_205: 'T-Secundaire aanvoer',
         live_cv_206: 'T-Secundaire retour',
         live_cv_113: 'Ruimtethermostaat contact',
         live_cv_210: 'Waterdruk in de binnenhuisinstallatie',
         live_cv_1007: 'Primaire flow energiemeter',
         live_cv_1008: 'Actuele vermogen',
         // Live Cooling
         live_cooling_title: 'Livelog koelen',
         live_cooling_104: 'Koelen status',
         live_cooling_109: 'Pomp',
         live_cooling_110: '6-weg kogelafsluiter',
         live_cooling_113: 'Ruimtethermostaat contact',
         live_cooling_108: 'Verwarmen/koelen regelafsluiter (M2)',
         live_cooling_202: 'T-Primaire aanvoer',
         live_cooling_204: 'Berekende aanvoertemperatuur (setpoint)',
         live_cooling_205: 'T-Secundaire aanvoer',
         live_cooling_206: 'T-Secundaire retour',
         // M Bus Limiet
         m_bus_limit_title: 'M-Bus Limiet',
         m_bus_limit_1500: 'Begrenzing inschakelen',
         m_bus_limit_1501: 'Serienummer begrenzing meter',
         m_bus_limit_1503: 'Begrens status',
         m_bus_limit_1504: 'Begrenzing configuratie Tapwater (h) en CV (l)',
         m_bus_limit_1505: 'Begrens vermogen',
         m_bus_limit_1506: 'Begrens flow',
         m_bus_limit_1507: 'Begrens retour temperatuur (Alleen voor CV)',
         // Meter 1
         m_bus_meter_1_title: 'Meter 1 (Warmte)',
         m_bus_meter_1_0000: 'Serienummer',
         m_bus_meter_1_0002: 'Fabrikant',
         m_bus_meter_1_0003: 'Meter Type',
         m_bus_meter_1_0004: 'Status',
         m_bus_meter_1_0005: 'Volumestroom',
         m_bus_meter_1_0006: 'Vermogen',
         m_bus_meter_1_0007: 'Aanvoertemperatuur',
         m_bus_meter_1_0008: 'Retourtemperatuur',
         m_bus_meter_1_0010: 'Totale energieverbruik',
         m_bus_meter_1_0012: 'Totale volume',
         // Meter 2
         m_bus_meter_2_title: 'Meter 2 (Koude)',
         m_bus_meter_2_0100: 'Serienummer',
         m_bus_meter_2_0102: 'Fabrikant',
         m_bus_meter_2_0103: 'Meter Type',
         m_bus_meter_2_0104: 'Status',
         m_bus_meter_2_0105: 'Volumestroom',
         m_bus_meter_2_0106: 'Vermogen',
         m_bus_meter_2_0107: 'Aanvoertemperatuur',
         m_bus_meter_2_0108: 'Retourtemperatuur',
         m_bus_meter_2_0110: 'Totale energieverbruik',
         m_bus_meter_2_0112: 'Totaal volume',
         // Meter 3
         m_bus_meter_3_title: 'Meter 3 (Elektriciteit)',
         m_bus_meter_3_0200: 'Serienummer',
         m_bus_meter_3_0202: 'Fabrikant',
         m_bus_meter_3_0203: 'Meter Type',
         m_bus_meter_3_0204: 'Status',
         m_bus_meter_3_0206: 'Power',
         m_bus_meter_3_0210: 'Energy',
         m_bus_meter_3_0214: 'Current',
         // Meter 4
         m_bus_meter_4_title: 'Meter 4 (Water)',
         m_bus_meter_4_0300: 'Serienummer',
         m_bus_meter_4_0302: 'Fabrikant',
         m_bus_meter_4_0303: 'Meter Type',
         m_bus_meter_4_0304: 'Status',
         m_bus_meter_4_0305: 'Volumestroom',
         m_bus_meter_4_0307: 'Aanvoertemperatuur',
         m_bus_meter_4_0312: 'Totaal volume',
         // Meter 5
         m_bus_meter_5_title: 'Meter 5 (Warmte/koude)',
         m_bus_meter_5_0400: 'Serienummer',
         m_bus_meter_5_0402: 'Fabrikant',
         m_bus_meter_5_0403: 'Meter Type',
         m_bus_meter_5_0404: 'Status',
         m_bus_meter_5_0405: 'Volumestroom',
         m_bus_meter_5_0406: 'Vermogen',
         m_bus_meter_5_0407: 'Aanvoertemperatuur',
         m_bus_meter_5_0408: 'Retourtemperatuur',
         m_bus_meter_5_0410: 'Totale energieverbruik (warmte)',
         m_bus_meter_5_0412: 'Volume',
         m_bus_meter_5_0414: 'Totale energieverbruik (koude)',
         // Geschiedenis Block Errors
         history_block_errors_title: 'Geschiedenis van block-errors',
         history_block_errors_860: 'Block Error 1',
         history_block_errors_861: 'Block Error 2',
         history_block_errors_862: 'Block Error 3',
         history_block_errors_863: 'Block Error 4',
         history_block_errors_864: 'Block Error 5',
         history_block_errors_865: 'Block Error 6',
         history_block_errors_866: 'Block Error 7',
         history_block_errors_867: 'Block Error 8',
         history_block_errors_868: 'Block Error 9',
         history_block_errors_869: 'Block Error 10',
         history_block_errors_870: 'Block Error 11',
         history_block_errors_871: 'Block Error 12',
         history_block_errors_872: 'Block Error 13',
         history_block_errors_873: 'Block Error 14',
         history_block_errors_874: 'Block Error 15',
         // Events
         device_events_type_filter_label: 'Event Type',
         device_events_type_filter_value_1: 'Audit',
         device_events_type_filter_value_2: 'Storing',
         // Presets
         presets: 'Presets',
         presets_all: 'ALLE',
         presets_active: 'ACTIEF',
         presets_inactive: 'INACTIEF',
         presets_add_new: 'Toevoegen',
         presets_filter_search: 'Zoeken',
         presets_filter_reset_button: 'Herstel',
         presets_filter_delete_button: 'Verwijderen',
         presets_table_name: 'Naam',
         presets_table_status: 'Status',
         presets_updated_date: 'Gewijzigd op',
         presets_action: 'Actions',
         presets_table_row_per_page: 'Resultaten per pagina',
         presets_status_menu_list: JSON.stringify([
           {
             key: 'settings_hot_water',
             value: 'Instellingen tapwater',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Instellingen verwarmen en koelen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbediening',
             permission: 'manual-control-tab-read',
           },
         ]),
         add_presets_title: 'Preset toevoegen',
         edit_presets_title: 'Preset wijzigen',
         presets_form_name_required: 'Naam van de preset is verplicht',
         presets_form_register_required: 'Register waarde is verplicht',
         presets_form_add_preset_button: 'Wijzigingen opslaan',
         presets_form_edit_preset_button: 'Wijzigingen opslaan',
         presets_form_cancel_button: 'Annuleer',
         presets_add_form_success: 'Preset is succesvol aangemaakt!',
         presets_edit_form_success: 'Preset is succesvol gewijzigd!',
         presets_form_api_error: 'Internal Server Error',
         presets_no_record_found: 'Geen presets gevonden',
         presets_deactivated_message: 'Preset is succesvol gedeactiveerd',
         presets_activated_message: 'Preset is succesvol geactiveerd',
         presets_bulk_no_user_is_selected_error_message:
           'Selecteer preset(s) om te deactiveren',
         presets_bulk_deactivated_message:
           '{{ USERS_COUNT }} Preset(s) zijn succesvol gedeactiveerd',
         // Audits
         audits: 'Logboek handelingen',
         audits_all: 'Alle',
         audits_active: 'Audit',
         audits_inactive: 'Storingen',
         audits_filter_search: 'Zoeken',
         audits_client_filter_label: 'Klant',
         audits_error_code_filter_label: 'Storing',
         audits_project_filter_label: 'Project',
         audits_order_number_filter_label: 'Ordernummer',
         audits_events_type_filter_label: 'Event Type',
         audits_date_range_filter_label: 'Bereik',
         audits_events_type_filter_value_1: 'Audit',
         audits_events_type_filter_value_2: 'Storing',
         audits_reset_filters_button: 'Herstel',
         audits_date: 'Datum',
         audits_device: 'Device',
         audits_client: 'Klant',
         audits_message: 'Bericht',
         audits_table_row_per_page: 'Resultaten per pagina',
         audits_table_no_record_found: 'Geen devices gevonden',
         // Instellingen
         settings_update_profile_title: 'Wijzig profiel',
         settings_notification_title: 'Berichten',
         settings_notification_window_exceptions_title:
           'Stuur bericht bij windows uitzondering',
         settings_notification_device_error_title:
           'Melding wanneer een apparaat zich in storing bevindt',
         settings_notification_channels_title: 'Meldingsmogelijkheden',
         settings_notification_use_email_title: 'Email',
         /* Admins */
         admins: 'Admins',
         admins_first_bread_crumb: 'Fortes administrators',
         admins_filter_search: 'Administrator zoeken',
         admins_add_new: 'Adminstrator toevoegen',
         admins_filter_reset_button: 'Herstel',
         admins_filter_delete_button: 'Deactiveren',
         admins_search: 'Zoeken',
         admins_all: 'ALLE',
         admins_active: 'ACTIEF',
         admins_inactive: 'INACTIEF',
         admins_table_name: 'Naam',
         admins_table_address: 'Adres',
         admins_table_role_id: 'Rol',
         admins_table_status: 'Status',
         admins_table_added: 'Toegevoegd',
         admins_table_no_record_found: 'Geen gebruiker gevonden',
         admins_table_row_per_page: 'Resultaten per pagina',
         admins_no_user_selected_for_de_activation_message:
           'Selecteer records om te deactiveren',
         admins_bread_crumb_main_title: 'Admins',
         add_admins_bread_crumb_first_link: 'Admin toevoegen',
         add_admins_form_header: 'Admin toevoegen',
         edit_admins_bread_crumb_first_link: 'Admin wijzigen',
         edit_admins_form_header: 'Admin wijzigen',
         admins_form_first_name_field: 'Voornaam',
         admins_form_last_name_field: 'Achternaam',
         admins_form_email_field: 'Email adres',
         admins_form_official_email_field: 'Email adres',
         admins_form_phone_field: 'Telefoonnummer',
         admins_form_official_phone_number_field: 'Telefoon nummer',
         admins_form_password_field: 'Wachtwoord',
         admins_form_role_id_field: 'Rol',
         admins_form_select_role_id_field: 'Selecteer rol',
         admins_form_street_number_field: 'Straat',
         admins_form_house_number_field: 'Huisnummer',
         admins_form_neighbourhood_field: 'Neighbourhood',
         admins_form_postal_code_field: 'Postcode',
         admins_form_city_field: 'Stad',
         admins_form_country_field: 'Land',
         admins_form_email_verification_field: 'Email verificatie',
         admins_form_email_verification_sub_title_field:
           'Als u dit uitschakelt, wordt de beheerder automatisch een verificatie email gestuurd',
         admins_form_add_admin_button: 'Wijzigingen opslaan',
         admins_form_edit_admin_button: 'Wijzigingen opslaan',
         admins_form_cancel_button: 'Annuleer',
         admins_form_submitting_admin_button: 'Verzenden...',
         admins_first_name_required_form_error: 'Voornaam is verplicht',
         admins_last_name_required_form_error: 'Achternaam is verplicht',
         admins_email_required_form_error: 'Email is verplicht',
         admins_email_valid_form_error: '',
         admins_password_required_form_error: 'Wachtwoord is verplicht',
         admins_country_required_form_error: 'Land is verplicht',
         admins_role_id_required_form_error: 'Rol is verplicht',
         admins_password_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken.',
         admins_add_form_success: 'Administrator is succesvol aangemaakt!',
         admins_edit_form_success: 'Administrator is succesvol gewijzigd!',
         admins_form_api_error: 'Internal Server Error',
         admins_form_email_error: 'Email adres bestaat al',
         admins_no_record_found: 'Geen administrator gevonden',
         admins_deactivated_message: 'Admin is succesvol gedeactiveerd',
         admins_activated_message: 'Administrator is sucesvol geactiveerd',
         admins_email_verified_title: 'Email bevestigd',
         admins_email_verified_sub_title:
           'Als u dit uitschakelt, wordt de beheerder automatisch een verificatie-e-mail gestuurd',
         admins_bulk_no_admin_is_selected_error_message:
           'Selecteer records om te deactiveren',
         admins_bulk_deactivated_message:
           '{{ USERS_COUNT }} Admin(s) zijn succesvol gedeactiveerd',
         // Zoeken Results
         search_result_title: 'Zoek resultaten: ',
         search_users: 'Gebruikers',
         search_clients: 'Klanten',
         search_service_clients: 'Service klanten',
         search_devices: 'Devices',
         search_presets: 'Presets',
         search_projects: 'Projecten',
         // Nieuwe Locales
         no_error_devices_found: 'Geen devices met storingen gevonden!',
         project_single_first_bread_crumb: 'Projecten',
         project_single_second_bread_crumb: 'Project weergeven',
         project_single_client_label: 'Klant',
         project_single_service_client_label: 'Service klant',
         project_single_devices_label: 'Devices',
         project_single_edit_button: 'Wijzigen',
         project_single_devices_tab: 'Devices',
         client_single_view_more_errors_button: 'Toon meer',
         client_error_single_devices_tab: 'Devices in storing',
         client_error_single_device_name: 'Device',
         client_error_single_device_error_code: 'Storingscode',
         client_error_single_device_error_date_time: 'Datum',
         devices_bulk_edit_registers_button: 'Instelling(en) wijzigen',
         devices_bulk_edit_ota_button: 'Device(s) updaten',
         client_error_single_device_errors_table_row_per_page:
           'Rijen per pagina',
         client_error_single_device_error_no_record_found:
           'Geen devices gevonden',
         devices_bulk_edit_registers_title: 'Device registers groepswijziging',
         devices_bulk_edit_registers_form_submit_button: 'Groepsupdate',
         devices_bulk_edit_registers_form_cancel_button: 'Annuleer',
         devices_bulk_edit_registers_form_success:
           'Bericht wordt doorgestuurd naar geselecteerde apparaat(en)',
         devices_bulk_edit_registers_form_error:
           'Gelieve ten minste één registerwaarde in te vullen',
         devices_bulk_edit_registers_form_api_error: 'Internal Server Error',
         devices_bulk_edit_ota_title: 'Device OTA groep Update',
         devices_bulk_edit_ota_form_firmware_field: 'Firmware',
         devices_bulk_edit_ota_form_submit_button: 'Groepsupdate',
         devices_bulk_edit_ota_form_cancel_button: 'Annuleer',
         devices_bulk_edit_ota_form_success:
           'Bericht wordt doorgestuurd naar geselecteerde apparaat(en)',
         devices_bulk_edit_ota_form_firmware_required_error:
           'Firmware is verplicht',
         devices_bulk_edit_ota_form_api_error: 'Internal Server Error',
         devices_bulk_edit_registers_selected_devices:
           'Apparaten geselecteerd voor groepsbewerking',
         // Device Status Table Pop Over
         custom_popover_min_value: 'Min waarde',
         custom_popover_max_value: 'Max waarde',
         custom_popover_list_options: 'Mogelijke waarden',
         custom_popover_short_description: 'Omschrijving',
         custom_popover_close_modal_button: 'Sluiten',
         // Warmtapwater PopOver Short Omschrijving
         custom_popover_300_short_description: 'Tapwater Setpoint',
         custom_popover_301_short_description: 'Preheat modus',
         custom_popover_302_short_description: 'Comfort Setpoint',
         custom_popover_303_short_description: 'Startdrempel voor debiet',
         custom_popover_304_short_description: 'Tapwater Ingeschakeld',
         // Thermal disinfection PopOver Short Omschrijving
         custom_popover_400_short_description: 'Aleg Ingeschakeld',
         custom_popover_401_short_description: 'Aleg Setpoint',
         custom_popover_402_short_description: 'Aleg Brandtijd',
         custom_popover_403_short_description: 'Aleg Wachttijd',
         // TE-Booster PopOver Short Omschrijving
         custom_popover_1800_short_description: 'TE-Booster Ingeschakeld',
         custom_popover_1804_short_description: 'TE-Booster Setpoint',
         custom_popover_1805_short_description:
           'TE-Booster Setpoint Hysteresis',
         custom_popover_1812_short_description: 'TE-Booster Setpoint Timeout',
         custom_popover_1813_short_description: 'TE-Booster Setpoint Timer',
         custom_popover_1815_short_description: 'TE-Booster Drempel',
         custom_popover_1816_short_description: 'TE-Booster Afgenomen Volume',
         // Heating PopOver Short Omschrijving
         custom_popover_500_short_description: 'CV Max Setpoint',
         custom_popover_501_short_description: 'CV Min Setpoint',
         custom_popover_506_short_description: 'Max Primaire Retour',
         custom_popover_507_short_description: 'Max Primaire Retour Percentage',
         custom_popover_508_short_description: 'Delta Prim CV Setpoint',
         custom_popover_510_short_description: 'Max Verwarmingstemperatuur',
         custom_popover_509_short_description: 'Delta Prim Min',
         custom_popover_514_short_description: 'CV Setpoint',
         custom_popover_515_short_description: 'CV Enable',
         // Cooling PopOver Short Omschrijving
         custom_popover_600_short_description: 'CoolTempSetpoint',
         custom_popover_601_short_description: 'Cool MaxPrimary Retour',
         custom_popover_602_short_description:
           'Cool % of Max Setpoint compensation',
         custom_popover_605_short_description: 'Max Cooling Temp',
         custom_popover_606_short_description: 'Cool setpoint min',
         custom_popover_607_short_description: 'Cool setpoint max',
         custom_popover_611_short_description: 'Cooling Enable',
         // Secundaire General PopOver Short Omschrijving
         custom_popover_512_short_description: 'CV Min Pressure',
         custom_popover_513_short_description: 'CV Max Pressure',
         custom_popover_603_short_description: 'CoolHeatWaitingTime',
         custom_popover_604_short_description: '6way valve waiting time',
         // Changeover WKW PopOver Short Omschrijving
         custom_popover_1850_short_description: '3.5Pipe Configuration',
         custom_popover_1851_short_description: 'Cooling detection threshold',
         custom_popover_1852_short_description: 'Heating detection threshold',
         custom_popover_1853_short_description: 'Heating lowered setpoint',
         custom_popover_1854_short_description: 'Lowered setpoint used',
         custom_popover_1855_short_description: 'Predetection MCV2 position',
         custom_popover_1856_short_description: 'Detection interval',
         custom_popover_1857_short_description: 'Detection interval timer',
         custom_popover_1858_short_description: 'Predetection timeout',
         custom_popover_1859_short_description: 'Predetection timer',
         custom_popover_1860_short_description: 'Configuration switch timer',
         custom_popover_1861_short_description: 'Switch timeout',
         custom_popover_1862_short_description:
           'Configuration switch threshold',
         custom_popover_1863_short_description: 'Boost function mode',
         custom_popover_1864_short_description: 'Boost detection timer',
         custom_popover_1865_short_description: 'Boost detection timeout',
         custom_popover_1866_short_description: 'Maximum boost position',
         // Manual Operation PopOver Short Omschrijving
         custom_popover_700_short_description: 'Manual Control ingeschakeld',
         custom_popover_701_short_description: 'MCV-1 Handbediening',
         custom_popover_702_short_description: 'MCV-2 Handbediening',
         custom_popover_703_short_description: 'Pump (P1) Handbediening',
         custom_popover_704_short_description: 'Valve1',
         custom_popover_706_short_description: 'Heat demand status output',
         custom_popover_707_short_description: 'MCV-1 Handbediening[%]',
         custom_popover_708_short_description: 'MCV-1 Handbediening[%]',
         custom_popover_1801_short_description: 'Elektrisch element TE-Booster',
         custom_popover_709_short_description: 'Handbediening timeout',
         // Warmtapwater PopOver Long Omschrijving
         custom_popover_300_long_description: 'Tapwater Setpoint',
         custom_popover_301_long_description: 'Preheat mode',
         custom_popover_302_long_description: 'Comfort Setpoint',
         custom_popover_303_long_description: 'Flow rate start threshold',
         custom_popover_304_long_description: 'Tapwater Enable',
         // Thermal disinfection PopOver Long Omschrijving
         custom_popover_400_long_description: 'Aleg Ingeschakeld',
         custom_popover_401_long_description: 'Aleg Setpoint',
         custom_popover_402_long_description: 'Aleg Burn Tijd',
         custom_popover_403_long_description: 'Aleg Wait Tijd',
         // TE-Booster PopOver Long Omschrijving
         custom_popover_1800_long_description: 'TE-Booster Ingeschakeld',
         custom_popover_1804_long_description: 'TE-Booster Setpoint',
         custom_popover_1805_long_description: 'TE-Booster Setpoint Hysteresis',
         custom_popover_1812_long_description: 'TE-Booster Setpoint Timeout',
         custom_popover_1813_long_description: 'TE-Booster Setpoint Timer',
         custom_popover_1815_long_description: 'TE-Booster Threshold',
         custom_popover_1816_long_description: 'TE-Booster Taped Volume',
         // Heating PopOver Long Omschrijving
         custom_popover_500_long_description: 'CV Max Setpoint',
         custom_popover_501_long_description: 'CV Min Setpoint',
         custom_popover_506_long_description: 'Max Primaire Retour',
         custom_popover_507_long_description: 'Max Primaire Retour Percentage',
         custom_popover_508_long_description: 'Delta Prim CV Setpoint',
         custom_popover_510_long_description: 'Max Heating Temperatuur',
         custom_popover_509_long_description: 'Delta Prim Min',
         custom_popover_514_long_description: 'CV Setpoint',
         custom_popover_515_long_description: 'CV Enable',
         // Cooling PopOver Long Omschrijving
         custom_popover_600_long_description: 'CoolTempSetpoint',
         custom_popover_601_long_description: 'Cool MaxPrimary Retour',
         custom_popover_602_long_description:
           'Cool % of Max Setpoint compensation',
         custom_popover_605_long_description: 'Max Cooling Temp',
         custom_popover_606_long_description: 'Cool setpoint min',
         custom_popover_607_long_description: 'Cool setpoint max',
         custom_popover_611_long_description: 'Cooling Enable',
         // Secundaire General PopOver Long Omschrijving
         custom_popover_512_long_description: 'CV Min Pressure',
         custom_popover_513_long_description: 'CV Max Pressure',
         custom_popover_603_long_description: 'CoolHeatWaitingTime',
         custom_popover_604_long_description: '6way valve waiting time',
         // Changeover WKW PopOver Long Omschrijving
         custom_popover_1850_long_description: '3.5Pipe Configuration',
         custom_popover_1851_long_description: 'Cooling detection threshold',
         custom_popover_1852_long_description: 'Heating detection threshold',
         custom_popover_1853_long_description: 'Heating lowered setpoint',
         custom_popover_1854_long_description: 'Lowered setpoint used',
         custom_popover_1855_long_description: 'Predetection MCV2 position',
         custom_popover_1856_long_description: 'Detection interval',
         custom_popover_1857_long_description: 'Detection interval timer',
         custom_popover_1858_long_description: 'Predetection timeout',
         custom_popover_1859_long_description: 'Predetection timer',
         custom_popover_1860_long_description: 'Configuration switch timer',
         custom_popover_1861_long_description: 'Switch timeout',
         custom_popover_1862_long_description: 'Configuration switch threshold',
         custom_popover_1863_long_description: 'Boost function mode',
         custom_popover_1864_long_description: 'Boost detection timer',
         custom_popover_1865_long_description: 'Boost detection timeout',
         custom_popover_1866_long_description: 'Maximum boost position',
         // Manual Operation PopOver Long Omschrijving
         custom_popover_700_long_description: 'Manual Control ingeschakeld',
         custom_popover_701_long_description: 'MCV-1 Handbediening',
         custom_popover_702_long_description: 'MCV-2 Handbediening',
         custom_popover_703_long_description: 'Pump (P1) Handbediening',
         custom_popover_704_long_description: 'Valve1',
         custom_popover_706_long_description: 'Heat demand status output',
         custom_popover_707_long_description: 'MCV-1 Handbediening[%]',
         custom_popover_708_long_description: 'MCV-1 Handbediening[%]',
         custom_popover_1801_long_description: 'Elektrisch element TE-Booster',
         custom_popover_709_long_description: 'Handbediening timeout',
         // API keys
         client_single_api_key_approve_api_key_label: 'API Key',
         client_single_api_key_app_id_label: 'APP Id',
         client_single_api_key_approve_api_key_button: 'Account toestaan',
         client_single_api_key_suspend_api_key_button: 'Opschorten API toegang',
         client_single_api_key_unblock_application_button: 'API deblokkeren',
         client_single_api_key_generate_api_key_button: 'API Key genereren',
         client_single_api_key_generate_api_key_success:
           'API Key is succesvol gegenereerd',
         client_single_api_key_generate_api_limit_error:
           'Sorry, we kunnen geen nieuwe API-sleutel opnieuw genereren omdat de limiet voor opnieuw genereren is bereikt.',
         client_single_api_key_generate_api_kong_error:
           'Kong API Error. Neem contact op met de beheerder.',
         client_single_api_application_suspend_success:
           'Kong Application is suspended succesvol',
         client_single_api_application_unblock_success:
           'Kong Application is Unblocked succesvol',
         client_single_api_application_suspend_error:
           'Application is already suspended',
         client_single_api_application_unblock_errpr:
           'Application is already unblocked',
         client_single_api_key_account_pending_approval:
           'Uw account is nog niet goedgekeurd. Neem contact op met de beheerder.',
         client_single_api_key_account_suspended:
           'Uw account is opgeschort. Neem contact op met de beheerder.',
         // Klanten Form
         three_scale_temporary_password_subject:
           'Fortes Data Beheer - Kong Account Aangemaakt',
         clients_form_password_field: 'Wachtwoord',
         clients_password_required_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken.',
         // Devices Form
         devices_form_mbus_meters_are_not_connected:
           'Energiemeters zijn niet verbonden.',
         MBUS_METER_HEATING: 'Warmtemeter',
         MBUS_METER_COOLING: 'Koudemeter',
         MBUS_METER_ELECTRICITY: 'Elektriciteitsmeter',
         MBUS_METER_WATER: 'Watermeter',
         MBUS_METER_BI_DIRECTIONAL: 'Warmte/koude meter',
         meter_enabled: 'Ingeschakeld',
         meter_disabled: 'Uitgeschakeld',
         devices_mbus_registers_toggle_register_status_success:
           'Het bericht is succesvol verstuurd naar het Device.',
         MBUS_METER_SERIAL_NUMBER: 'Serienummer',
         MBUS_METER_MANUFACTURER: 'Fabrikant',
         MBUS_METER_METER_TYPE: 'Meter type',
         MBUS_METER_STATUS: 'Status',
         MBUS_METER_FLOW: 'Volumestroom',
         MBUS_METER_POWER: 'Vermogen',
         MBUS_METER_SUPPLY_TEMPERATURE: 'Aanvoertemperatuur',
         MBUS_METER_RETURN_TEMPERATURE: 'Retourtemperatuur',
         MBUS_METER_HEATING_ENERGY: 'Totale energieverbruik (warmte)',
         MBUS_METER_ELECTRICITY_ENERGY:
           'Totale energieverbruik (elektriciteit)',
         MBUS_METER_VOLUME: 'Totale volume',
         MBUS_METER_COOLING_ENERGY: 'Totale energieverbruik (koude)',
         MBUS_METER_CURRENT: 'Current',
         custom_popover_MBUS_METER_SERIAL_NUMBER_short_description:
           'Serienummer',
         custom_popover_MBUS_METER_MANUFACTURER_short_description: 'Fabrikant',
         custom_popover_MBUS_METER_METER_TYPE_short_description: 'Meter type',
         custom_popover_MBUS_METER_STATUS_short_description: 'Status',
         custom_popover_MBUS_METER_FLOW_short_description: 'Volumestroom',
         custom_popover_MBUS_METER_POWER_short_description: 'Vermogen',
         custom_popover_MBUS_METER_SUPPLY_TEMPERATURE_short_description:
           'Aanvoertemperatuur',
         custom_popover_MBUS_METER_RETURN_TEMPERATURE_short_description:
           'Retourtemperatuur',
         custom_popover_MBUS_METER_HEATING_ENERGY_short_description:
           'Totale energieverbruik (warmte)',
         custom_popover_MBUS_METER_ELECTRICITY_ENERGY:
           'Totale energieverbruik (elektriciteit)',
         custom_popover_MBUS_METER_VOLUME_short_description: 'Totale volume',
         custom_popover_MBUS_METER_COOLING_ENERGY_short_description:
           'Totale energieverbruik (koude)',
         custom_popover_MBUS_METER_CURRENT_short_description: 'Stroom',
         // Device Set Logs Per Device
         device_single_device_registers_tab: 'Set log',
         device_single_device_registers_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Bedrijfsstatus',
             permission: 'operating-state-tab-read',
           },
         ]),
         device_status_interval: 'Interval',
         device_status_interval_in_seconds: 'Interval in seconden',
         device_single_set_registers_error:
           'Selecteer tenminste 1 register om door te gaan.',
         device_single_set_registers_success:
           'Bericht is succesvol doorgestuurd naar het Device.',
         device_single_set_register_form_interval_required_error:
           'Interval (seconden) is vereist',
         device_single_set_register_form_interval_numeric_error:
           'Interval (seconden) moet een getal zijn',
         device_single_set_register_form_send_button: 'Instellen',
         device_single_set_register_form_cancel_button: 'Annuleren',
         clients_kong_email_error: 'Email bestaal al in Kong',
         page_title_appending_title: ' | Fortes Data Beheer',
         project_single_device_registers_tab: 'Set log',
         audits_user: 'Gebruiker',
         audits_system_generated: 'Applicatie',
         device_single_project_set_registers_error:
           'Bericht wordt niet doorgestuurd omdat er geen devices aan het project zijn gekoppeld',
         audits_user_filter_label: 'Gebruiker',
         audits_event_type_field: 'Event type',
         audits_select_event_type_field: 'Selecteer event type',
         audits_select_event_type_incoming_option: 'Inkomend',
         audits_select_event_type_outgoing_option: 'Uitgaand',
         audits_select_event_type_system_generated_option: 'Applicatie',
         audits_table_loading: 'Events laden',
         appliance_type_reference_table_appliance_name: 'Naam',
         appliance_type_reference_table_appliance_type: 'Toesteltype',
         appliance_type_reference_table_updated_at: 'Gewijzigd op',
         sidebar_appliance_type_reference_menu: 'Toesteltype',
         appliance_types_register_form_send_button: 'Opslaan',
         appliance_types_register_form_cancel_button: 'Annuleer',
         appliance_types: 'Toesteltypes',
         appliance_types_table_no_record_found: 'Geen Device gevonden',
         appliance_types_table_row_per_page: 'Resultaten per pagina',
         add_appliance_type: 'Toesteltype toevoegen',
         add_appliance_types_form_header: 'Toestel type toevoegen',
         edit_appliance_types_form_header: 'Toesteltype wijzigen',
         appliance_types_add_new: 'Toevoegen',
         appliance_type_form_name_label: 'Naam',
         appliance_type_form_type_label: 'Toesteltype',
         appliance_type_form_select_type_label: 'Selecteer Toesteltype',
         appliance_name_form_required_error: 'Naam is verplicht',
         appliance_type_form_required_error: 'Toesteltype is verplicht',
         add_appliance_type_form_success:
           'Toesteltype record is succesvol aangemaakt',
         add_appliance_type_form_api_error:
           'Internal Server add toesteltype - API Storing',
         edit_appliance_type_form_success:
           'Toesteltype record is succesvol gewijzigd',
         edit_appliance_type_form_api_error:
           'Internal Server edit toesteltype - API Storing',
         device_status_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Bedrijfstatus',
             permission: 'operating-state-tab-read',
           },
           {
             key: 'settings_hot_water',
             value: 'Instellingen tapwater',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Instellingen verwarmen en koelen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbediening',
             permission: 'manual-control-tab-read',
           },
           {
             key: 'firmware_registers',
             value: 'Firmware Registers',
             permission: 'firmware-registers-tab-read',
           },
           {
             key: 'lin_pump_delta_parameters',
             value: 'LIN-Pump DeltaP Parameters',
             permission: 'lin-pump-tab-read',
           },
           {
             key: 'm_bus_meters',
             value: 'Energiemeters (M-Bus)',
             permission: 'm-bus-meters-tab-read',
           },
           {
             key: 'live_hot_water',
             value: 'Livelog tapwater',
             permission: 'livelog-hot-water-tab-read',
           },
           {
             key: 'live_heating',
             value: 'Livelog verwarming',
             permission: 'livelog-heating-tab-read',
           },
           {
             key: 'live_cooling',
             value: 'Livelog koeling',
             permission: 'livelog-cooling-tab-read',
           },
         ]),
         appliance_type_reference_table_filter_reset_button: 'Herstel',
         appliance_type_reference_table_search: 'Zoeken',
         appliance_type_reference_status_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Bedrijfsstatus',
             permission: 'operating-state-tab-read',
           },
           {
             key: 'settings_hot_water',
             value: 'Instellingen tapwater',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Instellingen verwarmen en koelen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbediening',
             permission: 'manual-control-tab-read',
           },
           {
             key: 'firmware_registers',
             value: 'Firmware Registers',
             permission: 'firmware-registers-tab-read',
           },
           {
             key: 'lin_pump_delta_parameters',
             value: 'LIN-Pump DeltaP Parameters',
             permission: 'lin-pump-tab-read',
           },
         ]),
         audits_filter_button: 'Filters',
         device_single_no_registers_are_available:
           'Registers zijn niet beschikbaar',
         device_single_device_diagnostics_tab: 'Diagnostiek',
         device_single_dp_measurement_back_button: 'Vorige',
         device_single_diagnostic_next_button: 'Volgende',
         device_single_dp_measurement_start_button: 'Start',
         device_single_diagnostict_finish_button: 'Finish',
         device_single_diagnostic_stop_button: 'Stop',
         device_single_dp_measurement_refresh_button: 'Verversen',
         device_single_dp_measurement_event_logs_title: 'Uitkomsten',
         device_single_dp_measurement_select_mbus_meter_button:
           'Selecteer Energiemeter',
         device_single_dp_measurement_step_1_title:
           'Drukverschil berekening - Kies flow',
         device_single_dp_measurement_pressure_difference_stepper_1_title:
           'Kies flow',
         device_single_dp_measurement_step_1_flow_1_label:
           'Drukverschil berekenen',
         device_single_dp_measurement_step_1_flow_2_label: 'Warmwater',
         device_single_dp_measurement_pressure_difference_step_2_title:
           'Drukverschil berekening - Uitleg van de wizard',
         device_single_dp_measurement_pressure_difference_stepper_2_title:
           'Uitleg van de wizard',
         device_single_dp_measurement_pressure_difference_step_2_description:
           'Beschikbaar drukverschil is altijd een vraagstuk bij collectieve netwerken. Met de krachtige combinatie van AquaHeat aflversets en het Fortes Data Beheer platform kunt u middels een gebruiksvriendelijke module eenvoudig een drukverschilberekening uitvoeren op ieder punt in uw collectieve netwerk. Dit geeft een uitstekende eerste indicatie van een mogelijke oorzaak. Middels deze diagnose stellen wij u in staat om een eerste indicatie van het beschikbare drukverschil te laten berekenen. <br /><br /><strong>Vereist: Warmtemeter dient verbonden te zijn aan de Mercurius Communicatie Module</strong>',
         device_single_dp_measurement_pressure_difference_step_3_title:
           'Drukverschil berekening - Aansturen van de juiste componenten',
         device_single_dp_measurement_pressure_difference_stepper_3_title:
           'Handbediening register',
         device_single_dp_measurement_step_3_pressure_difference_output_1:
           'Uitkomst 1 - Aanzetten van de handbediening',
         device_single_dp_measurement_pressure_difference_output_2:
           'Uitkomst 2 - Openen van de regelafsluiter',
         device_single_dp_measurement_pressure_difference_step_3_mbus_meter_title:
           'Selecteer M-Bus Meter (bij meerdere meters betreft het de energiemeter welke het tapwater registreert.)',
         device_single_dp_measurement_pressure_difference_stepper_4_title:
           'Energiemeter selecteren',
         device_single_dp_measurement_pressure_difference_step_4_flow_label:
           'Debiet',
         device_single_dp_measurement_pressure_difference_stepper_5_title:
           'Berekenen van het drukverschil',
         device_single_dp_measurement_pressure_difference_output_3:
           'Uitkomst 3 - Selecteer Meter',
         device_single_dp_measurement_pressure_difference_output_4:
           'Uitkomst 4 - Calculation of de differential druk',
         device_single_dp_measurement_pressure_difference_stepper_6_title:
           'Resultaat',
         device_single_dp_measurement_pressure_difference_step_6_description:
           'Uw drukverschil is OK! Er zijn geen verdere acties vereist. ',
         device_single_dp_measurement_download_button: 'Download resultaten',
         device_single_dp_measurement_pressure_difference_step_7_description:
           'De drukverschil berekening is succesvol afgerond.',
         device_single_general_title: 'Algemeen',
         device_single_hotwater_title: 'Warmwater',
         device_single_dp_measurement_hot_water_flow_1_stepper_1_title:
           'Kies flow',
         device_single_dp_measurement_hot_water_flow_1_stepper_2_title:
           'Uitleg van de wizard',
         device_single_dp_measurement_hot_water_flow_1_stepper_3_title:
           'Open kraan',
         device_single_dp_measurement_hot_water_flow_1_stepper_4_title:
           'Checken kraan en tapwater klep',
         device_single_dp_measurement_hot_water_flow_1_stepper_5_title:
           'Energiemeter',
         device_single_dp_measurement_hot_water_flow_1_stepper_6_title:
           'Berekening van warm water flow',
         device_single_dp_measurement_hot_water_flow_1_stepper_7_title:
           'Resultaten',
         device_single_dp_measurement_hot_water_flow_1_step_2_title:
           'Drukverschil berekening - Uitleg van flow',
         device_single_dp_measurement_hot_water_flow_1_step_2_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
         device_single_dp_measurement_hot_water_flow_1_step_3_title:
           'Open kraan',
         device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error:
           'Flow Sensor Defect',
         device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error_one_try_left:
           'Probeer nog een keer',
         diagnostic_flows_form_open_faucet_title: 'Kraan is geopend door klant',
         diagnostic_flows_form_open_faucet_off_option_description:
           'Kraan is niet geopend door klant',
         device_single_dp_measurement_hot_water_flow_1_output_1:
           'Output 1 - Tapwater State',
         device_single_dp_measurement_hot_water_flow_1_output_2:
           'Output 2 - Tapwaterklep (M1)',
         device_single_dp_measurement_hot_water_flow_1_step_3_mbus_meter_title:
           'Selecteer Energiemeter',
         device_single_dp_measurement_hot_water_flow_1_step_4_flow_label:
           'Flow',
         device_single_dp_measurement_step_4_hot_water_flow_1_output_3:
           'Output 3 - Selecteer Energiemeter',
         device_single_dp_measurement_hot_water_flow_1_output_4:
           'Output 4 - Energiemeter Flow',
         device_single_dp_measurement_hot_water_flow_1_output_5:
           'Output 5 - Berekening van warm water flow',
         device_single_dp_measurement_hot_water_flow_1_step_7_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
         device_single_dp_measurement_hot_water_flow_1_step_8_description:
           'Warm water - Flow 1 Diagnostiek is afgerond',
         device_single_heating_title: 'Verwarming',
         device_single_dp_measurement_step_1_flow_3_label: 'Flow 1',
         device_single_cooling_title: 'Koeling',
         device_single_dp_measurement_step_1_flow_4_label: 'Flow 1',
         device_single_temperature_graph_loading_data: 'Laden van de grafiek',
         device_single_temperature_no_graph_data_error:
           'De grafiek is niet beschikbaar',
         device_graph_week_filter: 'W',
         device_graph_weeks_filter: 'W',
         device_graph_day_filter: 'D',
         device_graph_days_filter: 'D',
         device_graph_hours_filter: 'H',
         device_single_registers_table_numeric_error:
           'Alleen numerieke waarden zijn toegestaan',
         device_single_registers_table_min_max_error:
           'Waarde moet tussen minimale en maximale waarde liggen',
         MBUS_METER_TYPE_WATER: 'Water',
         MBUS_METER_TYPE_ELECTRICITY: 'Elektriciteit',
         MBUS_METER_TYPE_HEATING: 'Warmte',
         MBUS_METER_TYPE_BIDIRECTIONAL: 'Warmte/koude',
         MBUS_METER_TYPE_COOLING: 'Koude',
         UNKNOWN_MANUFACTURER: 'Onbekende fabrikant',
         commissioning_form_header: 'Inbedrijfstelling',
         commissioning_form_serial_number_scan_button: 'Scan QR code Mercurius',
         commissioning_form_heat_interface_unit_scan_button:
           'Scan QR code afleverset',
         commissioning_device_submit_button: 'Gereed',
         commissioning_form_search_field: 'Serienummer Mercurius',
         sidebar_commissioning_overview: 'Inbedrijfstelling',
         sidebar_commissioning: 'Inbedrijfstelling',
         commissioning_form_success: 'De inbedrijfstelling is succesvol!',
         commissioning_error_message: 'Device is niet gevonden',
         swapping_form_header: 'Uitwisselen Device',
         swapping_old_device_title: 'Oude Device (serienummer)',
         swapping_new_device_title: 'Nieuwe Device (serienummer)',
         swapping_form_scan_button: 'Scan',
         swapping_form_search_field: 'Serienummer Mercurius',
         sidebar_swapping_overview: 'Inbedrijfstelling',
         sidebar_swapping: 'Uitwisseling',
         swapping_device_submit_button: 'Gereed',
         swapping_form_success: 'Device is succesvol bijgewerkt!',
         swapping_old_device_error_message: 'Oude Device is niet gevonden',
         swapping_new_device_error_message: 'Nieuwe Device is niet gevonden',
         email_device_swapped_by: 'Device uitgewisseld door:',
         swapping_device_unique_error: 'Device bestaat reeds',
         clear_device_form_header: 'Wissen',
         clear_device_form_scan_button: 'Scan',
         clear_device_form_search_field: 'Serienummer Mercurius',
         sidebar_clear_device_overview: 'Wis Device',
         sidebar_clear_device: 'Wissen',
         clear_device_form_success: 'Device is succesvol bijgewerkt!',
         clear_device_error_message: 'Device is niet gevonden',
         clear_device_submit_button: 'Wis Device',
         clear_device_audit_message: 'Device is gewist',
         device_single_swapped_devices_tab: 'Uitgewisselde devices',
         devices_single_swapped_old_device_name: 'Uitgewisseld van (Oud)',
         devices_single_swapped_device_old_firmware: 'Oude firmware',
         devices_single_swapped_device_new_firmware: 'Nieuwe firmware',
         devices_single_swapped_old_device_type: 'Oud Device type',
         devices_single_swapped_new_device_type: 'Nieuw Device type',
         devices_single_swapped_device_old_box_serial_number:
           'Oud Serienummer afleverset',
         devices_single_swapped_device_new_box_serial_number:
           'Serienummer afleverset',
         devices_single_swapped_device_old_sim_card_number:
           'Oud simkaartnummer',
         devices_single_swapped_device_new_sim_card_number:
           'Nieuwe simkaartnummer',
         devices_single_swapped_device_created_at: 'Datum uitwisseling',
         devices_single_swapped_device_name_search: 'Zoek',
         devices_single_swapped_new_device_name: 'Uitgewisseld voor (Nieuw)',
         devices_single_swapped_device_table_no_record_found: 'Niet gevonden!',
         devices_single_swapped_device_table_loading: 'Laden',
         devices_single_swapped_device_table_row_per_page:
           'Resultaten per pagina',
         sidebar_swapped_devices_overview: 'Uitgewisselde devices',
         sidebar_swapped_devices: 'Uitgewisselde devices',
         swapped_devices_form_header: 'Uitgewisselde devices',
         devices_bulk_edit_registers_form_no_devices_error:
           'Selecteer devices om door te gaan.',
         devices_bulk_edit_firmware_modal_title:
           'Weet u zeker dat u een firmware update wilt uitvoeren?',
         devices_bulk_edit_firmware_modal_subtitle:
           'Wilt u firmwareversie ##firmware_value## in bulk bijwerken voor ##total_devices## devices?',
         devices_bulk_edit_registers_modal_title:
           'Weet u zeker dat u de groepsactie wilt uitvoeren?',
         devices_bulk_edit_registers_modal_subtitle:
           'Wilt u de registers voor ##total_devices## devices in een keer bijwerken?',
         devices_bulk_edit_registers_modal_cancel_button: 'Annuleer',
         devices_bulk_edit_registers_modal_submit_button: 'Bevestigen',
         devices_bulk_edit_firmware_modal_cancel_button: 'Annuleer',
         devices_bulk_edit_firmware_modal_submit_button: 'Bevestigen',
         devices_bulk_export_button: 'Exporteer devices',
         projects_bulk_export_button: 'Exporteer projecten',
         project_devices_bulk_export_button: 'Exporteer projecten',
         devices_bulk_export_file_name: 'Device groeps export',
         projects_bulk_export_file_name: 'Project groeps export',
         project_devices_bulk_export_file_name: 'Device groeps export',
         sidebar_reporting_overview: 'Rapporten',
         sidebar_bulk_edit_ota_reporting: 'Updates',
         sidebar_bulk_reporting_registers_table_title: 'Groepswijzigingen',
         edit_ota_reporting: 'Update rapporten',
         reporting_ota_all: 'Alle',
         reporting_ota_success: 'Succesvol',
         reporting_ota_failure: 'Mislukt',
         reporting_ota_bulk_export_button: 'Exporteren',
         reporting_ota_filter_reset_button: 'Herstel',
         reporting_ota_table_filter_search: 'Zoeken',
         reporting_ota_table_updated_at: 'Datum',
         reporting_ota_table_total_devices: 'Totaal aantal devices',
         reporting_ota_table_user: 'Gebruiker',
         reporting_ota_table_message: 'Opmerking',
         reporting_registers_table_user: 'Gebruiker',
         reporting_registers_table_message: 'Opmerking',
         reporting_ota_table_no_record_found: 'Geen zoekresultaten gevonden',
         reporting_ota_table_row_per_page: 'Resultaten per pagina',
         edit_ota_reporting_details: 'OTA Audit Details',
         reporting_ota_details_all_tab: 'Alle',
         reporting_ota_details_pending_tab: 'Bezig',
         reporting_ota_details_success_tab: 'Succesvol',
         reporting_ota_details_failure_tab: 'Mislukt',
         reporting_ota_details_bulk_export_button: 'Exporteren',
         reporting_ota_details_filter_reset_button: 'Herstel',
         reporting_ota_details_table_filter_search: 'Zoeken',
         reporting_ota_details_message: 'Firmwareversie bijgewerkt naar',
         reporting_ota_details_table_created_at: 'Datum',
         reporting_ota_details_table_device: 'Device',
         reporting_ota_details_table_user: 'Gebruiker',
         reporting_ota_details_table_old_firmware_version:
           'Oude firmwareversie',
         reporting_ota_details_table_current_firmware_version:
           'Huidige firmwareversie',
         reporting_ota_details_table_current_status: 'Status',
         reporting_ota_details_table_message: 'Bericht',
         reporting_ota_details_table_no_record_found:
           'Geen zoekresultaten gevonden',
         reporting_ota_details_table_row_per_page: 'Resultaten per pagina',
         reporting_ota_bulk_export_file_name: 'OTA Rapport',
         reporting_ota_details_bulk_export_file_name:
           'Bulk OTA Details Rapportage',
         devices_single_swapped_bulk_export_button_file_name:
           'Swapped Devices Rapportage',
         devices_single_swapped_bulk_export_button: 'Exporteren',
         reporting_registers_all: 'Alle',
         reporting_registers_success: 'Succesvol',
         reporting_registers_failure: 'Mislukt',
         reporting_registers_table_created_at: 'Datum',
         reporting_registers_table_total_devices: 'Totaal aantal devices',
         reporting_registers_bulk_export_button: 'Exporteren',
         reporting_registers_filter_reset_button: 'Herstel',
         reporting_registers_table_filter_search: 'Zoeken',
         reporting_registers_table_no_record_found:
           'Geen zoekresultaten gevonden',
         reporting_registers_table_row_per_page: 'Resultaten per pagina',
         reporting_registers_table_title: 'Groepswijzigingen rapporten',
         reporting_registers_table_bulk_export_file_name:
           'Groeps Registers Rapportage',
         registers_reporting_details_title: 'Details groepswijzigingen',
         reporting_registers_details_bulk_export_button: 'Exporteren',
         reporting_registers_details_filter_reset_button: 'Herstel',
         reporting_registers_details_table_filter_search: 'Zoeken',
         reporting_registers_details_table_created_at: 'Datum',
         reporting_registers_details_table_device: 'Device',
         reporting_registers_details_table_user: 'Gebruiker',
         reporting_registers_details_table_message: 'Bericht',
         reporting_registers_details_table_failed_message:
           'Firmwareversie is niet bijgewerkt naar',
         reporting_registers_details_table_pending_message:
           'Firmwareversie updaten naar',
         reporting_registers_details_table_successful_message:
           'Firmwareversie is bijgewerkt naar',
         reporting_registers_details_table_no_record_found:
           'Geen zoekresultaten gevonden',
         reporting_registers_details_table_row_per_page:
           'Resultaten per pagina',
         reporting_registers_details_bulk_export_file_name:
           'Groeps Registers Details Rapportage',
         devices_bulk_edit_ota_form_firmware_field_select_label:
           'Selecteer firmware',
         dashboard_users_overview: 'Gebruikersoverzicht',
         dashboard_users_title: 'Gebruikers',
         dashboard_devices_overview: 'Device overzicht',
         dashboard_devices_title: 'Devices',
         dashboard_commissioning_overview: 'Inbedrijfstelling',
         dashboard_commissioning_title: 'Inbedrijfstelling',
         devices_filters: 'Filters:',
         devices_submit_button: 'Verzenden',
         device_single_device_documentation_tab: 'Documenten',
         device_single_documentation_brochure_button: 'Brochure',
         device_single_documentation_installation_manual_button:
           'gebruikers-/montagehandleiding',
         device_single_documentation_dimensional_drawing_button: 'Maattekening',
         device_single_documentation_parts_drawing_button: 'Onderdelentekening',
         device_single_documentation_flow_direction_button: 'Stromingsrichting',
         device_single_documentation_principle_diagram_button: 'Principeschema',
         device_single_documentation_information_end_user_button:
           'Informatie eindgebruiker',
         device_single_documentation_malfunction_key_button: 'Storingssleutel',
         reporting_ota_failed_message: 'Firmwareversie is niet bijgewerkt naar',
         reporting_ota_pending_message: 'Firmwareversie updaten naar',
         reporting_ota_successful_message: 'Firmwareversie bijgewerkt naar',
         ota_pending: 'Bezig',
         ota_success: 'Succesvol',
         ota_failed: 'Mislukt',
         clear_devices_modal_title: 'Wis Device',
         clear_devices_modal_subtitle:
           'Weet u zeker dat u dit apparaat wilt wissen met serienummer <strong>##serial_number##</strong>',
         clear_devices_modal_cancel_button: 'Annuleer',
         clear_devices_modal_submit_button: 'Wis Device',
         sidebar_fortes_roles: 'Rollen',
         sidebar_fortes_permissions: 'Recht',
         roles_all: 'Alle',
         roles_active: 'Actief',
         roles_inactive: 'Inactief',
         roles_filter_delete_button: 'Verwijderen',
         roles_table_identifier: 'Identifier',
         roles_table_name_en: 'Naam (EN)',
         roles_table_name_nl: 'Naam (NL)',
         roles_table_description_en: 'Omschrijving (EN)',
         roles_table_description_nl: 'Omschrijving (NL)',
         roles_table_status: 'Status',
         roles_table_updated_at: 'Bijgewerkt op',
         roles_filter_search: 'Zoeken',
         roles_no_record_found: 'Geen resultaten gevonden',
         roles_table_row_per_page: 'Resultaten per pagina',
         roles_bulk_no_record_is_selected_error_message:
           'Selecteer devices voor groepsdeactivering.',
         roles_deactivated_message: 'Rol is sucesvol gedeactiveerd',
         roles_activated_message: 'Rol is succesvol geactiveerd',
         roles_bulk_deactivated_message:
           '##ROLES_COUNT## Rollen zijn succesvol gedeactiveerd',
         roles_title: 'Rollen',
         roles_add_new: 'Rol toevoegen',
         roles_bread_crumb_main_title: 'Rollen',
         add_roles_bread_crumb_first_link: 'Toevoegen',
         edit_roles_bread_crumb_first_link: 'Rol wijzigen',
         add_roles_form_header: 'Rol toevoegen',
         edit_roles_form_header: 'Rol wijzigen',
         roles_form_role_identifier_field: 'Identifier',
         roles_identifier_required_form_error: 'Identifier is verplicht',
         roles_form_api_error: 'Interne Server Error',
         roles_form_unqiue_role_name_error: 'Identifier moet uniek zijn',
         roles_form_api_forbidden:
           'Gebruiker heeft geen rechten om een rol te maken',
         roles_add_form_success: 'Rol is succesvol aangemaakt',
         roles_edit_form_success: 'Rol is succesvol bijgewerkt',
         roles_form_add_role_button: 'Rol toevoegen',
         roles_form_edit_role_button: 'Rol wijzigen',
         roles_form_cancel_button: 'Annuleer',
         roles_form_permissions_title: 'Rechten',
         sidebar_fortes_modules: 'Modules',
         modules_all: 'Alle',
         modules_active: 'Actief',
         modules_inactive: 'Inactief',
         modules_filter_delete_button: 'Verwijderen',
         modules_table_identifier: 'Identifier',
         modules_table_name_en: 'Naam (EN)',
         modules_table_name_nl: 'Naam (NL)',
         modules_table_description_en: 'Omschrijving (EN)',
         modules_table_description_nl: 'Omschrijving (NL)',
         modules_table_status: 'Status',
         modules_table_updated_at: 'Bijgewerkt op',
         modules_filter_search: 'Zoeken',
         modules_no_record_found: 'Geen devices gevonden',
         modules_table_row_per_page: 'Resulataten per pagina',
         modules_bulk_no_record_is_selected_error_message:
           'Selecteer rijen voor bulkdeactivering.',
         modules_deactivated_message: 'Module is succesvol gedeactiveerd',
         modules_activated_message: 'Module is succesvol geactiveerd',
         modules_bulk_deactivated_message:
           '##MODULES_COUNT## modules zijn succesvol gedeactiveerd',
         modules_title: 'Modules',
         modules_add_new: 'Toevoegen',
         modules_bread_crumb_main_title: 'Modules',
         add_modules_bread_crumb_first_link: 'Module toevoegen',
         edit_modules_bread_crumb_first_link: 'Module wijzigen',
         add_modules_form_header: 'Module toevoegen',
         edit_modules_form_header: 'Module wijzigen',
         modules_form_module_identifier_field: 'Identifier',
         modules_identifier_required_form_error: 'Identifier is verplicht',
         modules_form_module_name_en_field: 'Naam (EN)',
         modules_form_module_name_nl_field: 'Naam (NL)',
         modules_name_en_required_form_error: 'Naam (EN) is verplicht',
         modules_name_nl_required_form_error: 'Naam (NL) is verplicht',
         modules_form_description_en_field: 'Omschrijving (EN)',
         modules_form_description_nl_field: 'Omschrijving (NL)',
         modules_form_api_error: 'Interne Server Error',
         modules_form_unqiue_module_name_error: 'Identifer moet uniek zijn',
         modules_form_api_forbidden:
           'Gebruiker heeft geen rechten om een module te maken',
         modules_add_form_success: 'Module is succesvol aangemaakt',
         modules_edit_form_success: 'Module is succesvol bijgewerkt',
         modules_form_add_module_button: 'Module toevoegen',
         modules_form_edit_module_button: 'Module wijzigen',
         modules_form_cancel_button: 'Annuleer',
         permissions_all: 'Alle',
         permissions_active: 'Actief',
         permissions_inactive: 'Inactief',
         permissions_filter_delete_button: 'Verwijderen',
         permissions_table_identifier: 'Identifier',
         permissions_table_module_name: 'Module',
         permissions_table_name_en: 'Naam (EN)',
         permissions_table_name_nl: 'Naam (NL)',
         permissions_table_description_en: 'Omschrijving (EN)',
         permissions_table_description_nl: 'Omschrijving (NL)',
         permissions_table_status: 'Status',
         permissions_filter_search: 'Zoeken',
         permissions_no_record_found: 'Geen Device gevonden',
         permissions_table_row_per_page: 'Resulataten per pagina',
         permissions_bulk_no_record_is_selected_error_message:
           'Selecteer rijen voor bulkdeactivering.',
         permissions_deactivated_message: 'Recht is succesvol gedeactiveerd',
         permissions_activated_message: 'Recht is succesvol geactiveerd',
         permissions_bulk_deactivated_message:
           '##PERMISSIONS_COUNT## rechten zijn succesvol gedeactiveerd',
         permissions_title: 'Rechten',
         permissions_add_new: 'Recht toevoegen',
         permissions_bread_crumb_main_title: 'Recht',
         add_permissions_bread_crumb_first_link: 'Recht toevoegen',
         edit_permissions_bread_crumb_first_link: 'Recht wijzigen',
         edit_permissions_form_header: 'Recht wijzigen',
         add_permissions_form_header: 'Recht toevoegen',
         permissions_form_permission_identifier_field: 'Identifier',
         permissions_form_permission_module_field: 'Module',
         permissions_form_permission_select_module_field: 'Selecteer module',
         permissions_module_required_form_error: 'Module is verplicht',
         permissions_identifier_required_form_error: 'Identifier is verplicht',
         permissions_form_permission_name_en_field: 'Naam (EN)',
         permissions_form_permission_name_nl_field: 'Naam (NL)',
         permissions_form_permission_sub_module_field: 'Sub-Module',
         permissions_form_permission_select_sub_module_field:
           'Selecteer Sub-Module',
         permissions_sub_module_required_form_error: 'Sub-Module is Verplicht',
         permissions_name_required_form_error: 'Naam is Verplicht',
         permissions_form_description_en_field: 'Beschrijving (EN)',
         permissions_form_description_nl_field: 'Omschrijving (NL)',
         permissions_form_api_error: 'Interne Server Error',
         permissions_form_unqiue_permission_name_error:
           'Identifer moet uniek zijn',
         permissions_edit_form_success: 'Klant is succesvol bijgewerkt!',
         permissions_form_api_forbidden:
           'Gebruiker heeft geen toestemming om rechten te maken',
         permissions_add_form_success: 'Recht succesvol aangemaakt',
         permissions_form_add_permission_button: 'Recht toevoegen',
         permissions_form_edit_permission_button: 'Recht wijzigen',
         permissions_form_cancel_button: 'Annuleer',
         permissions_read: 'Lezen',
         permissions_create: 'Toevoegen',
         permissions_update: 'Bijwerken',
         permissions_delete: 'Verwijderen',
         permissions_status: 'Toggle Status',
         permissions_get_all: 'Haal Alle Op',
         permissions_get_by_id: 'Haal Op By Id',
         permissions_bulk_deactivate: 'Deactiveer groep',
         permissions_form_title: 'Rechten',
         duplicate_roles_form_header: 'Rol dupliceren',
         duplicate_roles_bread_crumb_first_link: 'Rol dupliceren',
         roles_duplicate_form_success: 'Rol is succesvol gedupliceerd',
         roles_filter_duplicate_button: 'Rol dupliceren',
         roles_pre_identifier_required_form_error:
           'Pre Identifier is verplicht',
         roles_form_pre_identifier_field: 'Pre Identifier',
         roles_form_pre_identifier_select: 'Selecteer Pre Identifier',
         duplicate_roles_identifier_message:
           '<ul style="margin: "0", padding-left: 15px"> <li>Voor nieuwe klant start rol altijd met de identifier van klant_</li> <li>For nieuwe sub client rol always start de identifier with service_client_</li> <li>For nieuwe fortes rol always start de identifier with fortes_</li> </ul>',
         devices_single_device_counters_title: 'Device Tellers',
         devices_single_device_counters_offline_counter: 'Offline Teller',
         devices_single_device_counters_online_counter: 'Online Teller',
         devices_single_device_counters_ota_fail_counter: 'OTA mislukt Teller',
         devices_single_device_counters_ota_update_counter:
           'OTA succesvol Teller',
         devices_single_device_counters_mbus_error_counter:
           'MBus alarm counter',
         devices_single_device_counters_modbus_error_counter:
           'Modbus alarm counter',
         devices_single_device_docs_directory: 'Directories',
         device_single_documentation_no_documents_found:
           'Geen documenten gevonden',
         reporting_registers_details_table_registers_pending:
           'Registers in afwachting',
         reporting_registers_details_table_registers_updated_fail:
           'Registers mislukt',
         reporting_registers_details_table_registers_updated_successful:
           'Registers succesvol',
         reporting_registers_details_table_status: 'Status',
         reporting_ota_message: 'Update Firmwareversie naar',
         devices_bulk_edit_registers_form_fix_register_values_error:
           'Corrigeer de fouten in alle tabbladen',
         login_permission_error:
           'Verkrijg de juiste machtigingen van Fortes Administrator',
         monitoring_title: 'Monitoring',
         monitoring_vm_title: 'Virtual Machine',
         monitoring_ram_title: 'RAM',
         monitoring_cpu_title: 'CPU',
         monitoring_disk_title: 'Disk',
         monitoring_restarts_title: 'Restarts',
         monitoring_uptime_title: 'Uptime',
         monitoring_services_title: 'Services',
         monitoring_service_table_service_name: 'Naam',
         monitoring_service_table_service_status: 'Status',
         be_user_service: 'Gebruikers',
         be_project_service: 'Projecten',
         be_export_service: 'Export',
         be_preset_service: 'Preset',
         be_status_updater_service: 'Status Updater',
         be_email_service: 'Email',
         be_permissions_rbac_service: 'Rechten',
         be_upload_images_service: 'Upload Images',
         be_db_archive_service: 'DB Archive',
         be_diagnostic_service: 'Diagnostisch',
         be_external_service: 'External APIs',
         be_mqtt_updater_service: 'MQTT',
         fortes_document_service: 'Fortes Document',
         external_client_service: 'Externe Klant',
         devices_form_service_client_field: 'Service klant',
         sidebar_kong_users: 'Kong klant Gebruikers',
         kong_users_title: 'Kong klant Gebruikers',
         kong_users_table_name: 'Naam',
         kong_users_table_email: 'Email',
         kong_users_table_client: 'Klant',
         kong_users_table_updated_at: 'Bijgewerkt Op',
         kong_users_bread_crumb_main_title: 'Kong klant Gebruikers',
         add_kong_users_bread_crumb_first_link: 'Add Kong klant gebruiker',
         add_kong_users_form_header: 'Add Kong klant gebruiker',
         kong_users_form_name_field: 'Naam',
         kong_users_form_email_field: 'Email Address',
         kong_users_form_client_field: 'Klant',
         kong_users_form_password_field: 'Wachtwoord',
         kong_users_name_required_form_error: 'Naam is verplicht',
         kong_users_email_valid_form_error: 'Must be a valid email',
         kong_users_email_required_form_error: 'Email is verplicht',
         kong_users_client_required_form_error: 'Klant is verplicht',
         kong_users_password_required_form_error:
           'Wachtwoord must contain at least 8 karakters with at least one uppercase letter, at least one number en at least one special character.',
         kong_users_no_record_found: 'Geen Records Gevonden',
         kong_users_table_row_per_page: 'Rijen Per Pagina',
         kong_users_add_new: 'Voeg Kong klant gebruiker toe',
         kong_users_filter_search: 'Zoeken',
         kong_user_single_first_bread_crumb: 'Kong klant Gebruikers',
         kong_user_single_second_bread_crumb: 'Bekijk Kong klant Gebruikers',
         kong_user_single_apis_tab: 'API Keys',
         kong_user_single_edit_button_label: 'Wijzig',
         kong_user_single_user_label: 'Gebruiker',
         kong_user_single_email_label: 'Email',
         kong_user_single_client_label: 'Klant',
         edit_kong_users_form_header: 'Wijzig Kong klant gebruiker',
         edit_kong_users_bread_crumb_first_link: 'Wijzig Kong klant gebruiker',
         device_single_temperature_start_date: 'Startdatum',
         device_single_temperature_end_date: 'Einddatum',
         device_single_temperature_start_date_error:
           'Startdatum moet eerder of gelijk zijn aan Einddatum',
         sidebar_kong_admins: 'Kong Admin Gebruikers',
         kong_user_admins_title: 'Kong Admin Gebruikers',
         kong_user_admins_table_name: 'Naam',
         kong_user_admins_table_email: 'Email',
         kong_user_admins_table_updated_at: 'Bijgewerkt Op',
         kong_user_admins_bread_crumb_main_title: 'Kong Admin Gebruikers',
         add_kong_user_admins_bread_crumb_first_link:
           'Voeg Kong klant gebruiker toe',
         add_kong_user_admins_form_header: 'AVoeg Kong klant gebruiker toe',
         kong_user_admins_form_name_field: 'Naam',
         kong_user_admins_form_email_field: 'Email Address',
         kong_user_admins_form_client_field: 'Admin',
         kong_user_admins_form_password_field: 'Wachtwoord',
         kong_user_admins_name_required_form_error: 'Naam is verplicht',
         kong_user_admins_email_valid_form_error: 'Must be a valid email',
         kong_user_admins_email_required_form_error: 'Email is verplicht',
         kong_user_admins_password_required_form_error:
           'Wachtwoord must contain at least 8 karakters with at least one uppercase letter, at least one number en at least one special character.',
         kong_user_admins_no_record_found: 'Geen Records Gevonden',
         kong_user_admins_table_row_per_page: 'Rijen Per Pagina',
         kong_user_admins_add_new: 'AVoeg Kong klant gebruiker toe',
         kong_user_admins_filter_search: 'Zoeken',
         kong_user_admin_single_first_bread_crumb: 'Kong Admin Gebruikers',
         kong_user_admin_single_second_bread_crumb:
           'Bekijk Kong Admin Gebruikers',
         kong_user_admin_single_apis_tab: 'API Keys',
         kong_user_admin_single_edit_button_label: 'Wijzig',
         kong_user_admin_single_user_label: 'Gebruiker',
         kong_user_admin_single_email_label: 'Email',
         edit_kong_user_admins_form_header: 'Wijzig Kong Admin gebruiker',
         edit_kong_user_admins_bread_crumb_first_link:
           'Wijzig Kong Admin gebruiker',
         projects_project_order_number_required_form_error:
           'Project Ordernummer is verplicht',
         projects_form_project_order_number_field: 'Project Ordernummer',
         client_error_single_device_address: 'Installatie adres',
         navbar_notification_mark_all_as_read: 'Mark all as read',
         navbar_notification_view_all: 'Bekijk Alle',
         notifications_title: 'Meldingen',
         notifications_all: 'Allen',
         notifications_unread: 'Ongelezen',
         notifications_read: 'Gelezen',
         notifications_table_title: 'Titel (EN)',
         notifications_table_description: 'Beschrijving (EN)',
         notifications_table_notification_type: 'Notificatie Type',
         notifications_table_notification_category: 'Notificatie Categorie',
         notifications_table_date: 'Datum',
         notifications_filter_search: 'Zoeken',
         notifications_no_record_found: 'Geen Records Gevonden',
         notifications_table_row_per_page: 'Rijen per pagina',
         add_notifications_form_header: 'Voeg Notificatie toe',
         notifications_title_en_required_form_error: 'Titel (EN) is verplicht',
         notifications_title_nl_required_form_error: 'Titel (NL) is verplicht',
         notifications_description_en_required_form_error:
           'Beschrijving (EN) is verplicht',
         notifications_description_nl_required_form_error:
           'Beschrijving (NL) is verplicht',
         notifications_form_notification_type_field: 'Notificatie Type',
         notifications_notification_type_required_form_error:
           'Notificatie Type is verplicht',
         notifications_form_notification_category_field: 'Notificatie Category',
         notifications_notification_category_required_form_error:
           'Notificatie Category is verplicht',
         notifications_form_title_en_field: 'Titel (EN)',
         notifications_form_title_nl_field: 'Titel (NL)',
         notifications_form_start_date_field: 'Startdatum',
         notifications_form_end_date_field: 'Einddatum',
         notifications_form_description_en_field: 'Beschrijving (EN)',
         notifications_form_description_nl_field: 'Beschrijving (NL)',
         notifications_form_add_button: 'Voeg Notificatie toe',
         notifications_form_edit_button: 'Wijzig Notificatie',
         notifications_form_cancel_button: 'Annuleer',
         notifications_add_form_success: 'Notificatie is succesvol toegevoegd',
         notifications_form_api_error: 'Interne Server Error',
         notification_type_information: 'Informatie',
         notification_type_warning: 'Waarschuwing',
         notification_category_simple: 'Simpel',
         notification_category_timebased: 'Tijd gevoelig',
         edit_notifications_form_header: 'Wijzig Notificatie',
         sidebar_notification_menu: 'Berichten',
         pop_over_close_button: 'Sluiten',
         docs_user_type_end_user: 'Eindgebruiker',
         docs_user_type_installer: 'Installateur',
         devices_single_device_docs_no_directory_found:
           'Geen Directories Gevonden',
         device_single_status_tab: 'Visuals',
         device_single_status_picture_tab: 'Foto',
         device_single_status_principle_tab: 'Principe',
         device_single_status_table_loading: 'Laden ...',
         device_single_status_mbus_export_button: 'Exporteer',
         device_single_status_mbus_date_range_filter_label: 'Datumbereik',
         device_single_status_mbus_date_export_label: 'Datum',
         device_single_status_mbus_time_export_label: 'Tijd',
         device_single_status_mbus_port_export_label: 'Poort',
         device_single_status_mbus_meter_connected_at_export_label:
           'Meter Verbonden Op',
         device_single_status_mbus_meter_status_export_label: 'Meter Status',
         device_single_status_mbus_meter_type_export_label: 'Meter Type',
         device_single_status_mbus_select_meter_type_export_label:
           'Selecteer Meter Type',
         device_single_status_mbus_select_meter_type_error:
           'Meter Type afwezig',
         device_single_status_mbus_export_internal_server_error:
           'Interne Server Error',
         device_single_status_mbus_export_no_data_found_error:
           'Geen record gevonden',
         sidebar_diagnostic_formulas: 'Diagnostische Formules',
         diagnostic_formulas_all: 'Allen',
         diagnostic_formulas_active: 'Actief',
         diagnostic_formulas_inactive: 'InActief',
         diagnostic_formulas_filter_delete_button: 'Verwijder',
         diagnostic_formulas_table_identifier: 'Identifier',
         diagnostic_formulas_table_name_en: 'Naam (EN)',
         diagnostic_formulas_table_name_nl: 'Naam (NL)',
         diagnostic_formulas_table_description_en: 'Beschrijving (EN)',
         diagnostic_formulas_table_description_nl: 'Beschrijving (NL)',
         diagnostic_formulas_table_formula: 'Formule',
         diagnostic_formulas_table_status: 'Status',
         diagnostic_formulas_table_updated_at: 'Bijgewerkt op',
         diagnostic_formulas_filter_search: 'Zoeken',
         diagnostic_formulas_no_record_found: 'Geen Record Gevonden',
         diagnostic_formulas_table_row_per_page: 'Rijen Per Pagina',
         diagnostic_formulas_bulk_no_record_is_selected_error_message:
           'Selecteer records voor groepsdeactivatie aub.',
         diagnostic_formulas_deactivated_message:
           'Diagnostiek is succesvol gedeactiveerd',
         diagnostic_formulas_activated_message:
           'Diagnostiek is succesvol geactiveerd',
         diagnostic_formulas_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Diagnostiek is succesvol gedeactiveerd',
         diagnostic_formulas_title: 'Diagnostische Formules',
         diagnostic_formulas_add_new: 'Voeg Nieuwe Toe',
         diagnostic_formulas_bread_crumb_main_title: 'Diagnostische Formules',
         add_diagnostic_formulas_bread_crumb_first_link:
           'Voeg Diagnostische Formules Toe',
         edit_diagnostic_formulas_bread_crumb_first_link:
           'Wijzig Diagnostische Formules',
         add_diagnostic_formulas_form_header: 'Voeg Diagnostische Formules Toe',
         edit_diagnostic_formulas_form_header: 'Wijzig Diagnostische Formules',
         diagnostic_formulas_form_diagnostic_formulas_identifier_field:
           'Identifier',
         diagnostic_formulas_identifier_required_form_error:
           'Identifier is verplicht',
         diagnostic_formulas_form_diagnostic_formulas_name_en_field:
           'Naam (EN)',
         diagnostic_formulas_form_diagnostic_formulas_name_nl_field:
           'Naam (NL)',
         diagnostic_formulas_name_en_required_form_error:
           'Naam (EN) is verplicht',
         diagnostic_formulas_name_nl_required_form_error:
           'Naam (NL) is verplicht',
         diagnostic_formulas_formula_required_form_error:
           'Formule is verplicht',
         diagnostic_formulas_form_description_en_field: 'Beschrijving (EN)',
         diagnostic_formulas_form_description_nl_field: 'Beschrijving (NL)',
         diagnostic_formulas_form_forumla_field: 'Formule',
         diagnostic_formulas_form_api_error: 'Interne Server Error',
         diagnostic_formulas_form_unqiue_diagnostic_formulas_name_error:
           'Identifer moet uniek zijn',
         diagnostic_formulas_form_api_forbidden:
           'Gebruiker heeft geen toestemming diagnostische formules toe te voegen',
         diagnostic_formulas_add_form_success:
           'Diagnostiek is succesvol aangemaakt',
         diagnostic_formulas_edit_form_success:
           'Diagnostiek is succesvol bijgewerkt',
         diagnostic_formulas_form_add_diagnostic_formulas_button:
           'Voeg Diagnostiek Toe',
         diagnostic_formulas_form_edit_diagnostic_formulas_button:
           'Wijzig Diagnostiek',
         diagnostic_formulas_form_cancel_button: 'Annuleer',
         sidebar_diagnostic_categories: 'Diagnostiek Categorien',
         diagnostic_categories_all: 'Allen',
         diagnostic_categories_active: 'Actief',
         diagnostic_categories_inactive: 'InActief',
         diagnostic_categories_filter_delete_button: 'Verwijder',
         diagnostic_categories_table_identifier: 'Identifier',
         diagnostic_categories_table_name_en: 'Naam (EN)',
         diagnostic_categories_table_name_nl: 'Naam (NL)',
         diagnostic_categories_table_description_en: 'Beschrijving (EN)',
         diagnostic_categories_table_description_nl: 'Beschrijving (NL)',
         diagnostic_categories_table_status: 'Status',
         diagnostic_categories_table_updated_at: 'Bijgewerkt op',
         diagnostic_categories_filter_search: 'Zoeken',
         diagnostic_categories_no_record_found: 'Geen Record Gevonden',
         diagnostic_categories_table_row_per_page: 'Rijen Per Pagina',
         diagnostic_categories_bulk_no_record_is_selected_error_message:
           'Selecteer records voor groepsdeactivatie aub.',
         diagnostic_categories_deactivated_message:
           'Categorie is succesvol gedeactiveerd',
         diagnostic_categories_activated_message:
           'Categorie is succesvol geactiveerd',
         diagnostic_categories_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Categorien zijn succesvol gedeactiveerd',
         diagnostic_categories_title: 'Diagnostische Categorien',
         diagnostic_categories_add_new: 'Voeg Nieuwe Toe',
         diagnostic_categories_bread_crumb_main_title:
           'Diagnostische Categorien',
         add_diagnostic_categories_bread_crumb_first_link:
           'Voeg Diagnostische Categorien Toe',
         edit_diagnostic_categories_bread_crumb_first_link:
           'Wijzig Diagnostische Categorien',
         add_diagnostic_categories_form_header:
           'Voeg Diagnostische Categorien Toe',
         edit_diagnostic_categories_form_header:
           'Wijzig Diagnostische Categorien',
         diagnostic_categories_form_diagnostic_categories_identifier_field:
           'Identifier',
         diagnostic_categories_identifier_required_form_error:
           'Identifier is verplicht',
         diagnostic_categories_form_diagnostic_categories_name_en_field:
           'Naam (EN)',
         diagnostic_categories_form_diagnostic_categories_name_nl_field:
           'Naam (NL)',
         diagnostic_categories_name_en_required_form_error:
           'Naam (EN) is verplicht',
         diagnostic_categories_name_nl_required_form_error:
           'Naam (NL) is verplicht',
         diagnostic_categories_form_description_en_field: 'Beschrijving (EN)',
         diagnostic_categories_form_description_nl_field: 'Beschrijving (NL)',
         diagnostic_categories_form_api_error: 'Interne Server Error',
         diagnostic_categories_form_unqiue_diagnostic_categories_name_error:
           'Identifer moet uniek zijn',
         diagnostic_categories_form_api_forbidden:
           'Gebruiker heeft geen rechten om diagnotische categorie aan te maken',
         diagnostic_categories_add_form_success:
           'Categorie is succesvol aangemaakt',
         diagnostic_categories_edit_form_success:
           'Categorie is succesvol bijgewerkt',
         diagnostic_categories_form_add_diagnostic_categories_button:
           'Voeg Categorie Toe',
         diagnostic_categories_form_edit_diagnostic_categories_button:
           'Wijzig Categorie',
         diagnostic_categories_form_cancel_button: 'Annuleer',
         sidebar_diagnostics_overview: 'Diagnostiek',
         sidebar_firmware_menu: 'Firmware',
         firmwares_all: 'Allen',
         firmwares_active: 'Actief',
         firmwares_inactive: 'InActief',
         firmwares_table_identifier: 'Identifier',
         firmwares_table_name_en: 'Naam (EN)',
         firmwares_table_name_nl: 'Naam (NL)',
         firmwares_table_firmware: 'Firmware',
         firmwares_table_status: 'Status',
         firmwares_table_updated_at: 'Bijgewerkt Op',
         firmwares_filter_search: 'Zoeken',
         firmwares_no_record_found: 'Geen Record Gevonden',
         firmwares_table_row_per_page: 'Rijen Per Pagina',
         firmwares_bulk_no_record_is_selected_error_message:
           'Selecteer records voor groepsdeactivatie aub..',
         firmwares_deactivated_message: 'Firmware is succesvol gedeactiveerd',
         firmwares_activated_message: 'Firmware is succesvol geactiveerd',
         firmwares_title: 'Firmware',
         firmwares_add_new: 'Voeg Nieuwe Toe',
         firmwares_bread_crumb_main_title: 'Firmware',
         add_firmwares_bread_crumb_first_link: 'Voeg firmware Toe',
         edit_firmwares_bread_crumb_first_link: 'Wijzig firmware',
         add_firmwares_form_header: 'Voeg firmware Toe',
         edit_firmwares_form_header: 'Wijzig firmware',
         firmwares_form_firmware_identifier_field: 'Identifier',
         firmwares_identifier_required_form_error: 'Identifier is verplicht',
         firmwares_form_firmware_name_en_field: 'Naam (EN)',
         firmwares_form_firmware_name_nl_field: 'Naam (NL)',
         firmwares_form_firmware_firmware_field: 'Firmware',
         firmwares_name_en_required_form_error: 'Naam (EN) is verplicht',
         firmwares_name_nl_required_form_error: 'Naam (NL) is verplicht',
         firmwares_form_description_en_field: 'Beschrijving (EN)',
         firmwares_form_description_nl_field: 'Beschrijving (NL)',
         firmwares_form_api_error: 'Interne Server Error',
         firmwares_form_unqiue_firmware_name_error: 'Identifer moet uniek zijn',
         firmwares_form_api_forbidden:
           'Gebruiker heeft geen toestemming om firmware aan te maken',
         firmwares_add_form_success: 'Firmware is succesvol aangemaakt',
         firmwares_edit_form_success: 'Firmware is updated succesvol',
         firmwares_form_edit_firmware_button: 'Wijzig firmware',
         firmwares_form_cancel_button: 'Annuleer',
         diagnostic_flows_all: 'Alle',
         diagnostic_flows_active: 'Active',
         diagnostic_flows_inactive: 'InActive',
         diagnostic_flows_filter_delete_button: 'Verwijder',
         diagnostic_flows_table_identifier: 'Identifier',
         diagnostic_flows_table_category: 'Category',
         diagnostic_flows_table_name_en: 'Naam (EN)',
         diagnostic_flows_table_name_nl: 'Naam (NL)',
         diagnostic_flows_table_description_en: 'Beschrijving (EN)',
         diagnostic_flows_table_description_nl: 'Beschrijving (NL)',
         diagnostic_flows_table_status: 'Status',
         diagnostic_flows_table_updated_at: 'Bijgewerkt Op',
         diagnostic_flows_filter_search: 'Zoeken',
         diagnostic_flows_no_record_found: 'Geen Record Gevonden',
         diagnostic_flows_table_row_per_page: 'Rijen Per Pagina',
         diagnostic_flows_bulk_no_record_is_selected_error_message:
           'Selecter records voor groepsdeactivatie aub.',
         diagnostic_flows_deactivated_message:
           'Flow is succesvol gedeactiveerd',
         diagnostic_flows_activated_message: 'Flow is succesvol geactiveerd',
         diagnostic_flows_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Flows zijn succesvol gedeactiveerd',
         diagnostic_flows_title: 'Diagnostische Flows',
         diagnostic_flows_add_new: 'Voeg Nieuwe Toe',
         diagnostic_flows_bread_crumb_main_title: 'Diagnostische Flows',
         add_diagnostic_flows_bread_crumb_first_link:
           'Voeg Diagnostische Flow Toe',
         edit_diagnostic_flows_bread_crumb_first_link:
           'Wijzig Diagnostische Flow',
         add_diagnostic_flows_form_header: 'Voeg Diagnostische Flow Toe',
         edit_diagnostic_flows_form_header: 'Wijzig Diagnostische Flow',
         diagnostic_flows_form_diagnostic_flows_identifier_field: 'Identifier',
         diagnostic_flows_identifier_required_form_error:
           'Identifier is verplicht',
         diagnostic_flows_form_diagnostic_flows_name_en_field: 'Naam (EN)',
         diagnostic_flows_form_diagnostic_flows_name_nl_field: 'Naam (NL)',
         diagnostic_flows_name_en_required_form_error: 'Naam (EN) is verplicht',
         diagnostic_flows_name_nl_required_form_error: 'Naam (NL) is verplicht',
         diagnostic_flows_category_required_form_error:
           'Categorie is verplicht',
         diagnostic_flows_form_description_en_field: 'Beschrijving (EN)',
         diagnostic_flows_form_description_nl_field: 'Beschrijving (NL)',
         diagnostic_flows_form_flow_condition_error_field:
           'Conditie moet true en false regels hebben',
         diagnostic_flows_form_flow_modal_title_field: 'Weet je dit zeker?',
         diagnostic_flows_form_flow_modal_subtitle_field:
           'Wil je de aanpassingen aan de flow opslaan in de browser?',
         diagnostic_flows_form_flow_modal_cancel_button_field: 'Annuleer',
         diagnostic_flows_form_flow_modal_save_button_field:
           'Sla In Browser Op',
         diagnostic_flows_form_flow_modal_save_success_message:
           'Flow is succesvol opgeslagen in de browser',
         diagnostic_flows_form_featured_image_field: 'Uitgelichte Afbeelding',
         diagnostic_flows_form_upload_featured_image_label:
           'Upload Uitgelichte Afbeelding',
         diagnostic_flows_form_thumbnail_field: 'Miniatuur',
         diagnostic_flows_form_upload_thumbnail_image_label:
           'Upload Miniatuur Afbeelding',
         diagnostic_flows_form_api_error: 'Interne Server Error',
         diagnostic_flows_form_unqiue_diagnostic_flows_name_error:
           'Identifer moet uniek zijn',
         diagnostic_flows_form_api_forbidden:
           'Gebruiker heeft geen rechten om diagnostische flow aan te maken',
         diagnostic_flows_add_form_success:
           'Diagnostisch Flow is succesvol aangemaakt',
         diagnostic_flows_edit_form_success:
           'Diagnostisch Flow is updated succesvol',
         diagnostic_flows_form_add_diagnostic_flows_button: 'Voeg Flow Toe',
         diagnostic_flows_form_edit_diagnostic_flows_button: 'Wijzig Flow',
         diagnostic_flows_form_cancel_button: 'Annuleer',
         audits_select_event_type_error_option: 'Storing',
         diagnostic_flows_form_flow_field: 'Flow',
         diagnostic_flows_form_flow_condition_button: 'Conditie',
         diagnostic_flows_form_flow_timer_button: 'Timer',
         diagnostic_flows_form_flow_normal_node_button: 'Node',
         diagnostic_flows_form_flow_download_image_button:
           'Download Afbeelding',
         diagnostic_flows_form_flow_start_button: 'Start',
         diagnostic_flows_form_flow_end_button: 'Einde',
         devices_bulk_export_mbus_historic_data_button: 'Download Mbus Data',
         devices_bulk_export_mbus_historic_data_file_name:
           'Mbus Historische Data',
         devices_bulk_mbus_download_title: 'Download Mbus Historische Data',
         devices_bulk_bulk_mbus_download_form_date_range: 'Datumbereik:',
         devices_bulk_bulk_mbus_download_form_submit_button:
           'Download Mbus Data',
         devices_bulk_bulk_mbus_download_form_cancel_button: 'Annuleer',
         devices_bulk_mbus_download_form_success:
           'Mbus Historische Data is succesvol gemaild naar ##EMAIL##!',
         devices_bulk_mbus_download_form_api_error: 'Interne Server Error',
         devices_bulk_mbus_download_no_devices_error: '',
         devices_bulk_mbus_download_form_no_devices_error:
           'Selecteer de devices om door te gaan.',
         devices_bulk_bulk_mbus_download_modal_title: 'Weet je het zeker?',
         devices_bulk_mbus_download_modal_subtitle:
           'Wil je een groepsdownload uitvoeren van Historische Mbus Data?',
         devices_bulk_mbus_download_modal_cancel_button: 'Annuleer',
         devices_bulk_mbus_download_modal_submit_button: 'Bevestig',
         projects_bulk_export_mbus_historic_data_button: 'Download Mbus Data',
         projects_bulk_export_mbus_historic_data_file_name:
           'Mbus Historic Data',
         projects_bulk_mbus_download_title: 'Download Mbus Historische Data',
         projects_bulk_bulk_mbus_download_form_date_range: 'Datumbereik:',
         projects_bulk_bulk_mbus_download_form_data_export_type:
           'Data Export Type',
         projects_bulk_bulk_mbus_download_form_data_export_type_combined:
           'Gecombineerd',
         projects_bulk_bulk_mbus_download_form_data_export_type_daily:
           'Dagelijks',
         projects_bulk_bulk_mbus_download_form_data_export_type_normal:
           'Normaal',
         projects_bulk_bulk_mbus_download_form_submit_button:
           'Download Mbus Data',
         projects_bulk_bulk_mbus_download_form_cancel_button: 'Annuleer',
         projects_bulk_mbus_download_form_success:
           'Mbus Historische Data is succesvol gemaild naar ##EMAIL##!',
         projects_bulk_mbus_download_form_api_error: 'Interne Server Error',
         projects_bulk_mbus_download_no_projects_error: '',
         projects_bulk_mbus_download_form_no_projects_error:
           'Selecteer projecten om door te gaan aub.',
         projects_bulk_bulk_mbus_download_modal_title: 'Weet je het zeker?',
         projects_bulk_mbus_download_modal_subtitle:
           'Wil je een groepsdownload uitvoeren van Historische Mbus Data?',
         projects_bulk_mbus_download_modal_cancel_button: 'Annuleer',
         projects_bulk_mbus_download_modal_submit_button: 'Bevestig',
         projects_bulk_mbus_selected_projects: 'Geselecteerde Projecten',
         sidebar_diagnostic_flows: 'Diagnostische Flows',
         diagnostic_flows_form_diagnostic_flows_category_field:
           'Diagnostische Flow Categorie',
         diagnostics_send_message_modal_registers_label: 'Registers',
         diagnostics_send_message_modal_operator_label: 'Operator',
         diagnostics_send_message_modal_value_label: 'Waarde',
         diagnostics_send_message_modal_registers_label_default_option:
           'Selecteer Register',
         diagnostics_send_message_modal_save_button: 'Opslaan',
         diagnostics_send_message_modal_cancel_button: 'Annuleer',
         diagnostics_condition_modal_sensor_type_label: 'Sensor Type',
         diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
         diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
         diagnostics_condition_modal_formula_output_label: 'Formule Output',
         diagnostics_condition_modal_meter_type_label: 'Meter Type',
         diagnostics_condition_modal_meter_type_label_default_option:
           'Selecteer Meter Type',
         diagnostics_condition_modal_operator_label: 'Operator',
         diagnostics_condition_modal_operator_option_1: 'Minder',
         diagnostics_condition_modal_operator_option_2:
           'Minder dan of gelijk aan',
         diagnostics_condition_modal_operator_option_3: 'Gelijk aan',
         diagnostics_condition_modal_operator_option_4: 'Meer',
         diagnostics_condition_modal_operator_option_5:
           'Meer dan of gelijk aan',
         diagnostics_condition_modal_operator_option_6: 'Niet Gelijk aan',
         diagnostics_condition_modal_register_label: 'Register',
         diagnostics_condition_modal_value_label: 'Waarde',
         diagnostics_condition_modal_timer_label: 'Timer',
         diagnostics_condition_modal_retries_label: 'Pogingen',
         diagnostics_condition_modal_delay_label: 'Vertraging',
         diagnostics_condition_modal_times_label: 'times',
         diagnostics_condition_modal_second_label: 'sec',
         diagnostics_condition_modal_save_button: 'Opslaan',
         diagnostics_condition_modal_cancel_button: 'Annuleer',
         extra_white_space_form_error: 'Extra witte ruimte is niet toegestaan',
         diagnostic_flows_form_incomplete_flow_error:
           'Check randen en nodes met zwarte kleur en rode animatie',
         diagnostic_flows_menu_information: 'Informatie',
         diagnostic_flows_menu_modbus: 'Modbus',
         diagnostic_flows_menu_message: 'Bericht',
         diagnostic_flows_menu_condition: 'Conditie',
         diagnostic_flows_menu_user_interaction: 'Gebruikersinteractie',
         diagnostic_flows_menu_steps: 'Pagina',
         diagnostic_flows_menu_timer: 'Timer',
         diagnostic_flows_menu_formula: 'Formule',
         diagnostic_flows_form_user_interaction_operation_type: 'Operatie Type',
         diagnostic_flows_form_user_interaction_operation_type_default_option:
           'Selecteer Operatie Type',
         diagnostic_flows_form_user_interaction_operation_type_option_choose_mbus_meter:
           'Kies Mbus Meter',
         diagnostic_flows_form_user_interaction_operation_type_option_choose_faucet:
           'Kies Kraan Register',
         diagnostic_flows_form_user_interaction_field_type_option_select_field:
           'Selecteer Field Type',
         diagnostic_flows_form_user_interaction_field_type: 'Field Type',
         diagnostic_flows_form_user_interaction_field_type_default_option:
           'Selecteer Field Type',
         diagnostic_flows_form_user_interaction_field_type_option_checkbox:
           'Checkbox',
         diagnostic_flows_form_user_interaction_field_type_option_radio_button:
           'Radio Knop',
         diagnostic_flows_form_user_interaction_field_type_option_text_field:
           'Textveld',
         diagnostic_flows_form_user_interaction_description_en:
           'Beschrijving (EN)',
         diagnostic_flows_form_user_interaction_description_nl:
           'Beschrijving (NL)',
         diagnostic_flows_form_steps_label_en: 'Beschrijving (EN)',
         diagnostic_flows_form_steps_label_nl: 'Beschrijving (NL)',
         diagnostic_formulas_form_diagnostic_formulas_retries_field: 'Pogingen',
         diagnostic_formulas_form_diagnostic_formulas_delay_field: 'Vertraging',
         diagnostic_formulas_form_diagnostic_formulas_unit_field:
           'Formule Unit',
         diagnostic_formulas_form_forumla_field_placeholder:
           'Kies @ om Modbus of Mbus Registers te selecteren',
         device_single_diagnostic_flow_missing_pages_error:
           '##SELECTED_FLOW## heeft geen paginas. Voeg paginas in de flow toe om door te gaan.',
         device_single_stepper_choose_flow_title: 'Kies de flow',
         device_single_stepper_choose_explanation_of_flow_wizard_title:
           'Uitleg van de wizard',
         device_single_stepper_result_title: 'Resultaat',
         diagnostic_flows_form_delete_node_modal_title: 'Weet je het zeker?',
         diagnostic_flows_form_delete_node_modal_subtitle:
           'Wil je de node ##NODE_LABEL## verwijderen?',
         diagnostic_flows_form_delete_node_modal_cancel_button: 'Annuleer',
         diagnostic_flows_form_delete_node_modal_delete_button: 'Verwijder',
         diagnostic_flows_form_loading_text: 'Laden ...',
         diagnostic_flows_form_processing_text: 'Verwerken',
         diagnostic_flows_form_main_error_text:
           'Flow is gestopt door een storing',
         diagnostic_flows_form_main_success_text: 'Flow is succesvol afgerond',
         devices_bulk_download_form_data_type: 'Data Type',
         devices_bulk_download_form_export_type: 'Export Type',
         devices_bulk_download_form_data_type_daily: 'Dagelijks',
         devices_bulk_download_form_data_type_hourly: 'Uurlijks',
         devices_bulk_download_form_export_type_combined: 'Gecombineerd',
         devices_bulk_download_form_export_type_separated: 'Gescheiden',
         diagnostic_flows_form_main_stopped_text:
           'Flow is gestopt door de gebruiker',
         diagnostic_execution_title: 'Diagnostisch Uitvoeringen',
         diagnostic_execution_table_created_at: 'Aangemaakt Op',
         diagnostic_execution_table_device: 'Device',
         diagnostic_execution_table_user: 'Gebruiker',
         diagnostic_execution_table_flow: 'Flow',
         diagnostic_execution_table_status: 'Status',
         diagnostic_execution_table_filter_search: 'Zoeken',
         diagnostic_executions_tab_all: 'Alle',
         diagnostic_executions_tab_completed: 'Voltooid',
         diagnostic_executions_tab_in_progress: 'In Uivoering',
         diagnostic_executions_tab_stopped: 'Gestopt',
         diagnostic_executions_tab_error: 'Storing',
         diagnostic_executions_table_no_record_found: 'Geen Records Gevonden',
         diagnostic_executions_table_row_per_page: 'Rijen Per Pagina',
         diagnostic_flows_menu_result: 'Resultaat',
         diagnostic_flows_menu_result_form_description_en: 'Beschrijving (EN)',
         diagnostic_flows_menu_result_form_description_nl: 'Beschrijving (NL)',
         diagnostic_flows_menu_result_form_cancel_button: 'Annuleer',
         diagnostic_flows_menu_result_form_save_button: 'Opslaan',
         diagnostic_flows_menu_page_form_cancel_button: 'Annuleer',
         diagnostic_flows_menu_page_form_save_button: 'Opslaan',
         diagnostic_flows_menu_information_form_description_en:
           'Beschrijving (EN)',
         diagnostic_flows_menu_information_form_description_nl:
           'Beschrijving (NL)',
         diagnostic_flows_menu_information_form_cancel_button: 'Annuleer',
         diagnostic_flows_menu_information_form_save_button: 'Opslaan',
         sidebar_diagnostic_execution_title: 'Diagnostsche Uitvoeringen',
         diagnostic_flow_execution_single_first_bread_crumb:
           'Diagnostische Uitvoeringen',
         diagnostic_flow_execution_single_second_bread_crumb:
           'Bekijk Diagnostische Execution',
         diagnostic_flow_execution_single_output_tab: 'Output',
         diagnostic_flow_execution_single_device_name: 'Device',
         diagnostic_flow_execution_single_flow: 'Flow',
         diagnostic_flow_execution_single_user: 'Gebruiker',
         diagnostic_flow_execution_single_status: 'Status',
         diagnostic_flow_execution_single_created_at: 'Aangemaakt Op',
         diagnostic_flow_execution_single_bulk_export_file_name:
           'Diagnostische Uitvoeringen in Groep',
         diagnostic_flow_execution_single_bulk_export_date: 'Datum',
         diagnostic_flow_execution_single_bulk_export_time: 'Tijd',
         diagnostic_flow_execution_single_bulk_export_device_name:
           'Device Naam',
         diagnostic_flow_execution_single_bulk_export_device_serial_number_mercurius:
           'Serienummer Mercurius',
         diagnostic_flow_execution_single_bulk_export_device_user_full_name:
           'Gebruiker',
         diagnostic_flow_execution_single_bulk_export_device_flow_name_en:
           'Flow Naam (EN)',
         diagnostic_flow_execution_single_bulk_export_device_flow_name_nl:
           'Flow Naam (NL)',
         diagnostic_flow_execution_single_bulk_export_device_status: 'Status',
         diagnostic_flows_form_main_flow_already_running_error_text:
           'Een flow is al bezig op dit Device. Meerdere flow uitvoeringen zijn niet toegestaan',
         diagnostic_flows_form_open_faucet_on_option: 'Aan',
         diagnostic_flows_form_open_faucet_off_option:
           'Uit (Flow gaat in storingsmodus)',
         diagnostic_flows_form_mbus_meters_error:
           'Device heeft geen vereiste Mbus Meters voor de flow',
         diagnostic_flows_result_title: 'Resultaat',
         duplicate_diagnostic_flows_form_header: 'Dupliceer Diagnostische Flow',
         duplicate_diagnostic_flows_bread_crumb_first_link:
           'Dupliceer Diagnostische Flow',
         diagnostic_flows_form_duplicate_diagnostic_flows_button: 'Dupliceer',
         diagnostic_flows_duplicate_form_success:
           'Diagnostische Flow is succesvol gedupliceerd ',
         diagnostic_flows_filter_duplicate_button: 'Dupliceer Flow',
         diagnostic_flows_menu_result_form_id: 'Id',
         audits_street_number_filter_label: 'Straat',
         audits_house_number_filter_label: 'Huisnummer',
         diagnostic_execution_counter_title: 'Diagnostisch Uitvoering Tellers',
         diagnostic_execution_counter_table_id: 'Id',
         diagnostic_execution_counter_table_counter: 'Tellers',
         diagnostic_execution_counter_table_flow: 'Flow',
         diagnostic_execution_counter_table_result: 'Resultaat',
         diagnostic_execution_counter_table_filter_search: 'Zoeken',
         diagnostic_execution_counter_table_no_record_found:
           'Geen Records Gevonden',
         diagnostic_execution_counter_table_row_per_page: 'Rijen Per Pagina',
         sidebar_diagnostic_flow_execution_counter: 'Diagnostisch Flow Tellers',
         device_single_firmware_hiu_label: 'Firmware afleverset',
         device_single_firmware_mercurius_label: 'Firmware Mercurius',
         custom_popover_105_long_description:
           'Storing Register Lange Beschrijving',
         settings_menu_title: 'Instellingen',
         settings_menu_clients_title: 'Klanten',
         settings_menu_admins_title: 'Admins',
         settings_menu_kong_admins_title: 'Kong admins',
         settings_menu_kong_clients_title: 'Kong klanten',
         settings_menu_diagnostics_title: 'Diagnostiek',
         settings_menu_formulas_title: 'Formules',
         settings_menu_flows_title: 'Flows',
         settings_menu_categories_title: 'Categorien',
         settings_menu_firmwares_title: 'Firmware',
         settings_menu_appliance_types_title: 'Toesteltypen',
         settings_menu_notifications_title: 'Berichten',
         settings_menu_roles_title: 'Rollen',
         settings_menu_modules_title: 'Modules',
         settings_menu_permissions_title: 'Rechten',
         settings_menu_go_back_button: 'Terug',
         audits_device_name_filter_label: 'Device naam',
         device_single_documentation_parts_button: 'Onderdelen',
         device_single_documentation_lab_vision_button: 'Labvision',
         device_single_documentation_manuals_button: 'Handleiding',
         device_single_documentation_incident_button: 'Storingsleutel',
         navbar_avg_proof_switch: 'Privacyschakelaar',
         clients_filter_bulk_delete_button: 'Verwijder',
         clients_bulk_delete_no_clients_with_zero_devices_is_selected_error_message:
           'Selecteer ten minste een record met 0 devices',
         clients_bulk_delete_clients_with_devices_count_is_selected_error_message:
           'Selecteer enkel die records die 0 devices hebben',
         projects_filter_bulk_delete_button: 'Verwijder',
         projects_bulk_delete_no_projects_with_zero_devices_is_selected_error_message:
           'Selecteer ten minste een record met 0 devices',
         projects_bulk_delete_projects_with_devices_count_is_selected_error_message:
           'Selecteer enkel die records die 0 devices hebben',
         projects_bulk_deleted_message:
           '{{ PROJECTS_COUNT }} Projecten zijn succesvol verwijderd',
         projects_delete_modal_title: 'Weet je het zeker?',
         projects_delete_modal_subtitle: 'Wil je de Records verwijderen?',
         projects_delete_modal_cancel_button: 'Annuleer',
         projects_delete_modal_delete_button: 'Verwijder',
         users_delete_modal_title: 'Weet je het zeker?',
         users_delete_modal_subtitle: 'Wil je de Records verwijderen?',
         users_delete_modal_cancel_button: 'Annuleer',
         users_delete_modal_delete_button: 'Verwijder',
         users_bulk_delete_no_user_is_selected_error_message:
           'Selecteer te verwijderen records',
         users_bulk_deleted_message:
           '{{ USERS_COUNT }} Gebruikers zijn succesvol verwijderd',
         service_client_single_filter_bulk_delete_button: 'Verwijder',
         service_client_single_no_user_selected_for_deleted_message:
           'Selecteer te verwijderen records',
         admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Admins zijn succesvol verwijderd',
         admins_no_user_selected_for_deleted_message:
           'Selecteer te verwijderen records',
         admins_filter_bulk_delete_button: 'Verwijder',
         kong_admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Kong Admins zijn succesvol verwijderd',
         audits_message_type_label: 'Bericht Type',
         audit_bulk_export_file_name: 'Audits Bestand',
         registers_reporting_details_all_tab: 'Alle',
         registers_reporting_details_pending_tab: 'Bezig',
         registers_reporting_details_success_tab: 'Succesvol',
         registers_reporting_details_failure_tab: 'Mislukt',
         devices_form_is_service_device_field: 'Service Device',
         service_portal_login_in_correct_qr_code:
           'Device serienummer is incorrect',
         service_portal_login_in_correct_credentials:
           'Device gegevens zijn incorrect. Neem contact op met Fortes Administrator.',
         service_portal_login_not_a_registered_service_device:
           'Device met serienummer {{ SERIAL_NUMBER }} is niet geregistreerd.',
         devices_unprovisioned: 'Ongeregistreerd',
         sidebar_unprovisioned_devices: 'Devices - Ongeregistreerd',
         dashboard_unprovisioned: 'ONGEREGISTREERD',
         device_single_documentation_warmwater_button: 'Storing Warm Water',
         device_single_documentation_verwarming_button: 'Storing Verwarming',
         service_portal_login_in_serial_number_scan_button: 'Scan serienummer',
         service_portal_login_in_heat_interface_unit_scan_button:
           'Scan afleverset',
         service_portal_login_in_serial_number_scanned_message:
           'Serienummer is gescand',
         service_portal_login_in_heat_interface_unit_scanned_message:
           'Afleverset is gescand',
         swapping_device_missing_new_device_error:
           'Nieuw Device is niet geimporteerd in de Mercurius database',
         device_single_dashboard_mbus_title: 'MBus',
         device_single_dashboard_tab: 'Dashboard',
         device_single_offline_detailed_message: 'Device is "Offline"',
         device_single_online_detailed_message:
           'Device is "Online", alles werkt prima',
         device_single_error_detailed_message: 'Device is in "Storing" mode',
         device_single_status_documents_link: 'Device Documenten',
         email_device_delete_subscription_title:
           'Fortes Data Beheer - Verwijder Abonnement',
         email_device_delete_subscription_api_status_title:
           'Maxitel API Response:',
         email_device_delete_subscription_type_swapped_device_message:
           'Device abonnement is verwijderd in Device Uitwisseling module',
         email_device_delete_subscription_type_clear_device_message:
           'Device abonnement is verwijderd in Device Wissen module',
         devices_form_subscription_id_field: 'Abonnement Id',
         device_single_rss_sim_status: 'SIM Status',
         device_monitoring_error_list_table_title: 'Storingsgeschiedenis',
         device_monitoring_error_list_table_device: 'Device',
         device_monitoring_error_list_table_alarm_code: 'Storingscode',
         device_monitoring_error_list_table_start_date: 'Startdatum',
         device_monitoring_error_list_table_end_date: 'Einddatum',
         device_monitoring_data_consumed_list_table_title: 'Data Verbruikt',
         device_monitoring_data_consumed_list_table_device: 'Device',
         device_monitoring_data_consumed_list_table_data_usage: 'Dataverbruik',
         device_monitoring_data_consumed_list_table_date: 'Datum',
         device_monitoring_ota_history_list_table_title: 'OTA Geschiedenis',
         device_monitoring_ota_history_list_table_device: 'Device',
         device_monitoring_ota_history_list_table_status: 'Status',
         device_monitoring_ota_history_list_table_date: 'Datum',
         device_monitoring_device_status_history_title:
           'Device Status Geschiedenis',
         device_monitoring_device_status_history_hr: 'Uur',
         device_monitoring_device_status_history_no_graph_data_error:
           'De grafiek is niet beschikbaar',
         device_monitoring_device_status_history_graph_loading_data:
           'Laden grafiek',
         device_monitoring_firmware_history_title: 'Firmware Geschiedenis',
         device_monitoring_device_firmware_history_graph_loading_data:
           'Laden staafdiagram',
         device_monitoring_device_firmware_history_no_graph_data_error:
           'De staafdiagram is niet beschikbaar',
         sidebar_devices_monitoring: 'Devices Monitoring',
         device_monitoring_projects_counter_title: 'Projecten Teller',
         device_monitoring_device_projects_counter_graph_loading_data:
           'Laden staafdiagram',
         device_monitoring_device_projects_counter_no_graph_data_error:
           'De staafdiagram is niet beschikbaar',
         device_monitoring_users_counter_title: 'Gebruikers Teller',
         device_monitoring_device_users_counter_graph_loading_data:
           'Laden staafdiagram',
         device_monitoring_device_users_counter_no_graph_data_error:
           'De staafdiagram is niet beschikbaar',
         device_monitoring_sim_counter_title: 'SIM Data Teller',
         device_monitoring_sim_counter_exceeded_data_title: 'Overschreden',
         device_monitoring_sim_counter_in_package_data_title:
           'In Pakket Limiet',
         device_monitoring_device_sim_counter_graph_loading_data:
           'Laden staafdiagram',
         device_monitoring_device_sim_counter_no_graph_data_error:
           'De staafdiagram is niet beschikbaar',
         service_portal_login_in_dont_have_hiu_qr_code_checkbox_field:
           'Geen afleverset QR Code',
         service_portal_login_hiu_autocomplete: 'Afleverset',
         device_monitoring_device_error_counters_title:
           'Storingsteller geschiedenis',
         device_monitoring_device_error_counters_error: 'Geen data gevonden',
         device_monitoring_device_error_counters_no_graph_data_error:
           'Het cirkeldiagram is niet beschikbaar',
         device_monitoring_date_range_field: 'Datumbereik',
         reporting_ota_details_retries_tab: 'Pogingen',
         device_monitoring_single_page_title: 'Device Monitoring',
         device_monitoring_ota_history_list_table_fail_counter: 'Pogingen',
         device_monitoring_ota_history_list_table_user: 'Gebruiker',
         device_monitoring_ota_history_list_table_message: 'Bericht',
         device_monitoring_ota_history_list_table_old_firmware_version:
           'Oude firmware versie',
         device_monitoring_project_list_table_title: 'Projecten',
         device_monitoring_firmware_list_table_title: 'Firmware',
         device_monitoring_firmware_list_table_firmware_version: 'Firmware',
         device_monitoring_firmware_list_table_device: 'Device',
         device_monitoring_firmware_list_table_project: 'Project',
         device_monitoring_firmware_list_table_saved_at: 'Datum',
         monitoring_users_list_table_title: 'Gebruikers',
         monitoring_kong_users_list_table_title: 'Kong Gebruikers',
         monitoring_kong_users_filter_kong_admin: 'Kong Admin',
         monitoring_kong_users_filter_kong_client: 'Kong klant',
         client_single_users_table_role: 'Rol',
         device_monitoring_device_status_list_table_title: 'Device Status',
         navbar_languages_english: 'English',
         navbar_languages_dutch: 'Nederlands',
         navbar_languages_german: 'Deutsch',
         device_single_device_settings_tab: 'Instellingen v2',
         device_single_device_settings_title: 'Device v2 Instellingen',
         device_single_device_settings_secondary_device_type_field:
           'Secundaire Device Type',
         device_single_device_settings_base_topic_field: 'Base Topic',
         device_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
         device_single_device_settings_select_baud_rate_uart_field:
           'Selecteer Baud Rate UART',
         device_single_device_settings_modbus_error_register_address_field:
           'Modbus storingsregister adres',
         device_single_device_settings_modbus_error_register_value_field:
           'Modbus verwachte storingsregister waarde',
         device_single_device_settings_reboot_device_v2: 'Herstarten',
         device_single_device_settings_send_button_v2: 'Versturen',
         device_single_device_settings_secondary_device_type_message_forwarded:
           'Device Secundaire Device Type bericht is verstuurd naar Device',
         device_single_device_settings_reboot_message_forwarded:
           'Device Reboot bericht is verstuurd naar Device',
         device_single_device_settings_base_topic_message_forwarded:
           'Device Base Topic bericht is verstuurd naar Device',
         device_single_device_settings_baud_rate_uart_message_forwarded:
           'Device Baud Rate UART bericht is verstuurd naar Device',
         device_single_device_settings_modbus_error_register_address_message_forwarded:
           'Device Modbus storingsregister adres bericht is verstuurd naar Device',
         device_single_device_settings_modbus_expected_error_register_value_message_forwarded:
           'Device Modbus verwachte storingsregister waarde bericht is verstuurd naar Device',
         device_single_device_settings_secondary_device_type_error:
           'Secundaire Device Type moet liggen tussen 1 en 254',
         device_single_device_settings_base_topic_error:
           'Base Topic lengte moet liggen tussen 5 en 50',
         device_single_device_settings_baud_rate_uart_error:
           'Selecteer een optie voor Baud Rate UART',
         device_single_device_settings_modbus_error_register_address_error:
           'Modbus storingsregister adres moet liggen tussen 0 en 65535',
         device_single_device_settings_modbus_error_register_value_error:
           'Modbus verwacht storingsregister waarde moet liggen tussen 0 en 65535',
         device_monitoring_device_error_counters_loading_data:
           'Laden cirkeldiagram',
         diagnostic_formulas_form_diagnostic_formulas_name_de_field:
           'Naam (DE)',
         diagnostic_formulas_name_de_required_form_error:
           'Naam (DE) is verplicht',
         diagnostic_formulas_form_description_de_field: 'Beschrijving (DE)',
         diagnostic_flows_form_diagnostic_flows_name_de_field: 'Naam (DE)',
         diagnostic_flows_name_de_required_form_error: 'Naam (DE) is verplicht',
         diagnostic_flows_form_description_de_field: 'Beschrijving (DE)',
         diagnostic_categories_form_diagnostic_categories_name_de_field:
           'Naam (DE)',
         diagnostic_categories_name_de_required_form_error:
           'Naam (DE) is verplicht',
         diagnostic_categories_form_description_de_field: 'Beschrijving (DE)',
         diagnostic_categories_table_name_de: 'Naam (DE)',
         diagnostic_flows_table_name_de: 'Naam (DE)',
         diagnostic_formulas_table_name_de: 'Naam (DE)',
         modules_form_module_name_de_field: 'Naam (DE)',
         modules_name_de_required_form_error: 'Naam (DE) is verplicht',
         modules_form_description_de_field: 'Beschrijving (DE)',
         modules_table_name_de: 'Naam (DE)',
         modules_table_description_de: 'Beschrijving (DE)',
         roles_form_role_name_de_field: 'Naam (DE)',
         roles_name_de_required_form_error: 'Naam (DE) is verplicht',
         roles_form_description_de_field: 'Beschrijving (DE)',
         roles_table_name_de: 'Naam (DE)',
         roles_table_description_de: 'Beschrijving (DE)',
         permissions_form_permission_name_de_field: 'Naam (DE)',
         permissions_form_description_de_field: 'Beschrijving (DE)',
         permissions_table_name_de: 'Naam (DE)',
         permissions_table_description_de: 'Beschrijving (DE)',
         notifications_form_title_de_field: 'Titel (DE)',
         notifications_form_description_de_field: 'Beschrijving (DE)',
         firmwares_name_de_required_form_error: 'Naam (DE)',
         firmwares_form_firmware_name_de_field: 'Naam (DE) is verplicht',
         navbar_languages_danish: 'Deens',
         firmwares_name_dk_required_form_error: 'Naam (DK)',
         firmwares_form_firmware_name_dk_field: 'Naam (DK) is verplicht',
         diagnostic_formulas_form_diagnostic_formulas_name_dk_field:
           'Naam (DK)',
         diagnostic_formulas_name_dk_required_form_error:
           'Naam (DK) is verplicht',
         diagnostic_formulas_form_description_dk_field: 'Beschrijving (DK)',
         diagnostic_flows_form_diagnostic_flows_name_dk_field: 'Naam (DK)',
         diagnostic_flows_name_dk_required_form_error: 'Naam (DK) is verplicht',
         diagnostic_flows_form_description_dk_field: 'Beschrijving (DK)',
         diagnostic_categories_form_diagnostic_categories_name_dk_field:
           'Naam (DK)',
         diagnostic_categories_name_dk_required_form_error:
           'Naam (DK) is verplicht',
         diagnostic_categories_form_description_dk_field: 'Beschrijving (DK)',
         diagnostic_categories_table_name_dk: 'Naam (DK)',
         diagnostic_flows_table_name_dk: 'Naam (DK)',
         diagnostic_formulas_table_name_dk: 'Naam (DK)',
         modules_form_module_name_dk_field: 'Naam (DK)',
         modules_name_dk_required_form_error: 'Naam (DK) is verplicht',
         modules_form_description_dk_field: 'Beschrijving (DK)',
         modules_table_name_dk: 'Naam (DK)',
         modules_table_description_dk: 'Beschrijving (DK)',
         roles_form_role_name_dk_field: 'Naam (DK)',
         roles_name_dk_required_form_error: 'Naam (DK) is verplicht',
         roles_form_description_dk_field: 'Beschrijving (DK)',
         roles_table_name_dk: 'Naam (DK)',
         roles_table_description_dk: 'Beschrijving (DK)',
         permissions_form_permission_name_dk_field: 'Naam (DK)',
         permissions_form_description_dk_field: 'Beschrijving (DK)',
         permissions_table_name_dk: 'Naam (DK)',
         permissions_table_description_dk: 'Beschrijving (DK)',
         notifications_form_title_dk_field: 'Titel (DK)',
         notifications_form_description_dk_field: 'Beschrijving (DK)',
         device_monitoring_data_consumed_list_table_project: 'Project',
         project_single_device_list_search_mbus_registers_by_project:
           'Zoeken Mbus Registers',
         project_single_fetch_mbus_registers_by_project_success_message:
           'Bericht is succesvol verstuurd naar Project ##PROJECT_NAME## Devices',
         project_single_fetch_mbus_registers_by_project_error_message:
           'Interval Server Storing',
         devices_list_diagnostic_filters_title: 'Registers Filter',
         devices_list_diagnostics_condition_modal_sensor_type_label:
           'Sensor Type',
         devices_list_diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
         devices_list_diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
         devices_list_diagnostics_condition_modal_formula_output_label:
           'Formule Output',
         devices_list_diagnostics_condition_modal_meter_type_label:
           'Meter Type',
         devices_list_diagnostics_condition_modal_meter_type_label_default_option:
           'Selecteer Meter Type',
         devices_list_diagnostics_condition_modal_operator_label: 'Operator',
         devices_list_diagnostics_condition_modal_operator_option_1: 'Minder',
         devices_list_diagnostics_condition_modal_operator_option_2:
           'Minder dan of gelijk aan',
         devices_list_diagnostics_condition_modal_operator_option_3:
           'Gelijk aan',
         devices_list_diagnostics_condition_modal_operator_option_4: 'Meer',
         devices_list_diagnostics_condition_modal_operator_option_5:
           'Meer dan of gelijk aan',
         devices_list_diagnostics_condition_modal_operator_option_6:
           'Niet Gelijk aan',
         devices_list_diagnostics_condition_modal_register_label: 'Register',
         devices_list_diagnostics_condition_modal_register_required_error:
           'Register is verplicht',
         devices_list_diagnostics_send_message_modal_registers_label_default_option:
           'Selecteer Register',
         devices_list_diagnostics_condition_modal_value_label: 'Waarde',
         devices_list_diagnostics_condition_modal_value_is_required_error:
           'Waarde is verplicht',
         devices_list_diagnostics_condition_modal_operator_is_required_error:
           'Operator is verplicht',
         devices_list_diagnostics_condition_modal_meter_is_required_error:
           'Meter is verplicht',
         devices_list_diagnostics_condition_modal_value_invalid_error:
           'Ongeldige Waarde, controleer aub',
         devices_list_diagnostics_condition_modal_filter_button: 'Filter',
         devices_list_diagnostics_condition_modal_close_button: 'Sluiten',
         devices_list_diagnostics_condition_modal_add_button:
           'Voeg meer filters toe',
         error_check_table_title: 'Device Storing Checks',
         error_check_table_filter_search: 'Zoeken',
         error_check_table_no_record_found: 'Geen Records Gevonden',
         error_check_table_row_per_page: 'Rijen Per Pagina',
         error_check_table_created_at: 'Aangemaakt Op',
         error_check_table_total_devices: 'Totale Devices',
         error_check_details_table_title: 'Device Storing Check Details',
         error_check_details_table_filter_search: 'Zoeken',
         error_check_details_table_no_record_found: 'Geen Records Gevonden',
         error_check_details_table_row_per_page: 'Rijen Per Pagina',
         error_check_details_table_created_at: 'Aangemaakt Op',
         error_check_details_table_device: 'Device',
         error_check_details_table_old_error: 'Oude Storing',
         error_check_details_table_new_error: 'Nieuwe Storing',
         error_check_details_table_status: 'Status',
         error_check_details_table_status_success: 'Succes',
         error_check_details_table_status_failed: 'Failure',
         error_check_details_table_all_tab: 'Alle',
         error_check_details_table_success_tab: 'Succes',
         error_check_details_table_failure_tab: 'Fout',
         error_check_details_table_device_name: 'Device Naam',
         error_check_details_table_device_serial_number: 'Serienummer',
         sidebar_devices_error_check: 'Storing Devices',
         error_check_details_table_bulk_export_file_name:
           'Overzicht Storing Devices Details',
         error_check_table_bulk_export_file_name: 'Overzicht Storing Devices',
         device_single_registers_table_min_error:
           'Waarde moet groter zijn dan of gelijk aan minimale waarde',
         device_single_registers_table_invalid_value_error:
           'Voer een geldig nummer in',
         project_single_project_setting_tab: 'Project Instellingen',
         project_single_project_setting_mbus_registers_title: 'MBus Registers',
         project_single_project_setting_mbus_register_accumulated_heating_energy_title:
           'Eenheid cumulatieve verwarmingsenergie',
         project_single_project_setting_mbus_register_accumulated_heating_energy_success_message:
           'Eenheid cumulatieve verwarmingsenergie is succesvol geupdatet',
         project_single_project_setting_mbus_register_accumulated_heating_energy_error_message:
           'Update Eenheid cumulatieve verwarmingsenergie niet geslaagd',
         project_single_project_setting_select_mbus_register_accumulated_heating_energy_title:
           'Selecteer Cumulatieve verwarmingsenergie',
         project_single_project_setting_select_mbus_register_update_button:
           'Update',
         custom_popover_100_short_description: 'Verwarming status',
         custom_popover_101_short_description: 'Tapwater status',
         custom_popover_102_short_description: 'Warmhoud status',
         custom_popover_103_short_description: 'Anti Legionella status',
         custom_popover_104_short_description: 'Koelen status',
         custom_popover_1802_short_description: 'Elektrisch element',
         custom_popover_105_short_description: 'Storing',
         custom_popover_106_short_description: 'Software versie',
         custom_popover_107: 'Tapwater regelafsluiter (M1)',
         custom_popover_108: 'Verwarmen/koelen regelafsluiter (M2)',
         custom_popover_109_short_description: 'Circulatie pomp',
         custom_popover_110_short_description: '6-weg kogelafsluiter',
         custom_popover_113_short_description: 'Ruimte thermostaat',
         custom_popover_114_short_description: 'OpenTherm Indicatie',
         custom_popover_200_short_description: 'Volumestroom tapwater',
         custom_popover_201_short_description: 'T-Tapwater',
         custom_popover_202_short_description: 'T-Primaire aanvoer',
         custom_popover_203_short_description:
           'T-Primaire retour (T-Booster voor TE-Booster toestellen)',
         custom_popover_205_short_description: 'T-Secundaire aanvoer',
         custom_popover_206_short_description: 'T-Secundaire retour',
         custom_popover_204_short_description:
           'Berekende aanvoertemperatuur (setpoint)',
         custom_popover_207_short_description: 'Ruimte temperatuur(OpenTherm)',
         custom_popover_208_short_description: 'Ruimte setpoint (OpenTherm)',
         custom_popover_210_short_description:
           'Waterdruk in de binnenhuisinstallatie',
         custom_popover_800_short_description: 'Totale bedrijfstijd afleverset',
         custom_popover_801_short_description:
           'Totale bedrijfstijd warmte-vraag',
         custom_popover_802_short_description:
           'Totale bedrijfstijd koude-vraag',
         custom_popover_803_short_description:
           'Totale bedrijfstijd tapwater-vraag',
         custom_popover_804_short_description: 'Aantal tappingen',
         custom_popover_805_short_description:
           'Totale volume tapwater (let op! dit is een indicatie)',
         custom_popover_806_short_description: 'Stroomonderbrekingen',
         create_role_stepper_information_title: 'Informatie',
         create_role_stepper_client_title: 'Klant',
         create_role_stepper_service_client_title: 'Service klant',
         create_role_stepper_project_title: 'Project',
         create_role_stepper_device_management_title: 'Device Beheer',
         create_role_stepper_commissioning_title: 'Inbedrijfstelling',
         create_role_stepper_reporting_title: 'Rapportage',
         create_role_stepper_general_title: 'Algemeen',
         roles_form_role_name_field: 'Naam',
         roles_name_required_form_error: 'Naam is verplicht',
         roles_form_description_field: 'Omschrijving',
         create_role_stepper_back_button: 'Terug',
         create_role_stepper_next_button: 'Volgende',
         create_role_stepper_finish_button: 'Voltooien',
         create_role_stepper_yes_label: 'Ja',
         create_role_stepper_no_label: 'Nee',
         create_role_stepper_step_2_client_view_all_users:
           'Bekijk alle gebruikers?',
         create_role_stepper_step_2_client_create_new_users:
           'Maak nieuwe gebruikers aan?',
         create_role_stepper_step_2_client_edit_new_users:
           'Wijzig bestaande gebruikers zoals de rol?',
         create_role_stepper_step_3_service_client_create_new:
           "Maak een nieuwe 'service klant' aan?",
         create_role_stepper_step_3_service_client_view_users:
           "Bekijk bestaande 'service klant' gebruikers?",
         create_role_stepper_step_3_service_client_create_users:
           "Maak nieuwe 'service klant' gebruiker aan?",
         create_role_stepper_step_3_service_client_modify_users:
           "Wijzig 'service klant' gebruikers zoals de rol?",
         create_role_stepper_step_4_project_view: 'Bekijk projecten?',
         create_role_stepper_step_4_project_create_new:
           'Maak nieuw project aan?',
         create_role_stepper_step_4_project_export_mbus_data:
           'Exporteer energiemeterdata van projecten?',
         create_role_stepper_step_5_device_view_single_page: 'Open een Device?',
         create_role_stepper_step_5_device_view_single_page_setting_registers:
           'Bekijk instelligen?',
         create_role_stepper_step_5_device_edit_single_page_setting_registers:
           'Wijzig instelligen?',
         create_role_stepper_step_5_device_edit_single_page_manual_control:
           'Gebruik handbediening?',
         create_role_stepper_step_5_device_single_page_view_history:
           'Bekijk geschiedenis?',
         create_role_stepper_step_5_device_single_page_view_meter_data:
           'Bekijk meterdata?',
         create_role_stepper_step_5_device_single_page_use_diagnostics_module:
           'Gebruik de diagnostische module?',
         create_role_stepper_step_5_device_single_page_information_and_delivery_set_data:
           'Bekijk informatie zoals Device info, adres details, en afleverset data?',
         create_role_stepper_step_6_commissioning_module_put_devices_into_operation:
           'Inbedrijfstelling module om devices in bedrijf te stellen?',
         create_role_stepper_step_6_commissioning_module_swapping:
           'Devices uitwisselen (verplaats data van oude Device naar nieuwe Device)?',
         create_role_stepper_step_7_reporting_audit:
           'Het logboek dat alle berichten, storingen, en acties bevat op devices?',
         create_role_stepper_step_7_reporting_swapped_devices:
           'Logboek van uitgewisselde devices?',
         create_role_stepper_step_7_reporting_error_devices:
           'Logboek van devices with faults?',
         create_role_stepper_step_7_reporting_bulk_registers_update:
           'Resultaten van groepsaanpassingen?',
         create_role_stepper_step_8_view_dashboard:
           'Dashboard volledige toegang en sidebar Device tellers',
         create_role_stepper_step_8_general_create_role: 'Rollen aanmaken?',
         create_role_stepper_question_required_error:
           'Een optie is verplicht om door te gaan',
         roles_form_role_name_en_field: 'Naam (EN)',
         roles_form_role_name_nl_field: 'Naam (NL)',
         roles_name_en_required_form_error: 'Naam (EN) is verplicht',
         roles_name_nl_required_form_error: 'Naam (NL) is verplicht',
         roles_form_description_en_field: 'Omschrijving (EN)',
         roles_form_description_nl_field: 'Omschrijving (NL)',
         project_single_project_setting_device_v2_settings_title:
           'Device V2 Instellingen',
         project_single_device_settings_secondary_device_type_field:
           'Secundaire Device Type',
         project_single_project_setting_base_topic_field: 'Base Topic',
         project_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
         project_single_device_settings_select_baud_rate_uart_field:
           'Selecteer Baud Rate UART',
         project_single_device_settings_modbus_error_register_address_field:
           'Modbus storingsregister adres',
         project_single_device_settings_modbus_error_register_value_field:
           'Modbus verwachte storingsregister waarde',
         project_single_project_setting_device_v2_send_button: 'Versturen',
         project_single_project_setting_base_topic_success_message:
           'Base Topic is succesvol verstuurd naar ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices van Project',
         project_single_project_setting_secondary_device_type_success_message:
           'Secundaire Device Type is succesvol verstuurd naar ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices van Project',
         project_single_project_setting_reboot_message:
           'Device Reboot Bericht is succesvol verstuurd naar ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices van Project',
         project_single_project_setting_baut_rate_uart_success_message:
           'Device Baut Rate UART Bericht is succesvol verstuurd naar ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices van Project',
         project_single_project_setting_modbus_expected_error_register_value_success_message:
           'Device Modbus Expected Storing Register Waarde Bericht is succesvol verstuurd naar ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices van Project',
         project_single_project_setting_modbus_error_register_address_success_message:
           'Device Reboot Bericht is succesvol verstuurd naar ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices van Project',
         docs_third_tab_video_instruction: 'Video Instructie',
         docs_third_tab_video_instruction_title: 'Video Instructie',
         custom_popover_106_long_description: 'Software versie',
         EXPORT_MBUS_METER_SERIAL_NUMBER: 'Serienummer meter',
         navbar_languages_french: 'Frans',
         diagnostic_formulas_form_diagnostic_formulas_name_fr_field:
           'Naam (FR)',
         diagnostic_formulas_name_fr_required_form_error:
           'Naam (FR) is verplicht',
         diagnostic_formulas_form_description_fr_field: 'Beschrijving (FR)',
         diagnostic_flows_form_diagnostic_flows_name_fr_field: 'Naam (FR)',
         diagnostic_flows_name_fr_required_form_error: 'Naam (FR) is verplicht',
         diagnostic_flows_form_description_fr_field: 'Beschrijving (FR)',
         diagnostic_categories_form_diagnostic_categories_name_fr_field:
           'Naam (FR)',
         diagnostic_categories_name_fr_required_form_error:
           'Naam (FR) is verplicht',
         diagnostic_categories_form_description_fr_field: 'Beschrijving (FR)',
         modules_form_module_name_fr_field: 'Naam (FR)',
         modules_name_fr_required_form_error: 'Naam (FR) is verplicht',
         modules_form_description_fr_field: 'Beschrijving (FR)',
         roles_form_role_name_fr_field: 'Naam (FR)',
         roles_name_fr_required_form_error: 'Naam (FR) is verplicht',
         roles_form_description_fr_field: 'Beschrijving (FR)',
         permissions_form_permission_name_fr_field: 'Naam (FR)',
         permissions_form_description_fr_field: 'Beschrijving (FR)',
         firmwares_form_firmware_name_fr_field: 'Naam (FR)',
         firmwares_name_fr_required_form_error: 'Naam (FR) is verplicht',
         notifications_form_title_fr_field: 'Naam (FR)',
         notifications_form_description_fr_field: 'Beschrijving (FR)',
         notifications_title_dk_required_form_error: 'Naam (DK) is verplicht',
         notifications_title_fr_required_form_error: 'Naam (FR) is verplicht',
         notifications_title_de_required_form_error: 'Naam (DE) is verplicht',
         notifications_description_dk_required_form_error:
           'Beschrijving (DK) is verplicht',
         notifications_description_de_required_form_error:
           'Beschrijving (DE) is verplicht',
         notifications_description_fr_required_form_error:
           'Beschrijving (FR) is verplicht',
         client_single_error_devices_bread_crumb: 'Devices in storing',
         service_client_single_error_devices_bread_crumb: 'Devices in storing',
         devices_list_diagnostics_condition_modal_fetch_and_filter_button:
           'Ophalen & Filteren',
         diagnostic_register_drawer_selected_devices_title:
           'Geselecteerde Devices',
         diagnostic_register_drawer_fetch_and_filter_message_1:
           'Ophalen devices data',
         diagnostic_register_drawer_fetch_and_filter_message_2:
           'Verversen Database',
         diagnostic_register_drawer_fetch_and_filter_message_3:
           'Filteren devices',
         devices_bulk_selected_devices_title: 'Geselecteerde Devices',
         custom_popover_100_long_description: 'Verwarming status',
         custom_popover_101_long_description: 'Tapwater status',
         custom_popover_102_long_description: 'Warmhoud status',
         custom_popover_103_long_description: 'Anti Legionella status',
         custom_popover_104_long_description: 'Koelen status',
         custom_popover_1802_long_description: 'Elektrisch element',
         custom_popover_109_long_description: 'Circulatie pomp',
         custom_popover_110_long_description: '6-weg kogelafsluiter',
         custom_popover_113_long_description: 'Ruimte thermostaat',
         custom_popover_114_long_description: 'OpenTherm Indicatie',
         custom_popover_200_long_description: 'Volumestroom tapwater',
         custom_popover_201_long_description: 'T-Tapwater',
         custom_popover_202_long_description: 'T-Primaire aanvoer',
         custom_popover_203_long_description:
           'T-Primaire retour (T-Booster voor TE-Booster toestellen)',
         custom_popover_205_long_description: 'T-Secundaire aanvoer',
         custom_popover_206_long_description: 'T-Secundaire retour',
         custom_popover_204_long_description:
           'Berekende aanvoertemperatuur (setpoint)',
         custom_popover_207_long_description: 'Ruimte temperatuur(OpenTherm)',
         custom_popover_208_long_description: 'Ruimte setpoint (OpenTherm)',
         custom_popover_210_long_description:
           'Waterdruk in de binnenhuisinstallatie',
         custom_popover_800_long_description: 'Totale bedrijfstijd afleverset',
         custom_popover_801_long_description:
           'Totale bedrijfstijd warmte-vraag',
         custom_popover_802_long_description: 'Totale bedrijfstijd koude-vraag',
         custom_popover_803_long_description:
           'Totale bedrijfstijd tapwater-vraag',
         custom_popover_804_long_description: 'Aantal tappingen',
         custom_popover_805_long_description:
           'Totale volume tapwater (let op! dit is een indicatie)',
         custom_popover_806_long_description: 'Stroomonderbrekingen',
         project_single_project_setting_mbus_hourly_logs_title:
           'Mbus Uurlijkse Logs',
         project_single_project_setting_mbus_hourly_logs_field:
           'Uurlijks ophalen Mbus waarden',
         project_single_project_setting_mbus_hourly_logs_update_button:
           'Update',
         project_single_project_setting_mbus_hourly_logs_success_set_message:
           'Uurlijkse Mbus logs zijn succesvol ingesteld voor het project',
         project_single_project_setting_mbus_hourly_logs_error_message:
           'Update Uurlijkse Mbus logs voor het project mislukt',
         sidebar_mbus_reporting: 'Mbus Rapportage',
         mbus_reporting_table_device: 'Device',
         mbus_reporting_table_project: 'Project',
         mbus_reporting_table_created_at: 'Aangemaakt Op',
         mbus_reporting_table_viewed_by: 'Bekeken door',
         mbus_reporting_table_viewed_at: 'Bekeken op',
         mbus_reporting_table_firmware: 'Firmware',
         mbus_reporting_table_no_record_found: 'Geen Records Gevonden',
         mbus_reporting_table_all_filter: 'Alle',
         mbus_reporting_table_read_filter: 'Gelezen',
         mbus_reporting_table_unread_filter: 'Ongelezen',
         mbus_reporting_table_row_per_page: 'Rijen Per Pagina',
         mbus_reporting_table_title: 'Mbus Rapportage',
         mbus_reporting_anomaly_table_title: 'Mbus Afwijkingen',
         mbus_reporting_anomaly_table_difference: 'Verschil',
         mbus_reporting_anomaly_table_detection: 'Detectie',
         mbus_reporting_anomaly_table_mbus_meter_type: 'Meter',
         mbus_reporting_anomaly_table_mbus_serial_number: 'Mbus serienummer',
         mbus_reporting_anomaly_table_mbus_old_value: 'Oude Waarde',
         mbus_reporting_anomaly_table_mbus_register: 'Register',
         mbus_reporting_anomaly_table_mbus_reason: 'Reden',
         mbus_reporting_anomaly_table_mbus_fail_reason: 'Meer dan',
         mbus_reporting_anomaly_table_mbus_suspicious_reason: 'Ligt tussen',
         mbus_reporting_anomaly_table_fail: 'Mislukt',
         mbus_reporting_anomaly_table_suspicious: 'Verdacht',
         mbus_reporting_table_bulk_view_reports_success_message:
           'Mbus Rapportage Berichten zijn succesvol gelezen',
         mbus_reporting_table_bulk_view_reports_error_message:
           'Selecteer Mbus Rapportage records om door te gaan',
         mbus_reporting_table_bulk_view_button: 'Bulk Bekijk',
         mbus_reporting_table_view_button: 'Bekijk',
         mbus_reporting_table_filters_project_field: 'Project',
         mbus_reporting_table_filters_device_name_field: 'Device',
         mbus_reporting_table_filters_start_date: 'Start Datum',
         mbus_reporting_table_filters_end_date: 'Eind Datum',
         mbus_reporting_table_filters_firmware: 'Firmware',
         mbus_reporting_table_filters_mbus_serial_number: 'Mbus serienummer',
         mbus_reporting_table_filters_viewed_by: 'Gezien door',
         business_situation_measured_values_TR03_209: 'Buiten Temperatuur',
         custom_popover_209_short_description: 'Buiten Temperatuur',
         custom_popover_209_long_description: 'Buiten Temperatuur',
         appliance_types_set_registers_table_dashboard: 'Dashboard',
         appliance_types_set_registers_table_dashboard_error_message:
           'Device dashboard registers moeten of 0 of precies 8 zijn.',
         sidebar_missing_hourly_reporting: 'Mbus Missende Data Rapportage',
         missing_hourly_reporting_table_title:
           'Mbus Uurlijkse Missende Data Rapportage',
         mbus_hourly_missing_reporting_table_filters_project_field: 'Project',
         mbus_hourly_missing_reporting_table_filters_device_name_field:
           'Device',
         mbus_hourly_missing_reporting_table_filters_firmware_field: 'Firmware',
         mbus_hourly_missing_reporting_table_last_seen: 'Mbus Bericht Op',
         mbus_hourly_missing_reporting_table_created_at: 'Aangemaakt Op',
         mbus_hourly_missing_reporting_table_device: 'Device',
         mbus_hourly_missing_reporting_table_project: 'Project',
         mbus_hourly_missing_reporting_table_firmware: 'Firmware',
         mbus_hourly_missing_reporting_table_no_record_found:
           'Geen Records Gevonden',
         mbus_hourly_missing_reporting_table_row_per_page: 'Rijen Per Pagina',
         device_monitoring_device_frequency_history_counters_title:
           'Frequentie Device Afwijkingen',
         device_monitoring_device_frequency_history_counters_error:
           'Geen data gevonden',
         device_monitoring_device_frequency_history_counters_no_graph_data_error:
           'Het cirkeldiagram is niet beschikbaar',
         device_monitoring_device_frequency_history_counters_loading_data:
           'Laden ...',
         device_monitoring_project_frequency_history_counters_title:
           'Frequentie Project Afwijkingen',
         device_monitoring_project_frequency_history_counters_error:
           'Geen data gevonden',
         device_monitoring_project_frequency_history_counters_no_graph_data_error:
           'Het cirkeldiagram is niet beschikbaar',
         device_monitoring_project_frequency_history_counters_loading_data:
           'Laden ...',
         device_monitoring_firmware_anomalies_history_title:
           'Frequentie firmware afwijkingen',
         device_monitoring_device_firmware_anomalies_history_graph_loading_data:
           'Het staafdiagram is niet beschikbaar',
         device_monitoring_device_firmware_anomalies_history_no_graph_data_error:
           'Laden ...',
         devices_device_id: 'Device Id',
         device_monitoring_device_frequency_details_table_title:
           'Frequentie Device Afwijkingen',
         device_monitoring_device_frequency_details_table_firmware: 'Firmware',
         device_monitoring_device_frequency_details_table_device: 'Device',
         device_monitoring_device_frequency_details_table_project: 'Project',
         device_monitoring_device_frequency_details_table_saved_at: 'Datum',
         device_monitoring_device_frequency_details_table_startup_messages_count:
           'Telling opstartberichten',
         device_monitoring_project_frequency_details_table_title:
           'Frequentie Project Afwijkingen',
         device_monitoring_project_frequency_details_table_project: 'Project',
         device_monitoring_project_frequency_details_table_devices_count:
           'Telling Devices',
         device_monitoring_project_frequency_details_table_startup_messages_count:
           'Telling opstartberichten',
         device_monitoring_firmware_frequency_details_table_title:
           'Frequentie firmware afwijkingen',
         device_monitoring_firmware_frequency_details_table_firmware:
           'Firmware',
         device_monitoring_firmware_frequency_details_table_startup_messages_count:
           'Telling opstartberichten',
         client_single_users_list_reset_and_send_email_button:
           'Herstel Wachtwoord',
         user_reset_password_form_success:
           'Wachtwoord gebruiker is succesvol geupdatet',
         users_reset_password_modal_title: 'Herstel Wachtwoord',
         users_reset_password_modal_subtitle:
           'Wil je het wachtwoord herstellen voor deze gebruiker en een email versturen?',
         users_reset_password_modal_cancel_button: 'Sluiten',
         users_reset_password_modal_reset_password_button:
           'Herstel en Versturen Email',
         reset_password_subject: 'Fortes Data Beheer - Herstel Wachtwoord',
         clients_form_is_2fa_enabled_field:
           'Schakel tweefactorauthenticatie in (2FA)',
         service_clients_form_is_2fa_enabled_field:
           'Schakel tweefactorauthenticatie in (2FA)',
         two_factor_authentication_qr_verified_title:
           'Tweefactorauthenticatie (2FA)',
         two_factor_authentication_qr_unverified_title:
           'Stel tweefactorauthenticatie in (2FA)',
         two_factor_authentication_permission_error: '',
         two_factor_authentication_description_step_1:
           'Installeer een van de volgende applicaties op jouw mobiele apparaat:',
         two_factor_authentication_description_step_2:
           'Open de applicatie en scan de QR Code:',
         two_factor_authentication_description_step_2_rescan:
           'Genereer QR Code opnieuw',
         two_factor_authentication_description_step_3:
           'Vul de eenmalige code in die door de applicatie aangeboden is en klik Indienen om de setup af te ronden.',
         two_factor_authentication_form_otp_field: 'OTP Code',
         two_factor_authentication_form_otp_characters_limit_error:
           'OTP moet 6 karakters zijn',
         two_factor_authentication_form_otp_required_error: 'OTP is verplicht',
         two_factor_authentication_form_incorrect_otp_error:
           'Ongeldige OTP, probeer opnieuw met nieuwe OTP',
         two_factor_authentication_form_success:
           'OTP is succesvol geauthenticeerd',
         two_factor_authentication_submit_button: 'Indienen',
         client_single_users_list_enable_2fa_qr_code_button:
           'Schakel QR-code voor tweefactorauthenticatie (2FA) in',
         service_client_single_users_list_enable_2fa_qr_code_button:
           'Schakel QR-code voor tweefactorauthenticatie (2FA) in',
         users_enable_2fa_qr_code_modal_title:
           'Schakel QR-code voor tweefactorauthenticatie (2FA) in',
         users_enable_2fa_qr_code_modal_subtitle:
           'Weet je het zeker dat je de QR-code voor tweefactorauthenticatie (2FA) in wilt schakelen voor deze gebruiker?',
         users_enable_2fa_qr_code_modal_cancel_button: 'Annuleer',
         users_enable_2fa_qr_code_modal_enable_2fa_qr_code_button:
           'Schakel QR-code voor tweefactorauthenticatie (2FA) in',
         client_single_users_list_enable_2fa_qr_code_success_message:
           'QR-code voor tweefactorauthenticatie (2FA) is succesvol ingeschakeld',
         client_single_2fa_label: 'Tweefactorauthenticatie (2FA)',
         enabled_2fa_label: 'Inschakelen',
         disabled_2fa_label: 'Uischakelen',
         service_client_single_2fa_label: 'Tweefactorauthenticatie (2FA)',
         client_single_users_table_last_access: 'Laatst gezien',
         service_client_single_users_table_last_access: 'Laatst gezien',
         projects_form_header: 'Project Informatie',
         projects_commissioning_form_header: 'Project Inbedrijfstelling',
         projects_commissioning_form_export_button: 'Exporteer Devices',
         projects_commissioning_form_export_file_name:
           'Project Inbedrijfstelling Export',
         projects_commissioning_form_import_button: 'Importeer Devices',
         projects_commissioning_form_import_message:
           '<strong>Stappen om Devices te importeren</strong> <ol> <li>Download de Project Inbedrijfstelling Bestand Voorbeeld via de groene Exporteer Devices Knop.</li> <li>Update het bestand zonder de namen van de kolommen aan te passen. in het geval dat ze aangepast zijn, zal de import mislukken.</li> <li>Import het bestand en de aanpassingen zullen geupdatet worden.</li> </ol>',
         projects_commissioning_form_import_success_message:
           'Devices data is succesvol geimporteerd',
         commissioning_device_stepper_step_1_scan_qr_serial_number_title:
           'Scan QR code Mercurius',
         commissioning_device_stepper_step_3_validate_project_setting_title:
           'Valideer verbinding',
         commissioning_device_stepper_step_4_update_address_information_title:
           'Update adres',
         commissioning_device_stepper_step_2_hiu_commissioning_title:
           'Scan QR code afleverset',
         commissioning_device_stepper_step_5_preview_and_submit_title:
           'Bekijken en verzenden',
         commissioning_form_dont_have_serial_number_checkbox:
           'Geen QR Code serienummer?',
         commissioning_form_back_button: 'Vorige',
         commissioning_form_next_button: 'Volgende',
         commissioning_form_finish_button: 'Afronden',
         commissioning_form_device_serial_is_missing_error:
           'Serienummer is verplicht',
         commissioning_form_modbus_mbus_validation_error:
           'Validatie mislukt, wil je toch doorgaan?',
         commissioning_form_stepper_validation_project_setting_title:
           'Project Instellingen',
         commissioning_form_stepper_validation_current_setting_title:
           'Device Instellingen',
         commissioning_form_stepper_validation_validate_to_view:
           'Valideer om details te bekijken',
         commissioning_form_stepper_validation_modbus_title: 'Modbus',
         commissioning_form_stepper_validation_mbus_title: 'M-Bus',
         commissioning_form_stepper_validation_modbus_connected_title:
           'Verbonden',
         commissioning_form_stepper_validation_modbus_not_connected_title:
           'Niet Verbonden',
         commissioning_form_stepper_validation_mbus_meters_title: 'meter(s)',
         project_single_project_setting_modbus_and_mbus_validation_form_title:
           'Modbus en M-Bus Instelling',
         commissioning_form_stepper_validation_is_modbus_connected:
           'Is Modbus Verbonden',
         commissioning_form_stepper_validation_mbus_meters_select_field:
           'M-Bus Meters',
         commissioning_form_stepper_validation_mbus_meters_select_option_field:
           'Selecteer M-Bus Meters',
         commissioning_form_stepper_validation_mbus_meters_validate_button:
           'Valideer',
         commissioning_form_upload_file_type_error:
           'Enkel XLSX bestanden zijn toegestaan.',
         commissioning_form_upload_file_invalid_column_names_error:
           'Ongeldige kolom ##COLUMNS##, kolom namen niet veranderen aub',
         commissioning_form_upload_file_size_error:
           'Bestandsgrootte mag niet groter dan 5 MB zijn.',
         commissioning_form_uploading_text: 'Uploaden...',
         project_single_project_setting_mbus_reporting_form_title:
           'Mbus Rapportage Instelling',
         project_single_project_setting_mbus_hourly_missing_data_reporting_value_field:
           'Mbus Uurlijks Missende Data Rapportage',
         project_single_project_setting_mbus_anomaly_reporting_value_field:
           'Mbus Afwijking Rapportage',
         project_single_project_setting_mbus_reporting_form_button: 'Update',
         project_single_project_setting_mbus_reporting_success_message:
           'Mbus Rapportage data is succesvol ingesteld voor het project',
         project_single_project_setting_mbus_reporting_error_message:
           'Updaten van Mbus Rapportage data voor het project mislukt',
         commissioning_form_upload_file_incorrect_serial_number_error:
           'Incorrecte serienummer(s) gevonden voor de inbedrijfstelling van het project',
         commissioning_form_upload_file_incorrect_devices_data_error:
           'Incorrecte Device(s) data gevonden voor de inbedrijfstelling van het project',
         commissioning_form_upload_file_empty_sheet_error:
           'Project Inbedrijfstelling bestand is leeg',
         commissioning_form_upload_success_message:
           'Project Inbedrijfstelling is succesvol afgerond',
         commissioning_validation_failed_subject:
           'Fortes Energy - Device Inbedrijfstelling Rapportage',
         commissioning_form_stepper_validation_mbus_meters_loading_title:
           'Mbus meters validatie duurt tot 30 - 60 seconden',
         commissioning_form_stepper_validation_mbus_meters_retries_label:
           'Mbus Pogingen',
         commissioning_form_stepper_validation_mbus_meters_retries_button:
           'Opnieuw proberen',
         mbus_reporting_table_bulk_snooze_button: 'Groeps Snooze',
         mbus_reporting_table_bulk_snooze_devices_success_message:
           'Mbus Rapportage Devices zijn succesvol gesnoozed',
         mbus_reporting_table_bulk_snooze_modal_title: 'Weet je het zeker?',
         mbus_reporting_table_bulk_snooze_modal_sub_title:
           'Wil je Mbus Rapportage snoozen voor de geselecteerde devices?',
         mbus_reporting_table_bulk_snooze_modal_cancel_button: 'Annuleer',
         mbus_reporting_table_bulk_snooze_modal_snooze_button: 'Snooze',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration: 'Snooze Duur',
         mbus_reporting_table_bulk_snooze_modal_select_snooze_duration:
           'Selecteer Snooze Duur',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_day: 'Day',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_week: 'Weken',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_month:
           'Maanden',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_year: 'Jaren',
         sidebar_mbus_reporting_snoozed_devices:
           'Mbus Reporting - Snoozed Devices',
         mbus_reporting_snoozed_devices_table_title: 'Snoozed Devices',
         mbus_reporting_table_bulk_un_snooze_devices_success_message:
           'Mbus Reporting Devices zijn succesvol UnSnoozed',
         mbus_reporting_table_bulk_un_snooze_modal_title: 'Weet je het zeker?',
         mbus_reporting_table_bulk_un_snooze_modal_sub_title:
           'Wil je Mbus Rapportage unsnoozen voor de geselecteerde devices?',
         mbus_reporting_table_bulk_un_snooze_modal_cancel_button: 'Annuleer',
         mbus_reporting_table_bulk_un_snooze_modal_snooze_button: 'UnSnooze',
         mbus_reporting_table_bulk_un_snooze_button: 'Groeps UnSnooze',
         mbus_reporting_table_snooze_request_at: 'Aangevaagd op',
         mbus_reporting_table_snooze_end_at: 'Eind op',
         mbus_reporting_snoozed_devices_table_all_filter: 'Alle',
         mbus_reporting_snoozed_devices_table_snoozed_filter: 'Snoozed',
         mbus_reporting_snoozed_devices_table_unsnoozed_filter: 'UnSnoozed',
         devices_list_diagnostics_condition_modal_single_meter_label: 'Meter',
         devices_list_diagnostics_condition_modal_multiple_meters_label:
           'Meters',
         devices_list_diagnostics_condition_modal_mbus_meters_dropdown_label:
           'Verbonden Mbus Meters',
         devices_list_diagnostics_condition_modal_are_mbus_connected_value_connected_label:
           'Verbonden',
         devices_list_diagnostics_condition_modal_are_mbus_connected_value_not_connected_label:
           'Niet Verbonden',
         devices_list_diagnostics_condition_modal_mbus_meters_dropdown_select_record_label:
           'Selecteer Verbonden Mbus Meters',
         devices_list_diagnostics_condition_modal_are_mbus_connected_dropdown_label:
           'Mbus Meters Verbinding',
         devices_list_diagnostics_condition_modal_are_mbus_connected_select_field_label:
           'Selecteer verbindingsstatus',
         devices_search_mbus_registers_multiple_devices_success:
           'Het bericht is succesvol verzonden naar de geselecteerde Device(s).',
         devices_testing_button: 'Device Testen',
         devices_testing_form_header: 'Device Test',
         devices_testing_device_serial_field: 'Device serienummer',
         devices_testing_modbus_registers_field: 'Modbus Registers',
         devices_testing_modbus_registers_button_field:
           'Versturen Modbus Bericht',
         devices_testing_single_mbus_register_field: 'Enkel MBus Register',
         devices_testing_single_mbus_register_button_field: 'Versturen Bericht',
         devices_testing_send_fetch_mbus_message:
           'Versturen Ophalen MBus Bericht',
         devices_testing_send_scan_mbus_message:
           'Versturen Scannen MBus Bericht',
         project_single_project_setting_mbus_hourly_logs_success_unset_message:
           'Mbus Uurlijkse logs zijn succesvol uitgezet voor het project',
         mbus_reporting_table_export_button: 'Exporteer',
         mbus_reporting_table_export_file: 'Mbus Rapportage',
         mbus_hourly_missing_reporting_export_button: 'Exporteer',
         mbus_hourly_missing_reporting_export_file:
           'Mbus Uurlijks Missende Data Reporting',
         snoozed_devices_export_button: 'Exporteer',
         snoozed_devices_export_file: 'Snoozed Devices',
         device_single_documentation_instructie_pomp_button: 'Instructie Pomp',
         sidebar_inconsistent_mbus_devices_reporting:
           'Inconsistent Mbus Devices',
         inconsistent_mbus_devices_reporting_table_header:
           'Inconsistent Mbus Devices',
         inconsistent_mbus_devices_reporting_bulk_export_button: 'Exporteer',
         inconsistent_mbus_devices_reporting_filter_reset_button: 'Herstel',
         inconsistent_mbus_devices_reporting_table_filter_search: 'Zoeken',
         inconsistent_mbus_devices_reporting_table_created_at: 'Aangemaakt Op',
         inconsistent_mbus_devices_reporting_table_total_devices:
           'Totale Devices',
         inconsistent_mbus_devices_reporting_table_total_projects:
           'Totale Projecten',
         inconsistent_mbus_devices_reporting_table_no_record_found:
           'Geen Record Gevonden',
         inconsistent_mbus_devices_reporting_table_row_per_page:
           'Rijen Per Pagina',
         devices_mbus_meter_count: 'Mbus Meters',
         devices_bulk_modbus_download_form_data_type: 'Data Type',
         devices_bulk_modbus_download_form_export_type: 'Export Type',
         devices_bulk_modbus_download_form_data_type_daily: 'Dagelijks',
         devices_bulk_modbus_download_form_data_type_hourly: 'Uurlijks',
         devices_bulk_modbus_download_form_export_type_combined: 'Gecombineerd',
         devices_bulk_modbus_download_form_export_type_separated: 'Gescheiden',
         devices_bulk_export_modbus_historic_data_button:
           'Download Modbus Data',
         devices_bulk_export_modbus_historic_data_file_name:
           'Modbus Historische Data',
         devices_bulk_modbus_download_title: 'Download Modbus Historische Data',
         devices_bulk_modbus_download_form_date_range: 'Datumbereik:',
         devices_bulk_modbus_download_form_submit_button:
           'Download Modbus Data',
         devices_bulk_modbus_download_form_cancel_button: 'Annuleer',
         devices_bulk_modbus_download_form_success:
           'Modbus Historische Data is succesvol gemaild naar ##EMAIL##!',
         devices_bulk_modbus_download_form_api_error: 'Interne Server Error',
         devices_bulk_modbus_download_no_devices_error:
           'Selecteer tenminste 1 devic met correct TR Type (TR03/TR04)',
         devices_bulk_modbus_download_form_no_devices_error:
           'Selecteer devices om door te gaan.',
         devices_bulk_modbus_download_modal_title: 'Weet je het zeker?',
         devices_bulk_modbus_download_modal_subtitle:
           'Wil je een groepsdownload uitvoeren voor Historische Modbus data?',
         devices_bulk_modbus_download_modal_cancel_button: 'Annuleer',
         devices_bulk_modbus_download_modal_submit_button: 'Bevestig',
         projects_bulk_export_modbus_historic_data_file_name:
           'Modbus Historische Data',
         projects_bulk_modbus_download_title:
           'Download Modbus Historische Data',
         projects_bulk_bulk_modbus_download_form_date_range: 'Datumbereik:',
         projects_bulk_bulk_modbus_download_form_data_export_type:
           'Datum Export Type',
         projects_bulk_bulk_modbus_download_form_data_export_type_combined:
           'Gecombineerd',
         projects_bulk_bulk_modbus_download_form_data_export_type_daily:
           'Dagelijks',
         projects_bulk_bulk_modbus_download_form_data_export_type_normal:
           'Normaal',
         projects_bulk_bulk_modbus_download_form_submit_button:
           'Download Modbus Data',
         projects_bulk_bulk_modbus_download_form_cancel_button: 'Annuleer',
         projects_bulk_modbus_download_form_success:
           'Modbus Historische Data is succesvol gemaild naar ##EMAIL##!',
         projects_bulk_modbus_download_form_api_error: 'Interne Server Error',
         projects_bulk_modbus_download_no_projects_error: '',
         projects_bulk_modbus_download_form_no_projects_error:
           'Selecteer projecten om door te gaan.',
         projects_bulk_bulk_modbus_download_modal_title: 'Weet je het zeker?',
         projects_bulk_modbus_download_modal_subtitle:
           'Wil je een groepsdownload uitvoeren voor Historische Modbus data?',
         projects_bulk_modbus_download_modal_cancel_button: 'Annuleer',
         projects_bulk_modbus_download_modal_submit_button: 'Bevestig',
         projects_bulk_modbus_selected_projects: '`Geselecteerde Projecten',
         device_single_status_modbus_date_export_label: 'Datum',
         device_single_download_modbus_data: 'Modbus Export',
         device_monitoring_maxitel_status_heading: 'Network Status',
         device_monitoring_maxitel_status_online: 'Online',
         device_monitoring_maxitel_status_offline: 'Offline',
         device_monitoring_maxitel_operational_status: 'Operationeel',
         device_monitoring_maxitel_non_operational_status: 'Niet Operationeel',
         device_monitoring_maxitel_mobile_on_cloud_network_green:
           'MaxiTEL MobileOnCloud - Netwerk Paars',
         device_monitoring_maxitel_mobile_on_cloud_network_purple:
           'MaxiTEL MobileOnCloud - Netwerk Groen',
         device_monitoring_maxitel_mobile_data_network_purple:
           'Mobiele data - Network Paars',
         device_monitoring_maxitel_mobile_m2m_blend: 'M2M Blend',
         device_monitoring_maxitel_other_api: 'API',
         device_monitoring_maxitel_other_portal: 'Portaal',
         device_monitoring_maxitel_updated_at: 'Geupdatet',
         admins_last_seen: 'Laatst gezien',
         device_monitoring_send_grid_email_reached: 'Email Limiet Bereikt',
         sidebar_email_reporting: 'Email Rapport',
         email_reporting_title: 'Email Rapportage',
         email_reporting_tab_all: 'Alle',
         email_reporting_tab_passed: 'Geslaagd',
         email_reporting_tab_failed: 'Mislukt',
         email_reporting_table_email_forwarded_at: 'Verzenddatum',
         email_reporting_table_to: 'Naar',
         email_reporting_table_recipient_name: 'Naam Ontvanger',
         email_reporting_table_subject: 'Onderwerp',
         email_reporting_table_status: 'Status',
         email_reporting_table_no_record_found: 'Geen Records Gevonden',
         email_reporting_single_output_tab: 'Email Inhoud',
         email_reporting_single_second_bread_crumb: 'Email Detail',
         email_reporting_single_email_logs_title: 'Email Logs',
         email_reporting_table_resend_failed_email_success_message:
           'Emails zijn opnieuw verstuurd.',
         email_reporting_table_resend_failed_email_error_message:
           'Selecteer tenminste een record om de email opnieuw te versturen.',
         email_reporting_table_resend_failed_email_button:
           'Verstuur Email Opnieuw',
         email_reporting_table_filter_search: 'Zoeken',
         email_reporting_table_row_per_page: 'Rijen Per Pagina',
         settings_menu_modbus_global_configuration_title:
           'Modbus Algemene Configuratie',
         settings_menu_modbus_global_configuration_title_form_header:
           'Modbus Algemene Configuratie',
         settings_menu_modbus_global_configuration_TR03_tab:
           'TR03 Instellingen',
         settings_menu_modbus_global_configuration_TR04_tab:
           'TR04 Instellingen',
         settings_menu_modbus_global_configuration_form_success:
           'Modbus Algemene Configuraties zijn succesvol opgeslagen',
         settings_menu_modbus_global_configuration_form_error:
           'Opslaan van Modbus Algemene Configuratie mislukt',
         settings_menu_modbus_global_configuration_form_save_button:
           'Opslaan Modbus Instellingen',
         device_single_unprovisioned_detailed_message:
           'Device is in "Ongeregistreerd" modus',
         commissioning_form_stepper_validation_modbus_loading_title:
           'Modbus validatie duurt tot 1 - 3 minuten',
         settings_menu_modbus_global_configuration_title_form_header_add_new:
           'Voeg Nieuwe Toe',
         settings_menu_modbus_global_configuration_title_add_form_header:
           'Voeg Artikelnummer toe',
         settings_menu_modbus_global_configuration_article_number_field:
           'Artikelnummer',
         settings_menu_modbus_global_configuration_article_number_required_form_error:
           'Artikelnummer is verplicht',
         settings_menu_modbus_global_configuration_article_number_unique_form_error:
           'Artikelnummer bestaat al. Vul een uniek Artikelnummer in',
         settings_menu_modbus_global_configuration_type_field: 'Type',
         settings_menu_modbus_global_configuration_type_required_form_error:
           'Type is verplicht',
         settings_menu_modbus_global_configuration_type_default_option:
           'Selecteer een Type',
         settings_menu_modbus_global_configuration_add_success_message:
           'Artikelnummer is succesvol toegevoegd',
         settings_menu_modbus_global_configuration_cancel_button: 'Annuleer',
         settings_menu_modbus_global_configuration_save_button: 'Opslaan',
         missing_article_number_message:
           'Het Artikelnummer veld is leeg of niet geregistreerd in de Modbus Algemene Configuraties. Stel het Device in bedrijf. Als het probleem blijft aanhouden, neem contact op met de administrator.',
         // General Modbus Control / Status registers(90-99) - TR04
         general_modbus_control_TR04_80: 'Firmware Image status',
         general_modbus_control_TR04_81: 'Firmware Image ID',
         general_modbus_control_TR04_82: 'Defaults Image status',
         general_modbus_control_TR04_83: 'Defaults Image Checksum',
         general_modbus_control_TR04_96: 'Modbus Versie',
         general_modbus_control_TR04_97: 'Modbus Device type / tabel',
         general_modbus_control_TR04_98: 'Unit selectie ',
         general_modbus_control_TR04_99: 'Control register ',

         // Operating Status - TR04
         business_situation_operating_status_TR04_title:
           'Bedrijfsstatus afleverset',
         business_situation_operating_status_TR04_100: 'Verwarmingsstatus',
         business_situation_operating_status_TR04_100_list: JSON.stringify({
           list: {
             0: 'Standby',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na circulatie',
             4: 'Uit',
             5: 'Voor circulatie',
           },
         }),
         business_situation_operating_status_TR04_101: 'Tapwater status',
         business_situation_operating_status_TR04_101_list: JSON.stringify({
           list: {
             0: 'Standby',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na circulatie',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR04_102: 'Warmhoud status',
         business_situation_operating_status_TR04_102_list: JSON.stringify({
           list: {
             0: 'Standby',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na circulatie',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR04_103: 'Anti Legionella status',
         business_situation_operating_status_TR04_103_list: JSON.stringify({
           list: {
             0: 'Standby',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na circulatie',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR04_104: 'Koelingsstatus',
         business_situation_operating_status_TR04_104_list: JSON.stringify({
           list: {
             0: 'Standby',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na koeling',
             4: 'Uit',
             5: 'Voor ciculatie',
             6: 'Initialiseren',
           },
         }),

         business_situation_operating_status_TR04_105: 'Storingscode',
         business_situation_operating_status_TR04_106: 'Software versie',
         business_situation_operating_status_TR04_107:
           'StepperMotor1 Huidige Positie',
         business_situation_operating_status_TR04_108:
           'StepperMotor2 Huidige Positie',
         business_situation_operating_status_TR04_109: 'Pomp',
         business_situation_operating_status_TR04_109_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         business_situation_operating_status_TR04_110: 'Klep1',
         business_situation_operating_status_TR04_110_list: JSON.stringify({
           list: {
             0: 'Positie: Verwarming',
             1: 'Positie: Koeling',
           },
         }),
         business_situation_operating_status_TR04_112_list: JSON.stringify({
           list: {
             0: 'Verwarming of vraag',
             1: 'Koeling',
           },
         }),
         business_situation_operating_status_TR04_113: 'Kamerthermostaat input',
         business_situation_operating_status_TR04_113_list: JSON.stringify({
           list: {
             0: 'Open',
             1: 'Gesloten',
           },
         }),
         business_situation_operating_status_TR04_114: 'OpenTherm Indicator',
         business_situation_operating_status_TR04_114_list: JSON.stringify({
           list: {
             0: 'Inactief',
             1: 'Actief',
           },
         }),
         business_situation_operating_status_TR04_115: 'Toesteltype',

         // Business situation - TR04
         business_situation_measured_values_TR04_title: 'Meetwaarden',
         business_situation_measured_values_TR04_200: 'Debiet',
         business_situation_measured_values_TR04_201:
           'Tapwater Uit temperatuur',
         business_situation_measured_values_TR04_202: 'Primaire aanvoer',
         business_situation_measured_values_TR04_203: 'Primaire retour',
         business_situation_measured_values_TR04_205: 'Secundaire CV Aanvoer',
         business_situation_measured_values_TR04_206: 'Secundaire CV Retour',
         business_situation_measured_values_TR04_204: 'CV berekende setpoint',
         business_situation_measured_values_TR04_207: 'OT Kamertemperatuur',
         business_situation_measured_values_TR04_208: 'OT CV Setpoint',
         business_situation_measured_values_TR04_209: 'Buiten Temperatuur',
         business_situation_measured_values_TR04_210: 'Waterdruk',
         business_situation_measured_values_TR04_213:
           'Primaire Tapwater Retour',
         business_situation_measured_values_TR04_214: 'Primaire CV Retour',

         // Operating time - TR04
         business_situation_operating_time_TR04_title: 'Bedrijfstijden',
         business_situation_operating_time_TR04_800:
           'Totale bedrijfstijd afleverset',
         business_situation_operating_time_TR04_801:
           'Totale bedrijfstijd verwarming',
         business_situation_operating_time_TR04_802:
           'Totale bedrijfstijd koeling',
         business_situation_operating_time_TR04_803:
           'Totale bedrijfstijd tapwatervraag',
         business_situation_operating_time_TR04_804: 'Aantal tappingen',

         // Lockout Geschiedenis-TR04
         lockout_time_TR04_title: 'Lockout Geschiedenis',
         lockout_time_TR04_820: 'Lock-out 1',
         lockout_time_TR04_821: 'Lock-out 2',
         lockout_time_TR04_822: 'Lock-out 3',
         lockout_time_TR04_823: 'Lock-out 4',
         lockout_time_TR04_824: 'Lock-out 5',
         lockout_time_TR04_825: 'Lock-out 6',
         lockout_time_TR04_826: 'Lock-out 7',
         lockout_time_TR04_827: 'Lock-out 8',
         lockout_time_TR04_828: 'Lock-out 9',
         lockout_time_TR04_829: 'Lock-out 10',
         lockout_time_TR04_830: 'Lock-out 11',
         lockout_time_TR04_831: 'Lock-out 12',
         lockout_time_TR04_832: 'Lock-out 13',
         lockout_time_TR04_833: 'Lock-out 14',
         lockout_time_TR04_834: 'Lock-out 15',
         lockout_time_TR04_835: 'Lock-out 16',
         lockout_time_TR04_836: 'Lock-out history time 1',
         lockout_time_TR04_837: 'Lock-out history time 2',
         lockout_time_TR04_838: 'Lock-out history time 3',
         lockout_time_TR04_839: 'Lock-out history time 4 ',
         lockout_time_TR04_840: 'Lock-out history time 5 ',
         lockout_time_TR04_841: 'Lock-out history time 6 ',
         lockout_time_TR04_842: 'Lock-out history time 7',
         lockout_time_TR04_843: 'Lock-out history time 8',
         lockout_time_TR04_844: 'Lock-out history time 9',
         lockout_time_TR04_845: 'Lock-out history time 10',
         lockout_time_TR04_846: 'Lock-out history time 11',
         lockout_time_TR04_847: 'Lock-out history time 12',
         lockout_time_TR04_848: 'Lock-out history time 13',
         lockout_time_TR04_849: 'Lock-out history time 14',
         lockout_time_TR04_850: 'Lock-out history time 15',
         lockout_time_TR04_851: 'Lock-out history time 16',
         // Blocking Geschiedenis-TR04
         blocking_history_TR04_860: 'Blocking 1',
         blocking_history_TR04_861: 'Blocking 2',
         blocking_history_TR04_862: 'Blocking 3',
         blocking_history_TR04_863: 'Blocking 4',
         blocking_history_TR04_864: 'Blocking 5',
         blocking_history_TR04_865: 'Blocking 6',
         blocking_history_TR04_866: 'Blocking 7',
         blocking_history_TR04_867: 'Blocking 8',
         blocking_history_TR04_868: 'Blocking 9',
         blocking_history_TR04_869: 'Blocking 10',
         blocking_history_TR04_870: 'Blocking 11',
         blocking_history_TR04_871: 'Blocking 12',
         blocking_history_TR04_872: 'Blocking 13',
         blocking_history_TR04_873: 'Blocking 14',
         blocking_history_TR04_874: 'Blocking 15',
         blocking_history_TR04_875: 'Blocking 16',
         blocking_history_TR04_876: 'Blocking history time 1',
         blocking_history_TR04_877: 'Blocking history time 2',
         blocking_history_TR04_878: 'Blocking history time 3',
         blocking_history_TR04_879: 'Blocking history time 4',
         blocking_history_TR04_880: 'Blocking history time 5',
         blocking_history_TR04_881: 'Blocking history time 6',
         blocking_history_TR04_882: 'Blocking history time 7',
         blocking_history_TR04_883: 'Blocking history time 8',
         blocking_history_TR04_884: 'Blocking history time 9',
         blocking_history_TR04_885: 'Blocking history time 10',
         blocking_history_TR04_886: 'Blocking history time 11',
         blocking_history_TR04_887: 'Blocking history time 12',
         blocking_history_TR04_888: 'Blocking history time 13',
         blocking_history_TR04_889: 'Blocking history time 14',
         blocking_history_TR04_890: 'Blocking history time 15',
         blocking_history_TR04_891: 'Blocking history time 16',
         // Warmtapwater - TR04
         settings_dhw_warm_tap_water_TR04_title: 'Instellingen tapwater',
         settings_dhw_warm_tap_water_TR04_300: 'Tapwater setpoint',
         settings_dhw_warm_tap_water_TR04_301: 'Warmhoudmodus',
         settings_dhw_warm_tap_water_TR04_301_list: JSON.stringify({
           list: { 0: 'Eco', 1: 'Antievries', 2: 'Comfort', 3: 'Dynamisch' },
         }),
         settings_dhw_warm_tap_water_TR04_302:
           'Warmhoudsetpoint (comfort stand)',
         settings_dhw_warm_tap_water_TR04_303: 'Tapdrempel',
         settings_dhw_warm_tap_water_TR04_304: 'Tapwatermodus',
         settings_dhw_warm_tap_water_TR04_304_list: JSON.stringify({
           list: {
             0: 'Uit',
             8: 'PID Gecontroleerd',
           },
         }),
         settings_dhw_warm_tap_water_TR04_305:
           'Tapwater Primaire Boost Exit Tijd',
         settings_dhw_warm_tap_water_TR04_306:
           'Tapwater Mode8 Dynamisch Setpoint Actief',
         settings_dhw_warm_tap_water_TR04_306_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_dhw_warm_tap_water_TR04_307: 'Tapwater Huidige Setpoint',

         // Thermal disinfection - TR04
         settings_thermal_disinfection_TR04_title:
           'Instellingen Thermische disinfectie',
         settings_thermal_disinfection_TR04_400: 'Aleg ingeschakeld',
         settings_thermal_disinfection_TR04_400_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_thermal_disinfection_TR04_401: 'Aleg bedrijfstijd',
         settings_thermal_disinfection_TR04_402: 'Aleg bedrijfstijd',
         settings_thermal_disinfection_TR04_403: 'Aleg wachttijd',

         // Heating - TR04
         heating_and_cooling_settings_heating_TR04_title:
           'Instellingen verwarming',
         heating_and_cooling_settings_heating_TR04_500:
           'Maximale setpoint verwarming',
         heating_and_cooling_settings_heating_TR04_501:
           'Minimale setpoint verwarming',
         heating_and_cooling_settings_heating_TR04_502: 'OutdoorResetMin',
         heating_and_cooling_settings_heating_TR04_503: 'OutdoorResetMax',
         heating_and_cooling_settings_heating_TR04_504: 'WarmWeatherShut',
         heating_and_cooling_settings_heating_TR04_506:
           'Maximum primaire retour',
         heating_and_cooling_settings_heating_TR04_507:
           'Compensatiefactor van de primaire retour',
         heating_and_cooling_settings_heating_TR04_508:
           'Delta primaire-secundaire setpoint',
         heating_and_cooling_settings_heating_TR04_509:
           'Minimum primaire-secundaire delta',
         heating_and_cooling_settings_heating_TR04_510:
           'Maximaal beveiliging verwarmen',
         heating_and_cooling_settings_heating_TR04_514: 'Setpoint verwarming',
         heating_and_cooling_settings_heating_TR04_515:
           'Verwarming inschakelen',
         heating_and_cooling_settings_heating_TR04_515_list: JSON.stringify({
           list: {
             0: 'PID',
             8: 'Open/Gesloten',
             9: 'Primaire Delta-T',
             10: 'Secundaire Delta-T',
           },
         }),

         // Cooling - TR04
         heating_and_cooling_settings_cooling_TR04_title:
           'Instellingen koeling',
         heating_and_cooling_settings_cooling_TR04_600: 'Setpoint koeling',
         heating_and_cooling_settings_cooling_TR04_601:
           'Maximale primaire retour',
         heating_and_cooling_settings_cooling_TR04_602:
           'Compensatiefactor van de primaire retour',
         heating_and_cooling_settings_cooling_TR04_605:
           'Maximaalbeveiliging koeling',
         heating_and_cooling_settings_cooling_TR04_606:
           'Minimale setpoint koeling',
         heating_and_cooling_settings_cooling_TR04_607:
           'Maximale setpoint koeling',
         heating_and_cooling_settings_cooling_TR04_608:
           'Condensatie bescherming',
         heating_and_cooling_settings_cooling_TR04_608_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         heating_and_cooling_settings_cooling_TR04_609: 'Condensatie timer',
         heating_and_cooling_settings_cooling_TR04_610:
           'Condensatie verhoogd setpoint',
         heating_and_cooling_settings_cooling_TR04_611: 'Koeling inschakelen',
         heating_and_cooling_settings_cooling_TR04_611_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),

         // Secundaire General - TR04
         heating_and_cooling_settings_secondary_general_TR04_title:
           'Algemene Instellingen',
         heating_and_cooling_settings_secondary_general_TR04_512:
           'Minimale druk CV',
         heating_and_cooling_settings_secondary_general_TR04_513:
           'Maximale druk CV',
         heating_and_cooling_settings_secondary_general_TR04_603:
           'Wachttijd omschakelen verwarmen-koelen',
         heating_and_cooling_settings_secondary_general_TR04_604:
           '6-weg kogelkraan wachttijd',

         // Manual Operation - TR04
         settings_manual_operation_TR04_title: 'Handbediening',
         settings_manual_operation_TR04_700: 'Handbediening inschakelen',
         settings_manual_operation_TR04_700_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_manual_operation_TR04_701:
           'Handbediening tapwater regelafsluiter (M1)',
         settings_manual_operation_TR04_702:
           'Handbediening verwarming/koeling regelafsluiter (M2)',
         settings_manual_operation_TR04_703: 'Handbediening pomp (CV)',
         settings_manual_operation_TR04_703_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_manual_operation_TR04_704:
           'Handbediening positie 6-weg kogelafsluiter',
         settings_manual_operation_TR04_704_list: JSON.stringify({
           list: {
             0: 'Verwarming',
             1: 'Koeling',
           },
         }),
         settings_manual_operation_TR04_707:
           'Handbediening tapwater regelafsluiter (M1)[%]',
         settings_manual_operation_TR04_708:
           'Handbediening verwarming/koeling regelafsluiter (M2)[%]',
         settings_manual_operation_TR04_709: 'Handbediening uitstellen',
         // DeltaP Parameters(LIN-PUMP)
         deltap_parameters_TR04_1870_1871: 'Klant installatie druk',
         deltap_parameters_TR04_1872_1873: 'DeltaP KV-Waarde',
         deltap_parameters_TR04_1874_1875: 'DeltaP Max Druk',
         device_status_name_TR03: 'Naam (TR03)',
         device_status_name_TR04: 'Naam (TR04)',
         device_single_set_register_device_tr_type_unavailable:
           'Device type is niet beschikbaar voor het gehele project',
         sidebar_terms_and_conditions_title: 'Voorwaarden',
         sidebar_support_text: 'Vragen of opmerkingen? Email',
         sidebar_support_text_copied: 'Email gekopieerd!',
         settings_menu_legal_document_title: 'Juridische documenten',
         legal_documents_name_en_required_form_error:
           'Document Naam (EN) is verplicht',
         legal_documents_name_nl_required_form_error:
           'Document Naam (NL) is verplicht',
         legal_documents_name_fr_required_form_error:
           'Document Naam (FR) is verplicht',
         legal_documents_name_de_required_form_error:
           'Document Naam (DE) is verplicht',
         legal_documents_name_dk_required_form_error:
           'Document Naam (DK) is verplicht',
         legal_documents_title_en_required_form_error:
           'Document Titel (EN) is verplicht',
         legal_documents_title_nl_required_form_error:
           'Document Titel (NL) is verplicht',
         legal_documents_title_fr_required_form_error:
           'Document Titel (FR) is verplicht',
         legal_documents_title_de_required_form_error:
           'Document Titel (DE) is verplicht',
         legal_documents_title_dk_required_form_error:
           'Document Titel (DK) is verplicht',
         legal_documents_checkbox_label_en_required_form_error:
           'Document Checkbox Label (EN) is verplicht',
         legal_documents_checkbox_label_nl_required_form_error:
           'Document Checkbox Label (NL) is verplicht',
         legal_documents_checkbox_label_fr_required_form_error:
           'Document Checkbox Label (FR) is verplicht',
         legal_documents_checkbox_label_de_required_form_error:
           'Document Checkbox Label (DE) is verplicht',
         legal_documents_checkbox_label_dk_required_form_error:
           'Document Checkbox Label (DK) is verplicht',
         legal_documents_document_url_required_form_error:
           'Juridisch Document is verplicht',
         legal_documents_version_required_form_error: 'Versie is verplicht',
         legal_documents_type_required_form_error: 'Document Type is verplicht',
         legal_documents_add_form_success:
           'Juridisch Document is succesvol aangemaakt',
         legal_documents_form_api_error:
           'Aanmaken juridisch document mislukt door een Interne Server Error',
         legal_documents_form_legal_document_name_en_field:
           'Document Naam (EN)',
         legal_documents_form_legal_document_name_nl_field:
           'Document Naam (NL)',
         legal_documents_form_legal_document_name_fr_field:
           'Document Naam (FR)',
         legal_documents_form_legal_document_name_de_field:
           'Document Naam (DE)',
         legal_documents_form_legal_document_name_dk_field:
           'Document Naam (DK)',
         legal_documents_form_legal_document_title_en_field:
           'Document Titel (EN)',
         legal_documents_form_legal_document_title_nl_field:
           'Document Titel (NL)',
         legal_documents_form_legal_document_title_fr_field:
           'Document Titel (FR)',
         legal_documents_form_legal_document_title_de_field:
           'Document Titel (DE)',
         legal_documents_form_legal_document_title_dk_field:
           'Document Titel (DK)',
         legal_documents_form_legal_document_checkbox_label_en_field:
           'Document Checkbox Label (EN)',
         legal_documents_form_legal_document_checkbox_label_nl_field:
           'Document Checkbox Label (NL)',
         legal_documents_form_legal_document_checkbox_label_fr_field:
           'Document Checkbox Label (FR)',
         legal_documents_form_legal_document_checkbox_label_de_field:
           'Document Checkbox Label (DE)',
         legal_documents_form_legal_document_checkbox_label_dk_field:
           'Document Checkbox Label (DK)',
         legal_documents_form_legal_document_document_url_field:
           'Upload Juridisch Document (PDF)',
         legal_documents_form_legal_document_version_field: 'Versie',
         legal_documents_form_legal_document_type_field: 'Document Type',
         legal_documents_form_legal_is_mandatory_field: 'Is Document Verplicht',
         legal_documents_form_add_legal_document_button: 'Voeg Document Toe',
         legal_documents_form_cancel_button: 'Annuleer',
         add_legal_documents_form_header: 'Voeg Juridisch Document Toe',
         add_legal_documents_bread_crumb_first_link:
           'Voeg Juridisch Document Toe',
         legal_documents_bread_crumb_main_title: 'Juridische Documenten',
         legal_documents_edit_form_success:
           'Juridisch Document is succesvol geupdatet',
         legal_documents_form_edit_legal_document_button: 'Wijzig Document',
         edit_legal_documents_form_header: 'Wijzig Juridisch Document',
         edit_legal_documents_bread_crumb_first_link:
           'Wijzig Juridisch Document',
         loginTermsOfUseInfo:
           'Accepteer de Gebruikersvoorwaarden om door te gaan',
         legal_documents_table_document_name_en: 'Document Naam (EN)',
         legal_documents_table_document_title_en: 'Document Titel (EN)',
         legal_documents_table_document_checkbox_label_en:
           'Document Checkbox Label (EN)',
         legal_documents_table_document_document_url: 'Juridisch Document',
         legal_documents_table_document_type: 'Document Type',
         legal_documents_table_version: 'Versie',
         legal_documents_table_updated_at: 'Bijgewerkt Op',
         legal_documents_table_is_default: 'Status',
         legal_documents: 'Juridisch Documenten',
         legal_documents_add_new: 'Voeg Juridisch Document Toe',
         legal_documents_table_row_per_page: 'Rijen Per Pagina',
         legal_documents_no_record_found: 'Geen records Gevonden',
         legal_documents_filter_delete_button: 'Verwijder',
         legal_documents_status_default: 'Standaard',
         legal_documents_status_archived: 'Gearchiveerd',
         legal_documents_all: 'Alle',
         legal_documents_filter_search: 'Zoeken',
         legal_documents_default_document_success_message:
           '"LEGAL_DOC_TYPE" standaard Juridisch Document is succesvol geupdatet.',
         legal_documents_bulk_delete_button: 'Verwijder',
         legal_documents_bulk_delete_success_message:
           'Juridisch Document(s) zijn succesvol verwijderd',
         legal_documents_bulk_delete_error_message:
           'Selecteer meerdere records om te verwijderen',
         legal_documents_bulk_delete_title: 'Weet je het zeker?',
         legal_documents_bulk_delete_subtitle: 'Wil je records verwijderen?',
         legal_documents_upload_success:
           'Document is succesvol geüpload, bevestig de aanpassingen.',
         legal_documents_upload_file_type_error:
           'Alleen PDF bestanden zijn toegestaan.',
         legal_documents_upload_file_size_error:
           'Bestand moet kleiner zijn dan 20 MB.',
         legal_documents_uploading_text: 'Uploaden...',
         legal_documents_reset_legal_documents_status_modal_title:
           'Weet je het zeker?',
         legal_documents_reset_legal_documents_status_modal_subtitle:
           'Gebruikers moeten het document nogmaals accepteren. Weet je zeker dat je wilt doorgaan?',
         legal_documents_reset_legal_documents_status_cancel_button: 'Annuleer',
         legal_documents_reset_legal_documents_status_update_without_acceptance_request_button:
           'Update zonder acceptatieverzoek',
         legal_documents_reset_legal_documents_status_update_with_acceptance_request_button:
           'Update met acceptatieverzoek',
         legal_documents_table_already_default_document_message:
           'Dit document is al ingesteld als een standaard "LEGAL_DOC_TYPE" document',
         cookie_banner_main_title: 'Toestemming beheren',
         cookie_banner_main_subtitle:
           'Deze website maakt gebruik van cookies om je gebruikerservaring te verbeteren. Door onze website te gebruiken, stem je in met alle cookies in overeenstemming met ons cookiebeleid.',
         cookie_banner_essential_cookie_title: 'Essentiële Cookies',
         cookie_banner_essential_cookie_status_title: 'Altijd Actief',
         cookie_banner_essential_cookie_subtitle:
           'Essentiële cookies zijn cruciaal voor de basisfuncties van een website, zoals ingelogd blijven, het onthouden van instellingen, en het waarborgen van de beveiliging. Ze zijn noodzakelijk om de site goed te laten functioneren en kunnen niet worden uitgeschakeld.',
         cookie_banner_tracking_cookie_title: 'Tracking Cookies',
         cookie_banner_tracking_cookie_subtitle:
           'Tracking cookies die door Google Analytics worden gebruikt, verzamelen gegevens over je interactie met de website, zoals bezochte paginas en bestede tijd. Dit helpt ons het gebruikersgedrag te begrijpen en de prestaties van de website te verbeteren. Deze cookies zijn optioneel en kunnen worden uitgeschakeld.',
         cookie_banner_accept_button_title: 'Accepteren',
         cookie_banner_decline_button_title: 'Afwijzen',
         cookie_banner_preferences_button_title: 'Voorkeuren',
         cookie_banner_save_preferences_button_title: 'Opslaan',
         settings_cookie_consent_title: 'Toestemming voor cookies beheren',
         settings_cookie_tracking_cookies_title: 'Tracking Cookies',
         settings_cookie_tracking_cookies_success_message:
           'Tracking Cookies Status is succesvol bijgewerkt',
         settings_menu_partner_setting_title: 'Portaal Instellingen',
         partner_settings_form_header: 'Portaal Instellingen',
         partner_settings_form_logo_title: 'Logo',
         partner_settings_form_primary_color_title: 'Primaire Kleur',
         partner_settings_form_secondary_color_title: 'Secundaire Kleur',
         partner_settings_form_submit_button: 'Wijzigingen opslaan',
         partner_settings_form_white_label_success_message:
           'White-label is succesvol bijgewerkt.',
         devices_bulk_edit_registers_tr_device_type_unavailable:
           'geselecteerde devices hebben geen correcte TR type. Voer de inbedrijfstelling van de devices uit',
         commissioning_form_stepper_validation_sim_connectivity_title:
           'Verbinding',
         commissioning_form_stepper_validation_sim_connectivity_default_setting:
           'Actief',
         commissioning_form_stepper_validation_sim_connectivity_success:
           'Verbinding actief',
         commissioning_form_stepper_validation_sim_connectivity_error:
           'Verbindingsprobleem',
         commissioning_form_stepper_validation_sim_connectivity_loading_title:
           'Verbindingscontrole duurt ongeveer 1 minuut',
         device_single_device_settings_fetch_latest_settings_button_v2:
           'Vernieuw Data',
         kong_account_created_subject:
           'Fortes Data Management - Nieuwe API Gateway Account Aangemaakt',
         kong_admin_user_form_create_success_message:
           'Kong Admin User succesvol aangemaakt!',
         kong_client_user_form_create_success_message:
           'Kong Client User succesvol aangemaakt!',
         kong_admin_user_form_update_success_message:
           'Kong Admin User succesvol vernieuwd!',
         kong_client_user_form_update_success_message:
           'Kong Client User succesvol vernieuwd!',
         kong_admin_user_form_error_message: 'Interne Serverfout',
         kong_client_user_form_error_message: 'Interne Serverfout',
         kong_admin_user_form_unique_error_message:
           'E-mail moet uniek zijn in Kong',
         kong_client_user_form_unique_error_message:
           'E-mail moet uniek zijn in Kong',
         kong_user_admin_single_api_documentation_link_label:
           'OpenAPI Specificatie',
         kong_client_bulk_deleted_message:
           '{{ USERS_COUNT }} Kong Klanten zijn succesvol verwijderd',
         devices_form_project_disabled_field:
           'Project kan niet ingevuld worden, omdat dit Device een service Device is',
         partner_settings_white_label: 'White label',
         partner_settings_general_settings: 'Algemene instelling',
         partner_settings_general_settings_partner_locales: 'Beschikbare talen',
         partner_settings_form_general_settings_success_message:
           'Algemene instellingen zijn succesvol bijgewerkt.',
         commissioning_form_article_number_is_missing_error:
           'Artikelnummer ontbreekt',
         commissioning_form_stepper_validation_sim_connectivity_check_started_title:
           'Verificatie Verbinding gestart',
         commissioning_form_stepper_validation_sim_connectivity_check_success_title:
           'Verbinding Succesvol',
         commissioning_form_stepper_validation_project_modbus_settings_check_started_title:
           'Verificatie Project Modbus Instelling gestart',
         commissioning_form_stepper_validation_project_modbus_settings_success_title:
           'Project Modbus Instelling is ingeschakeld',
         commissioning_form_stepper_validation_project_modbus_settings_disabled_title:
           'Geen Modbus toegewezen in Project',
         commissioning_form_stepper_validation_sim_connectivity_missing_sim_number_error_event:
           'Verbindingsprobleem - SIM Nummer ontbreekt in de database',
         commissioning_form_stepper_validation_sim_connectivity_maxitel_error_event:
           'Verbindingsprobleem - Network Provider API reageert niet',
         commissioning_form_stepper_validation_firmware_is_less_then_2_title:
           'Device firmware versie, d.w.z. ###DEVICE_FIRMWARE_VERSIE###, moet 2.x.x zijn. Update de firmware naar de laatste versie',
         commissioning_form_stepper_validation_firmware_is_missing_title:
           'Device firmware versie ontbreekt',
         commissioning_form_stepper_validation_baud_rate_fetch_failed_error_event:
           'Modbus - Kan Baud Rate niet ophalen. Verwachte Baud Rate was ###BAUD_RATE###',
         commissioning_form_stepper_validation_baud_rate_set_failed_error_event:
           'Modbus - Kan Baud Rate niet instellen ###BAUD_RATE###',
         commissioning_form_stepper_validation_modbus_connection_error_event:
           'Modbus - Niet Verbonden',
         commissioning_form_stepper_validation_modbus_connection_success_event:
           'Modbus - Verbonden',
         commissioning_form_stepper_validation_mbus_meters_check_success_event:
           'Mbus - Aantal aangesloten Mbus meters, d.w.z. ###AANTAL_MBUS_METERS_AANGESL### komt overeen met aantal aangesloten Project Instellingen, d.w.z. ###PROJECT_SETTINGS_MBUS_METERS_COUNT###',
         commissioning_form_stepper_validation_mbus_meters_check_error_event:
           'Mbus - Aantal aangesloten Mbus Meters , d.w.z. ###AANTAL_MBUS_METERS_AANGESL### komt niet overeen met aantal aangesloten meters Project Instellingen, d.w.z. ###PROJECT_SETTINGS_MBUS_METERS_COUNT###',
         commissioning_form_stepper_validation_complete_success_event:
           'Validatieproces succesvol afgerond',
         device_bulk_insert_form_header: 'Device Toegevoegd',
         devices_form_order_type_field: 'Order Type',
         partner_settings_general_settings_distributor_article_number_label:
           'Afleverset Instellingen',
         partner_settings_general_settings_distributor_article_number_enable_settings_label:
           'Artikel- en serienummer van distributeur inschakelen',
         partner_settings_general_settings_distributor_article_number_disable_settings_label:
           'Afleverset Instellingen van distributeur uitschakelen',
         devices_partner_serial_number:
           'Afleverset serienummer van distributeur',
         devices_partner_article_number_field:
           'Afleverset artikelnummer van distributeur',
         commissioning_form_stepper_validation_baud_rate_retries_label:
           'Baud Rate Pogingen',
         commissioning_form_stepper_validation_modbus_retries_label:
           'Modbus Verbindingspogingen',
         commissioning_form_header_updated_information_title:
           'Bijgewerkte Informatie',
         commissioning_form_header_report_title: 'Verslag',
         commissioning_form_header_report_connectivity:
           'Verbinding Step Status: ',
         commissioning_form_header_report_modbus_validation_title:
           'Modbus Validatie: ',
         commissioning_form_header_report_mbus_validation_title:
           'Mbus Validatie: ',
         commissioning_form_header_report_failed_status_title: 'Mislukt',
         commissioning_form_header_report_passed_status_title: 'Geslaagd',
         commissioning_form_header_report_not_status_title: 'Niet beschikbaar',
         commissioning_form_stepper_validation_sim_connectivity_maxitel_success_event:
           'Network Provider API werkt prima',
         project_single_project_error_devices_tab:
           'Geschiedenis Project-Storingen',
         device_monitoring_project_active_device_errors_counters_title:
           'Aantal devices met actieve storingen',
         device_monitoring_project_active_device_errors_list_title:
           'Aantal devices met actieve storingen',
         device_v2_settings_tab_general: 'Algemeen',
         device_v2_settings_tab_modbus: 'Modbus',
         device_v2_settings_tab_mbus: 'Mbus',
         device_single_device_settings_hiu_reset_device_v2:
           'Afleverset Herstel',
         device_single_device_settings_hiu_reset_message_forwarded:
           'Afleverset Modbus Reset-verzoek is verstuurd naar Device',
         device_single_device_settings_interval_field: 'Interval',
         device_single_device_settings_interval_in_seconds_field:
           'Interval (Minuten)',
         device_single_device_settings_mbus_interval_message_forwarded:
           'Set Mbus Interval bericht is verstuurd naar Device',
         device_single_device_settings_mbus_interval_error_message_forwarded:
           'Mbus Interval moet 0 of hoger zijn',
         device_single_device_settings_mbus_interval: 'Mbus Interval',
         email_device_swapping_title:
           'Fortes Data Management - Device Uitwisseling',
         email_device_swapping_subject:
           'Fortes Data Management - Device swapping',
         email_device_swapping_recipient_name: 'Fortes Data Management',
         mbus_export_is_validated_field: 'Is Gevalideerd',
         device_single_device_settings_fqdn_field: 'Device FQDN',
         device_single_device_settings_fqdn_field_default_option:
           'Selecteer FQDN',
         device_single_device_settings_fqdn_error:
           'Selecteer een FQDN om verder te gaan',
         device_single_device_settings_fqdn_message_forwarded:
           'FQDN bericht is verstuurd naar het Device',
         projects_table_online_devices: 'Online Devices',
         add_subscriptions_form_header: 'Verleng Abonnement',
         subscriptions_bread_crumb_main_title: 'Abonnementen',
         add_subscriptions_bread_crumb_first_link: 'Verlenging Abonnement',
         subscriptionOrderNumber_required_form_error:
           'Abonnement ordernummer is verplicht',
         subscriptionPeriod_required_form_error:
           'Abonnementsperiode is verplicht',
         projectId_required_form_error: 'Project is verplicht',
         clientId_required_form_error: 'Klant is verplicht',
         subscriptionType_required_form_error: 'Abonnementstype is verplicht',
         subscription_form_subscriptionOrderNumber_field:
           'Ordernummer abonnement ',
         subscription_form_subscriptionPeriod_field:
           'Periode Abonnement (Maanden)',
         subscription_form_projectId_field: 'Project',
         subscription_form_clientId_field: 'Klant',
         subscription_form_subscriptionType_field: 'Type Abonnement',
         subscription_form_subscriptionStartDate_field: 'Startdatum Abonnement',
         subscription_form_subscriptionEndDate_field:
           'Verwachte Einddatum Abonnement',
         subscription_form_description_field: 'Beschrijving',
         subscription_form_notes_field: 'Notities',
         subscription_form_add_subscription_button: 'Wijzigingen Opslaan',
         subscription_form_cancel_button: 'Annuleer',
         subscription_add_form_success: 'Abonnement is succesvol aangemaakt',
         subscription_form_api_error:
           'Poging om Abonnement aan te maken niet gelukt vanwege een Interne Server Storing',
         subscriptions_form_header: 'Abonnementen',
         subscriptions_add_new: 'Maak Verlenging Abonnement aan',
         subscriptions_table_project: 'Project',
         subscriptions_table_client: 'Klant',
         subscriptions_table_start_date: 'Startdatum',
         subscriptions_table_end_date: 'Einddatum',
         subscriptions_table_subscriptionPeriod: 'Duur',
         subscriptions_table_total_device: 'Totale Aantal Devices',
         subscriptions_table_total_credit: 'Totaal Credits',
         subscriptions_table_credit_used: 'Gebruikte Credits',
         subscriptions_table_credit_left: 'Resterende Credits',
         subscriptions_table_month_to_zero_credit: 'Maanden tot Nul Credit',
         subscriptions_table_status: 'Status',
         subscriptions_table_loading: 'Laden...',
         subscriptions_no_record_found: 'Geen Records Gevonden',
         subscriptions_all: 'Alle',
         subscriptions_active: 'Actief',
         subscriptions_inactive: 'Inactief',
         subscriptions_expired: 'Verlopen',
         subscriptions_expiring_soon: 'Verloopt Binnenkort',
         subscriptions_table_row_per_page: 'Rijen Per Pagina',
         subscriptions_filter_search: 'Zoeken',
         subscriptions_filters: 'Filters',
         sidebar_subscriptions_overview: 'Overzicht Abonnementen',
         sidebar_subscriptions: 'Abonnementen',
         project_single_project_subscription_devices_tab: 'Device Abonnementen',
         project_single_project_subscription_overview_tab:
           'Verlenging Abonnementen',
         sidebar_subscriptions_analytics: 'Analyse Abonnementen',
         subscriptions_analytics_form_header: 'Analyse Abonnement',
         devices_form_order_type_order_with_hiu: 'Order met afleverset',
         devices_form_order_type_order_without_hiu: 'Order zonder afleverset',
         subscription_status_overview_title: 'Abonnement Status Overzicht',
         subscription_analytics_data_missing_message:
           'Data is niet beschikbaar',
         subscription_analytics_data_loading_message:
           'Data wordt opgehaald ...',
         subscription_status_overview_subscriptions: 'Abonnementen',
         subscription_status_overview_expiring_soon: 'Verloopt binnenkort',
         subscription_status_overview_not_expiring_in_3_months:
           'Verloopt niet over 3 maanden',
         subscription_status_overview_expired: 'Verlopen',
         subscription_analytics_subscriptions_expiring_soon_title:
           'Abonnementen Verlopen Binnenkort',
         subscription_analytics_subscriptions_expiring_soon_2_weeks: '2 Weken',
         subscription_analytics_subscriptions_expiring_soon_1_month: '1 maand',
         subscription_analytics_subscriptions_expiring_soon_2_months:
           '2 maanden',
         subscription_analytics_subscriptions_expiring_soon_3_months:
           '3 maanden',
         subscription_analytics_subscriptions_expiring_soon_6_months:
           '6 maanden',
         subscription_analytics_subscriptions_expiring_soon_12_months:
           '12 maanden',
         subscription_analytics_subscriptions_expiring_soon_no_of_subscriptions:
           'Aantal Abonnementen',
         subscription_analytics_projects_credits_timeline_title:
           'Projecten Credits Tijdslijn',
         subscription_analytics_projects_credits_timeline_no_of_credits:
           'Aantal Credits',
         subscription_analytics_projects_credits_timeline_months: 'Maanden',
         subscription_analytics_projects_credits_timeline_jan: 'Jan',
         subscription_analytics_projects_credits_timeline_feb: 'Feb',
         subscription_analytics_projects_credits_timeline_mar: 'Mar',
         subscription_analytics_projects_credits_timeline_apr: 'Apr',
         subscription_analytics_projects_credits_timeline_may: 'Mei',
         subscription_analytics_projects_credits_timeline_jun: 'Jun',
         subscription_analytics_projects_credits_timeline_jul: 'Jul',
         subscription_analytics_projects_credits_timeline_aug: 'Aug',
         subscription_analytics_projects_credits_timeline_sep: 'Sep',
         subscription_analytics_projects_credits_timeline_oct: 'Okt',
         subscription_analytics_projects_credits_timeline_nov: 'Nov',
         subscription_analytics_projects_credits_timeline_dec: 'Dec',
         subscription_analytics_projects_credits_consumed: 'Verbruikte Credits',
         subscription_analytics_projects_credits_details_active_subscriptions:
           'Actieve Abonnementen',
         subscription_analytics_projects_credits_details_expiring_soon:
           'Verloopt binnenkort',
         subscription_analytics_projects_credits_details_expired_subscriptions:
           'Verlopen Abonnementen',
         subscription_analytics_projects_credits_details_expiring_in_3_months:
           'Verloopt over 3 maanden',
         subscription_analytics_projects_credits_details_expiring_in_6_months:
           'Verloopt over 6 maanden',
         partner_settings_general_settings_partner_timezone:
           'Standaard Tijdzone',
         distributor_timezone_modal_update_distributor_and_external_api_timezone_only:
           'Alleen distributeur en externe API tijdzone bijwerken',
         distributor_timezone_modal_update_distributor_and_all_users_timezone:
           'Update Distributeur en timezone van alle gebruikers',
         distributor_timezone_modal_cancel: 'Annuleer',
         distributor_timezone_modal_title: 'Weet je het zeker?',
         distributor_timezone_modal_subtitle:
           'Wil je de tijdzone voor de distributeurmodule bijwerken?',
         distributor_timezone_modal_success_message:
           'Tijdzone is succesvol bijgewerkt',
         distributor_timezone_modal_error_message: 'Update tijdzone mislukt',
         commissioning_email_commissioned_at_field: 'In bedrijf gesteld op',
         commissioning_email_commissioned_by_field: 'In bedrijf gesteld door',
         commissioning_email_commissioned_status_field:
           'Inbedrijfstelling status',
         commissioning_email_commissioned_completed_hiu_details_field:
           'Voltooide afleverset details',
         commissioning_email_mobile_connectivity_validation_field:
           'Validatie mobiele verbinding',
         commissioning_email_modbus_connectivity_validation_field:
           'Validatie Modbus verbinding',
         commissioning_email_mbus_connectivity_validation_field:
           'Validatie Mbus verbinding',
         commissioning_email_updated_address_details_field:
           'Bijgewerkte adresdetails',
         commissioning_email_title: 'Inbedrijfstellingsrapport Device',
         commissioning_email_commissioned_by_message:
           'heeft het inbedrijfstellingsproces afgerond.',
         commissioning_email_subtitle:
           'Hieronder vind je het inbedrijfstellingrapport van het onlangs in bedrijf gestelde apparaat in je project.',
         commissioning_email_information:
           '{{ NaamDistributeur }} genereert dit rapport nadat het inbedrijfstellingsproces van het apparaat is voltooid. Hierdoor kan jij of je organisatie tijdig actie ondernemen om eventuele problemen met de aansluiting van de afleversets op te lossen en een correcte inbedrijfstelling te garanderen.',
         commissioning_email_questions:
           'Vragen? Neem contact op met <a href="mailto:support@fortesdata.com"><span style="color: {{ primaryColor }};">support@fortesdata.com</span></a> voor verdere ondersteuning.',
         commissioning_none_added_status: 'Niets toegevoegd',
         commissioning_partially_updated_status: 'Deels bijgewerkt',
         commissioning_completed_status: 'Voltooid',
         commissioning_hiu_failed: 'Mislukt',
         commissioning_hiu_passed: 'Gelukt',
         subscription_credits_overview_title: 'Overzicht Credits',
         subscription_credits_positive_credits: 'Positief',
         subscription_credits_negative_credits: 'Negatief',
         partner_settings_general_settings_partner_locale: 'Standaardtaal',
         distributor_locale_modal_update_distributor_locale_only:
           'Vernieuw alleen de standaardtaal',
         distributor_locale_modal_update_distributor_and_all_users_locale:
           'Vernieuw Distributeur en talen van alle gebruikers',
         distributor_locale_modal_cancel: 'Annuleer',
         distributor_locale_modal_title: 'Weet je het zeker?',
         distributor_locale_modal_subtitle:
           'Wil je de taal voor de distributeurmodule bijwerken?',
         distributor_locale_modal_success_message:
           'Taal is succesvol bijgewerkt',
         distributor_locale_modal_error_message: 'Taalupdate mislukt',
         users_form_timezone_field: 'Tijdzone',
         users_form_locale_field: 'Taal',
         error_check_details_table_new_tab: 'Nieuwe',
         error_check_details_table_resolved_tab: 'Opgelost',
         error_check_details_table_active_tab: 'Actief',
         error_check_details_table_error_status: 'Status Storing',
         error_check_details_table_error: 'Storing Code',
         error_check_details_table_error_at: 'Storing bij',
         partner_settings_general_settings_distributor_serial_number_and_article_number_settings_label:
           'Distributeur Serie- en Artikelmodule',
         kong_user_admin_single_client_label: 'Klant',
         commissioning_updated_information_title: 'Bijgewerkte Informatie',
         subscription_form_customerReference_field: 'Referentie klant',
         subscriptionCustomerReference_required_form_error:
           'Referentie klant is verplicht',
         subscription_previous_credits_left: 'Resterende Credits ',
         subscription_new_credits_added: 'Nieuwe Credits Toegevoegd',
         subscription_actual_credits_available: 'Beschikbare Credits',
         subscription_start_date: 'Startdatum',
         subscription_expected_expiry_date: 'Verwachte Vervaldatum',
         subscription_created_at: 'Aangemaakt Op',
         subscription_created_by: 'Gemaakt Door',
         subscription_notes_detail_for_users:
           '(Alleen voor Fortes Intern gebruik): opmerkingen m.b.t. sales, follow-ups en andere relevante informatie kunnen hier worden gedocumenteerd.',
         subscription_extension_overview: 'Overzicht Abonnementsverlenging',
         view_subscriptions_form_header: 'Bekijk Abonnement',
         current_subscription_state: 'Huidige abonnementsstatus',
         subscription_analytics_projects_credits_details_expiring_in_1_month:
           'Verloopt over 1 maand',
         business_situation_measured_values_TR04_211:
           'Gefilterde Buitentemperatuur',
         business_situation_measured_values_TR04_212:
           'Secundair Binnenkomend Koud Water',
         business_situation_measured_values_TR04_215:
           'T-Primaire Aanvoer Tapwater',
         business_situation_measured_values_TR04_216: 'Primaire CV Aanvoer',
         appliance_types_applicable: 'Van toepassing',
         appliance_types_device_dashboard: 'Dashboard Device',
         appliance_types_register_name: 'Registreer Naam',
         firmware_registers_TR04_80: 'Firmware Image status',
         firmware_registers_TR04_80_list: JSON.stringify({
           list: {
             0: 'Gedeeltelijke Afbeelding (Inkomende Afbeelding)',
             1: 'Geldige Afbeelding (Klaar voor programmeren)',
             2: 'Corrupte Afbeelding',
             3: 'Afbeelding Onbekend',
             4: 'Afbeelding Onbekend',
             5: 'Afbeelding Onbekend',
             6: 'Afbeelding Onbekend',
             7: 'Afbeelding Onbekend',
             8: 'Afbeelding Onbekend',
             9: 'Afbeelding Onbekend',
             10: 'Afbeelding Onbekend',
           },
         }),
         firmware_registers_TR04_81_list: JSON.stringify({
           list: {
             0: 'Firmware/applicatie afbeelding',
             1: 'Defaults Afbeelding',
             2: 'Afbeelding Onbekend',
             3: 'Afbeelding Onbekend',
             4: 'Afbeelding Onbekend',
             5: 'Afbeelding Onbekend',
             6: 'Afbeelding Onbekend',
             7: 'Afbeelding Onbekend',
             8: 'Afbeelding Onbekend',
             9: 'Afbeelding Onbekend',
             10: 'Afbeelding Onbekend',
           },
         }),
         firmware_registers_TR04_82_list: JSON.stringify({
           list: {
             0: 'Gedeeltelijke Afbeelding (Inkomende Afbeelding)',
             1: 'Geldige Afbeelding (Klaar voor programmeren)',
             2: 'Corrupte Afbeelding',
             3: 'Afbeelding Onbekend',
             4: 'Afbeelding Onbekend',
             5: 'Afbeelding Onbekend',
             6: 'Afbeelding Onbekend',
             7: 'Afbeelding Onbekend',
             8: 'Afbeelding Onbekend',
             9: 'Afbeelding Onbekend',
             10: 'Afbeelding Onbekend',
           },
         }),
         firmware_registers_TR04_98_list: JSON.stringify({
           list: {
             0: '°C bar',
             1: '°F bar',
             2: '°C psi',
             3: '°F psi',
           },
         }),
         firmware_registers_TR04_81: 'Firmware Image ID',
         firmware_registers_TR04_82: 'Default Image status',
         firmware_registers_TR04_98: 'Selectie eenheid (C/F)',
         firmware_registers_TR04_title: 'Firmware Registers',
         lin_pump_delta_parameters_TR04_title: 'LIN-Pomp DeltaP Parameters',
         lin_pump_delta_parameters_TR04_1871: 'DeltaP Customer Installation',
         lin_pump_delta_parameters_TR04_1873: 'DeltaP Kv Waarde',
         lin_pump_delta_parameters_TR04_1875: 'NV DeltaP Max',
         appliance_types_form_cancel_button: 'Annuleer',
         appliance_types_form_submit_button: 'Wijzigingen opslaan',
         custom_popover_211_short_description: 'Gefilterde Buitentemperatuur',
         custom_popover_212_short_description: 'T-secundair Aanvoer Tapwater',
         custom_popover_215_short_description: 'T-Primaire Aanvoer Tapwater',
         custom_popover_216_short_description: 'Primaire CV Aanvoer',
         custom_popover_80_short_description: 'Firmware Image status',
         custom_popover_81_short_description: 'Firmware Image ID',
         custom_popover_82_short_description: 'Defaults Image status',
         custom_popover_98_short_description: 'Selectie eenheid (C/F)',
         custom_popover_1871_short_description: 'DeltaP Klantinstallatie',
         custom_popover_1873_short_description: 'DeltaP Kv Waarde',
         custom_popover_1875_short_description: 'NV DeltaP Max',
         edit_devices_device_name_unique_error: 'Naam Device moet uniek zijn',
         edit_devices_serial_number_unique_error:
           'Serienummer Mercurius moet uniek zijn',
         edit_devices_description_error: 'Type afleverset is verplicht',
         edit_devices_box_serial_number_unique_error:
           'Serienummer afleverset moet uniek zijn',
         edit_devices_article_number_error: 'Artikelnummer is verplicht',
         edit_devices_distributor_article_number_error:
           'Artikelnummer Distributeur is verplicht',
         edit_devices_distributor_serial_number_unique_error:
           'Distributeur serienummer van afleverset moet uniek zijn',
         edit_devices_prod_order_nr_week_error:
           'Productie ordernummer is verplicht',
         edit_devices_sim_card_number_error: 'Simkaartnummer verplicht',
         edit_devices_production_date_error: 'Productiedatum verplicht',
         custom_popover_211_long_description: 'Gefilterde Buitentemperatuur',
         custom_popover_212_long_description: 'T-secundair Aanvoer Tapwater',
         custom_popover_215_long_description: 'T-Primaire Aanvoer Tapwater',
         custom_popover_216_long_description: 'Primaire CV Aanvoer',
         custom_popover_80_long_description: 'Firmware Image status',
         custom_popover_81_long_description: 'Firmware Image ID',
         custom_popover_82_long_description: 'Defaults Image status',
         custom_popover_98_long_description: 'Selectie eenheid (C/F)',
         custom_popover_1871_long_description: 'DeltaP Klantinstallatie',
         custom_popover_1873_long_description: 'DeltaP Kv Waarde',
         custom_popover_1875_long_description: 'NV DeltaP Max',
         devices_form_backend_error_message:
           'Er zijn enkele problemen met de invulvelden. Herzie het formulier en corrigeer om verder te gaan.',
         devices_sim_card_number_length_and_type_form_error:
           'Het Simkaartnummer moet numeriek zijn en exact 19 cijfers bevatten',
         devices_sim_card_number_required_form_error:
           'Simkaartnummer is verplicht',
         devices_name_type_form_error:
           'Naam van Device mag alleen hoofdletters en cijfers bevatten.',
         devices_name_min_length_form_error:
           'De naam van het Device moet mimimaal 5 tekens bevatten.',
         devices_name_max_length_form_error:
           'De naam van het Device mag maximaal 6 tekens bevatten.',
         devices_serial_number_type_form_error:
           'Het Mercurius serienummer mag alleen letters en cijfers bevatten.',
         devices_serial_number_min_length_form_error:
           'Het Mercurius serienummer moet exact 24 tekens lang zijn.',
         devices_serial_number_max_length_form_error:
           'Het Mercurius serienummer moet exact 24 tekens lang zijn.',
         devices_article_number_min_length_form_error:
           'Artikelnummer moet tussen de 10 en 13 tekens bevatten.',
         devices_article_number_max_length_form_error:
           'Artikelnummer moet tussen de 10 en 13 tekens bevatten.',
         device_single_device_error_reporting_tab: 'Storingsrapportage',
         error_reporting_alarm_type_field: 'Type Storing',
         error_reporting_description_field: 'Beschrijving',
         error_reporting_resolution_field: 'Oplossing',
         error_reporting_error_start_date_field: 'Startdatum',
         error_reporting_error_end_date_field: 'Einddatum',
         error_reporting_error_counters_title: 'Storingen tot nu toe',
         error_reporting_error_counters_error: 'Geen data gevonden',
         error_reporting_error_counters_no_graph_data_error:
           'De cirkeldiagram is niet beschikbaar',
         error_reporting_error_counters_loading_data: 'Laden ...',
         errorReporting_TR04_255_errorName: 'Geen storing',
         errorReporting_TR04_255_errorDescription: 'Geen storing',
         errorReporting_TR04_255_errorResolution: 'Geen',

         errorReporting_TR04_0_errorName: 'E2PROM_LEES_STORING',
         errorReporting_TR04_0_errorDescription: 'Interne software storing',
         errorReporting_TR04_0_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_8_errorName: 'RAM_STORING',
         errorReporting_TR04_8_errorDescription: 'Interne software storing',
         errorReporting_TR04_8_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_10_errorName: 'E2PROM_STORING',
         errorReporting_TR04_10_errorDescription:
           'Verkeerde veiligheidsparameters in E2prom',
         errorReporting_TR04_10_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_11_errorName: 'STATUS_STORING',
         errorReporting_TR04_11_errorDescription: 'Interne software storing',
         errorReporting_TR04_11_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_12_errorName: 'ROM_STORING',
         errorReporting_TR04_12_errorDescription: 'Interne software storing',
         errorReporting_TR04_12_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_15_errorName: 'MAX_TEMP_STORING',
         errorReporting_TR04_15_errorDescription:
           'De externe oververhittingsbeveiliging is ingeschakeld of de secundaire aanvoertemp sensor meet een temp hoger dan max setpoint',
         errorReporting_TR04_15_errorResolution:
           'Los de storing op en druk op de resetknop',

         errorReporting_TR04_17_errorName: 'STACK_STORING',
         errorReporting_TR04_17_errorDescription: 'Interne software storing',
         errorReporting_TR04_17_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_18_errorName: 'INSTRUCTIE_STORING',
         errorReporting_TR04_18_errorDescription: 'Interne software storing',
         errorReporting_TR04_18_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_23_errorName: 'BESCHADIGING_STORING_NR',
         errorReporting_TR04_23_errorDescription:
           'Storingscode RAM byte beschadigd door onbekende storingscode',
         errorReporting_TR04_23_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_29_errorName: 'PSM_STORING',
         errorReporting_TR04_29_errorDescription: 'Interne software storing',
         errorReporting_TR04_29_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_30_errorName: 'REGISTER_STORING',
         errorReporting_TR04_30_errorDescription: 'Interne software storing',
         errorReporting_TR04_30_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_37_errorName: 'ADC_STORING',
         errorReporting_TR04_37_errorDescription: 'Interne hardware storing',
         errorReporting_TR04_37_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_39_errorName: 'E2PROM_VERSIE_CHECK_STORING',
         errorReporting_TR04_39_errorDescription: 'Interne software storing',
         errorReporting_TR04_39_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_106_errorName: 'REFHI_TE_HOOG',
         errorReporting_TR04_106_errorDescription: 'Interne hardware storing',
         errorReporting_TR04_106_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_107_errorName: 'REFHI_TE_LAAG',
         errorReporting_TR04_107_errorDescription: 'Interne hardware storing',
         errorReporting_TR04_107_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_108_errorName: 'REFLO_TE_HOOG',
         errorReporting_TR04_108_errorDescription: 'Interne hardware storing',
         errorReporting_TR04_108_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_109_errorName: 'REFLO_TE_LAAG',
         errorReporting_TR04_109_errorDescription: 'Interne hardware storing',
         errorReporting_TR04_109_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_114_errorName: 'E2PROM_VERSIE_STORING',
         errorReporting_TR04_114_errorDescription: 'Interne software storing',
         errorReporting_TR04_114_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_116_errorName: 'LAGE_WATER_DRUK_STORING',
         errorReporting_TR04_116_errorDescription:
           'Lage waterdruk in het CV systeem',
         errorReporting_TR04_116_errorResolution:
           'Vul water aan tot juiste druk in CV systeem',

         errorReporting_TR04_119_errorName: 'TEMP_PRIMAIR_RETOUR_CV_OPEN',
         errorReporting_TR04_119_errorDescription:
           'Temperatuursensor primaire retour verwarming open',
         errorReporting_TR04_119_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR04_120_errorName: 'T_PRIMAIR_AANVOER_OPEN',
         errorReporting_TR04_120_errorDescription:
           'Temperatuursensor primaire aanvoer open',
         errorReporting_TR04_120_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR04_122_errorName: 'T_SWW_UIT_OPEN',
         errorReporting_TR04_122_errorDescription:
           'Temperatuursensor tapwater uitvoer open',
         errorReporting_TR04_122_errorResolution:
           'Controleer en/of vervang sensor tapwater en/of kabel',

         errorReporting_TR04_126_errorName: 'T_PRIMAIR_RETOUR_CV_KORTSL',
         errorReporting_TR04_126_errorDescription:
           'Kortsluiting in temperatuursensor primaire CV retour',
         errorReporting_TR04_126_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR04_127_errorName: 'T_PRIMAIR_AANVOER_KORTSL',
         errorReporting_TR04_127_errorDescription:
           'Kortsluiting in temperatuursensor primaire aanvoer',
         errorReporting_TR04_127_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR04_129_errorName: 'T_SWW_UIT_KORTSL',
         errorReporting_TR04_129_errorDescription:
           'Kortsluiting in temperatuursensor tapwater uitvoer',
         errorReporting_TR04_129_errorResolution:
           'Controleer en/of vervang sensor tapwater en/of kabel',

         errorReporting_TR04_134_errorName: 'RESET_KNOP_STORING',
         errorReporting_TR04_134_errorDescription:
           'Te veel resets vlak achter elkaar',
         errorReporting_TR04_134_errorResolution:
           'Onderbreek de stroomtoevoer van de afleverset voor ongeveer 10 seconden',

         errorReporting_TR04_158_errorName: 'T_SECONDAIR_AANV_CV_OPEN',
         errorReporting_TR04_158_errorDescription:
           'Temperatuursensor secundaire aanvoer CV open',
         errorReporting_TR04_158_errorResolution:
           'Controleer en/of vervang sensor aanvoer en/of kabel',

         errorReporting_TR04_159_errorName: 'SEC_RETOUR_CV_TEMP_OPEN',
         errorReporting_TR04_159_errorDescription:
           'Sensor secundaire retour CV open',
         errorReporting_TR04_159_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR04_160_errorName: 'SECONDAIRE_AANV_CV_TEMP_KORTSL',
         errorReporting_TR04_160_errorDescription:
           'Kortsluiting in secundaire aanvoer CV sensor',
         errorReporting_TR04_160_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR04_161_errorName: 'SEC_RETOUR_CV_TEMP_KORTSL',
         errorReporting_TR04_161_errorDescription:
           'Kortsluiting in secundaire retour CV sensor',
         errorReporting_TR04_161_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR04_171_errorName: 'ADC_ONSTABIEL',
         errorReporting_TR04_171_errorDescription:
           'ADC meting detecteert te veel onstabiele metingen',
         errorReporting_TR04_171_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_174_errorName: 'PRIMAIRE_RETOUR_SWW_TEMP_OPEN',
         errorReporting_TR04_174_errorDescription:
           'Primaire Tapwater retour sensor open',
         errorReporting_TR04_174_errorResolution:
           'Controleer en/of vervang sensor tapwater en/of kabel',

         errorReporting_TR04_175_errorName: 'PRIMAIRE_RETOUR_SWW_TEMP_KORTSL',
         errorReporting_TR04_175_errorDescription:
           'Kortsluiting in primaire Tapwater retour sensor',
         errorReporting_TR04_175_errorResolution:
           'Controleer en/of vervang sensor tapwater en/of kabel',

         errorReporting_TR04_203_errorName: 'HOGE_DRUK',
         errorReporting_TR04_203_errorDescription:
           'Hoge waterdruk in CV systeem gedetecteerd',
         errorReporting_TR04_203_errorResolution:
           'Verlaag de waterdruk in het CV systeem tot onder de 3 bar',

         errorReporting_TR04_206_errorName: 'TE_BOOSTER_WAARSCHUWING',
         errorReporting_TR04_206_errorDescription:
           'Water in TE Booster gedurende 24u niet op 75C gekomen of thermische beveiliging geactiveerd',
         errorReporting_TR04_206_errorResolution:
           'Power Cycle TE Booster, druk op resetknop regelaar, power cycle TE booster. Check rode knop aan onderkant TE booster. Druk knop in indien deze uitsteekt',

         errorReporting_TR04_207_errorName: 'POWERPACK_DEFECT_WAARSCHUWING',
         errorReporting_TR04_207_errorDescription: 'PowerPack is defect',
         errorReporting_TR04_207_errorResolution: 'Vervang regelaar',

         errorReporting_TR04_208_errorName: 'STORINGSLOG_OPSLAG_FOUT',
         errorReporting_TR04_208_errorDescription:
           'Interne software waarschuwing',
         errorReporting_TR04_208_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_209_errorName: 'DATALOG_OPSLAG_FOUT',
         errorReporting_TR04_209_errorDescription:
           'Interne software waarschuwing',
         errorReporting_TR04_209_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_210_errorName: 'FIRMWARE_OPSLAG_FOUT',
         errorReporting_TR04_210_errorDescription:
           'Interne software waarschuwing',
         errorReporting_TR04_210_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_211_errorName: 'STANDAARDINSTELLINGEN_OPSLAG_FOUT',
         errorReporting_TR04_211_errorDescription:
           'Interne software waarschuwing',
         errorReporting_TR04_211_errorResolution:
           'Druk op de resetknop. Als storing aanhoudt, vervang dan de regelaar',

         errorReporting_TR04_212_errorName: 'GEEN_PRIMAIR_DEBIET',
         errorReporting_TR04_212_errorDescription:
           'Geen primair debiet beschikbaar MCV-1',
         errorReporting_TR04_212_errorResolution:
           'Check en/of vervang primaire leiding, kogelkranen en/of MCV-1',

         errorReporting_TR04_213_errorName: 'LAGE_PRIMAIR_DEBIET',
         errorReporting_TR04_213_errorDescription:
           'Geringe primair debiet beschikbaar MCV-1',
         errorReporting_TR04_213_errorResolution:
           'Check en/of vervang primaire leiding, filter en/of MVC-1',

         errorReporting_TR04_214_errorName: 'ONTERECHTE_PRIMAIRE_DEBIET_MCV1',
         errorReporting_TR04_214_errorDescription:
           'Onterechte primair debiet waarschuwing MCV-1',
         errorReporting_TR04_214_errorResolution:
           'Check sluiting MCV-1, vervuiling en/pf te hoge Delta-P',

         errorReporting_TR04_215_errorName: 'ONTERECHTE_PRIMAIR_DEBIET_MCV1_2',
         errorReporting_TR04_215_errorDescription:
           'Onterechte primair debiet waarschuwing MCV1 en MCV2',
         errorReporting_TR04_215_errorResolution:
           'Check sluiting MCV-1 en MCV22, vervuiling en/of te hoge Delta-P',

         errorReporting_TR04_216_errorName: 'TEMP_ALEG_TE_LAAG',
         errorReporting_TR04_216_errorDescription:
           'Retour temperatuur te laag tijdens thermische desinfectie',
         errorReporting_TR04_216_errorResolution:
           'Check primaire retourtemperatuur',

         errorReporting_TR04_217_errorName: 'TEMP_TAPW_TE_HOOG',
         errorReporting_TR04_217_errorDescription:
           'Tapwater temp is te hoog ( > 70ºC voor > 5 s.)',
         errorReporting_TR04_217_errorResolution:
           'Check primaire aanvoertemperatuur tapwater',

         errorReporting_TR04_218_errorName: 'EVALUATION_TIMER_EXPIRED',
         errorReporting_TR04_218_errorDescription:
           'Evaluation Timer has expired',
         errorReporting_TR04_218_errorResolution: 'N.v.t.',

         errorReporting_TR04_219_errorName: 'WATER_LEKKAGE',
         errorReporting_TR04_219_errorDescription: 'Waterlekkage gedetecteerd',
         errorReporting_TR04_219_errorResolution: 'Los waterlekkage op',

         errorReporting_TR03_255_errorName: 'GEEN_STORING',
         errorReporting_TR03_255_errorDescription: 'Geen storing',
         errorReporting_TR03_255_errorResolution: 'None',

         errorReporting_TR03_0_errorName: 'E2PROM_LEES_STORING',
         errorReporting_TR03_0_errorDescription: 'Interne software storing',
         errorReporting_TR03_0_errorResolution:
           'Druk op de resetknop. Vervang regelaar als het probleem aanhoudt',

         errorReporting_TR03_4_errorName: 'STORING_TE_LANG',
         errorReporting_TR03_4_errorDescription:
           'Regelaar staat langer dan 20 uur in een blokkerende storing',
         errorReporting_TR03_4_errorResolution:
           'Los onderliggende storing op en druk op resetknop',

         errorReporting_TR03_8_errorName: 'RAM_STORING',
         errorReporting_TR03_8_errorDescription: 'Interne software storing',
         errorReporting_TR03_8_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_9_errorName: 'VERKEERDE_EEPROM_SLEUTEL',
         errorReporting_TR03_9_errorDescription:
           'Inhoud van E2prom is niet up to date',
         errorReporting_TR03_9_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_10_errorName: 'E2PROM_STORING',
         errorReporting_TR03_10_errorDescription:
           'Verkeerde veiligheidsparameters in E2prom',
         errorReporting_TR03_10_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_11_errorName: 'STATUS_STORING',
         errorReporting_TR03_11_errorDescription: 'Interne software storing',
         errorReporting_TR03_11_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_12_errorName: 'ROM_STORING',
         errorReporting_TR03_12_errorDescription: 'Interne software storing',
         errorReporting_TR03_12_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_15_errorName: 'MAX_TEMP_STORING',
         errorReporting_TR03_15_errorDescription:
           'De externe oververhittingsbeveiliging is ingeschakeld of de secundaire aanvoertemp sensor meet een temp hoger dan max setpoint',
         errorReporting_TR03_15_errorResolution:
           'Los de storing op en druk op de reset knop',

         errorReporting_TR03_17_errorName: 'STACK_STORING',
         errorReporting_TR03_17_errorDescription: 'Interne software storing',
         errorReporting_TR03_17_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_18_errorName: 'INSTRUCTIE_STORING',
         errorReporting_TR03_18_errorDescription: 'Interne software storing',
         errorReporting_TR03_18_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_23_errorName: 'BESCHADIGING_STORING_NR',
         errorReporting_TR03_23_errorDescription:
           'Foutmelding RAM byte is beschadigd door een onbekende foutmelding',
         errorReporting_TR03_23_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_29_errorName: 'PSM_STORING',
         errorReporting_TR03_29_errorDescription: 'Interne software storing',
         errorReporting_TR03_29_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_30_errorName: 'REGISTER_STORING',
         errorReporting_TR03_30_errorDescription: 'Interne software storing',
         errorReporting_TR03_30_errorResolution:
           'Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_36_errorName: 'KOELING_TE_HOOG',
         errorReporting_TR03_36_errorDescription: 'Koeltemperatuur te hoog',
         errorReporting_TR03_36_errorResolution:
           'Verlaag de koeltemperatuur. Druk op reset knop. Vervang de regelaar als de storing aanhoudt',

         errorReporting_TR03_106_errorName: 'REFHI_TE_HOOG',
         errorReporting_TR03_106_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_106_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_107_errorName: 'REFHI_TE_LAAG',
         errorReporting_TR03_107_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_107_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_108_errorName: 'REFLO_TE_HOOG',
         errorReporting_TR03_108_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_108_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_109_errorName: 'REFLO_TE_LAAG',
         errorReporting_TR03_109_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_109_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_110_errorName: 'REFHI2_TE_HOOG',
         errorReporting_TR03_110_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_110_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_111_errorName: 'REFHI2_TE_LAAG',
         errorReporting_TR03_111_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_111_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_112_errorName: 'REFLO2_TE_HOOG',
         errorReporting_TR03_112_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_112_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_113_errorName: 'REFLO2_TE_LAAG',
         errorReporting_TR03_113_errorDescription: 'Interne hardware storing',
         errorReporting_TR03_113_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_115_errorName: 'LAGE_WATERDRUK_STORING',
         errorReporting_TR03_115_errorDescription: 'Lage waterdruk storing',
         errorReporting_TR03_115_errorResolution:
           'Vul CV circuit bij met water tot gewenste druk',

         errorReporting_TR03_116_errorName: 'LAGE_WATERDRUK_SENSOR',
         errorReporting_TR03_116_errorDescription: 'Lage waterdruk',
         errorReporting_TR03_116_errorResolution:
           'Vul CV circuit bij met water tot gewenste druk',

         errorReporting_TR03_119_errorName: 'T_RETOUR_OPEN',
         errorReporting_TR03_119_errorDescription:
           'Temperatuursensor primaire retour open',
         errorReporting_TR03_119_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR03_120_errorName: 'T_AANVOER_OPEN',
         errorReporting_TR03_120_errorDescription:
           'Temperatuursensor primaire aanvoer open',
         errorReporting_TR03_120_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR03_121_errorName: 'T_SEC_AANVOER_OPEN',
         errorReporting_TR03_121_errorDescription:
           'Temperatuursensor secundaire aanvoer open',
         errorReporting_TR03_121_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR03_122_errorName: 'T_TAPWATER_OPEN',
         errorReporting_TR03_122_errorDescription:
           'Temperatuursensor tapwater open',
         errorReporting_TR03_122_errorResolution:
           'Controleer en/of vervang Tapwater sensor en/of kabel',

         errorReporting_TR03_126_errorName: 'T_RETOUR_KORTSL',
         errorReporting_TR03_126_errorDescription:
           'Kortsluiting in temperatuursensor primaire retour',
         errorReporting_TR03_126_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR03_127_errorName: 'T_AANVOER_KORTSL',
         errorReporting_TR03_127_errorDescription:
           'Kortsluiting in temperatuursensor primaire aanvoer',
         errorReporting_TR03_127_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR03_128_errorName: 'T_SEC_AANVOER_KORTSL',
         errorReporting_TR03_128_errorDescription:
           'Kortsluiting in temperatuursensor secundaire aanvoer',
         errorReporting_TR03_128_errorResolution:
           'Controleer en/of vervang aanvoer sensor en/of kabel',

         errorReporting_TR03_129_errorName: 'T_TAPWATER_KORTSL',
         errorReporting_TR03_129_errorDescription:
           'Kortsluiting in temperatuursensor tapwater',
         errorReporting_TR03_129_errorResolution:
           'Controleer en/of vervang tapwater sensor en/of kabel',

         errorReporting_TR03_134_errorName: 'RESETKNOP_STORING',
         errorReporting_TR03_134_errorDescription:
           'Te veel resets achter elkaar',
         errorReporting_TR03_134_errorResolution:
           'Onderbreek de stroomtoevoer van de afleverset gedurende 10 seconden',

         errorReporting_TR03_167_errorName: 'T_SEC_RETOUR_KORTSL',
         errorReporting_TR03_167_errorDescription:
           'Kortsluiting in temperatuursensor secundaire retour',
         errorReporting_TR03_167_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR03_168_errorName: 'T_SEC_RETOUR_OPEN',
         errorReporting_TR03_168_errorDescription:
           'Temperatuursensor in secundaire retour open',
         errorReporting_TR03_168_errorResolution:
           'Controleer en/of vervang retour sensor en/of kabel',

         errorReporting_TR03_169_errorName: 'WARMTE_KOELING_ACTIEF',
         errorReporting_TR03_169_errorDescription:
           'Gelijktijdige vraag naar koeling en verwarming',
         errorReporting_TR03_169_errorResolution:
           'Controleer thermostaat/regelaar en/of kabels om gelijktijdige vraag naar koeling en verwarming te voorkomen',

         errorReporting_TR03_170_errorName: 'HW_IDENTIFICATIE_STORING ',
         errorReporting_TR03_170_errorDescription:
           'Niet-overeenkomende hardware ID detectie',
         errorReporting_TR03_170_errorResolution: 'Vervang regelaar',

         errorReporting_TR03_203_errorName: 'HOGE_DRUK',
         errorReporting_TR03_203_errorDescription:
           'Hoge waterdruk in CV systeem gedetecteerd',
         errorReporting_TR03_203_errorResolution:
           'Verlaag de waterdruk in het CV systeem tot onder de 3 bar',

         errorReporting_TR03_204_errorName: 'KORTSL_BUITENTEMP_SENSOR',
         errorReporting_TR03_204_errorDescription:
           'Kortsluiting in buitentemperatuur sensor',
         errorReporting_TR03_204_errorResolution:
           'Controleer en/of vervang sensor en/of kabel',

         errorReporting_TR03_206_errorName: 'TE_BOOSTER_WAARSCHUWING',
         errorReporting_TR03_206_errorDescription:
           'TE booster setpoint niet binnen de gestelde timer behaald',
         errorReporting_TR03_206_errorResolution:
           'Controleer e/o vervang TE booster (onderdelen)',
         errorReporting_errorType_lockout: 'Vergrendeling',
         errorReporting_errorType_blocking: 'Blokkering',
         errorReporting_errorType_warning: 'Waarschuwing',
         device_single_dashboard_active_error_title: 'Actieve Storing',
         devices_box_serial_number_start_with_F_error:
           'Serienummer afleverset moet beginnen met een F',
         devices_form_default_controller_type_field: 'Standaard Regelaar Type',
         devices_form_default_soft_version_field: 'Standaard Software Versie',
         devices_form_default_appliance_type_field: 'Standaard Toestel Type',
         devices_form_default_client_name_field: 'Standaard Klant Naam',
         projects_form_total_amount_order_field:
           'Aantal afleversets op de Productie Bestelling',
         error_check_details_table_device_status_field: 'Device Status',
         error_check_details_table_export_generated_at_field: 'Generated At',
         error_check_details_table_export_generated_for_field: 'Generated for',
         error_check_details_table_export_generated_by_user_field:
           'Generated By',
         error_check_details_table_export_report_name_field: 'Name',
         error_check_details_table_export_active_alarms_report_field:
           'Active Alarms Report',
         error_check_details_table_export_active_new_alarms_report_field:
           'New Active Alarms Report',
         error_check_details_table_export_resolved_alarms_report_field:
           'Resolved Alarms Report',
         error_check_details_table_export_active_alarms_sheet_report_field:
           'Active Alarms',
         error_check_details_table_export_active_new_alarms_sheet_report_field:
           'New Active Alarms',
         error_check_details_table_export_resolved_alarms_sheet_report_field:
           'Resolved Alarms',
         projects_form_main_client_contact_field: 'Main Client Contact',
         projects_form_main_service_client_contact_field:
           'Main Service Client Contact',
         errorReporting_TR03_IN_ERROR_MODE_BUT_SHOULD_NOT_BE_VISIBLE_TO_USER_errorName:
           'Alarm',
         errorReporting_TR03_IN_ERROR_MODE_BUT_SHOULD_NOT_BE_VISIBLE_TO_USER_errorDescription:
           'Device is in Alarm Mode',
         errorReporting_TR03_IN_ERROR_MODE_BUT_SHOULD_NOT_BE_VISIBLE_TO_USER_errorResolution:
           'Contact Support Department',
         errorReporting_TR04_IN_ERROR_MODE_BUT_SHOULD_NOT_BE_VISIBLE_TO_USER_errorName:
           'Alarm',
         errorReporting_TR04_IN_ERROR_MODE_BUT_SHOULD_NOT_BE_VISIBLE_TO_USER_errorDescription:
           'Device is in Alarm Mode',
         errorReporting_TR04_IN_ERROR_MODE_BUT_SHOULD_NOT_BE_VISIBLE_TO_USER_errorResolution:
           'Contact Support Department',
         error_check_details_table_error_name: 'Alarm Name',
         devices_sim_card_number_form_unique_error:
           'Sim card number must be unique',
         roles_overview_bread_crumb_first_link:
           'Role Permissions Overview (Active Roles)',
         roles_overview_form_header: 'Role Permissions Overview (Active Roles)',
         roles_overview_fortes_roles_tab: 'Fortes Roles',
         roles_overview_client_roles_tab: 'Client roles',
         roles_overview_service_client_roles_tab: 'Service client roles',
         roles_overview_filter_roles_button: 'Filter Roles',
         roles_form_permissions_loading:
           'Loading data... This may take between 10 to 20 seconds. Thank you for your patience.',
         roles_overview_success_message:
           'Role permissions for active roles have been successfully updated.',
         roles_overview_error_message:
           'Failed to update role permissions for active roles. Please contact the administrator.',
         roles_overview_success_no_permission_change_message:
           'No permission changes detected. Navigating back to the roles overview page.',
         roles_overview_export_button: 'Export',
         roles_overview_export_file_name: 'Roles Overview',
         roles_list_table_roles_overview_button: 'Roles Overview',
         roles_overview_update_button: 'Update Roles',
         devices_form_first_event_timestamp_field: 'Eerste Event Datum',
         devices_list_diagnostics_condition_modal_sensor_type_field_label:
           'Sensor Type',
         devices_list_diagnostics_condition_modal_controller_type_field_label:
           'Controller Type',
         subscription_form_unique_error_message:
           'Subscription Order Number already exist',
         email_common_questions: `Questions? Please contact <a style="color: ${primaryColor};" href="mailto:${process.env.REACT_APP_SUPPORT_EMAIL}"><span>${process.env.REACT_APP_SUPPORT_EMAIL}</span></a> for further support.`,
         email_common_footer_receiving:
           'You are receiving this email because you have an active account on the ##distributorName## portal and have chosen to receive notifications via email.',
         email_common_footer_unsubscribe: `Do not wish to receive these emails? Turn off email notifications <a style="color: ${primaryColor};" href="${process.env.REACT_APP_BASE_URL}/admin/setting">here</a>.`,

         email_template_new_account_created_title: 'New Account Created',
         email_template_new_account_created_main_content:
           'Thank you for registering with ##distributorName##, the platform to remotely monitor and manage your Fortes Heating Interface Units and linked energy meters.',
         email_template_new_account_created_password_intro:
           'To get you started, we have created an account for you on the ##distributorName## portal. Please find your temporary password below.',
         email_template_new_account_created_temp_password:
           'Temporary Password:',
         email_template_new_account_created_login_instruction: `Please follow this <a style="font-weight: 600;color: ${primaryColor};" href="${process.env.REACT_APP_BASE_URL}"> link</a>, to <a style="font-weight: 600;color: ${primaryColor};" href="${process.env.REACT_APP_BASE_URL}"> login</a> and change your password.`,
         email_template_new_account_created_button: 'LOGIN',
         email_template_modbus_data_title: 'Modbus Data',
         email_template_modbus_data_content:
           'You have requested an export for the available (Modbus) data of the Heating Interface Unit(s). The export file is password protected to safeguard your data.<br />Please click on this link to download the zip file. Enter the password when opening the zip file.',
         email_template_modbus_data_password: 'Password:',
         email_template_modbus_data_expiry_note:
           'Note: The above link will expire after 12 hours.',
         email_template_modbus_data_button: 'DOWNLOAD ZIP FILE',
         email_template_mbus_data_title: 'Mbus Data',
         email_template_mbus_data_content:
           'You have requested an export for the available (Mbus) data of the energy meter(s).',
         email_template_mbus_data_content_1:
           'The export file is password protected to safeguard your data.',
         email_template_mbus_data_content_2:
           'Please click on this link to download the zip file. Enter the password when opening the zip file.',
         email_template_mbus_data_password: 'Password:',
         email_template_mbus_data_expiry:
           'Note: The above link will expire after 12 hours.',
         email_template_mbus_data_button: 'DOWNLOAD ZIP FILE',

         email_template_device_swapping_title: 'Device Swapping',
         email_template_device_swapping_content:
           'You have recently swapped a Mercurius Communication Module for a new unit.',
         email_template_device_swapping_content_1:
           'Please find below a summary of the swap in your project.',
         email_template_device_swapping_old_device_section: 'Old Device',
         email_template_device_swapping_old_project: 'Project',
         email_template_device_swapping_old_client: 'Client',
         email_template_device_swapping_old_device_name: 'Device name',
         email_template_device_swapping_old_swapped_at: 'Swapped at',
         email_template_device_swapping_old_swapped_by: 'Swapped by',
         email_template_device_swapping_old_serial_number_mercurius:
           'Serial number Mercurius',
         email_template_device_swapping_old_type_heat_interface_unit:
           'Type Heat Interface Unit',
         email_template_device_swapping_old_distributor_serial_number:
           'Distributor Serial # heat interface unit',
         email_template_device_swapping_old_distributor_article_number:
           'Distributor Article # heat interface unit',
         email_template_device_swapping_old_serial_number:
           'Serial number Heat Interface Unit',
         email_template_device_swapping_old_production_date: 'Production date',
         email_template_device_swapping_old_customer_reference:
           'Customer reference',
         email_template_device_swapping_old_article_number_heat_interface_unit:
           'Article number heat interface unit',
         email_template_device_swapping_old_production_order_number:
           'Production order number',
         email_template_device_swapping_old_order_number: 'Order number',
         email_template_device_swapping_new_device_section: 'New Device',

         email_template_device_swapping_new_device_name: 'Name',
         email_template_device_swapping_new_serial_number:
           'Serial number Mercurius',
         email_template_device_swapping_new_sim_card: 'Sim card number',
         email_template_device_swapping_new_firmware: 'Firmware version',
         email_template_device_swapping_new_device_type: 'Device type',

         email_template_device_swapping_completion:
           'has proceeded to complete the swapping process.',
         email_template_device_swapping_completion_info:
           '##distributorName## generates this report after completing the device swapping process. This will allow you or your organisation to take timely action to resolve any issues.',

         email_template_active_alarms_title: 'Active Alarms Report',
         email_template_active_alarms_intro:
           'Please find below a summary of the Active Alarms <sup>2</sup> for the connected Fortes Heating Interface Units in your project(s).',
         email_template_active_alarms_new_active_alarms:
           'New Active Alarms<sup>2</sup>',
         email_template_active_alarms_existing_active_alarms:
           'Existing Active Alarms',
         email_template_active_alarms_resolved_alarms:
           'Resolved Alarms<sup>3</sup>',
         email_template_active_alarms_download_report:
           'To download the full Excel report, please click on this link.',
         email_template_active_alarms_download_report_button:
           'DOWNLOAD FULL EXCEL REPORT',
         email_template_active_alarms_report_info:
           '##distributorName## generates this report every 6 hours. This will allow you or your organisation to take timely action to resolve the alarms in the heating interface units and ensure correct operation.',
         email_template_active_alarms_report_info_1:
           '1. An Active Alarm is an alarm that has occurred and is not resolved yet.',
         email_template_active_alarms_report_info_2:
           '2. A New Active Alarm is an alarm that has occurred since the last report.',
         email_template_active_alarms_report_info_3:
           '3. A Resolved Alarm is an alarm that has been resolved since the last report.',

         email_template_api_gateway_title: 'New API Gateway Account Created',
         email_template_api_gateway_main_content:
           'Thank you for registering with ##distributorName##, the platform to remotely monitor and manage your Fortes Heating Interface Units and linked energy meters.',
         email_template_api_gateway_info:
           'To get you started, we have created an account for you on the ##distributorName## Platform. Please find your API Key below.',
         email_template_api_gateway_api_key: 'API Key:',
         email_template_api_gateway_spec_link: `Please click on this <a href="#" style="font-weight: 600; color: ${primaryColor};">link</a>, to review OpenAPI Specification.`,
         email_template_api_gateway_button: 'OpenAPI Specification',

         email_template_forgot_password_title: 'Forgot Password',
         email_template_forgot_password_content:
           'We received a request to reset the password for your account.',
         email_template_forgot_password_content_1: `Please click on this <a href="#" style="font-weight: 600; color: ${primaryColor};">link</a>, to reset your password.`,
         email_template_forgot_password_reset_password_button: 'RESET PASSWORD',

         email_template_mbus_hourly_logs_missing_title:
           'Mbus hourly logs Missing report',
         email_template_mbus_hourly_logs_missing_content:
           'We have identified that certain devices have not sent mbus hourly data.',
         email_template_mbus_hourly_logs_view_report_button: 'VIEW REPORT',

         email_template_anomaly_digest_title: 'Anomaly Digest',
         email_template_anomaly_digest_content:
           'Here are the details of Anomaly Digest',
         email_template_anomaly_digest_duration:
           'Duration: <b>{{ anomalyDuration }}</b>',
         email_template_anomaly_digest_project_name:
           'Project name: {{projectName}}',
         email_template_anomaly_digest_device_serial: 'Device serial',
         email_template_anomaly_digest_anomaly_count: 'Anomaly count',
         email_template_anomaly_digest_button: 'VIEW ANOMALY REPORT',

         email_template_maxitel_m2m_blend_and_api_status_title:
           'Maxitel M2M Blend And API Status',
         email_template_maxitel_m2m_blend_and_api_status_intro:
           'Here are the details of Maxitel M2M Blend And API Status.',
         email_template_maxitel_m2m_blend_and_api_status_service_header:
           'Maxitel Service',
         email_template_maxitel_m2m_blend_and_api_status_status_header:
           'Status',
         email_template_maxitel_m2m_blend_and_api_status_api_status:
           'Non-operational',
         email_template_maxitel_m2m_blend_and_api_status_operational:
           'Operational',

         email_template_device_commissioning_report_title:
           'Device Commissioning Report',
         email_template_device_commissioning_report_intro:
           'Please find below the commissioning report of the recently commissioned device in your project.',
         email_template_device_commissioning_report_project: 'Project',
         email_template_device_commissioning_report_client: 'Client',
         email_template_device_commissioning_report_device_name: 'Device name',
         email_template_device_commissioning_report_commissioned_at:
           'Commissioned at',
         email_template_device_commissioning_report_commissioned_by:
           'Commissioned by',
         email_template_device_commissioning_report_commissioning_status:
           'Commissioning status',
         email_template_device_commissioning_report_completed_hiu_details:
           '-Completed HIU details',
         email_template_device_commissioning_report_mobile_connectivity_validation:
           '-Mobile connectivity validation',
         email_template_device_commissioning_report_modbus_connectivity_validation:
           '-Modbus connectivity validation',
         email_template_device_commissioning_report_mbus_connectivity_validation:
           '-MBus connectivity validation',
         email_template_device_commissioning_report_updated_address_details:
           '-Updated address details',
         email_template_device_commissioning_report_completion:
           'User has proceeded to complete the commissioning process.',
         email_template_device_commissioning_report_footer:
           'generates this report after completing the device commissioning process. This will allow you or your organisation to take timely action to resolve any issues with connecting the heating interface units and ensure correct commissioning.',

         email_template_subscriptions_expiring_title:
           'Project Subscriptions Expiring Soon Notification',
         email_template_subscriptions_expiring_content:
           'This is to inform you that {{ numberOfProjects }} projects are expiring soon.',
         email_template_subscriptions_expiring_content_1: `Please click on the <a style="font-weight: 600; color: ${primaryColor};" href={{ subscriptionsExpiringUrl }}>link</a> to view the details.`,
         email_template_subscriptions_expiring_soon_notification:
           'Expiring soon notification',
         email_template_subscriptions_expiring_months: 'months',
         email_template_subscriptions_expiring_button:
           'VIEW EXPIRING SOON SUBSCRIPTIONS',

         email_template_delete_device_sim_subscription_title:
           'Delete device sim subscription',
         email_template_delete_device_sim_subscription_content:
           'Please find below the device information required to manually delete the SIM subscription, as the Maxitel Delete Subscription API is currently not functioning.',

         email_template_delete_device_sim_subscription_project: 'Project',
         email_template_delete_device_sim_subscription_client: 'Client',
         email_template_delete_device_sim_subscription_device_name:
           'Device name',
         email_template_delete_device_sim_subscription_device_edited_by:
           'Device edited by',
         email_template_delete_device_sim_subscription_device_edited_at:
           'Device edited at',
         email_template_delete_device_sim_subscription_old_sim_number:
           'Old SIM number',
         email_template_greeting: 'Dear',
         swapping_email_swapped_at_field: 'Swapped At',
         swapping_email_swapped_by_field: 'Swapped By',
         projects_form_created_at_field: 'Creation Date',
         add_subscription_order_number_duplicate_error:
           'Subscription order number must be unique.',
         add_subscription_project_not_found_error:
           'The specified project does not exist.',
         add_subscription_subscription_project_credits_missing_error:
           'No devices found for the selected project.',
         add_subscription_subscription_creation_failed_error:
           'Failed to create the subscription due to an internal server error.',
         add_subscription_invalid_or_null_payload_error:
           'The provided payload is either null or invalid.',
         inconsistent_mbus_devices_reporting_details_table_header:
           'Inconsistent Mbus Devices',
         inconsistent_mbus_devices_reporting_details_bulk_export_button:
           'Export',
         inconsistent_mbus_devices_reporting_details_filter_reset_button:
           'Reset',
         inconsistent_mbus_devices_reporting_details_table_filter_search:
           'Search',
         inconsistent_mbus_devices_reporting_details_table_created_at:
           'Created At',
         inconsistent_mbus_devices_reporting_details_table_device: 'Device',
         inconsistent_mbus_devices_reporting_details_table_project: 'Project',
         inconsistent_mbus_devices_reporting_details_table_client: 'Client',
         inconsistent_mbus_devices_reporting_details_table_firmware: 'Firmware',
         inconsistent_mbus_devices_reporting_details_table_actual_rmeters_count:
           'Actual Meters Count',
         inconsistent_mbus_devices_reporting_details_table_shown_meters_count:
           'Shown Meters Count',
         inconsistent_mbus_devices_reporting_details_table_no_record_found:
           'No Record Found',
         inconsistent_mbus_devices_reporting_details_table_row_per_page:
           'Rows per page',
         error_check_details_table_export_success_message:
           'An alarm email has been sent to your inbox.',
         error_check_details_table_export_error_message:
           'Alarm email forwarding failed. Please contact support for assistance.',
       };
